import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../pagination.css';



const CustomerMaster = () => {

  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const [data, setData] = useState([])

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Total number of pages




  // const navigate = useNavigate();

  function openAdd() {

    navigate("/app/add")
  }


  const handleUpdate = (cu_id) => {
    // Navigate two steps back
    navigate(`/app/update_customer/${cu_id}`);
  };



///////////////////////////////////api call////////////////////////////////

  async function deleteOperation(id) {
    // alert(id)
    const confirmDelete  = window.confirm('Do you want delete?');
    if (confirmDelete ) {
    let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_cust/" + id, {
      method: 'DELETE'
    });
    result = await result.json();
    // console.log(result)
    if (result.message) {
      // If the response status is within the 2xx range, it means the request was successful.
      if (result.message ) {
        // alert(result.message);
        alert('Deletion confirmed. Deleting...');
        }else{
          alert('Deletion cancelled.');
        }

    } else {
      alert("Customer not found.");
    }

    getData();

  }
  }

//////////////////////////////////////////////////////////////////////

useEffect(() => {

  getData()

}, [currentPage])

  function getData() {

    async function fetchData() {
      try {
        let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/customer_list?page=${currentPage}&perPage=${itemsPerPage}`);
        const { data: fetchedData, last_page: total } = await result.json();
        // console.log("data", result);
        setData(fetchedData);
        setTotalPages(total);
      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchData();

  }


  const filteredData = data.filter(item => {
    const cuIdString = item.cu_unique_id.toString();
    const lowercaseSearch = searchValue.toLowerCase();
    const status = item.cu_lift_type.toLowerCase(); // Assuming the status field exists

    return cuIdString.includes(lowercaseSearch) ||
        item.cu_name.toLowerCase().includes(lowercaseSearch) ||
        status.includes(lowercaseSearch);
});


   // Filtering logic
  //  const filteredData = data.filter(item => {
  //   const cuIdString = item.cu_unique_id.toString();
  //   const lowercaseSearch = searchValue.toLowerCase();
  //   return cuIdString.includes(lowercaseSearch) || item.cu_name.toLowerCase().includes(lowercaseSearch);
  // });



  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Calculate the range of page numbers to display in the pagination UI
  const getPageRange = () => {
    const rangeSize = 5; // Number of page numbers to display
    const totalPagesInRange = Math.min(rangeSize, totalPages);
    let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
    let endPage = startPage + totalPagesInRange - 1;

    // Adjust endPage if it exceeds totalPages
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - totalPagesInRange + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };


  return (
    <>
      

      <div className='conatiner'>

        <div className="row ">
          <div className='col-md-10 mt-4 mt-md-0'>
            <h5>Customer Master</h5>
          </div>

          <div className='col-md-2'>

            <input class="form-control"  style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
          onChange={e => setSearchValue(e.target.value)} />

          </div>

        </div>

        <hr />

        <button onClick={openAdd} className="btn btn-primary buttonMargin">+Add</button>

        <Table className="table">
          <thead>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Job Id</th>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Firm Name</th>
              {/* <th scope="col">GST</th> */}
              {/* <th scope="col">PAN</th> */}
              {/* <th scope="col">E-Mail</th> */}
              <th scope="col">Mobile</th>
              {/* <th scope="col">Lift Bal</th> */}
              {/* <th scope="col">Amc Bal</th> */}
              {/* <th scope="col">Mod Bal</th> */}
              <th scope="col">Type of Enquiry</th>
              <th scope="col">Address</th>
               <th scope="col">Landmark</th>
              <th scope="col">Operations</th>


            </tr>
          </thead>
          <tbody>
            {
              filteredData.map((item) =>
                <tr key={item.cu_id}>
                  {/* <th scope="row">1</th> */}
                  <td>{item.cu_id}</td>
                  <td>{item.cu_unique_id}</td>
                  <td>{item.cu_date}</td>
                  <td>{item.cu_name}</td>
                  <td>{item.cu_firm}</td>
                  {/* <td>{item.cu_gst}</td> */}
                  {/* <td>{item.cu_pan}</td> */}
                  {/* <td>{item.cu_email}</td> */}
                  <td>{item.cu_mobile}</td>
                  {/* <td>{item.cu_lift_pre_bal}</td> */}
                  {/* <td>{item.cu_amc_pre_bal}</td> */}
                  {/* <td>{item.cu_mod_pre_bal}</td> */}
                  <td>{item.cu_lift_type}</td>
                  <td>{item.cu_address}</td>
                  <td>{item.cu_landmark}</td>



                  {/* <td><span onClick={()=>{deleteOperation(item.id)}} className='delete'>Delete</span></td> */}
                  {/* <td><Link to={"app/update_customer/"+item.cu_id}><span  className='update'>{<UpdateIcon/>}</span></Link><span onClick={()=>{deleteOperation(item.cu_id)}} >{<DeleteIcon style={{color:'red'}}/>}</span></td> */}
                  <td><span className='update' onClick={() => handleUpdate(item.cu_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.cu_id) }}>{<DeleteIcon style={{ color: 'red' }} />}</span></td>
                </tr>

              )

            }
          </tbody>
        </Table>



        {/* Pagination */}
        <div className='pagination'>
          {/* Show previous arrow icon */}
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
          )}

          {/* Show page numbers */}
          {getPageRange().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}

          {/* Show next arrow icon */}
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
          )}
        </div>


      </div>

    </>



  )
}

export default CustomerMaster