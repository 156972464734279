import * as Yup from "yup";

export const enquiryAddSchema = Yup.object({

customerName:  Yup.string().required("Please select customer name"),
quoDate:  Yup.string().required("Please select date"),
// mobileNo :Yup.string().required("Please enter mobile no"),
// liftType:Yup.string().required("Please select lift type"),
// liftTravel:Yup.string().required("Please enter lift travel"),
// workingStatus:Yup.string().required("Please select status type"),
preAddress:Yup.string().min(2).max(100).required("Please enter address"),
typeOfEnquiry:Yup.string().required("Please select enquiry type"),
// shaftDetails:Yup.string().required("Please enter shaft details"),

mobileNo: Yup.string()
  .matches(/^(0\d{10}|[1-9]\d{9})$/, 'Mobile number must be 10 digits')
  .required('Please enter your mobile number'),

  // mobileNo: Yup.string()
  // .matches(/^0?\d{10}$/, 'Mobile number must be 10 digits')
  // .required('Please enter your mobile number'),
 

}); 