
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import UpdateIcon from '@mui/icons-material/Update';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Newlift() {

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');

    const [data, setData] = useState([])

    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    const [statusByItemId, setStatusByItemId] = useState({});

    function openAdd() {

        navigate("/app/new_lift_add")

    }

    const handleUpdate = (li_no) => {
        // Navigate two steps back
        navigate(`/app/update_new_lift_add/${li_no}`);
    };

    // function deleteOperation() {
        function openView(item) {
            // Construct the URL with route parameters
            navigate(`/app/new_lift_view_print/${item.li_no}`, {
              state: { data: item }, // Pass the data as part of the route state
            });
          }



////////////////////// To select an Status //////////////////////////

    async function updateStatusInAPI(li_no, newStatus) {
        try {
            const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/update_new_lift_Status", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    li_no: 	li_no,
                    newStatus: newStatus,
                }),
            });
    
            if (!response.ok) {
                // Handle non-successful responses (e.g., server error)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
    
            // Handle the response from the server (e.g., show a success message)
            console.log(data.message);
    
            // Return the updated status or response data if needed
            return data.status; // You may replace 'status' with the actual property name from your API response
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error(error);
    
            // Return null or an error indicator if needed
            return null;
        }
    }
    

//////////////////////////////////Select an Status/////////////////////////////////////////////////

// Usage within your component
const handleStatusChange = async (li_no, newStatus) => {
    // Update the status in the state immediately for a smooth user experience
    setStatusByItemId((prevStatus) => ({
        ...prevStatus,
        [li_no]: newStatus,
    }));

    // Make the API call to update the status in the backend
    const updatedStatus = await updateStatusInAPI(li_no, newStatus);

    if (updatedStatus !== null) {
        // Handle the updated status if needed
    } else {
        // Handle errors, e.g., revert the state to the previous value
        setStatusByItemId((prevStatus) => ({
            ...prevStatus,
            [li_no]: prevStatus[li_no], // Revert to the previous status
        }));
    }
};


//////////////////////////////// Delete Api ///////////////////////////////

///////////////////////////////////api call////////////////////////////////

    async function deleteOperation(id) {
        // alert(id)
        const confirmDelete = window.confirm('Do you want delete?');
        if (confirmDelete) {
            let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_newlift/" + id, {
                method: 'DELETE'
            });
            result = await result.json();
            // console.log(result)
            if (result.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                if (result.message) {
                    // alert(result.message);
                    alert('Deletion confirmed. Deleting...');
                } else {
                    alert('Deletion cancelled.');
                }

            } else {
                alert("Customer not found.");
            }

            getData();

        }
    }


    /////// api call to get data //////////

    useEffect(() => {

        getData()

    }, [currentPage])


    function getData() {

        async function fetchData() {
            try {
                let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_newlift_list?page=${currentPage}&perPage=${itemsPerPage}`);
                const { data: fetchedData, last_page: total } = await result.json();
                // console.log("data", result);

                const statusData = {};
                fetchedData.forEach((item) => {
                    statusData[item.li_no] = item.li_working_status; // Replace 'li_quo_status' with the actual property name from your API response
                });

                
                setData(fetchedData);
                setStatusByItemId(statusData); 
                setTotalPages(total);
            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchData();

    }



    // Filtering logic
    // const filteredData = data.filter(item => {
    //     const cuIdString = item.li_fl_cu_unique_id.toString();
    //     const lowercaseSearch = searchValue.toLowerCase();
    //     return cuIdString.includes(lowercaseSearch) || item.li_customer_name.toLowerCase().includes(lowercaseSearch);
    // });

    const filteredData = data.filter(item => {
        const cuIdString = item.li_fl_cu_unique_id.toString();
        const lowercaseSearch = searchValue.toLowerCase();
        const status = item.li_working_status.toLowerCase(); // Assuming the status field exists
        const date = item.li_date.toLowerCase(); 
    
        return cuIdString.includes(lowercaseSearch) ||
            item.li_customer_name.toLowerCase().includes(lowercaseSearch) ||
            status.includes(lowercaseSearch) || 
            date.includes(lowercaseSearch) ;
    });
    


    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Calculate the range of page numbers to display in the pagination UI
    const getPageRange = () => {
        const rangeSize = 5; // Number of page numbers to display
        const totalPagesInRange = Math.min(rangeSize, totalPages);
        let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
        let endPage = startPage + totalPagesInRange - 1;

        // Adjust endPage if it exceeds totalPages
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - totalPagesInRange + 1, 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };




    return (
        <>


            <div className=''>

                <div className="row ">
                    <div className='col-md-10 mt-4 mt-md-0'>
                        <h5>Final Lift Quotation Details</h5>
                    </div>

                    <div className='col-md-2'>

                        <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
                            onChange={e => setSearchValue(e.target.value)} />
                    </div>

                </div>

                <hr />

                <button onClick={openAdd} className="btn btn-primary buttonMargin">+Add Lift</button>

                <Table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Sl No.</th>
                            <th scope="col">Job Id</th>
                            <th scope="col">Quo No</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Address</th>
                            <th scope="col">Lift Status</th>
                            <th scope="col">Lift Type</th>
                            <th scope="col">Quotation Amt</th>
                            <th scope="col">Action</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.map((item) =>
                                <tr key={item.li_no}>

                                    <td>{item.li_no}</td>
                                    <td>{item.li_fl_cu_unique_id}</td>
                                    <td>{item.li_quotation_no}</td>
                                    <td>{item.li_customer_name}</td>
                                    <td>{item.li_date}</td>
                                    <td>{item.li_mobile}</td>
                                    <td>{item.li_address}</td>
                                    {/* <td>{item.li_working_status}</td>
                                     */}
                                     <td><select className="form-select border border-dark form-control a1"
                                        style={{ boxShadow: 'none',width:"170px" }}
                                        aria-label="Default select example"
                                        name='liftStatus'
                                        id='liftStatus'
                                        value={statusByItemId[item.li_no] || ''} // Set the value of the select element to the state variable
                                        onChange={(e) => handleStatusChange(item.li_no, e.target.value)} // Update the state when the user changes the value
                                    >
                                        <option value="Ongoing" style={{ background: '#dddddd', color: "blue" }}>Ongoing</option>
                                        <option value="Pending" style={{ background: '#dddddd', color: "red" }}>Pending</option>
                                        <option value="Completed" style={{ background: '#dddddd', color: "green" }}>Completed</option>
                                      
                                      
                                    </select></td>
                                    <td>{item.li_lifttype}</td>
                                    <td>{item.li_amount}</td>
                                    <td>
                                        <button
                                            type='button'
                                            className='btn btn-primary mx-3'
                                            onClick={() => openView(item)} // Pass the item data to the openAdd function
                                        >
                                            View
                                        </button>
                                        </td>

                                    {/* <td><Link to={"/app/update_new_lift_add"}><span className='update'>{<EditIcon />}</span></Link><span onClick={() => { deleteOperation() }} >{<DeleteIcon style={{ color: 'red' }} />}</span></td> */}
                                    <td><span className='update' onClick={() => handleUpdate(item.li_no)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.li_fl_cu_unique_id) }}>{<DeleteIcon style={{ color: 'red' }} />}</span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                {/* Pagination */}
                <div className='pagination'>
                    {/* Show previous arrow icon */}
                    {currentPage > 1 && (
                        <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
                    )}

                    {/* Show page numbers */}
                    {getPageRange().map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}

                    {/* Show next arrow icon */}
                    {currentPage < totalPages && (
                        <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                    )}

                </div>

            </div>


        </>


    )
}

export default Newlift
