import * as Yup from "yup";


export const newAmcSchema = Yup.object({

    amcQuotationNo:Yup.string().required("Please enter amc quotation no."), 
    invoiceDate: Yup.string().required("Please select  date"),
    amcStatus: Yup.string().required("Please select status"),
    // customerName: Yup.string().required("Please enter  name"),
    // gstNo: Yup.string().required("Please enter  GST no."),
    // panNo: Yup.string().required("Please enter  PAN no."),
    // inputEmail: Yup.string().email().required("Please enter email address"),
    // mobileNo: Yup.number().integer().positive().required("Please enter mobile number"),
    // amcNo: Yup.string().required("Please enter  amc no."), 
    // preAddress: Yup.string().required("Please enter  Address "),
    // liftType: Yup.string().required("Please select lift type"),
    // stops:Yup.string().required("Please enter  stops "),
    // doorType:Yup.string().required("Please select door type"),
    // make:Yup.string().required("Please enter make "),
    // quantity:Yup.string().required("Please enter quantity "),
    // quotationAmt: Yup.number().integer().positive().required("Please enter quotation amount"),
    //  previousBal: Yup.number().required("Please enter  previous balance"),
    // paidAmt:Yup.number().integer().positive().required("Please enter paid amount"),
    // totalBalance:Yup.number().integer().positive().required("Please enter total balance"),
    // liftType: Yup.string().required("Please select lift type"),
    // liftType: Yup.string().required("Please select lift type"),
    // amcDate: Yup.string().required("Please select date"),
    // amcStartDate: Yup.string().required("Please select amc start date"),
    // amcEndDate: Yup.string().required("Please Select amc end date"),




  });
  
