import React from 'react'
import { useNavigate } from 'react-router-dom'

const ReceivePaymentTypes = () => {


    
  const navigate = useNavigate();
  

  function openLiftPayment(){
    navigate("/app/lift_payment")
  }

  function openAmcPayment(){
    navigate("/app/amc_payment")
  }

  function openModulePayment(){
    navigate("/app/modulation_payment")
  }

  function openOtherPayment(){
    navigate("/app/other_payment")
  }


  return (
    <>
    

    <div className='row d-flex justify-content-around'>
      <button onClick={openLiftPayment} className='btn btn-primary col-md-2 mt-4'>Lift Payment</button>
      <button onClick={openAmcPayment} className='btn btn-primary col-md-2 mt-4'>AMC Payment</button>
      <button onClick={openModulePayment} className='btn btn-primary col-md-2 mt-4'>Modulation Payment</button>
      <button onClick={openOtherPayment} className='btn btn-primary col-md-2 mt-4'>Other Payment</button>
    </div>
    
    </>
  )
}

export default ReceivePaymentTypes