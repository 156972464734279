import React from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap'
import { overduePaymentSchema } from '../Schema/OverduePaymentIndex';




const initialValues = {
    gender: "",
    customerName: "",


};

const OverduePaymentMaster = () => {



    const navigate = useNavigate();

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: overduePaymentSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                action.resetForm();
                // navigate("/app/breakdown")
            },
        });



    return (
        <>

            <div className='container'>
                <form onSubmit={handleSubmit} >

                    <div className='page__main'>

                        <h4>Overdue Payment</h4>


                        <hr />
                    </div>
                    <div className="d-md-flex justify-content-between">


                        <div className="form-check mt-4 ">
                            <input className="form-check-input border border-3 border-primary" type="radio" name="gender" id="liftPayment"
                                value="liftPayment" onChange={handleChange} onBlur={handleBlur} />
                            <label className="form-check-label fw-bold ms-1" for="flexRadioDefault1">
                                Lift Payment
                            </label>
                            {touched.gender && errors.gender ? (
                                <p className='form-error d-none d-md-block mt-1' style={{ color: "red", fontSize: "12px" }}>{errors.gender}</p>
                            ) : null}
                        </div>

                        <div className="form-check mt-4 ">
                            <input className="form-check-input border border-3 border-primary" type="radio" name="gender" id="amcPayment"
                                value="amcPayment" onChange={handleChange} onBlur={handleBlur} />
                            <label className="form-check-label fw-bold ms-1" for="flexRadioDefault1">
                                AMC Payment
                            </label>
                        </div>

                        <div className="form-check mt-4 ">
                            <input className="form-check-input border border-3 border-primary" type="radio" name="gender" id="modulationPayment"
                                value="modulationPayment" onChange={handleChange} onBlur={handleBlur} />
                            <label className="form-check-label fw-bold ms-1 " for="flexRadioDefault1">
                                Modulation Payment
                            </label>
                        </div>
                        {touched.gender && errors.gender ? (
                            <p className='form-error d-md-none ms-4' style={{ color: "red", fontSize: "12px" }}>{errors.gender}</p>
                        ) : null}


                        <div className='form-check ' >

                            <label htmlFor="customerName " className=''></label>

                            <select className="form-select border border-dark " style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='customerName'
                                value={values.customerName}
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                <option selected >Select Customer</option>
                                <option value="1">Customer name 1</option>
                                <option value="2">Customer name 2</option>
                                <option value="3">Customer name 3</option>
                                <option value="4">Customer name 4</option>
                                <option value="5">Customer name 5</option>
                                <option value="6">Customer name 6</option>
                            </select>
                            {touched.customerName && errors.customerName ? (
                                <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.customerName}</p>
                            ) : null}

                        </div>


                        <div className="form-check ">
                            <div className="form-group  mt-4">
                                <button className='btn btn-primary  '>Search</button>
                            </div>
                        </div>






                    </div>


                    <div className="">
                        <div className="form-group ms-md-0 ms-4 mt-4">
                            <button className='btn btn-primary '>Print</button>
                        </div>
                    </div>


                    <Table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">Date</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Job Id</th>
                                <th scope="col">Payment</th>



                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>


                            </tr>


                        </tbody>

                        <tbody>
                            <tr>
                                <th scope="row">2</th>
                                <td>Mark</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>


                            </tr>


                        </tbody>

                        <tbody>
                            <tr>
                                <th scope="row">3</th>
                                <td>Mark</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>

                            </tr>


                        </tbody>

                        <tbody>
                            <tr>
                                <th scope="row">4</th>
                                <td>Mark</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>


                            </tr>


                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="row">5</th>
                                <td>Mark</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>


                            </tr>


                        </tbody>
                        <tbody>
                            <tr>
                                <th scope="row">6</th>
                                <td>Mark</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>

                            </tr>


                        </tbody>

                        <tbody>
                            <tr>
                                <th scope="row">7</th>
                                <td>Mark</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>


                            </tr>


                        </tbody>
                    </Table>




                </form>

            </div>

        </>
    )
}

export default OverduePaymentMaster




































// import React from 'react'

// const OverduePaymentMaster = () => {
//     return (
//         <>

//             <div className='container'>


//                 <div className='page__main'>

//                     <h4>Overdue Payment</h4>


//                     <hr />
//                 </div>
//                 <div className="d-md-flex justify-content-between">

//                     <div className="form-check mt-4 ">
//                         <input className="form-check-input border border-3 border-primary" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
//                         <label className="form-check-label fw-bold ms-1" for="flexRadioDefault1">
//                             Lift Payment
//                         </label>
//                     </div>

//                     <div className="form-check mt-4">
//                         <input className="form-check-input border border-3 border-primary" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
//                         <label className="form-check-label fw-bold ms-1" for="flexRadioDefault1">
//                             AMC Payment
//                         </label>
//                     </div>

//                     <div className="form-check mt-4 ">
//                         <input className="form-check-input border border-3 border-primary" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
//                         <label className="form-check-label fw-bold ms-1" for="flexRadioDefault1">
//                             Modulation Payment
//                         </label>
//                     </div>

//                     <div className='form-check' >

//                         <label htmlFor="customerName " className=''></label>

//                         <select className="form-select border border-dark " style={{ boxShadow:'none' }}aria-label="Default select example">
//                             <option selected >Select Customer</option>
//                             <option value="1">Customer name 1</option>
//                             <option value="2">Customer name 2</option>
//                             <option value="3">Customer name 3</option>
//                             <option value="4">Customer name 4</option>
//                             <option value="5">Customer name 5</option>
//                             <option value="6">Customer name 6</option>
//                         </select>

//                     </div>


//                     <div className="form-check">
//                         <div className="form-group  mt-4">
//                             <button className='btn btn-primary '>Search</button>
//                         </div>
//                     </div>






//                 </div>

//             </div>

//         </>
//     )
// }

// export default OverduePaymentMaster