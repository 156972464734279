import * as Yup from "yup";



export const updateBreakdownAddSchema = Yup.object({

     
    customerName: Yup.string().min(2).max(25).required("Please enter your name"),
    mobileNo: Yup.number().integer().positive().required("Please enter mobile number"),
    siteAddress: Yup.string().required("Please enter Site address "),
    liftType: Yup.string().required("Please enter lift type"),
    liftTravel: Yup.string().required("Please enter lift travel "),
    status:Yup.string().required("Please enter status "),
    contactPersonName: Yup.string().min(2).max(25).required("Please enter contact person name"),
    contactNo: Yup.number().integer().positive().required("Please enter contact number"),
    technicianName: Yup.string().min(2).max(25).required("Please enter technician name"),
    technicianNumber: Yup.number().integer().positive().required("Please enter technician contact number"),
    breakdownDesc: Yup.string().required("Please enter  breakdown description "),
    breakDate: Yup.string().required("Please select date"),
    uniqueId: Yup.string().required("Please enter Site Id"),



  });