import * as Yup from "yup";


export const liftQuotationSchema = Yup.object({
 
    // customerName:  Yup.string().required("Please select customer name"),
    // gstNo: Yup.string().required("Please enter GST no."),
    // panNo: Yup.string().required("Please enter PAN no."),
    // inputEmail: Yup.string().email().required("Please enter email address "),
    // mobileNo: Yup.number().integer().positive().required("Please enter mobile number"),
    // quotationNo:Yup.string().required("Please enter amc quotation No."),
    quoDate: Yup.string().required("Please select date "),
    liftWorkStatus:Yup.string().required("Please select status "),
    subject: Yup.string().required("Please enter subject "),
    // requireTo: Yup.string().required("Please enter require to "),
    // toBeInstalledAt: Yup.string().required("Please enter installed at "),
    // liftType: Yup.string().required("Please select lift type"),
    // capacity: Yup.string().required("Please enter capacity"),
    // powerPackLocationNo:Yup.string().required("Please enter power pack location no"),
    // motorMachine:Yup.string().required("Please enter motor machine"),
    // ropes:Yup.string().required("Please enter ropes"),
    // speed:Yup.string().required("Please enter Your speed"),
    // controller:Yup.string().required("Please enter controller"),
    // drive:Yup.string().required("Please enter drive"),
    // signals:Yup.string().required("Please enter signals"),
    // emergencySignals:Yup.string().required("Please enter emergency signals"),
    // liftTravel:Yup.string().required("Please enter your Lift Travel"),
    // landingAndOpening:Yup.string().required("Please enter landing and opening"),
    // stops:Yup.string().required("Please enter stops "),
    // carEncloser:Yup.string().required("Please enter car encloser"),
    // flooring:Yup.string().required("Please enter flooring"),
    // doorType:Yup.string().required("Please select door type"),
    // landingDoor:Yup.string().required("Please enter Landing Door"),
    // powerSupply:Yup.string().required("Please enter power supply"),
    quotationAmt: Yup.number().integer().positive().required("Please enter your Quotation Amount"),
    amtToWord: Yup.string().required("Please convert amt in word "),
    description:Yup.string().required("Please enter description "),
    // quoDate: Yup.string().required("Please select date"),

  });