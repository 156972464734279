import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { updateLiftQuotationAddSchema } from '../Schema/UpdateLiftQuotationAddIndex';




const initialValues = {

  customerName: "",
  gstNo: "",
  panNo: "",
  inputEmail: "",
  mobileNo: "",
  quotationNo: "",
  preAddress: "",
  subject: "",
  requireTo: "",
  toBeInstalledAt: "",
  liftType: "",
  capacity: "",
  powerPackLocationNo: "",
  motorMachine: "",
  mmBrand: "",
  ropes: "",
  speed: "",
  controller: "",
  drive: "",
  signals: "",
  emergencySignals: "",
  liftTravel: "",
  landingAndOpening: "",
  stops: "",
  carEncloser: "",
  flooring: "",
  doorType: "",
  landingDoor: "",
  powerSupply: "",
  quotationAmt: "",
  quoDate: "",
  liftWorkStatus: "",
  description: "",
  callBackDate: "",
  amtToWord: "",
};


const UpdateLiftQuotationAdd = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([])

  const [uniqueId, setUniqueId] = useState("");
  const [quoDate, setQuoDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [preAddress, setPreAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [requireTo, setRequireTo] = useState("");
  const [toBeInstalledAt, setToBeInstalledAt] = useState("");
  const [liftType, setLiftType] = useState("");
  const [capacity, setCapacity] = useState("");
  const [powerPackLocationNo, setPowerPackLocationNo] = useState("");
  const [motorMachine, setMotorMachine] = useState("");
  const [mmBrand, setMmBrand] = useState("");
  const [ropesBrand, setRopesBrand] = useState("");
  const [controllerBrand, setControllerBrand] = useState("");
  const [doorTypeBrand, setDoorTypeBrand] = useState("");
  const [landingDoorBrand, setLandingDoorBrand] = useState("");
  const [ropes, setRopes] = useState("");
  const [speed, setSpeed] = useState("");
  const [controller, setController] = useState("");
  const [drive, setDrive] = useState("");
  const [signals, setSignals] = useState("");
  const [emergencySignals, setEmergencySignals] = useState("");
  const [liftTravel, setLiftTravel] = useState("");
  const [landingAndOpening, setLandingAndOpening] = useState("");
  const [stops, setStops] = useState("");
  const [carEncloser, setCarEncloser] = useState("");
  const [flooring, setFlooring] = useState("");
  const [doorType, setDoorType] = useState("");
  const [landingDoor, setLandingDoor] = useState("");
  const [powerSupply, setPowerSupply] = useState("");
  const [quotationAmt, setQuotationAmt] = useState("");
  // const [previousLiftAmt, setPreviousLiftAmt] = useState(0);
  const [description, setDescription] = useState("");
  const [callBackDate, setCallBackDate] = useState("");
  const [liftWorkStatus, setLiftWorkStatus] = useState("");
  const [amtToWord, setAmtToWord] = useState("");
  const [note, setNote] = useState("");
  const [gst, setGst] = useState("");



  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      // validationSchema: updateLiftQuotationAddSchema,
      onSubmit: (values, action) => {
        console.log("values:", values);
        // action.resetForm();
        // navigate("/app/lift_data")
      },
    });
  console.log(errors);


  function saveData() {
    navigate("/app/quotation")
  }

  function openAmcAdd() {
    navigate("/app/amc_quotation_add")
  }

  function openModuleAdd() {
    navigate("/app/modulation_quatation_add")
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////


  const handleAmtToWord = (event) => {

    setAmtToWord(event.target.value);
    handleChange(event);
  }


  const handleNote = (event) => {

    setNote(event.target.value);
    handleChange(event);
  }

  const handleGst = (event) => {

    setGst(event.target.value);
    handleChange(event);
  }

  const handleChangeUniqueId = (event) => {

    setUniqueId(event.target.value);
    handleChange(event);
  }
  // const handlePreviousLiftAmt = (event) => {

  //   setPreviousLiftAmt(event.target.value);
  //   handleChange(event);
  // }


  const handleQuoDate = (event) => {

    setQuoDate(event.target.value);
    handleChange(event);
  }

  const handleCustomerName = (event) => {

    setCustomerName(event.target.value);
    handleChange(event);
  }


  const handleGstNo = (event) => {
    // console.log("On Change");

    setGstNo(event.target.value);
    handleChange(event);
  }
  const handlePanNo = (event) => {
    // console.log("On Change");

    setPanNo(event.target.value);
    handleChange(event);
  }
  const handleInputEmail = (event) => {
    // console.log("On Change");

    setInputEmail(event.target.value);
    handleChange(event);
  }

  const handleMobileNo = (event) => {
    // console.log("On Change");

    setMobileNo(event.target.value);
    handleChange(event);
  }


  const handleQuotationNo = (event) => {
    // console.log("On Change");

    setQuotationNo(event.target.value);
    handleChange(event);
  }

  const handlePreAddress = (event) => {
    // console.log("On Change");

    setPreAddress(event.target.value);
    handleChange(event);
  }

  const handleSubject = (event) => {
    // console.log("On Change");

    setSubject(event.target.value);
    handleChange(event);
  }

  const handleRequireTo = (event) => {
    // console.log("On Change");

    setRequireTo(event.target.value);
    handleChange(event);
  }

  const handleToBeInstalledAt = (event) => {
    // console.log("On Change");

    setToBeInstalledAt(event.target.value);
    handleChange(event);
  }

  const handleLiftType = (event) => {
    // console.log("On Change");

    setLiftType(event.target.value);
    handleChange(event);
  }

  const handleCapacity = (event) => {
    // console.log("On Change");

    setCapacity(event.target.value);
    handleChange(event);
  }

  const handlePowerPackLocationNo = (event) => {
    // console.log("On Change");

    setPowerPackLocationNo(event.target.value);
    handleChange(event);
  }

  const handleMotorMachine = (event) => {
    // console.log("On Change");

    setMotorMachine(event.target.value);
    handleChange(event);
  }

  const handleMMBrand = (event) => {
    // console.log("On Change");

    setMmBrand(event.target.value);
    handleChange(event);
  }

  const handleRopes = (event) => {
    // console.log("On Change");

    setRopes(event.target.value);
    handleChange(event);
  }


  const handleRopesBrand = (event) => {
    // console.log("On Change");

    setRopesBrand(event.target.value);
    handleChange(event);
  }


  const handleLandingDoorBrand = (event) => {
    // console.log("On Change");

    setLandingDoorBrand(event.target.value);
    handleChange(event);
  }

  const handleSpeed = (event) => {
    // console.log("On Change");

    setSpeed(event.target.value);
    handleChange(event);
  }

  const handleController = (event) => {
    // console.log("On Change");

    setController(event.target.value);
    handleChange(event);
  }

  const handleControllerBrand = (event) => {
    // console.log("On Change");

    setControllerBrand(event.target.value);
    handleChange(event);
  }


  const handleDoorTypeBrand = (event) => {
    // console.log("On Change");

    setDoorTypeBrand(event.target.value);
    handleChange(event);
  }


  const handleDrive = (event) => {
    // console.log("On Change");

    setDrive(event.target.value);
    handleChange(event);
  }

  const handleSignals = (event) => {
    // console.log("On Change");

    setSignals(event.target.value);
    handleChange(event);
  }

  const handleEmergencySignals = (event) => {
    // console.log("On Change");

    setEmergencySignals(event.target.value);
    handleChange(event);
  }

  const handleLiftTravel = (event) => {
    // console.log("On Change");

    setLiftTravel(event.target.value);
    handleChange(event);
  }

  const handleLandingAndOpening = (event) => {
    // console.log("On Change");

    setLandingAndOpening(event.target.value);
    handleChange(event);
  }

  const handleStops = (event) => {
    // console.log("On Change");

    setStops(event.target.value);
    handleChange(event);
  }

  const handleCarEncloser = (event) => {
    // console.log("On Change");

    setCarEncloser(event.target.value);
    handleChange(event);
  }

  const handleFlooring = (event) => {
    // console.log("On Change");

    setFlooring(event.target.value);
    handleChange(event);
  }

  const handleDoorType = (event) => {
    // console.log("On Change");

    setDoorType(event.target.value);
    handleChange(event);
  }

  const handleLandingDoor = (event) => {
    // console.log("On Change");

    setLandingDoor(event.target.value);
    handleChange(event);
  }

  const handlePowerSupply = (event) => {
    // console.log("On Change");

    setPowerSupply(event.target.value);
    handleChange(event);
  }

  const handleQuotationAmt = (event) => {
    // console.log("On Change");

    setQuotationAmt(event.target.value);
    handleChange(event);
  }

  const handleSetDescription = (event) => {
    // console.log("On Change");

    setDescription(event.target.value);
    handleChange(event);
  }

  const handleCallBackDate = (event) => {
    // console.log("On Change");

    setCallBackDate(event.target.value);
    handleChange(event);
  }

  const handleLiftWorkStatus = (event) => {
    // console.log("On Change");

    setLiftWorkStatus(event.target.value);
    handleChange(event);
  }





  ///////////////////////////////////////////// Api  Call /////////////////////////////////////

  useEffect(() => {

    async function fetchresult() {
      try {
        const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_quolift_update/${id}`);
        const result = await response.json();
        console.log("result:", result);
        setData(result);
        setUniqueId(result.li_cu_unique_id)
        setQuoDate(result.li_quo_date)
        setQuotationNo(result.li_quo_no)
        setCustomerName(result.li_quo_cu_name)
        setGstNo(result.li_quo_cu_gst_no)
        setPanNo(result.li_quo_cu_pan_no)
        setInputEmail(result.li_quo_cu_email)
        setMobileNo(result.li_quo_cu_mobNo)
        setPreAddress(result.li_quo_cu_address)
        setSubject(result.li_quo_sub)
        setRequireTo(result.li_quo_req_to)
        setToBeInstalledAt(result.li_quo_installed)
        setLiftType(result.li_quo_lift_type)
        setCapacity(result.li_quo_capacity)
        setPowerPackLocationNo(result.li_quo_location_no)
        setMotorMachine(result.li_quo_mo_machine)
        setRopes(result.li_quo_ropes)
        setSpeed(result.li_quo_speed)
        setController(result.li_quo_controller)
        setDrive(result.li_quo_drive)
        setSignals(result.li_quo_signal)
        setEmergencySignals(result.li_quo_em_signal)
        setLiftTravel(result.li_quo_travel)
        setLandingAndOpening(result.li_quo_land_open)
        setStops(result.li_quo_stops)
        setCarEncloser(result.li_quo_car_encloser)
        setFlooring(result.li_quo_flooring)
        setDoorType(result.li_quo_cardoor)
        setLandingDoor(result.li_quo_land_door)
        setPowerSupply(result.li_quo_pow_sply)
        setQuotationAmt(result.li_quo_amount)
        setLiftWorkStatus(result.li_quo_work_status)
        setDescription(result.li_quo_description)
        setCallBackDate(result.li_call_back_date)
        setMmBrand(result.li_quo_mo_machine_brand)
        setRopesBrand(result.li_quo_ropes_brand)
        setControllerBrand(result.li_quo_controller_brand)
        setDoorTypeBrand(result.li_quo_cardoor_brand)
        setLandingDoorBrand(result.li_quo_land_door_brand)
        setAmtToWord(result.li_quo_amt_word)
        setNote(result.li_quo_taxes)
        setGst(result.li_quo_gst)



      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchresult();

  }, [id]);


  ///////////////////// Api to enquiry updated data Store Api  ///////////////////////////////////////////

  async function storeUpdateQuoLiftData(id) {
    const formData = new FormData();
    formData.append("uniqueId", uniqueId)
    formData.append("quoDate", quoDate)
    formData.append("customerName", customerName)
    formData.append("panNo", panNo)
    formData.append("gstNo", gstNo)
    formData.append("inputEmail", inputEmail)
    formData.append("mobileNo", mobileNo)
    formData.append("quotationNo", quotationNo)
    formData.append("preAddress", preAddress)
    formData.append("subject", subject)
    formData.append("requireTo", requireTo)
    formData.append("toBeInstalledAt", toBeInstalledAt)
    formData.append("liftType", liftType)
    formData.append("capacity", capacity)
    formData.append("powerPackLocationNo", powerPackLocationNo)
    formData.append("motorMachine", motorMachine)
    formData.append("ropes", ropes)
    formData.append("speed", speed)
    formData.append("controller", controller)
    formData.append("drive", drive)
    formData.append("signals", signals)
    formData.append("emergencySignals", emergencySignals)
    formData.append("liftTravel", liftTravel)
    formData.append("landingAndOpening", landingAndOpening)
    formData.append("stops", stops)
    formData.append("carEncloser", carEncloser)
    formData.append("flooring", flooring)
    formData.append("doorType", doorType)
    formData.append("landingDoor", landingDoor)
    formData.append("powerSupply", powerSupply)
    formData.append("quotationAmt", quotationAmt)
    formData.append("description", description)
    formData.append("callBackDate", callBackDate)
    formData.append("liftWorkStatus", liftWorkStatus)
    formData.append("mmBrand", mmBrand)
    formData.append("ropesBrand", ropesBrand)
    formData.append("controllerBrand", controllerBrand)
    formData.append("doorTypeBrand", doorTypeBrand)
    formData.append("landingDoorBrand", landingDoorBrand)
    formData.append("amtToWord", amtToWord)
    formData.append("note", note)
    formData.append("gst", gst)

    try {
      let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/lift_quo_update_store/' + id + '?method=PUT', {
        method: 'POST',
        body: formData
      });

      let data1 = await response.json(); // Parse the response body as JSON

      if (data1.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        alert(data1.message);
        navigate("/app/lift_data")


      } else {
        alert("An error occurred while saving the data.");
      }


    } catch (error) {
      console.error('Error while adding customer:', error);
      alert("An error occurred while saving the data2.");

    }
  }

  return (
    <>

      <div className=' row d-flex justify-content-around'>
        <button className='btn btn-primary col-md-3 mt-4'>Lift Quatation</button>
        <button onClick={openAmcAdd} className='btn btn-primary col-md-3 mt-4'>AMC Quotation</button>
        <button onClick={openModuleAdd} className='btn btn-primary col-md-3 mt-4'>Modulation Quatation</button>
      </div>


      <div className='container'>

        <form onSubmit={handleSubmit}>
          <div className="row mb-1 mt-5">
            <div className="col-md-6 ">
              <div className="form-group">
                <h4 className='mt-4'>Lift Update Quotation</h4>
              </div>
            </div>


            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Site Id</label>
                <input type="text" className="form-control a1" id="uniqueId" name='uniqueId'
                  value={uniqueId}
                  onChange={handleChangeUniqueId}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date</label>
                <input type="date" name='quoDate' className="form-control a1" id="quoDate"
                  value={quoDate}
                  onChange={handleQuoDate}
                  onBlur={handleBlur} />

              </div>
            </div>


          </div>

          <hr />

          <div className="row mb-1">


            <div className='col-md-4'>
              <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
              <input type="text" name='customerName' className="form-control a1" id="customerName"
                value={customerName}
                onChange={handleCustomerName}
                onBlur={handleBlur} />
            </div>


            <div className="col-md-4 ">
              <div className="form-group">


                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No.</label>
                <input type="text" name='gstNo' className="form-control a1" id="gstNo"
                  value={gstNo}
                  onChange={handleGstNo}
                  onBlur={handleBlur} />

              </div>
            </div>
            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN No.</label>
                <input type="text" name='panNo' className="form-control a1" id="panNo"
                  value={panNo}
                  onChange={handlePanNo}
                  onBlur={handleBlur} />

              </div>
            </div>


          </div>

          <div className="row mb-1">
            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="inputEmail" className='' style={{ fontSize: '14px' }}>Email</label>
                <input type="email" name='inputEmail' className="form-control a1" id="inputEmail"
                  value={inputEmail}
                  onChange={handleInputEmail}
                  onBlur={handleBlur} />

              </div>
            </div>
            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.</label>
                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo"
                  value={mobileNo}
                  onChange={handleMobileNo}
                  onBlur={handleBlur} />


              </div>
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quotation No</label>
                <input type="text" name='quotationNo' className="form-control a1" id="quotationNo"
                  value={quotationNo}
                  onChange={handleQuotationNo}
                  onBlur={handleBlur} />

              </div>
            </div>


            <div className="row mb-5">
              <div className="col-md-12 ">
                <div className="form-group">
                  <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                  <textarea className="form-control a1" name='preAddress' id="preAddress" rows="5"
                    value={preAddress}
                    onChange={handlePreAddress}
                    onBlur={handleBlur}></textarea>

                </div>
              </div>

            </div>
          </div>

          <div className='page__main'>

            <h4>Lift Details</h4>
            <hr />
            <div className='row mb-1'>
              <div className="col-md-12 ">
                <div className="form-group">
                  <label htmlFor="subject" className='' style={{ fontSize: '14px' }}>Subject</label>
                  <input type="text" name='subject' className="form-control a1" id="subject"
                    value={subject}
                    onChange={handleSubject}
                    onBlur={handleBlur} />

                </div>
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-md-12 ">
                <div className="form-group">
                  <label htmlFor="description" className='' style={{ fontSize: '14px' }}>Description</label>
                  <input type="text" name="description" className="form-control a1" id="description"
                    value={description}
                    onChange={handleSetDescription}
                    onBlur={handleBlur} />
                  {/* {touched.description && errors.description ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.description}</p>
                                ) : null} */}
                </div>
              </div>
            </div>

            <div className='row mb-1'>

              <div className="col-md-12 ">
                <div className="form-group">
                  <label htmlFor="signals" className='' style={{ fontSize: '14px' }}>Signals</label>
                  <input type="text" name='signals' className="form-control a1" id="signals"
                    value={signals}
                    onChange={handleSignals}
                    onBlur={handleBlur} disabled="true" />
                </div>
              </div>

            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="requireTo" className='' style={{ fontSize: '14px' }}>Required To</label>
                  <input type="text" name='requireTo' className="form-control a1" id="requireTo"
                    value={requireTo}
                    onChange={handleRequireTo}
                    onBlur={handleBlur} />

                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="toBeInstalledAt" className='' style={{ fontSize: '14px' }}>To be Installed at</label>
                  <input type="text" className="form-control a1" name='toBeInstalledAt' id="toBeInstalledAt"
                    value={toBeInstalledAt}
                    onChange={handleToBeInstalledAt}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="motorMachine" className='' style={{ fontSize: '14px' }}>Motor Machines </label>
                  <select type="text" name='motorMachine' className="form-select border border-dark" id="motorMachine"
                    value={motorMachine}
                    onChange={handleMotorMachine}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="5HP MR Geared" style={{ background: '#eaeaea' }}>5HP MR Geared</option>
                    <option value="7.5HP MR Geared" style={{ background: '#eaeaea' }}>7.5HP MR Geared</option>
                    <option value="10HP MR Geared" style={{ background: '#eaeaea' }}>10HP MR Geared</option>
                    <option value="5HP MRL Gearless" style={{ background: '#eaeaea' }}>5HP MRL Gearless</option>
                    <option value="7.5HP MRL Gearless" style={{ background: '#eaeaea' }}>7.5HP MRL Gearless</option>
                    <option value="10HP MRL Gearless" style={{ background: '#eaeaea' }}>10HP MRL Gearless</option>

                  </select>




                  {/* {touched.motorMachine && errors.motorMachine ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.motorMachine}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="mmBrand" className='' style={{ fontSize: '14px' }}>MM Brands</label>
                  <select type="text" name='mmBrand' className="form-select border border-dark" id="mmBrand"
                    value={mmBrand}
                    onChange={handleMMBrand}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="(Sharp)" style={{ background: '#eaeaea' }}>Sharp</option>
                    <option value="(Montanari)" style={{ background: '#eaeaea' }}>Montanari</option>
                    <option value="(Bharat Bijali)" style={{ background: '#eaeaea' }}>Bharat Bijali</option>


                  </select>

                </div>
              </div>

            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="capacity" className='' style={{ fontSize: '14px' }}>Capacity*</label>
                  <select type="text" name='capacity' className="form-select border border-dark" id="capacity"
                    value={capacity}
                    onChange={handleCapacity}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="2 Person's (136 Kgs)" style={{ background: '#eaeaea' }}>2 Person's (136 Kgs)</option>
                    <option value="3 Person's (204 Kgs)" style={{ background: '#eaeaea' }}>3 Person's (204 Kgs)</option>
                    <option value="4 Person's (272 Kgs)" style={{ background: '#eaeaea' }}>4 Person's (272 Kgs)</option>
                    <option value="5 Person's (340 Kgs)" style={{ background: '#eaeaea' }}>5 Person's (340 Kgs)</option>
                    <option value="6 Person's (408 Kgs)" style={{ background: '#eaeaea' }}>6 Person's (408 Kgs)</option>
                    <option value="8 Person's (544 Kgs)" style={{ background: '#eaeaea' }}>8 Person's (544 Kgs)</option>
                    <option value="10 Person's (680 Kgs)" style={{ background: '#eaeaea' }}>10 Person's (680 Kgs)</option>
                    <option value="13 Person's (884 Kgs)" style={{ background: '#eaeaea' }}>13 Person's (884 Kgs)</option>
                    <option value="16 Person's (1088 Kgs)" style={{ background: '#eaeaea' }}>16 Person's (1088 Kgs)</option>

                  </select>


                  {/* {touched.capacity && errors.capacity ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.capacity}</p>
                  ) : null} */}
                </div>
              </div>

              <div className='col-md-3'>

                <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Type Lift:</label>

                <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                  name='liftType'
                  id='liftType'
                  value={liftType}
                  onChange={handleLiftType}
                  onBlur={handleBlur}>
                  {/* <option value="" style={{ display: 'none' }}></option> */}
                  <option selected style={{ dispaly: 'none' }}></option>
                  <option value="Passanger Lift" style={{ background: '#eaeaea' }}>Passanger Lift</option>
                  <option value="Hospital Lift" style={{ background: '#eaeaea' }}>Hospital Lift</option>
                  <option value="Hydrolic Lift" style={{ background: '#eaeaea' }}>Hydrolic Lift</option>
                  <option value="Good's Lift" style={{ background: '#eaeaea' }}>Good's Lift</option>
                  <option value="Good's with Passanger Lift" style={{ background: '#eaeaea' }}>Good's with Passanger Lift</option>
                  <option value="Scissor Lift" style={{ background: '#eaeaea' }}>Scissor Lift</option>

                </select>

              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="ropes" className='' style={{ fontSize: '14px' }}>Ropes</label>
                  <select type="text" name='ropes' className="form-select border border-dark" id="ropes"
                    value={ropes}
                    onChange={handleRopes}
                    onBlur={handleBlur} >

                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="10 mm dia 3nos" style={{ background: '#eaeaea' }}>10 mm dia 3nos</option>
                    <option value="13 mm dia 3nos" style={{ background: '#eaeaea' }}>13 mm dia 3nos</option>
                    <option value="8 mm dia 4nos" style={{ background: '#eaeaea' }}>8 mm dia 4nos</option>
                    <option value="6 mm dia 5nos" style={{ background: '#eaeaea' }}>6 mm dia 5nos</option>
                    <option value="6 mm dia 6nos" style={{ background: '#eaeaea' }}>6 mm dia 6nos</option>
                    <option value="8 mm dia 6nos" style={{ background: '#eaeaea' }}>8 mm dia 6nos</option>

                  </select>


                  {/* {touched.ropes && errors.ropes ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.ropes}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="ropesBrand" className='' style={{ fontSize: '14px' }}>Ropes Brands</label>
                  <select type="text" name='ropesBrand' className="form-select border border-dark" id="ropesBrand"
                    value={ropesBrand}
                    onChange={handleRopesBrand}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="(Usha Martine)" style={{ background: '#eaeaea' }}>Usha Martine</option>

                  </select>


                </div>
              </div>

            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="speed" className='' style={{ fontSize: '14px' }}>Speed</label>
                  <select type="text" name='speed' className="form-select border border-dark" id="speed"
                    value={speed}
                    onChange={handleSpeed}
                    onBlur={handleBlur} >

                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="0.68 mtr/sec" style={{ background: '#eaeaea' }}>0.68 mtr/sec</option>
                    <option value="1 mtr/sec" style={{ background: '#eaeaea' }}>1 mtr/sec</option>

                  </select>

                  {/* {touched.speed && errors.speed ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.speed}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="drive" className='' style={{ fontSize: '14px' }}>Drive</label>
                  <select type="text" name='drive' className="form-select border border-dark" id="drive"
                    value={drive}
                    onChange={handleDrive}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="Monarch" style={{ background: '#eaeaea' }}>Monarch</option>
                    <option value="INVT" style={{ background: '#eaeaea' }}>INVT</option>
                    <option value="FUJI" style={{ background: '#eaeaea' }}>FUJI</option>
                    <option value="Close Loop" style={{ background: '#eaeaea' }}>Close Loop</option>

                  </select>

                  {/* {touched.drive && errors.drive ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.drive}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="controller" className='' style={{ fontSize: '14px' }}>Controller</label>
                  <input type="text" className="form-control a1" id="controller"
                    value={controller}
                    onChange={handleController}
                    onBlur={handleBlur} disabled="true" />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="controllerBrand" className='' style={{ fontSize: '14px' }}>Controller Brands</label>
                  <select type="text" name='controllerBrand' className="form-select border border-dark" id="controllerBrand"
                    value={controllerBrand}
                    onChange={handleControllerBrand}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="(ADCO)" style={{ background: '#eaeaea' }}>ADCO</option>
                    <option value="(RIDHI)" style={{ background: '#eaeaea' }}>RIDHI</option>
                    <option value="(MONARCH)" style={{ background: '#eaeaea' }}>MONARCH</option>

                  </select>
                </div>
              </div>

            </div>

            <div className="row mb-1">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="emergencySignals" className='' style={{ fontSize: '14px' }}>Emergency Signals</label>
                  <input type="text" name='emergencySignals' className="form-control a1" id="emergencySignals"
                    value={emergencySignals}
                    onChange={handleEmergencySignals}
                    onBlur={handleBlur} disabled="true" />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="liftTravel" className='' style={{ fontSize: '14px' }}>Travel </label>
                  <select type="text" name='liftTravel' className="form-select border border-dark" id="liftTravel"
                    value={liftTravel}
                    onChange={handleLiftTravel}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="G+1 (G 1)" style={{ background: '#eaeaea' }}>G+1 (G 1)</option>
                    <option value="G+2 (G 1 2)" style={{ background: '#eaeaea' }}>G+2 (G 1 2)</option>
                    <option value="G+3 (G 1 2 3)" style={{ background: '#eaeaea' }}>G+3 (G 1 2 3)</option>
                    <option value="G+4 (G 1 2 3 4)" style={{ background: '#eaeaea' }}>G+4 (G 1 2 3 4)</option>

                  </select>




                  {/* {touched.liftTravel && errors.liftTravel ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.liftTravel}</p>
                  ) : null} */}
                </div>
              </div>


              <div className='col-md-3'>

                <label htmlFor="doorType  " className='' style={{ fontSize: '14px' }}>Car Door</label>

                <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                  name='doorType'
                  id='doorType'
                  value={doorType}
                  onChange={handleDoorType}
                  onBlur={handleBlur}>
                  {/* <option value="" style={{ display: 'none' }}></option> */}
                  <option selected style={{ dispaly: 'none' }}></option>
                  <option value="S.S Automatic Door" style={{ background: '#dddddd' }}>S.S Automatic Door</option>
                  <option value="M.S Automatic Door" style={{ background: '#dddddd' }}>M.S Automatic Door</option>
                  <option value="S.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>S.S Automatic Big Vision Door</option>
                  <option value="M.S Automatic Big Vision Door<" style={{ background: '#dddddd' }}>M.S Automatic Big Vision Door</option>
                  <option value="M.S Swing Door's" style={{ background: '#dddddd' }}>M.S Swing Door's</option>
                  <option value="M.S IMPERFORATED Door's" style={{ background: '#dddddd' }}>M.S IMPERFORATED Door's</option>
                </select>


                {/* {touched.doorType && errors.doorType ? (
  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.doorType}</p>
) : null} */}

              </div>



              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="doorTypeBrand" className='' style={{ fontSize: '14px' }}>CD Brands</label>
                  <select type="text" name='doorTypeBrand' className="form-select border border-dark" id="doorTypeBrand"
                    value={doorTypeBrand}
                    onChange={handleDoorTypeBrand}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="(EC India)" style={{ background: '#eaeaea' }}>EC India</option>
                    <option value="(BST)" style={{ background: '#eaeaea' }}>BST</option>
                    <option value="(Fermator)" style={{ background: '#eaeaea' }}>Fermator</option>
                    <option value="(Genisis)" style={{ background: '#eaeaea' }}>Genisis</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="landingAndOpening" className='' style={{ fontSize: '14px' }}>Landings and Opening</label>
                  <select type="text" name='landingAndOpening' className="form-select border border-dark" id="landingAndOpening"
                    value={landingAndOpening}
                    onChange={handleLandingAndOpening}
                    onBlur={handleBlur} >

                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="2 Landing's 2 Opening's" style={{ background: '#eaeaea' }}>2 Landing's 2 Opening's</option>
                    <option value="3 Landing's 3 Opening's" style={{ background: '#eaeaea' }}>3 Landing's 3 Opening's</option>
                    <option value="4 Landing's 4 Opening's" style={{ background: '#eaeaea' }}>4 Landing's 4 Opening's</option>
                    <option value="5 Landing's 5 Opening's" style={{ background: '#eaeaea' }}>5 Landing's 5 Opening's</option>
                    <option value="6 Landing's 6 Opening's" style={{ background: '#eaeaea' }}>6 Landing's 6 Opening's</option>
                    <option value="7 Landing's 7 Opening's" style={{ background: '#eaeaea' }}>7 Landing's 7 Opening's</option>
                    <option value="8 Landing's 8 Opening's" style={{ background: '#eaeaea' }}>8 Landing's 8 Opening's</option>

                  </select>



                  {/* {touched.landingAndOpening && errors.landingAndOpening ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.landingAndOpening}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                  <select type="text" className="form-select border border-dark" id="stops"
                    value={stops}
                    onChange={handleStops}
                    onBlur={handleBlur} >

                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="2" style={{ background: '#eaeaea' }}>2</option>
                    <option value="3" style={{ background: '#eaeaea' }}>3</option>
                    <option value="4" style={{ background: '#eaeaea' }}>4</option>
                    <option value="5" style={{ background: '#eaeaea' }}>5</option>
                    <option value="6" style={{ background: '#eaeaea' }}>6</option>
                    <option value="7" style={{ background: '#eaeaea' }}>7</option>
                    <option value="8" style={{ background: '#eaeaea' }}>8</option>

                  </select>

                  {/* {touched.stops && errors.stops ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.stops}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="landingDoor" className='' style={{ fontSize: '14px' }}>Landing Door </label>
                  <select type="text" name='landingDoor' className="form-select border border-dark" id="landingDoor"
                    value={landingDoor}
                    onChange={handleLandingDoor}
                    onBlur={handleBlur} >

                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="S.S Automatic Door" style={{ background: '#dddddd' }}>S.S Automatic Door</option>
                    <option value="M.S Automatic Door" style={{ background: '#dddddd' }}>M.S Automatic Door</option>
                    <option value="S.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>S.S Automatic Big Vision Door</option>
                    <option value="M.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>M.S Automatic Big Vision Door</option>
                    <option value="M.S Swing Door's" style={{ background: '#dddddd' }}>M.S Swing Door's</option>
                    <option value="M.S IMPERFORATED Door's" style={{ background: '#dddddd' }}>M.S IMPERFORATED Door's</option>

                  </select>



                  {/* {touched.landingDoor && errors.landingDoor ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.landingDoor}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">

                <div className="form-group">
                  <label htmlFor="landingDoorBrand" className='' style={{ fontSize: '14px' }}>LD Brands</label>
                  <select type="text" name='landingDoorBrand' className="form-select border border-dark" id="landingDoorBrand"
                    value={landingDoorBrand}
                    onChange={handleLandingDoorBrand}
                    onBlur={handleBlur} >
                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="(EC India)" style={{ background: '#eaeaea' }}>EC India</option>
                    <option value="(BST)" style={{ background: '#eaeaea' }}>BST</option>
                    <option value="(Fermator)" style={{ background: '#eaeaea' }}>Fermator</option>
                    <option value="(Genisis)" style={{ background: '#eaeaea' }}>Genisis</option>

                  </select>

                </div>
              </div>

            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="carEncloser " className='' style={{ fontSize: '14px' }}>Car Encloser </label>
                  <select type="text" name='carEncloser' className="form-select border border-dark" id="carEncloser"
                    value={carEncloser}
                    onChange={handleCarEncloser}
                    onBlur={handleBlur} >

                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="S.S Hairline Finish Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Hairline Finish Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="S.S Designer Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Designer Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="M.S Designer Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>M.S Designer Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="S.S Glass Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Glass Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="M.S Glass Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>M.S Glass Cabin with Spotlight Fall Ceilling & Bloor</option>

                  </select>
                  {/* {touched.carEncloser && errors.carEncloser ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.carEncloser}</p>
                  ) : null} */}



                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="flooring" className='' style={{ fontSize: '14px' }}>Flooring</label>
                  <select type="text" name='flooring' className="form-select border border-dark" id="flooring"
                    value={flooring}
                    onChange={handleFlooring}
                    onBlur={handleBlur} >

                    {/* <option value="" style={{ display: 'none' }}></option> */}
                    <option selected style={{ dispaly: 'none' }}></option>
                    <option value="Antiskid PVC Designer Flooring" style={{ background: '#eaeaea' }}>Antiskid PVC Designer Flooring</option>
                    <option value="Chequer Plate Flooring" style={{ background: '#eaeaea' }}>Chequer Plate Flooring</option>


                  </select>
                  {/* {touched.flooring && errors.flooring ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.flooring}</p>
                  ) : null} */}



                </div>
              </div>


              <div className='col-md-3'>

                <label htmlFor="liftWorkStatus" className='' style={{ fontSize: '14px' }}>Status</label>

                <select className="form-select border border-dark" aria-label="Default select example"
                  name='liftWorkStatus'
                  id='liftWorkStatus'
                  value={liftWorkStatus}
                  onChange={handleLiftWorkStatus}
                  onBlur={handleBlur}>
                  <option selected style={{ dispaly: 'none' }}></option>
                  <option value="Visit Site" style={{ background: '#dddddd', color: "blue" }}>Visit Site</option>
                  <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                  <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                  <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                  <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                  <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                  <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                </select>

                {touched.liftWorkStatus && errors.liftWorkStatus ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.status}</p>
                ) : null}
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="powerSupply" className='' style={{ fontSize: '14px' }}>Power Supply</label>
                  <input type="text" name='powerSupply' className="form-control a1" id="powerSupply"
                    value={powerSupply}
                    onChange={handlePowerSupply}
                    onBlur={handleBlur} disabled="true" />
                  {/* {touched.powerSupply && errors.powerSupply ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.powerSupply}</p>
                  ) : null} */}
                </div>
              </div>

            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="quotationAmt" className='' style={{ fontSize: '14px' }}>Quotation Amount</label>
                  <input type="text" name='quotationAmt' className="form-control a1" id="quotationAmt"
                    value={quotationAmt}
                    onChange={handleQuotationAmt}
                    onBlur={handleBlur} />

                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="amtToWord" className='' style={{ fontSize: '14px' }}>Amt in Words</label>
                  <input type="text" name='amtToWord' className="form-control a1" id="amtToWord"
                    value={amtToWord}
                    onChange={handleAmtToWord}
                     />             
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="gst" className='' style={{ fontSize: '14px' }}>Gst</label>
                  <input type="text" name='gst' className="form-control a1" id="gst"
                    value={gst}
                    onChange={handleGst}
                   />
              
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="callBackDate" className='name_label stops' >Follow up Date</label>
                  <input type="date" name='callBackDate' className="form-control a1 stops_input" id="callBackDate"
                    value={callBackDate}
                    onChange={handleCallBackDate}
                    onBlur={handleBlur} />
                  {touched.callBackDate && errors.callBackDate ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.callBackDate}</p>
                  ) : null}

                </div>
              </div>


            </div>

            <div className="row mb-1">
              {/* <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="previousLiftAmt" className='' style={{ fontSize: '14px' }}>Previous Amount</label>
                  <input type="number" name='previousLiftAmt' className="form-control a1" id="previousLiftAmt"
                    value={previousLiftAmt}
                    onChange={handlePreviousLiftAmt}
                    onBlur={handleBlur} disabled="true" />
                 
                </div>
              </div> */}

            </div>

            <div className="row mb-1">
            <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="note" className='' style={{ fontSize: '14px' }}>Note</label>
                  <input type="text" name='note' className="form-control a1" id="note"
                    value={note}
                    onChange={handleNote}
                     />
              
                </div>
              </div>

            </div>

            <div className='d-flex justify-content-end'>

              <div className="btn-group my-3 ">
                {/* <button type="button" className="btn btn-primary mx-2">Preview</button>
                <button type="button" className="btn btn-primary mx-3">Print</button> */}
                {/* <button type="submit" className="btn btn-primary mx-3">Save</button> */}
                < button onClick={() => storeUpdateQuoLiftData(data.li_quo_cu_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>
              </div>

            </div>

          </div>
        </form>
      </div>

    </>
  )
}

export default UpdateLiftQuotationAdd