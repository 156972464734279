import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";


const initialValues = {

    customerName: "",
    quoDate: "",
    mobileNo: "",
    liftType: "",
    liftTravel: "",
    workingStatus: "",
    preAddress: ""

};




const UpdateEnquiryAdd = () => {


    const navigate = useNavigate();

    const { id } = useParams();

    const [data, setData] = useState([])
    const [customerName, setCustomerName] = useState("");
    const [quoDate, setQuoDate] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [liftType, setLiftType] = useState("");
    const [liftTravel, setLiftTravel] = useState("");
    const [preAddress, setPreAddress] = useState("");
    const [workingStatus, setWorkingStatus] = useState("");
    const [shaftDetails, setShaftDetails] = useState("");
    const [typeOfEnquiry, setTypeOfEnquiry] = useState("");



    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            // validationSchema: enquiryAddSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                // action.resetForm();
                navigate("/app/enquiry")
            },
        });


    const handleCustomerName = (event) => {
        // console.log("On Change");

        setCustomerName(event.target.value);
        handleChange(event);
    }

    const handleQuoDate = (event) => {
        // console.log("On Change");

        setQuoDate(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }

    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }


    const handleLiftTravel = (event) => {
        // console.log("On Change");

        setLiftTravel(event.target.value);
        handleChange(event);
    }


    const handlePreAddress = (event) => {
        // console.log("On Change");

        setPreAddress(event.target.value);
        handleChange(event);
    }

    const handleWorkingStatus = (event) => {
        // console.log("On Change");

        setWorkingStatus(event.target.value);
        handleChange(event);
    }

    const handleShaftDetails = (event) => {
        // console.log("On Change");

        setShaftDetails(event.target.value);
        handleChange(event);
    }

    const handleTypeOfEnquiry = (event) => {
        // console.log("On Change");

        setTypeOfEnquiry(event.target.value);
        handleChange(event);
    }

    ///////////////////////////////////////////////////////// Api call /////////////////////////////////////////////

    useEffect(() => {

        async function fetchresult() {
            try {
                const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_enquiry_update/${id}`);
                const result = await response.json();
                console.log("result:", result);
                setData(result);
                setQuoDate(result.en_date)
                setCustomerName(result.en_cu_name)
                setMobileNo(result.en_mob_no)
                setPreAddress(result.en_cu_address)
                setLiftType(result.en_lift_type)
                setLiftTravel(result.en_lift_travel)
                setWorkingStatus(result.en_status)
                setShaftDetails(result.en_shaft_details)
                setTypeOfEnquiry(result.en_type_status)

            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchresult();

    }, [id]);


    ///////////////////// Api to enquiry updated data Store Api  ///////////////////////////////////////////

    async function storeUpdatedEnquiryData(id) {
        const formData = new FormData();
        formData.append("customerName", customerName)
        formData.append("quoDate", quoDate)
        formData.append("mobileNo", mobileNo)
        formData.append("workingStatus", workingStatus)
        formData.append("liftType", liftType)
        formData.append("liftTravel", liftTravel)
        formData.append("preAddress", preAddress)
        formData.append("shaftDetails", shaftDetails)
        formData.append("typeOfEnquiry", typeOfEnquiry)

        try {
            let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/enquiry_update_store/' + id + '?method=PUT', {
                method: 'POST',
                body: formData
            });

            let data1 = await response.json(); // Parse the response body as JSON

            if (data1.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data1.message);


            } else {
                alert("An error occurred while saving the data.");
            }


        } catch (error) {
            console.error('Error while adding customer:', error);
            alert("An error occurred while saving the data2.");

        }
    }




    return (
        <>
            <div className='container'>

                <form onSubmit={handleSubmit} >

                    <div className="row mb-1 mt-5">
                        <div className="col-md-9 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Enquiry</h4>
                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date</label>
                                <input type="date" name='quoDate' className="form-control a1" id="quoDate"
                                    value={quoDate}
                                    onChange={handleQuoDate}
                                    onBlur={handleBlur}
                                />

                            </div>
                        </div>


                    </div>

                    <hr />

                    <div className="row mb-1">


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name</label>
                                <input type="text" name='customerName' className="form-control a1" id="customerName"
                                    value={customerName}
                                    onChange={handleCustomerName}
                                    onBlur={handleBlur}
                                />

                            </div>

                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No</label>
                                <input type="number" name='mobileNo' className="form-control a1" id="mobileNo"
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="liftType" className='' style={{ fontSize: '14px' }}>Lift type</label>
                                <input type="text" name='liftType' className="form-control a1" id="liftType"
                                    value={liftType}
                                    onChange={handleLiftType}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>


                        {/* <div className="col-4 ">

                            <div className="form-group">
                                <label htmlFor="liftType" className='name_label door_type' style={{ fontSize: '12px' }}>Lift type:*</label>
                                <div className="dropdown-select">
                                    <select
                                        name="liftType" className="form-control a1" id="liftType"
                                        value={liftType}
                                        onChange={handleLiftType}
                                        onBlur={handleBlur}
                                    >

                                        <option value=""></option>
                                        <option value="I M P Door" style={{ background: '#eaeaea' }}>I M P Door</option>
                                        <option value="Swing Door" style={{ background: '#eaeaea' }}>Swing Door</option>
                                        <option value="Automatic Door MR" style={{ background: '#eaeaea' }}>Automatic Door MR</option>
                                        <option value="Automatic Door MRL" style={{ background: '#eaeaea' }}>Automatic Door MRL</option>
                                        <option value="Hydrolic Lift" style={{ background: '#eaeaea' }}>Hydrolic Lift</option>
                                        <option value="Good's Lif" style={{ background: '#eaeaea' }}>Good's Lift</option>
                                        <option value="Typing Manually" style={{ background: '#eaeaea' }}>Typing Manually</option>
                                    </select>
                                    <i className="dropdown-icon fas fa-caret-down"></i>
                                </div>
                                {touched.liftType && errors.liftType ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.liftType}</p>
                                ) : null}
                            </div>
                        </div> */}


                    </div>



                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="liftTravel" className='' style={{ fontSize: '14px' }}>Lift Travel</label>
                                <input type="text" name='liftTravel' className="form-control a1" id="liftTravel"
                                    value={liftTravel}
                                    onChange={handleLiftTravel}
                                    onBlur={handleBlur}
                                />

                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="workingStatus" className='' style={{ fontSize: '14px' }}>Enquiry Status</label>
                                <input type="text" name='workingStatus' className="form-control a1" id="workingStatus"
                                    value={workingStatus}
                                    onChange={handleWorkingStatus}
                                    onBlur={handleBlur}
                                />

                            </div>
                        </div>

                        {/* <div className='col-4'>

                            <label htmlFor="workingStatus " className='name_label door_type' style={{ fontSize: '12px' }} >Enquiry Status:*</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='workingStatus'
                                id='workingStatus'
                                value={workingStatus}
                                onChange={handleWorkingStatus}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                <option value="Pending" style={{ background: '#eaeaea' }}>Pending</option>
                                <option value="Finalised" style={{ background: '#eaeaea' }}>Finalised</option>
                                <option value="Quotation Raised" style={{ background: '#eaeaea' }}>Quotation Raised</option>
                                <option value="Cancelled" style={{ background: '#eaeaea' }}>Cancelled</option>
                                <option value="Cancelled" style={{ background: '#eaeaea' }}>Manually Typing</option>
                            </select>
                            {touched.workingStatus && errors.workingStatus ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.workingStatus}</p>
                            ) : null}

                        </div> */}


                     <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="typeOfEnquiry" className='' style={{ fontSize: '14px' }}>Type of Enquiry</label>
                                <input type="text" name='typeOfEnquiry' className="form-control a1" id="typeOfEnquiry"
                                    value={typeOfEnquiry}
                                    onChange={handleTypeOfEnquiry}
                                    onBlur={handleBlur}
                                />

                            </div>
                        </div>


                        {/* <div className='col-4'>

                            <label htmlFor="typeOfEnquiry " className='name_label door_type' style={{ fontSize: '12px' }} >Type of Enquiry</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='typeOfEnquiry'
                                id='typeOfEnquiry'
                                value={typeOfEnquiry}
                                onChange={handleTypeOfEnquiry}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                <option value="New Lift" style={{ background: '#eaeaea' }}>New Lift</option>
                                <option value="AMC" style={{ background: '#eaeaea' }}>AMC</option>
                                <option value="Modulation" style={{ background: '#eaeaea' }}>Modulation</option>
                                <option value="Call Base" style={{ background: '#eaeaea' }}>Call Base</option>
                                <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                            </select>
                            {touched.typeOfEnquiry && errors.typeOfEnquiry ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.typeOfEnquiry}</p>
                            ) : null}

                        </div> */}

                    </div>

                    
                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="shaftDetails" className='' style={{ fontSize: '12px' }}>Shaft Details</label>
                                <input type="text" name='shaftDetails' className="form-control a1" id="shaftDetails"
                                    value={shaftDetails}
                                    onChange={handleShaftDetails}
                                    onBlur={handleBlur}
                                />
                                {touched.shaftDetails && errors.shaftDetails ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.shaftDetails}</p>
                                ) : null}
                            </div>
                        </div>
                        </div>

                    <div className="row mb-5">
                        <div className="col-12 ">
                            <div className="form-group">
                                <label htmlFor="preAddress" className='name_label address' style={{ fontSize: '14px' }} >Address</label>
                                <textarea className="form-control a1 address_input" name='preAddress' id="preAddress" rows="5"
                                    value={preAddress}
                                    onChange={handlePreAddress}
                                    onBlur={handleBlur}
                                ></textarea>
                            </div>
                        </div>

                    </div>

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                        < button onClick={() => storeUpdatedEnquiryData(data.en_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>

                    </div>

                </form>

            </div>



        </>
    )
}

export default UpdateEnquiryAdd