import React, { useState } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { liftReportSchema } from '../Schema/LiftReportIndex';
import { Table } from 'react-bootstrap';


const initialValues = {
    startDate: "",
    endDate: "",
    customerName: "",
}


const EnquiryReportMaster = () => {


    const navigate = useNavigate();

    const [customerDetails, setCustomerDetails] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: liftReportSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                action.resetForm();
                // navigate("/app/breakdown")

            },
        });

    /////////////////////////////////////////////////////////////////////////////////


  

    const openPrint = () => {
       
        navigate("/app/enquiry_report_print", {
          
            state : { customerDetails , searchValue  },
          });
    }



    /////////////////////////////// Api Call////////////////////////////////


    const handleSearchClick = async (event) => {
        event.preventDefault();

        const startDate = values.startDate;
        const endDate = values.endDate;

        try {
            let response;

            response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_enquiry_report?startDate=${startDate}&endDate=${endDate}`);


            if (response.ok) {
                const data = await response.json();
                console.log("Enquiry report details:", data);

                // Apply search filtering to the data
                const filteredData = data
                    .filter(item =>
                        item.en_type_status.toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.en_status.toLowerCase().includes(searchValue.toLowerCase())
                    );

                if (filteredData.length === 0) {
                    // No data found, display a message to the user
                    console.log("No data found.");
                    window.alert("Please select Start date and End date.");
                    // You can update the state or display a message to the user here.
                } else {
                    setCustomerDetails(filteredData);
                }
            } else {
                console.error('Error Enquiry report details:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching Enquiry report details:', error);
        }
    };

    return (
        <>
            <div className='container'>

                <form onSubmit={handleSubmit}>


                    <div className="row ">
                        <div className='col-md-10 mt-4 mt-md-0'>
                            <h5>Enquiry Report</h5>
                        </div>


                        <div className='col-md-2'>

                            <input
                                type="text"
                                className="form-control"
                                style={{ borderColor: 'black' }}
                                placeholder="Search by Customer ID or Name"
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />

                        </div>
                    </div>

                    <hr />



                    <div className="d-md-flex justify-content-between">

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="startDate" className='' style={{ fontSize: '14px' }}>Start Date</label>
                                <input type="date" className="form-control a1" id="startDate" name='startDate'
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.startDate && errors.startDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="endDate" className='' style={{ fontSize: '14px' }}>End Date</label>
                                <input type="date" className="form-control a1" id="endDate" name='endDate'
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.endDate && errors.endDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='submit' className='btn btn-primary' onClick={(event) => { handleSearchClick(event); setSearchValue(''); }}>Search</button>
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='button' onClick={openPrint} className='btn btn-primary '>Print</button>
                            </div>
                        </div>

                    </div>

                    <Table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col"> Enquiry Date</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Mobile No.</th>
                                <th scope="col">Lift type</th>
                                <th scope="col">Lift Travel</th>
                                <th scope="col">Status Enquiry:</th>
                                <th scope="col">Type of Enquiry</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {customerDetails
                                .filter(item =>
                                    item.en_type_status.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.en_status.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.en_date}</td>
                                        <td>{item.en_cu_name}</td>
                                        <td>{item.en_mob_no}</td>
                                        <td>{item.en_lift_type}</td>
                                        <td>{item.en_lift_travel}</td>
                                        <td>{item.en_status}</td>
                                        <td>{item.en_type_status}</td>
                                        
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </form>
           </div>

        </>
    )
}

export default EnquiryReportMaster