import * as Yup from "yup";


export const updateModulationQuotationSchema = Yup.object({

    // modulationQuotationNo:Yup.string().required("Please enter your Modulation Quotation No."), 
    // customerName:Yup.string().required("Please enter lift type"),
    // mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
    // liftType: Yup.string().required("Please Select lift type"),
    // panNo: Yup.string().required("Please enter your PAN No."),
    // gstNo: Yup.string().required("Please enter your GST No."),
    // stops:Yup.string().required("Please enter your stops "),
    // perticular:Yup.string().required("Please enter your Perticular "),
    // make:Yup.string().required("Please enter your Make "),
    // quantity:Yup.string().required("Please enter your Quantity "),
    // rate: Yup.number().integer().positive().required("Please enter your Rate"),
    // quoDate: Yup.string().required("Please Select Date"),
    // inputEmail: Yup.string().email().required("Please enter your email"),

  });
  