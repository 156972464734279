import React ,{useState,useEffect} from 'react'
import { Table } from 'react-bootstrap'
// import '../App.css';
import { useNavigate, Link } from "react-router-dom";
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';

const Users = () => {

    
    const navigate = useNavigate();
    const [data, setData] = useState([])

    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total number of pages


    const handleUpdate = (us_id) => {
        // Navigate two steps back
        navigate(`/app/update_user/${us_id}`);
      };
    


    useEffect( () => {
       
        getData()
        
    }, [currentPage])


    async  function deleteOperation(id)
    {
        // alert(id)
        const confirmDelete  = window.confirm('Do you want delete?');
      if (confirmDelete ) {
        let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_user/"+id,{
            method:'DELETE'
        });
        result = await result.json();
        // console.log(result)
        if (result.message) {
          // If the response status is within the 2xx range, it means the request was successful.
          if (result.message ) {
            // alert(result.message);
            alert('Deletion confirmed. Deleting...');
            }else{
              alert('Deletion cancelled.');
            }
       
        }else{
          alert("User not found.");
     }

     getData();
        

    }
}


    function getData(){
      
        async function fetchData(){
            try{
            let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_user?page=${currentPage}&perPage=${itemsPerPage}`);
            const { data: fetchedData, last_page: total } = await result.json();
            // console.log("data", result);
            setData(fetchedData);
            setTotalPages(total);
            } catch (error){
                console.error('Error',error);
            } 
        }
    
        fetchData();
    
    }


    function userAdd() {

        navigate("/app/add_user")
    }


        // Handle page change
        const handlePageChange = (pageNumber) => {
            setCurrentPage(pageNumber);
        };
            
            
        // Calculate the range of page numbers to display in the pagination UI
        const getPageRange = () => {
            const rangeSize = 5; // Number of page numbers to display
            const totalPagesInRange = Math.min(rangeSize, totalPages);
            let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
            let endPage = startPage + totalPagesInRange - 1;
        
            // Adjust endPage if it exceeds totalPages
            if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - totalPagesInRange + 1, 1);
            }
        
            return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
        };



    return (
        <>
            <div className=''>
                <h5> User Master</h5>
                <hr />
                <button onClick={userAdd} className="btn btn-primary buttonMargin">+Add</button>
            </div>

            <div className='container'>

                <Table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Sl No</th>
                            <th scope="col">User Name</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status</th>
                            <th scope="col">Address</th>
                            {/* <th scope="col">image</th> */}
                            <th scope="col">Action</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item)=>
                        <tr key={item.li_no}>
                        {/* <th scope="row">1</th> */}
                        <td>{item.us_id}</td>
                        <td>{item.us_name}</td>
                        <td>{item.us_email}</td>
                        <td>{item.us_mobile}</td>                       
                        <td>{item.us_role}</td>
                        <td>{item.user_status}</td>
                        <td>{item.us_address}</td>
                        {/* <td>item.us_image</td> */}
                                               
                        {/* <td><span onClick={()=>{deleteOperation(item.id)}} className='delete'>Delete</span></td> */}
                        {/* <td><Link to={"app/updateCustomer/"+item.cu_id}><span  className='update'>{<UpdateIcon/>}</span></Link><span onClick={()=>{deleteOperation(item.cu_id)}} >{<DeleteIcon style={{color:'red'}}/>}</span></td> */}
                        <td><span  className='update' onClick={() => handleUpdate(item.us_id)}>{<UpdateIcon/>}</span><span onClick={()=>{deleteOperation(item.us_id)}} >{<DeleteIcon style={{color:'red'}}/>}</span></td>
                        </tr>
                        
                        )
                        
                    }
                    </tbody>
                </Table>

                  {/* Pagination */}
                    <div className='pagination'>
                    {/* Show previous arrow icon */}
                    {currentPage > 1 && (
                        <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
                    )}

                    {/* Show page numbers */}
                    {getPageRange().map((page) => (
                        <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={currentPage === page ? 'active' : ''}
                        >
                        {page}
                        </button>
                    ))}

                    {/* Show next arrow icon */}
                    {currentPage < totalPages && (
                        <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                    )}
                    </div>

            </div>
        </>
    )
}

export default Users