import * as Yup from "yup";



export const newModulationSchema = Yup.object({


  moduQuotationNo: Yup.string().required("Please enter quotation no."),
  paidAmt: Yup.number().required("Please enter paid amount"),
  moduDate: Yup.string().required("Please select date"),
  // followUpDate: Yup.string().required("Please select follow up date"),
  // termsAndConditions:Yup.string().required("Please select terms and conditions"),
  workingStatus:Yup.string().required("Please select status"),
  // modulationNo:Yup.string().required("Please enter modulation no."), 
  // customerName: Yup.string().min(2).max(25).required("Please enter your name"),
  // mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
  // liftType: Yup.string().required("Please enter lift type"),
  // panNo: Yup.string().required("Please enter PAN no."),
  // gstNo: Yup.string().required("Please enter GST no."),
  // stops:Yup.string().required("Please enter stops "),
  make: Yup.string().required("Please enter make "),
  perticular: Yup.string().required("Please enter perticular "),
  quantity: Yup.number().integer().positive().required("Please enter quantity"),
  rate: Yup.number().integer().positive().required("Please enter rate"),
  previousBal: Yup.string().required("Please enter previous balance"),
  // totalBalance:Yup.number().integer().positive().required("Please enter total balance"),
  // inputEmail: Yup.string().email().required("Please enter email address"),



});