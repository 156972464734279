import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { useNavigate , useLocation } from "react-router-dom";

const AmcServicePrint = () => {

    const navigate = useNavigate();
    
    const componentPDF = useRef();
    const location = useLocation();

    const selectedItem = location.state.selectedItem;

    console.log("selectedItem",selectedItem);


    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    function backtodata() {
        navigate("/app/amc_service_data");
    }


  return (
    <>
    
    <div className='container d-flex justify-content-center align-items-center w-75 ' ref={componentPDF} style={{ width: "60%" }}>
                <div className='row border border-dark text-dark '>

                <div className=' d-flex  justify-content-between mt-1 '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com</p>
                
              </div>

                    <div className='text-center'><h5><b>AMC Service:</b></h5></div>

                    <div className='d-flex justify-content-between  mt-4'>
                        <div className='ms-5 text-danger' style={{textTransform : "capitalize"}}>Cust Id: <strong className='text-primary'>{ selectedItem.amc_ser_cust_id } </strong></div>
                        <div className=' me-5' style={{textTransform : "capitalize"}}>Date:{ selectedItem.amc_ser_date }</div>
                    </div><br /><br /><br /><br />

                     <div className='mt-3'>
                     
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Customer Name:{ selectedItem.amc_ser_cust_name }</div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Quotation No: { selectedItem.amc_ser_quo_no }</div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Technician Name: { selectedItem.amc_ser_tech_name }</div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Remark: { selectedItem.amc_ser_remark }</div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Mobile No: { selectedItem.amc_ser_mob_no }</div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Address: { selectedItem.amc_ser_address }</div>


                    </div>
                </div>
            </div>

            <div className=' d-flex justify-content-end w-50 float-end mt-4'>

            <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata}>Cancel</button>
            <button type="button" onClick={generatePDF} style={{ width: "80px" }} className="btn btn-primary mx-3">Ok</button>

        </div>
    
    </>
  )
}

export default AmcServicePrint