import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { liftQuotationSchema } from '../Schema/LiftQuotationindex';




const initialValues = {


  customerName: "",
  gstNo: "",
  panNo: "",
  inputEmail: "",
  mobileNo: "",
  quotationNo: "",
  preAddress: "",
  subject: "",
  requireTo: "",
  toBeInstalledAt: "",
  liftType: "",
  capacity: "",
  // powerPackLocationNo: "",
  motorMachine: "",
  ropes: "",
  speed: "",
  controller: "",
  drive: "",
  signals: "",
  emergencySignals: "",
  liftTravel: "",
  landingAndOpening: "",
  stops: "",
  carEncloser: "",
  flooring: "",
  doorType: "",
  landingDoor: "",
  powerSupply: "",
  quotationAmt: "",
  quoDate: "",
  uniqueId: "",
  liftWorkStatus: "",
  description: "",
  callBackDate: "",
  amtToWord: "",
};


const LiftQuatation = () => {


  const [inputVisible, setInputVisible] = useState(false);


  //////////////////////////////////////////////////

  const navigate = useNavigate();
  // const [customerName1, setCustomerName1] = useState("");
  // const [gstNo1, setGstNo1] = useState("");
  // const [panNo1, setPanNo1] = useState("");
  // const [inputEmail1, setInputEmail1] = useState("");
  // const [mobileNo1, setMobileNo1] = useState("");


  const [showInputField, setShowInputField] = useState(false);

  const [selCustomerNames, setSelCustomerNames] = useState([]);

  const [uniqueId, setUniqueId] = useState("");
  const [quoDate, setQuoDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [preAddress, setPreAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [requireTo, setRequireTo] = useState("");
  const [toBeInstalledAt, setToBeInstalledAt] = useState("");
  const [liftType, setLiftType] = useState("");
  const [capacity, setCapacity] = useState("");
  const [note, setNote] = useState("");
  const [gst, setGst] = useState("");
  const [amtToWord, setAmtToWord] = useState("");
  const [motorMachine, setMotorMachine] = useState("");
  const [mmBrand, setMmBrand] = useState("");
  const [ropesBrand, setRopesBrand] = useState("");
  const [controllerBrand, setControllerBrand] = useState("");
  const [doorTypeBrand, setDoorTypeBrand] = useState("");
  const [landingDoorBrand, setLandingDoorBrand] = useState("");
  const [ropes, setRopes] = useState("");
  const [speed, setSpeed] = useState("");
  const [controller, setController] = useState("");
  const [drive, setDrive] = useState("");
  const [signals, setSignals] = useState("Up/Down direction indicators & floor position indicators in car and every landing, call register indicator,door open and floor announcement system.");
  const [emergencySignals, setEmergencySignals] = useState("Alarm and emergency light");

  const [liftTravel, setLiftTravel] = useState("");
  const [landingAndOpening, setLandingAndOpening] = useState("");
  const [stops, setStops] = useState("");
  const [carEncloser, setCarEncloser] = useState("");
  const [flooring, setFlooring] = useState("");
  const [doorType, setDoorType] = useState("");
  const [landingDoor, setLandingDoor] = useState("");
  const [powerSupply, setPowerSupply] = useState("230 Volts, 50HZ, A.C. sUPPLY, 415 volts, 50Hz, A.C. Supply for motor");
  const [quotationAmt, setQuotationAmt] = useState("");
  const [description, setDescription] = useState("");
  const [callBackDate, setCallBackDate] = useState("");
  const [liftWorkStatus, setLiftWorkStatus] = useState("");
  const [liftmanuallyTyping, setLiftmanuallyTyping] = useState("");
  const [capacitymanuallyTyping, setCapacitymanuallyTyping] = useState("");
  const [motormanuallyTyping, setMotormanuallyTyping] = useState("");
  const [mmBrandmanuallyTyping, setMmBrandmanuallyTyping] = useState("");
  const [ropesmanuallyTyping, setRopesmanuallyTyping] = useState("");
  const [speedmanuallyTyping, setSpeedmanuallyTyping] = useState("");
  const [ropesBrandmanuallyTyping, setRopesBrandmanuallyTyping] = useState("");
  const [controllerBrandmanuallyTyping, setControllerBrandmanuallyTyping] = useState("");
  const [liftTravelmanuallyTyping, setLiftTravelmanuallyTyping] = useState("");
  const [landingAndOpeningmanuallyTyping, setLandingAndOpeningmanuallyTyping] = useState("");
  const [stopsmanuallyTyping, setStopsmanuallyTyping] = useState("");
  const [carEncloserManuallyTyping, setCarEncloserManuallyTyping] = useState("");
  const [flooringManuallyTyping, setFlooringManuallyTyping] = useState("");
  const [doorTypemanuallyTyping, setDoorTypemanuallyTyping] = useState("");
  const [doorTypeBrandmanuallyTyping, setdoorTypemanuallyTyping] = useState("");
  const [landingDoormanuallyTyping, setLandingDoormanuallyTyping] = useState("");
  const [landingDoorBrandmanuallyTyping, setLandingDoorBrandemanuallyTyping] = useState("");

  const [customerDetails, setCustomerDetails] = useState({
    gstNo: '',
    panNo: '',
    inputEmail: '',
    mobileNo: '',
    preAddress: ''
  });
  /////////////////////////////////////////////////////////////////////////////


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      validationSchema: liftQuotationSchema,
      onSubmit: async (values, action) => {
        // console.log("values:", values);
        await addLiftQuotation();
        paymentPrint();
        // navigate("/app/lift_data")
      },
    });
  console.log(errors);


  const handleChangeUniqueId = (event) => {

    setUniqueId(event.target.value);
    handleChange(event);
  }
  // const handlePreviousLiftAmt = (event) => {

  //   setPreviousLiftAmt(event.target.value);
  //   handleChange(event);
  // }

  const handleNote = (event) => {

    setNote(event.target.value);
    handleChange(event);
  }

  const handleGst = (event) => {

    setGst(event.target.value);
    handleChange(event);
  }

  const handleAmtToWord = (event) => {

    setAmtToWord(event.target.value);
    handleChange(event);
  }


  const handleQuoDate = (event) => {

    setQuoDate(event.target.value);
    handleChange(event);
  }

  const handleCustomerName = (event) => {

    setCustomerName(event.target.value);
    handleChange(event);
  }


  const handleGstNo = (event) => {
    // console.log("On Change");

    setGstNo(event.target.value);
    handleChange(event);
  }
  const handlePanNo = (event) => {
    // console.log("On Change");

    setPanNo(event.target.value);
    handleChange(event);
  }
  const handleInputEmail = (event) => {
    // console.log("On Change");

    setInputEmail(event.target.value);
    handleChange(event);
  }

  const handleMobileNo = (event) => {
    // console.log("On Change");

    setMobileNo(event.target.value);
    handleChange(event);
  }


  const handleQuotationNo = (event) => {
    // console.log("On Change");

    setQuotationNo(event.target.value);
    handleChange(event);
  }

  const handlePreAddress = (event) => {
    // console.log("On Change");

    setPreAddress(event.target.value);
    handleChange(event);
  }

  const handleSubject = (event) => {
    // console.log("On Change");

    setSubject(event.target.value);
    handleChange(event);
  }

  const handleRequireTo = (event) => {
    // console.log("On Change");

    setRequireTo(event.target.value);
    handleChange(event);
  }

  const handleToBeInstalledAt = (event) => {
    // console.log("On Change");

    setToBeInstalledAt(event.target.value);
    handleChange(event);
  }

  const handleLiftType = (event) => {
    // console.log("On Change");

    setLiftType(event.target.value);
    handleChange(event);
  }

  const handleCapacity = (event) => {
    // console.log("On Change");

    setCapacity(event.target.value);
    handleChange(event);
  }

  // const handlePowerPackLocationNo = (event) => {
  // console.log("On Change");

  //   setPowerPackLocationNo(event.target.value);
  //   handleChange(event);
  // }

  const handleMotorMachine = (event) => {
    // console.log("On Change");

    setMotorMachine(event.target.value);
    handleChange(event);
  }

  const handleMMBrand = (event) => {
    // console.log("On Change");
    setMmBrand(event.target.value);
    handleChange(event);
  }

  const handleRopesBrand = (event) => {
    // console.log("On Change");

    setRopesBrand(event.target.value);
    handleChange(event);
  }

  const handleControllerBrand = (event) => {
    // console.log("On Change");

    setControllerBrand(event.target.value);
    handleChange(event);
  }


  const handleDoorTypeBrand = (event) => {
    // console.log("On Change");

    setDoorTypeBrand(event.target.value);
    handleChange(event);
  }

  const handleLandingDoorBrand = (event) => {
    // console.log("On Change");

    setLandingDoorBrand(event.target.value);
    handleChange(event);
  }


  const handleRopes = (event) => {
    // console.log("On Change");

    setRopes(event.target.value);
    handleChange(event);
  }

  const handleSpeed = (event) => {
    // console.log("On Change");

    setSpeed(event.target.value);
    handleChange(event);
  }

  const handleController = (event) => {
    // console.log("On Change");

    setController(event.target.value);
    handleChange(event);
  }

  const handleDrive = (event) => {
    // console.log("On Change");

    setDrive(event.target.value);
    handleChange(event);
  }

  const handleSignals = (event) => {
    // console.log("On Change");

    setSignals(event.target.value);
    handleChange(event);
  }

  const handleEmergencySignals = (event) => {
    // console.log("On Change");

    setEmergencySignals(event.target.value);
    handleChange(event);
  }

  const handleLiftTravel = (event) => {
    // console.log("On Change");

    setLiftTravel(event.target.value);
    handleChange(event);
  }

  const handleLandingAndOpening = (event) => {
    // console.log("On Change");

    setLandingAndOpening(event.target.value);
    handleChange(event);
  }

  const handleStops = (event) => {
    // console.log("On Change");

    setStops(event.target.value);
    handleChange(event);
  }

  const handleCarEncloser = (event) => {
    // console.log("On Change");

    setCarEncloser(event.target.value);
    handleChange(event);
  }

  const handleFlooring = (event) => {
    // console.log("On Change");

    setFlooring(event.target.value);
    handleChange(event);
  }

  const handleDoorType = (event) => {
    // console.log("On Change");

    setDoorType(event.target.value);
    handleChange(event);
  }

  const handleLandingDoor = (event) => {
    // console.log("On Change");

    setLandingDoor(event.target.value);
    handleChange(event);
  }

  const handlePowerSupply = (event) => {
    // console.log("On Change");

    setPowerSupply(event.target.value);
    handleChange(event);
  }

  const handleQuotationAmt = (event) => {
    // console.log("On Change");

    setQuotationAmt(event.target.value);
    handleChange(event);
  }

  const handleSetDescription = (event) => {
    // console.log("On Change");

    setDescription(event.target.value);
    handleChange(event);
  }

  const handleCallBackDate = (event) => {
    // console.log("On Change");

    setCallBackDate(event.target.value);
    handleChange(event);
  }

  const handleLiftWorkStatus = (event) => {
    // console.log("On Change");

    setLiftWorkStatus(event.target.value);
    handleChange(event);
  }


  const handleLiftManuallyTyping = (e) => {
    const newValue = e.target.value;
    setLiftmanuallyTyping(newValue);
  };

  const handleRopesManuallyTyping = (e) => {
    const newValue = e.target.value;
    setRopesmanuallyTyping(newValue);
  };

  const handleSpeedManuallyTyping = (e) => {
    const newValue = e.target.value;
    setSpeedmanuallyTyping(newValue);
  };


  const handleCapacityManuallyTyping = (e) => {
    const newValue = e.target.value;
    setCapacitymanuallyTyping(newValue);
  };

  const handleMotorManuallyTyping = (e) => {
    const newValue = e.target.value;
    setMotormanuallyTyping(newValue);
  };

  const handleMMBrandManuallyTyping = (e) => {
    const newValue = e.target.value;
    setMmBrandmanuallyTyping(newValue);
  };

  const handleDoorTypeBrandManuallyTyping = (e) => {
    const newValue = e.target.value;
    setdoorTypemanuallyTyping(newValue);
  };


  const handleLandingDoorBrandManuallyTyping = (e) => {
    const newValue = e.target.value;
    setLandingDoorBrandemanuallyTyping(newValue);
  };


  const handleRopesBrandManuallyTyping = (e) => {
    const newValue = e.target.value;
    setRopesBrandmanuallyTyping(newValue);
  };

  const handleControllerBrandManuallyTyping = (e) => {
    const newValue = e.target.value;
    setControllerBrandmanuallyTyping(newValue);
  };

  const handleLiftTravelManuallyTyping = (e) => {
    const newValue = e.target.value;
    setLiftTravelmanuallyTyping(newValue);
  };

  const handleDoorTypeManuallyTyping = (e) => {
    const newValue = e.target.value;
    setDoorTypemanuallyTyping(newValue);
  };

  const handleLandingDoorManuallyTyping = (e) => {
    const newValue = e.target.value;
    setLandingDoormanuallyTyping(newValue);
  };

  const handleLandingAndOpeningManuallyTyping = (e) => {
    const newValue = e.target.value;
    setLandingAndOpeningmanuallyTyping(newValue);
  };

  const handleStopsManuallyTyping = (e) => {
    const newValue = e.target.value;
    setStopsmanuallyTyping(newValue);
  };

  const handleCarEncloserManuallyTyping = (e) => {
    const newValue = e.target.value;
    setCarEncloserManuallyTyping(newValue);
  };


  const handleFlooringManuallyTyping = (e) => {
    const newValue = e.target.value;
    setFlooringManuallyTyping(newValue);
  };







  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleSearchClick = async () => {
    try {

      const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_data/${values.uniqueId}`);



      // if (!response.ok) {
      //     throw new Error('Error fetching data');
      // }

      const data = await response.json();

      if (data.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        alert(data.message);

      } else {



        console.log("data", data);
        setCustomerDetails(data);
        setCustomerName(data.cu_name)
        setGstNo(data.cu_gst)
        setPanNo(data.cu_pan)
        setInputEmail(data.cu_email)
        setMobileNo(data.cu_mobile)
        setPreAddress(data.cu_address)
        setUniqueId(data.cu_unique_id)
        // setPreviousLiftAmt(data.cu_lift_pre_bal)

      }
    } catch (error) {
      console.error('Error fetching lift quotation data:', error);
    }
  };




  //////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   getData();

  // }, []);



  // function getData() {

  //   async function fetchCustomerNames() {
  //     try {
  //       const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_customer_name');
  //       console.log("response:", response);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch customer names.');
  //       }

  //       const data = await response.json();
  //       console.log("data:", data);
  //       const customerArray = Object.entries(data);
  //       console.log("customerArray:", customerArray);
  //       setSelCustomerNames(customerArray);
  //     } catch (error) {
  //       console.error('Error fetching customer names:', error);
  //     }
  //   }

  //   fetchCustomerNames();
  // }


  // const handleChangeCustomer = async (event) => {
  //   const selectedCustomerId = event.target.value;

  //   setCustomerName(event.target.options[event.target.selectedIndex].text);



  //   if (selectedCustomerId) {

  //     try {
  //       const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_data/${selectedCustomerId}`);

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch customer details.');
  //       }

  //       const data1 = await response.json();
  //       console.log("selected", data1);

  //       setCustomerDetails(data1);
  //       setGstNo(data1.cu_gst)
  //       setPanNo(data1.cu_pan)
  //       setInputEmail(data1.cu_email)
  //       setMobileNo(data1.cu_mobile)
  //       setPreAddress(data1.cu_address)
  //       setUniqueId(data1.cu_unique_id)
  //       setPreviousLiftAmt(data1.cu_lift_pre_bal)
  //       console.log('Customer details:', data1)
  //     } catch (error) {
  //       console.error('Error fetching customer details:', error);
  //     }
  //   }
  //   else {
  //     setCustomerDetails({
  //       gstNo: '',
  //       panNo: '',
  //       inputEmail: '',
  //       mobileNo: '',
  //       preAddress: ''
  //     });
  //   }
  // }



  useEffect(() => {
    // Fetch the next quotation number when the component mounts
    const fetchNextQuotationNumber = async () => {
      try {
        const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/increment-quotation');
        const data = await response.json();
        console.log(data);
        setQuotationNo(data.newId.toString());
      } catch (error) {
        console.error('Error fetching next quotation number:', error);
      }
    };

    fetchNextQuotationNumber();
  }, []); // Empty dependency array to ensure this runs only once when the component mounts

  //////////////////////////////////////////////////




  async function addLiftQuotation() {
    // console.log(name,file,price,description)
    const confirmPrint = window.confirm('Do you want save?');
    if (confirmPrint) {

      const formData = new FormData();
      formData.append("uniqueId", uniqueId)
      formData.append("quoDate", quoDate)
      formData.append("customerName", customerName)
      formData.append("panNo", panNo)
      formData.append("gstNo", gstNo)
      formData.append("inputEmail", inputEmail)
      formData.append("mobileNo", mobileNo)
      formData.append("quotationNo", quotationNo)
      formData.append("preAddress", preAddress)
      formData.append("subject", subject)
      formData.append("requireTo", requireTo)
      formData.append("toBeInstalledAt", toBeInstalledAt)
      formData.append("note", note)
      formData.append("gst", gst)
      formData.append("amtToWord", amtToWord)

      formData.append("liftType", liftType)
      if (liftType === 'Manually Typing') {
        formData.append("liftmanuallyTyping", liftmanuallyTyping);
      }


      formData.append("capacity", capacity)
      if (capacity === 'Manually Typing') {
        formData.append("capacitymanuallyTyping", capacitymanuallyTyping);
      }
      // formData.append("powerPackLocationNo", powerPackLocationNo)
//////////////////////////////////////////////////////////////////////////////      
      formData.append("motorMachine", motorMachine)
      if (motorMachine === 'Manually Typing') {
        formData.append("motormanuallyTyping", motormanuallyTyping);
      }

      formData.append("mmBrand", mmBrand)
      if (mmBrand === 'Manually Typing') {
        formData.append("mmBrandmanuallyTyping", mmBrandmanuallyTyping);
      }
//////////////////////////////////////////////////////////////////////////
      formData.append("ropes", ropes)
      if (ropes === 'Manually Typing') {
        formData.append("ropesmanuallyTyping", ropesmanuallyTyping);
      }
      formData.append("ropesBrand", ropesBrand)
      if (ropesBrand === 'Manually Typing') {
        formData.append("ropesBrandmanuallyTyping", ropesBrandmanuallyTyping);
      }
/////////////////////////////////////////////////////////////////////////////////
      formData.append("speed", speed)
      if (speed === 'Manually Typing') {
        formData.append("speedmanuallyTyping", speedmanuallyTyping);
      }
///////////////////////////////////////////////////////////////////////////////      
      formData.append("controller", controller)
      formData.append("controllerBrand", controllerBrand)
      if (controllerBrand === 'Manually Typing') {
        formData.append("controllerBrandmanuallyTyping", controllerBrandmanuallyTyping);
      }
      formData.append("drive", drive)
      formData.append("signals", signals)
      formData.append("emergencySignals", emergencySignals)
//////////////////////////////////////////////////////////////////////////////////////////     
      formData.append("liftTravel", liftTravel)
      if (liftTravel === 'Manually Typing') {
        formData.append("liftTravelmanuallyTyping", liftTravelmanuallyTyping);
      }
/////////////////////////////////////////////////////////////////////////////////////////
      formData.append("landingAndOpening", landingAndOpening)
      if (landingAndOpening === 'Manually Typing') {
        formData.append("landingAndOpeningmanuallyTyping", landingAndOpeningmanuallyTyping);
      }
//////////////////////////////////////////////////////////////////////////////////////
      formData.append("stops", stops)
      if (stops === 'Manually Typing') {
        formData.append("stopsmanuallyTyping", stopsmanuallyTyping);
      }
////////////////////////////////////////////////////////////////////////////////////////
      formData.append("carEncloser", carEncloser)
      if (carEncloser === 'Manually Typing') {
        formData.append("carEncloserManuallyTyping", carEncloserManuallyTyping);
      }
///////////////////////////////////////////////////////////////////////////////////////
      formData.append("flooring", flooring)
      if (flooring === 'Manually Typing') {
        formData.append("flooringManuallyTyping", flooringManuallyTyping);
      }
////////////////////////////////////////////////////////////////////////////////////////
      formData.append("doorType", doorType)
      if (doorType === 'Manually Typing') {
        formData.append("doorTypemanuallyTyping", doorTypemanuallyTyping);
      }

      formData.append("doorTypeBrand", doorTypeBrand)
      if (doorTypeBrand === 'Manually Typing') {
        formData.append("doorTypeBrandmanuallyTyping", doorTypeBrandmanuallyTyping);
      }
/////////////////////////////////////////////////////////////////////////////////////
      formData.append("landingDoor", landingDoor)
      if (landingDoor === 'Manually Typing') {
        formData.append("landingDoormanuallyTyping", landingDoormanuallyTyping);
      }
      formData.append("landingDoorBrand", landingDoorBrand)
      if (landingDoorBrand === 'Manually Typing') {
        formData.append("landingDoorBrandmanuallyTyping", landingDoorBrandmanuallyTyping);
      }
/////////////////////////////////////////////////////////////////////////////////////
      formData.append("powerSupply", powerSupply)
      formData.append("quotationAmt", quotationAmt)
      formData.append("description", description)
      formData.append("callBackDate", callBackDate)
      formData.append("liftWorkStatus", liftWorkStatus)
   

      try {
        let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/lift_quo_store', {
          method: 'POST',
          body: formData
        });

        let data = await response.json(); // Parse the response body as JSON

        if (data.message) {
          // If the response status is within the 2xx range, it means the request was successful.


          alert(data.message);


        } else {
          alert("An error occurred while saving the data.");
        }


      } catch (error) {
        console.error('Error while adding customer:', error);
        alert("An error occurred while saving the data2.");

      }
    }
  }


  function saveData() {
    navigate("/app/quotation")
  }

  function openAmcAdd() {
    navigate("/app/amc_quotation_add")
  }

  function openModuleAdd() {
    navigate("/app/modulation_quotation_add")
  }


  function paymentPrint() {
    // navigate("/app/quotation_print")

    const confirmPrint = window.confirm('Do you want to proceed with printing?');

    if (confirmPrint) {
      navigate("/app/quotation_print", {

        state: {

          customerName: customerName,
          uniqueId: uniqueId,
          quoDate: quoDate,
          quotationNo: quotationNo,
          mobileNo: mobileNo,
          panNo: panNo,
          gstNo: gstNo,
          inputEmail: inputEmail,
          preAddress: preAddress,
          subject: subject,
          description: description,
          requireTo: requireTo,
          toBeInstalledAt: toBeInstalledAt,
          liftType: liftType,
          capacity: capacity,
          // powerPackLocationNo: powerPackLocationNo,
          motorMachine: motorMachine,
          ropes: ropes,
          speed: speed,
          controller: controller,
          drive: drive,
          signals: signals,
          emergencySignals: emergencySignals,
          liftTravel: liftTravel,
          landingAndOpening: landingAndOpening,
          stops: stops,
          carEncloser: carEncloser,
          flooring: flooring,
          doorType: doorType,
          landingDoor: landingDoor,
          powerSupply: powerSupply,
          quotationAmt: quotationAmt,
          mmBrand:mmBrand,
          note:note,
          gst:gst,
          amtToWord:amtToWord,
          ropesBrand:ropesBrand,
          controllerBrand:controllerBrand,
          doorTypeBrand:doorTypeBrand,
          landingDoorBrand:landingDoorBrand,
          liftmanuallyTyping:liftmanuallyTyping,
          capacitymanuallyTyping:capacitymanuallyTyping,
          motormanuallyTyping:motormanuallyTyping,
          mmBrandmanuallyTyping:mmBrandmanuallyTyping,
          ropesmanuallyTyping:ropesmanuallyTyping,
          speedmanuallyTyping:speedmanuallyTyping,
          ropesBrandmanuallyTyping:ropesBrandmanuallyTyping,
          controllerBrandmanuallyTyping:controllerBrandmanuallyTyping,
          liftTravelmanuallyTyping:liftTravelmanuallyTyping,
          landingAndOpeningmanuallyTyping:landingAndOpeningmanuallyTyping,
          stopsmanuallyTyping:stopsmanuallyTyping,
          carEncloserManuallyTyping:carEncloserManuallyTyping,
          flooringManuallyTyping:flooringManuallyTyping,
          doorTypemanuallyTyping:doorTypemanuallyTyping,
          doorTypeBrandmanuallyTyping:doorTypeBrandmanuallyTyping,
          landingDoormanuallyTyping:landingDoormanuallyTyping,
          landingDoorBrandmanuallyTyping:landingDoorBrandmanuallyTyping,

        },
      });
    }
  }


  return (
    <>
      <div className='d-flex justify-content-around row'>
        <button className='btn btn-primary col-md-3 mt-4'>Lift Quotation</button>
        <button onClick={openAmcAdd} className='btn btn-primary col-md-3 mt-4'>AMC Quotation</button>
        <button onClick={openModuleAdd} className='btn btn-primary col-md-3 mt-4'>Modulation Quotation</button>
      </div>


      <div className='container'>

        <form onSubmit={handleSubmit}>

          <div className="row mb-1 mt-5">
            <div className="col-md-6 ">
              <div className="form-group">
                <h4 className='mt-4'>Lift Quotation</h4>
              </div>
            </div>


            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                <input type="text" className="form-control a1" defaultValue={customerDetails.cu_unique_id} name="uniqueId" id="uniqueId"
                  onChange={handleChangeUniqueId}
                  disabled="true"
                />
              </div>
            </div>


            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date</label>
                <input type="date" className="form-control a1" id="quoDate" name="quoDate"
                  // value={setQuoDate}
                  onChange={handleQuoDate}
                />
                {touched.quoDate && errors.quoDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.quoDate}</p>
                ) : null}
              </div>
            </div>
          </div>

          <hr />

          <div className="row mb-1">


            {/* <div className='col-4'>

              <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>

              <select className="form-select border border-dark" defaultValue={customerDetails.cu_name} style={{ boxShadow: 'none' }}
                name='customerName'
                id='customerName'
              
                onChange={handleChangeCustomer}>

                <option value="1" style={{ background: '#dddddd' }}>Select Name</option>

                {selCustomerNames.map(([cu_unique_id, cu_name]) => (
                  <option key={cu_unique_id} value={cu_unique_id} style={{ background: '#dddddd' }}>{cu_name}</option>
                ))}

                {touched.customerName && errors.customerName ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.customerName}</p>
                ) : null}

              </select>

            </div> */}

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Site Id:*</label>
                <input type="text" name="uniqueId" className="form-control a1" id="uniqueId"
                  value={uniqueId}
                  onChange={handleChangeUniqueId}
                  onBlur={handleBlur} />
                {touched.uniqueId && errors.uniqueId ? (
                  <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.uniqueId}</p>
                ) : null}
              </div>
            </div>



            <div className="col-4 d-grid ">
              <div className=" form-group ">
                <button className='btn btn-primary col-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
              </div>
            </div>

          </div>

          <div className='row mb-1'>
            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                <input type="text" name="customerName" className="form-control a1" defaultValue={customerDetails.cu_name} id="customerName"

                  onChange={handleCustomerName}
                  onBlur={handleBlur} disabled="true" />

              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No.</label>
                <input type="text" name='gstNo' className="form-control a1" defaultValue={customerDetails.cu_gst} id="gstNo"
                  value={gstNo}
                  onChange={handleGstNo} disabled="true" />
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN No.</label>
                <input type="text" name='panNo' className="form-control a1" defaultValue={customerDetails.cu_pan} id="panNo"
                  value={panNo}
                  onChange={handlePanNo} disabled="true" />
              </div>
            </div>
          </div>


          <div className="row mb-1">

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="inputEmail" className='' style={{ fontSize: '14px' }}>Email</label>
                <input type="email" name='inputEmail' className="form-control a1" defaultValue={customerDetails.cu_email} id="inputEmail"
                  value={inputEmail}
                  onChange={handleInputEmail} disabled="true" />

              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.</label>
                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo"
                  value={mobileNo}
                  onChange={handleMobileNo} disabled="true"
                />
              </div>
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quotation No</label>
                <input type="text" name='quotationNo' className="form-control a1" id="quotationNo"
                  value={quotationNo}
                  onChange={handleQuotationNo}
                  onBlur={handleBlur} disabled="true" />
                {/* {touched.quotationNo && errors.quotationNo ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.quotationNo}</p>
                ) : null} */}
              </div>
            </div>


            <div className="row mb-5">
              <div className="col-12 ">
                <div className="form-group">
                  <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                  <textarea className="form-control a1" name='preAddress' defaultValue={customerDetails.cu_address} id="preAddress" rows="5"
                    value={preAddress}
                    onChange={handlePreAddress}
                    onBlur={handleBlur} disabled="true"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className='page__main'>

            <h4>Lift Details</h4>
            <hr />

            <div className='row mb-1'>
             <div className="col-12 ">
                <div className="form-group">
                  <label htmlFor="subject" className='' style={{ fontSize: '14px' }}>Subject</label>
                  <input type="text" name='subject' className="form-control a1" id="subject"
                    value={values.subject}
                    onChange={handleSubject}
                    onBlur={handleBlur} />
                  {touched.subject && errors.subject ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.subject}</p>
                  ) : null}
                </div>
              </div>
            </div>


            <div className="row mb-1">
              <div className="col-12 ">
                <div className="form-group">
                  <label htmlFor="description" className='' style={{ fontSize: '14px' }}>Description</label>
                  <input type="text" name="description" className="form-control a1" id="description"
                    value={description}
                    onChange={handleSetDescription}
                    onBlur={handleBlur} />
                  {touched.description && errors.description ? (
                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.description}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='row mb-1'>
              {showInputField && (
                <div className="col-12 ">
                  <div className="form-group">
                    <label htmlFor="signals" className='' style={{ fontSize: '14px' }}>Signals</label>
                    <input type="text" name='signals' className="form-control a1" id="signals"
                      value={signals}
                      onChange={handleSignals}
                      onBlur={handleBlur} disabled="true" />
                    {/* {touched.signals && errors.signals ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.signals}</p>
                  ) : null} */}
                  </div>
                </div>
              )}

            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="requireTo" className='' style={{ fontSize: '14px' }}>Required To</label>
                  <input type="text" name='requireTo' className="form-control a1" id="requireTo"
                    value={values.requireTo}
                    onChange={handleRequireTo}
                    onBlur={handleBlur} />
                  {/* {touched.requireTo && errors.requireTo ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.requireTo}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="toBeInstalledAt" className='' style={{ fontSize: '14px' }}>To be Installed at</label>
                  <input type="text" className="form-control a1" name='toBeInstalledAt' id="toBeInstalledAt"
                    value={values.toBeInstalledAt}
                    onChange={handleToBeInstalledAt}
                    onBlur={handleBlur}
                  />

                  {/* {touched.toBeInstalledAt && errors.toBeInstalledAt ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.toBeInstalledAt}</p>
                  ) : null} */}
                </div>
              </div>



              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="motorMachine" className='' style={{ fontSize: '14px' }}>Motor Machines </label>
                  <select type="text" name='motorMachine' className="form-select border border-dark" id="motorMachine"
                    value={values.motorMachine}
                    onChange={handleMotorMachine}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="5HP MR Geared" style={{ background: '#eaeaea' }}>5HP MR Geared</option>
                    <option value="7.5HP MR Geared" style={{ background: '#eaeaea' }}>7.5HP MR Geared</option>
                    <option value="10HP MR Geared" style={{ background: '#eaeaea' }}>10HP MR Geared</option>
                    <option value="5HP MRL Gearless" style={{ background: '#eaeaea' }}>5HP MRL Gearless</option>
                    <option value="7.5HP MRL Gearless" style={{ background: '#eaeaea' }}>7.5HP MRL Gearless</option>
                    <option value="10HP MRL Gearless" style={{ background: '#eaeaea' }}>10HP MRL Gearless</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {motorMachine === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="motormanuallyTyping"
                      id="motormanuallyTyping"
                      value={motormanuallyTyping}
                      onChange={handleMotorManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                  {/* {touched.motorMachine && errors.motorMachine ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.motorMachine}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="mmBrand" className='' style={{ fontSize: '14px' }}>MM Brands</label>
                  <select type="text" name='mmBrand' className="form-select border border-dark" id="mmBrand"
                    value={values.mmBrand}
                    onChange={handleMMBrand}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="(Sharp)" style={{ background: '#eaeaea' }}>Sharp</option>
                    <option value="(Montanari)" style={{ background: '#eaeaea' }}>Montanari</option>
                    <option value="(Bharat Bijali)" style={{ background: '#eaeaea' }}>Bharat Bijali</option>

                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {mmBrand === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="mmBrandmanuallyTyping"
                      id="mmBrandmanuallyTyping"
                      value={mmBrandmanuallyTyping}
                      onChange={handleMMBrandManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-1">

              <div className="col-md-3 ">
                <div className="form-group">
                  <label htmlFor="capacity" className='' style={{ fontSize: '14px' }}>Capacity*</label>
                  <select type="text" name='capacity' className="form-select border border-dark" id="capacity"
                    value={values.capacity}
                    onChange={handleCapacity}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="2 Person's (136 Kgs)" style={{ background: '#eaeaea' }}>2 Person's (136 Kgs)</option>
                    <option value="3 Person's (204 Kgs)" style={{ background: '#eaeaea' }}>3 Person's (204 Kgs)</option>
                    <option value="4 Person's (272 Kgs)" style={{ background: '#eaeaea' }}>4 Person's (272 Kgs)</option>
                    <option value="5 Person's (340 Kgs)" style={{ background: '#eaeaea' }}>5 Person's (340 Kgs)</option>
                    <option value="6 Person's (408 Kgs)" style={{ background: '#eaeaea' }}>6 Person's (408 Kgs)</option>
                    <option value="8 Person's (544 Kgs)" style={{ background: '#eaeaea' }}>8 Person's (544 Kgs)</option>
                    <option value="10 Person's (680 Kgs)" style={{ background: '#eaeaea' }}>10 Person's (680 Kgs)</option>
                    <option value="13 Person's (884 Kgs)" style={{ background: '#eaeaea' }}>13 Person's (884 Kgs)</option>
                    <option value="16 Person's (1088 Kgs)" style={{ background: '#eaeaea' }}>16 Person's (1088 Kgs)</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {capacity === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="capacitymanuallyTyping"
                      id="capacitymanuallyTyping"
                      value={capacitymanuallyTyping}
                      onChange={handleCapacityManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                  {/* {touched.capacity && errors.capacity ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.capacity}</p>
                  ) : null} */}
                </div>
              </div>

              <div className='col-md-3'>

                <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Type Lift:</label>

                <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                  name='liftType'
                  id='liftType'
                  value={values.liftType}
                  onChange={handleLiftType}
                  onBlur={handleBlur}>
                  <option value="" style={{ display: 'none' }}></option>
                  <option value="Passanger Lift" style={{ background: '#eaeaea' }}>Passanger Lift</option>
                  <option value="Hospital Lift" style={{ background: '#eaeaea' }}>Hospital Lift</option>
                  <option value="Hydrolic Lift" style={{ background: '#eaeaea' }}>Hydrolic Lift</option>
                  <option value="Good's Lift" style={{ background: '#eaeaea' }}>Good's Lift</option>
                  <option value="Good's with Passanger Lift" style={{ background: '#eaeaea' }}>Good's with Passanger Lift</option>
                  <option value="Scissor Lift" style={{ background: '#eaeaea' }}>Scissor Lift</option>
                  <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                </select>

                {liftType === 'Manually Typing' && (
                  <input
                    type="text"
                    className="form-control a1"
                    name="liftmanuallyTyping"
                    id="liftmanuallyTyping"
                    value={liftmanuallyTyping}
                    onChange={handleLiftManuallyTyping}
                    onBlur={handleBlur}
                  />
                )}
              </div>

              <div className="col-md-3 ">
                <div className="form-group">
                  <label htmlFor="ropes" className='' style={{ fontSize: '14px' }}>Ropes</label>
                  <select type="text" name='ropes' className="form-select border border-dark" id="ropes"
                    value={values.ropes}
                    onChange={handleRopes}
                    onBlur={handleBlur} >

                    <option value="" style={{ display: 'none' }}></option>
                    <option value="10 mm dia 3nos" style={{ background: '#eaeaea' }}>10 mm dia 3nos</option>
                    <option value="13 mm dia 3nos" style={{ background: '#eaeaea' }}>13 mm dia 3nos</option>
                    <option value="8 mm dia 4nos" style={{ background: '#eaeaea' }}>8 mm dia 4nos</option>
                    <option value="6 mm dia 5nos" style={{ background: '#eaeaea' }}>6 mm dia 5nos</option>
                    <option value="6 mm dia 6nos" style={{ background: '#eaeaea' }}>6 mm dia 6nos</option>
                    <option value="8 mm dia 6nos" style={{ background: '#eaeaea' }}>8 mm dia 6nos</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {ropes === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="ropesmanuallyTyping"
                      id="ropesmanuallyTyping"
                      value={ropesmanuallyTyping}
                      onChange={handleRopesManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                  {/* {touched.ropes && errors.ropes ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.ropes}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="ropesBrand" className='' style={{ fontSize: '14px' }}>Ropes Brands</label>
                  <select type="text" name='ropesBrand' className="form-select border border-dark" id="ropesBrand"
                    value={values.ropesBrand}
                    onChange={handleRopesBrand}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="(Usha Martine)" style={{ background: '#eaeaea' }}>Usha Martine</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {ropesBrand === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="ropesBrandmanuallyTyping"
                      id="ropesBrandmanuallyTyping"
                      value={ropesBrandmanuallyTyping}
                      onChange={handleRopesBrandManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
               </div>
              </div>

            </div>

            <div className="row mb-1">
              <div className="col-md-3 ">
                <div className="form-group">
                  <label htmlFor="speed" className='' style={{ fontSize: '14px' }}>Speed</label>
                  <select type="text" name='speed' className="form-select border border-dark" id="speed"
                    value={values.speed}
                    onChange={handleSpeed}
                    onBlur={handleBlur} >

                    <option value="" style={{ display: 'none' }}></option>
                    <option value="0.68 mtr/sec" style={{ background: '#eaeaea' }}>0.68 mtr/sec</option>
                    <option value="1 mtr/sec" style={{ background: '#eaeaea' }}>1 mtr/sec</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {speed === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="speedmanuallyTyping"
                      id="speedmanuallyTyping"
                      value={speedmanuallyTyping}
                      onChange={handleSpeedManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                  {/* {touched.speed && errors.speed ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.speed}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="drive" className='' style={{ fontSize: '14px' }}>Drive Brand</label>
                  <select type="text" name='drive' className="form-select border border-dark" id="drive"
                    value={values.drive}
                    onChange={handleDrive}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="Monarch" style={{ background: '#eaeaea' }}>Monarch</option>
                    <option value="INVT" style={{ background: '#eaeaea' }}>INVT</option>
                    <option value="FUJI" style={{ background: '#eaeaea' }}>FUJI</option>
                    <option value="Close Loop" style={{ background: '#eaeaea' }}>Close Loop</option>

                  </select>

                  {/* {touched.drive && errors.drive ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.drive}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="controller" className='' style={{ fontSize: '14px' }}>Controller </label>
                  <select type="text" className="form-select border border-dark" id="controller"
                    value={values.controller}
                    onChange={handleController}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="Collective Micro-Processor Advanced Memory Controller" style={{ background: '#eaeaea' }}>Collective Micro-Processor Advanced Memory Controller</option>


                  </select>
                  {/* {touched.controller && errors.controller ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.controller}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="controllerBrand" className='' style={{ fontSize: '14px' }}>Controller Brands</label>
                  <select type="text" name='controllerBrand' className="form-select border border-dark" id="controllerBrand"
                    value={values.controllerBrand}
                    onChange={handleControllerBrand}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="(ADCO)" style={{ background: '#eaeaea' }}>ADCO</option>
                    <option value="(RIDHI)" style={{ background: '#eaeaea' }}>RIDHI</option>
                    <option value="(MONARCH)" style={{ background: '#eaeaea' }}>MONARCH</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {controllerBrand === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="controllerBrandmanuallyTyping"
                      id="controllerBrandmanuallyTyping"
                      value={controllerBrandmanuallyTyping}
                      onChange={handleControllerBrandManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-1">
              {showInputField && (
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="emergencySignals" className='' style={{ fontSize: '14px' }}>Emergency Signals </label>
                    <input type="text" name='emergencySignals' className="form-control a1" id="emergencySignals"
                      value={emergencySignals}
                      onChange={handleEmergencySignals}
                      onBlur={handleBlur} disabled="true" />
                    {/* {touched.emergencySignals && errors.emergencySignals ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.emergencySignals}</p>
                  ) : null} */}
                  </div>
                </div>
              )}

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="liftTravel" className='' style={{ fontSize: '14px' }}>Travel </label>
                  <select type="text" name='liftTravel' className="form-select border border-dark" id="liftTravel"
                    value={values.liftTravel}
                    onChange={handleLiftTravel}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="G+1 (G 1)" style={{ background: '#eaeaea' }}>G+1 (G 1)</option>
                    <option value="G+2 (G 1 2)" style={{ background: '#eaeaea' }}>G+2 (G 1 2)</option>
                    <option value="G+3 (G 1 2 3)" style={{ background: '#eaeaea' }}>G+3 (G 1 2 3)</option>
                    <option value="G+4 (G 1 2 3 4)" style={{ background: '#eaeaea' }}>G+4 (G 1 2 3 4)</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {liftTravel === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="liftTravelmanuallyTyping"
                      id="liftTravelmanuallyTyping"
                      value={liftTravelmanuallyTyping}
                      onChange={handleLiftTravelManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}


                  {/* {touched.liftTravel && errors.liftTravel ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.liftTravel}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="landingAndOpening" className='' style={{ fontSize: '14px' }}>Landings and Opening</label>
                  <select type="text" name='landingAndOpening' className="form-select border border-dark" id="landingAndOpening"
                    value={values.landingAndOpening}
                    onChange={handleLandingAndOpening}
                    onBlur={handleBlur} >

                    <option value="" style={{ display: 'none' }}></option>
                    <option value="2 Landing's 2 Opening's" style={{ background: '#eaeaea' }}>2 Landing's 2 Opening's</option>
                    <option value="3 Landing's 3 Opening's" style={{ background: '#eaeaea' }}>3 Landing's 3 Opening's</option>
                    <option value="4 Landing's 4 Opening's" style={{ background: '#eaeaea' }}>4 Landing's 4 Opening's</option>
                    <option value="5 Landing's 5 Opening's" style={{ background: '#eaeaea' }}>5 Landing's 5 Opening's</option>
                    <option value="6 Landing's 6 Opening's" style={{ background: '#eaeaea' }}>6 Landing's 6 Opening's</option>
                    <option value="7 Landing's 7 Opening's" style={{ background: '#eaeaea' }}>7 Landing's 7 Opening's</option>
                    <option value="8 Landing's 8 Opening's" style={{ background: '#eaeaea' }}>8 Landing's 8 Opening's</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {landingAndOpening === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="landingAndOpeningmanuallyTyping"
                      id="landingAndOpeningmanuallyTyping"
                      value={landingAndOpeningmanuallyTyping}
                      onChange={handleLandingAndOpeningManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}

                  {/* {touched.landingAndOpening && errors.landingAndOpening ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.landingAndOpening}</p>
                  ) : null} */}
                </div>
              </div>

              <div className='col-md-3'>

                <label htmlFor="doorType  " className='' style={{ fontSize: '14px' }}>Car Door</label>

                <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                  name='doorType'
                  id='doorType'
                  value={doorType}
                  onChange={handleDoorType}
                  onBlur={handleBlur}>
                  <option value="" style={{ display: 'none' }}></option>
                  <option value="S.S Automatic Door" style={{ background: '#dddddd' }}>S.S Automatic Door</option>
                  <option value="M.S Automatic Door" style={{ background: '#dddddd' }}>M.S Automatic Door</option>
                  <option value="S.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>S.S Automatic Big Vision Door</option>
                  <option value="M.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>M.S Automatic Big Vision Door</option>
                  <option value="M.S Swing Door's" style={{ background: '#dddddd' }}>M.S Swing Door's</option>
                  <option value="M.S IMPERFORATED Door's" style={{ background: '#dddddd' }}>M.S IMPERFORATED Door's</option>
                  <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                </select>


                {doorType === 'Manually Typing' && (
                  <input
                    type="text"
                    className="form-control a1"
                    name="doorTypemanuallyTyping"
                    id="doorTypemanuallyTyping"
                    value={doorTypemanuallyTyping}
                    onChange={handleDoorTypeManuallyTyping}
                    onBlur={handleBlur}
                  />
                )}


                {/* {touched.doorType && errors.doorType ? (
  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.doorType}</p>
) : null} */}

              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="doorTypeBrand" className='' style={{ fontSize: '14px' }}>CD Brands</label>
                  <select type="text" name='doorTypeBrand' className="form-select border border-dark" id="doorTypeBrand"
                    value={values.doorTypeBrand}
                    onChange={handleDoorTypeBrand}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="(EC India)" style={{ background: '#eaeaea' }}>EC India</option>
                    <option value="(BST)" style={{ background: '#eaeaea' }}>BST</option>
                    <option value="(Fermator)" style={{ background: '#eaeaea' }}>Fermator</option>
                    <option value="(Genisis)" style={{ background: '#eaeaea' }}>Genisis</option>

                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {doorTypeBrand === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="doorTypeBrandmanuallyTyping"
                      id="doorTypeBrandmanuallyTyping"
                      value={doorTypeBrandmanuallyTyping}
                      onChange={handleDoorTypeBrandManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-1">

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                  <select type="text" className="form-select border border-dark" id="stops"
                    value={values.stops}
                    onChange={handleStops}
                    onBlur={handleBlur} >

                    <option value="" style={{ display: 'none' }}></option>
                    <option value="2" style={{ background: '#eaeaea' }}>2</option>
                    <option value="3" style={{ background: '#eaeaea' }}>3</option>
                    <option value="4" style={{ background: '#eaeaea' }}>4</option>
                    <option value="5" style={{ background: '#eaeaea' }}>5</option>
                    <option value="6" style={{ background: '#eaeaea' }}>6</option>
                    <option value="7" style={{ background: '#eaeaea' }}>7</option>
                    <option value="8" style={{ background: '#eaeaea' }}>8</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {stops === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="stopsmanuallyTyping"
                      id="stopsmanuallyTyping"
                      value={stopsmanuallyTyping}
                      onChange={handleStopsManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}

                  {/* {touched.stops && errors.stops ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.stops}</p>
                  ) : null} */}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="carEncloser " className='' style={{ fontSize: '14px' }}>Car Encloser </label>
                  <select type="text" name='carEncloser' className="form-select border border-dark" id="carEncloser"
                    value={values.carEncloser}
                    onChange={handleCarEncloser}
                    onBlur={handleBlur} >

                    <option value="" style={{ display: 'none' }}></option>
                    <option value="S.S Hairline Finish Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Hairline Finish Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="S.S Designer Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Designer Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="M.S Designer Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>M.S Designer Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="S.S Glass Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Glass Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="M.S Glass Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>M.S Glass Cabin with Spotlight Fall Ceilling & Bloor</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>
                  {/* {touched.carEncloser && errors.carEncloser ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.carEncloser}</p>
                  ) : null} */}

                  {carEncloser === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="carEncloserManuallyTyping"
                      id="carEncloserManuallyTyping"
                      value={carEncloserManuallyTyping}
                      onChange={handleCarEncloserManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}

                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="landingDoor" className='' style={{ fontSize: '14px' }}>Landing Door </label>
                  <select type="text" name='landingDoor' className="form-select border border-dark" id="landingDoor"
                    value={values.landingDoor}
                    onChange={handleLandingDoor}
                    onBlur={handleBlur} >

                    <option value="" style={{ display: 'none' }}></option>
                    <option value="S.S Automatic Door" style={{ background: '#dddddd' }}>S.S Automatic Door</option>
                    <option value="M.S Automatic Door" style={{ background: '#dddddd' }}>M.S Automatic Door</option>
                    <option value="S.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>S.S Automatic Big Vision Door</option>
                    <option value="M.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>M.S Automatic Big Vision Door</option>
                    <option value="M.S Swing Door's" style={{ background: '#dddddd' }}>M.S Swing Door's</option>
                    <option value="M.S IMPERFORATED Door's" style={{ background: '#dddddd' }}>M.S IMPERFORATED Door's</option>
                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>


                  {landingDoor === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="landingDoormanuallyTyping"
                      id="landingDoormanuallyTyping"
                      value={landingDoormanuallyTyping}
                      onChange={handleLandingDoorManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
              </div>


              <div className="col-md-3">

                <div className="form-group">
                  <label htmlFor="landingDoorBrand" className='' style={{ fontSize: '14px' }}> LD Brands</label>
                  <select type="text" name='landingDoorBrand' className="form-select border border-dark" id="landingDoorBrand"
                    value={values.landingDoorBrand}
                    onChange={handleLandingDoorBrand}
                    onBlur={handleBlur} >
                    <option value="" style={{ display: 'none' }}></option>
                    <option value="(EC India)" style={{ background: '#eaeaea' }}>EC India</option>
                    <option value="(BST)" style={{ background: '#eaeaea' }}>BST</option>
                    <option value="(Fermator)" style={{ background: '#eaeaea' }}>Fermator</option>
                    <option value="(Genisis)" style={{ background: '#eaeaea' }}>Genisis</option>

                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {landingDoorBrand === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="landingDoorBrandmanuallyTyping"
                      id="landingDoorBrandmanuallyTyping"
                      value={landingDoorBrandmanuallyTyping}
                      onChange={handleLandingDoorBrandManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}

                </div>
              </div>

            </div>

            <div className="row mb-1">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="flooring" className='' style={{ fontSize: '14px' }}>Flooring</label>
                  <select type="text" name='flooring' className="form-select border border-dark" id="flooring"
                    value={values.flooring}
                    onChange={handleFlooring}
                    onBlur={handleBlur} >

                    <option value="" style={{ display: 'none' }}></option>
                    <option value="Antiskid PVC Designer Flooring" style={{ background: '#eaeaea' }}>Antiskid PVC Designer Flooring</option>
                    <option value="Chequer Plate Flooring" style={{ background: '#eaeaea' }}>Chequer Plate Flooring</option>

                    <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                  </select>

                  {flooring === 'Manually Typing' && (
                    <input
                      type="text"
                      className="form-control a1"
                      name="flooringManuallyTyping"
                      id="flooringManuallyTyping"
                      value={flooringManuallyTyping}
                      onChange={handleFlooringManuallyTyping}
                      onBlur={handleBlur}
                    />
                  )}

                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="quotationAmt" className='' style={{ fontSize: '14px' }}>Quotation Amount</label>
                  <input type="number" name='quotationAmt' className="form-control a1" id="quotationAmt"
                    value={values.quotationAmt}
                    onChange={handleQuotationAmt}
                    onBlur={handleBlur} />
                  {touched.quotationAmt && errors.quotationAmt ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.quotationAmt}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="amtToWord" className='' style={{ fontSize: '14px' }}>Amt in Words</label>
                  <input type="text" name='amtToWord' className="form-control a1" id="amtToWord"
                    value={values.amtToWord}
                    onChange={handleAmtToWord}
                    onBlur={handleBlur} />
                     {touched.amtToWord && errors.amtToWord ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.amtToWord}</p>
                  ) : null}
              
                </div>
              </div>

              

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="callBackDate" className='name_label stops' >Follow up Date</label>
                  <input type="date" name='callBackDate' className="form-control a1 stops_input" id="callBackDate"
                    value={callBackDate}
                    onChange={handleCallBackDate}
                    onBlur={handleBlur} />
                  {touched.callBackDate && errors.callBackDate ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.callBackDate}</p>
                  ) : null}

                </div>
              </div>
            </div>
            <div className="row mb-1">
            <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="note" className='' style={{ fontSize: '14px' }}>Note</label>
                  <input type="text" name='note' className="form-control a1" id="note"
                    value={values.note}
                    onChange={handleNote}
                    onBlur={handleBlur} />
              
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="gst" className='' style={{ fontSize: '14px' }}>Gst</label>
                  <input type="text" name='gst' className="form-control a1" id="gst"
                    value={values.gst}
                    onChange={handleGst}
                    onBlur={handleBlur} />
              
                </div>
              </div>

               <div className='col-md-3'>

                <label htmlFor="liftWorkStatus" className='' style={{ fontSize: '14px' }}>Status</label>

                <select className="form-select border border-dark" aria-label="Default select example"
                  name='liftWorkStatus'
                  id='liftWorkStatus'
                  value={liftWorkStatus}
                  onChange={handleLiftWorkStatus}
                  onBlur={handleBlur}>
                  <option selected style={{ dispaly: 'none' }}></option>
                  <option value="Visit Site" style={{ background: '#dddddd', color: "blue" }}>Visit Site</option>
                  <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                  <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                  <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                  <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                  <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                  <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                </select>

                {touched.liftWorkStatus && errors.liftWorkStatus ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.liftWorkStatus}</p>
                ) : null}
              </div>
               
              </div>

            <div className="row mb-1">


              {showInputField && (
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="powerSupply" className='' style={{ fontSize: '14px' }}>Power Supply</label>
                    <input type="text" name='powerSupply' className="form-control a1" id="powerSupply"
                      value={powerSupply}
                      onChange={handlePowerSupply}
                      onBlur={handleBlur} disabled="true" />
                    {/* {touched.powerSupply && errors.powerSupply ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.powerSupply}</p>
                  ) : null} */}
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex justify-content-end'>

              <div className="btn-group my-3 ">
                {/* <button type="button" className="btn btn-primary mx-2">Preview</button> */}
                {/* <button type="button" onClick={paymentPrint} className="btn btn-primary mx-3">Print</button> */}
                <button type="submit" className="btn btn-primary mx-3">Save</button>
              </div>

            </div>

          </div>
        </form>
      </div>
    </>

  )
}

export default LiftQuatation