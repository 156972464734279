
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { updateAmcQuotationAddSchema } from '../Schema/UpdateAmcQuotationAddIndex';
import { Input } from '@mui/icons-material';



const initialValues = {

  customerName: "",
  gstNo: "",
  panNo: "",
  inputEmail: "",
  mobileNo: "",
  quotationNo: "",
  preAddress: "",
  liftType: "",
  stops: "",
  doorType: "",
  make: "",
  quantity: "",
  quotationAmt: "",
  quoDate: "",
  amcStartDate: "",
  amcEndDate: "",
  amcWorkStatus: "",

};

const UpdateAmcQuotationAdd = () => {



  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([])

  const [uniqueId, setUniqueId] = useState("");
  const [quoDate, setQuoDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [preAddress, setPreAddress] = useState("");

  const [liftType, setLiftType] = useState("");
  const [stops, setStops] = useState("");
  const [doorType, setDoorType] = useState("");
  const [make, setMake] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quotationAmt, setQuotationAmt] = useState("");
  const [amcStartDate, setAmcStartDate] = useState("");
  const [amcEndDate, setAmcEndDate] = useState("");
  const [amcWorkStatus, setAmcWorkStatus] = useState("");
  const [callBackDate, setCallBackDate] = useState("");

  const [customerDetails, setCustomerDetails] = useState({
    gstNo: '',
    panNo: '',
    inputEmail: '',
    mobileNo: '',
    preAddress: ''
  });



  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      validationSchema: updateAmcQuotationAddSchema,
      onSubmit: (values, action) => {
        console.log("values:", values);
        // action.resetForm();
        // navigate("/app/new_Amc")
      },
    });


  console.log(errors);

  function saveData() {
    navigate("/app/quotation")
  }

  function openAmcAdd() {
    navigate("/app/amc_quotation_add")
  }

  function openAdd() {
    navigate("/app/lift_quotation_add")
  }

  function openModuleAdd() {
    navigate("/app/modulation_quotation_add")
  }


  ////////////////////////////////////////////////////////////////////////

  const handleChangeUniqueId = (event) => {

    setUniqueId(event.target.value);
    handleChange(event);
  }


  const handleQuoDate = (event) => {

    setQuoDate(event.target.value);
    handleChange(event);
  }

  const handleCustomerName = (event) => {

    setCustomerName(event.target.value);
    handleChange(event);
  }

  const handleGstNo = (event) => {
    // console.log("On Change");

    setGstNo(event.target.value);
    handleChange(event);
  }
  const handlePanNo = (event) => {
    // console.log("On Change");

    setPanNo(event.target.value);
    handleChange(event);
  }
  const handleInputEmail = (event) => {
    // console.log("On Change");

    setInputEmail(event.target.value);
    handleChange(event);
  }

  const handleMobileNo = (event) => {
    // console.log("On Change");

    setMobileNo(event.target.value);
    handleChange(event);
  }


  const handleQuotationNo = (event) => {
    // console.log("On Change");

    setQuotationNo(event.target.value);
    handleChange(event);
  }

  const handlePreAddress = (event) => {
    // console.log("On Change");

    setPreAddress(event.target.value);
    handleChange(event);
  }


  // const handleLiftType = (event) => {
  //   // console.log("On Change");

  //   setLiftType(event.target.value);
  //   handleChange(event);
  // }



  const handleDoorType = (event) => {
    // console.log("On Change");

    setDoorType(event.target.value);
    handleChange(event);
  }
  // const handleMake = (event) => {
  //   // console.log("On Change");

  //   setMake(event.target.value);
  //   handleChange(event);
  // }
  const handleQuantity = (event) => {
    // console.log("On Change");

    setQuantity(event.target.value);
    handleChange(event);
  }
  const handleQuotationAmt = (event) => {
    // console.log("On Change");

    setQuotationAmt(event.target.value);
    handleChange(event);
  }
  const handleAmcStartDate = (event) => {
    // console.log("On Change");

    setAmcStartDate(event.target.value);
    handleChange(event);
  }
  const handleAmcEndDate = (event) => {
    // console.log("On Change");

    setAmcEndDate(event.target.value);
    handleChange(event);
  }

  const handleAmcWorkStatus = (event) => {
    // console.log("On Change");

    setAmcWorkStatus(event.target.value);
    handleChange(event);
  }

  const handleCallBackDate = (event) => {
    // console.log("On Change");

    setCallBackDate(event.target.value);
    handleChange(event);
  }


  ///////////////////////////////////////////// Api  Call /////////////////////////////////////

  useEffect(() => {

    async function fetchresult() {
      try {
        const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_quoamc_update/${id}`);
        const result = await response.json();
        console.log("result:", result);
        setData(result);
        setQuotationNo(result.amc_quo_cu_quono)
        setUniqueId(result.amc_cu_unique_id)
        setQuoDate(result.amc_quo_date)
        setCustomerName(result.amc_quo_cu_name)
        setGstNo(result.amc_quo_cu_gst_no)
        setPanNo(result.amc_quo_cu_pan_no)
        setInputEmail(result.amc_quo_cu_email)
        setMobileNo(result.amc_quo_cu_mobno)
        setPreAddress(result.amc_quo_cu_address)
        setDoorType(result.amc_quo_elev_type)
        setQuantity(result.amc_quo_quantity)
        setQuotationAmt(result.amc_quo_quo_amount)
        setAmcStartDate(result.amc_quo_start_date)
        setAmcEndDate(result.amc_quo_end_date)
        setAmcWorkStatus(result.amc_quo_work_status)
        setCallBackDate(result.amc_call_back_date)



      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchresult();

  }, [id]);



 ///////////////////// Api to enquiry updated data Store Api  ///////////////////////////////////////////

       async function storeUpdateQuoAmcData(id) {
        const formData = new FormData();
        formData.append("quoDate", quoDate)
        formData.append("uniqueId", uniqueId)
        formData.append("customerName", customerName)
        formData.append("panNo", panNo)
        formData.append("gstNo", gstNo)
        formData.append("inputEmail", inputEmail)
        formData.append("mobileNo", mobileNo)
        formData.append("quotationNo", quotationNo)
        formData.append("preAddress", preAddress)
        formData.append("liftType", liftType)
        formData.append("stops", stops)
        formData.append("doorType", doorType)
        formData.append("make", make)
        formData.append("quantity", quantity)
        formData.append("quotationAmt", quotationAmt)
        formData.append("amcStartDate", amcStartDate)
        formData.append("amcEndDate", amcEndDate)
        formData.append("amcWorkStatus", amcWorkStatus)
        formData.append("callBackDate", callBackDate)
       
        try {
            let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/amc_quo_update_store/' + id + '?method=PUT', {
                method: 'POST',
                body: formData
            });
  
            let data1 = await response.json(); // Parse the response body as JSON
  
            if (data1.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data1.message);
                navigate("/app/amc_data")
  
  
            } else {
                alert("An error occurred while saving the data.");
            }
  
  
        } catch (error) {
            console.error('Error while adding customer:', error);
            alert("An error occurred while saving the data2.");
  
        }
    }


  return (
    <>


      <div className= 'row d-flex justify-content-around'>
        <button onClick={openAdd} className='btn btn-primary col-md-3 mt-4'>Lift Quotation</button>
        <button onClick={openAmcAdd} className='btn btn-primary col-md-3 mt-4'>AMC Quotation</button>
        <button onClick={openModuleAdd} className='btn btn-primary col-md-3 mt-4'>Modulation Quotation</button>
      </div>


      <div className='container'>

        <form onSubmit={handleSubmit}>


          <div className="row mb-1 mt-5">
            <div className="col-md-6 ">
              <div className="form-group">
                <h4 className='mt-4'>AMC Update Quotation Data</h4>
              </div>
            </div>


            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                <input type="text" className="form-control a1" id="uniqueId" name='uniqueId'
                  value={uniqueId}
                  onChange={handleChangeUniqueId}
                  onBlur={handleBlur} />
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date</label>
                <input type="date" className="form-control a1" id="quoDate"
                  value={quoDate}
                  onChange={handleQuoDate}
                  onBlur={handleBlur}
                />
                {touched.quoDate && errors.quoDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.quoDate}</p>
                ) : null}
              </div>
            </div>


          </div>

          <hr />
          <div className="row mb-1">


            <div className='col-md-4'>

              <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>

              <input type="customerName" className="form-control a1" id="customerName" name="customerName"
                value={customerName}
                onChange={handleCustomerName}
                onBlur={handleBlur}
              />

              {touched.customerName && errors.customerName ? (
                <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.customerName}</p>
              ) : null}
            </div>



          </div>


          <div className='row mb-1'>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No.</label>
                <input type="text" name='gstNo' className="form-control a1" id="gstNo"
                  value={gstNo}
                  onChange={handleGstNo}
                  onBlur={handleBlur} />

                {touched.gstNo && errors.gstNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.gstNo}</p>
                ) : null}
              </div>
            </div>



            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN</label>
                <input type="text" name='panNo' className="form-control a1" id="panNo"
                  value={panNo}
                  onChange={handlePanNo}
                  onBlur={handleBlur} />
                {touched.panNo && errors.panNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.panNo}</p>
                ) : null}
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="inputEmail" className='' style={{ fontSize: '14px' }}>Email:*</label>
                <input type="text" name='inputEmail' className="form-control a1" id="inputEmail"
                  value={inputEmail}
                  onChange={handleInputEmail}
                  onBlur={handleBlur} />
                {touched.inputEmail && errors.inputEmail ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.inputEmail}</p>
                ) : null}
              </div>
            </div>


          </div>




          <div className="row mb-1">




            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.</label>
                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo"
                  value={mobileNo}
                  onChange={handleMobileNo}
                  onBlur={handleBlur} />
                {touched.mobileNo && errors.mobileNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.mobileNo}</p>
                ) : null}
              </div>
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quotation No:*</label>
                <input type="text" name='quotationNo' className="form-control a1" id="quotationNo"
                  value={quotationNo}
                  onChange={handleQuotationNo}
                  onBlur={handleBlur} />

                {touched.quotationNo && errors.quotationNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.quotationNo}</p>
                ) : null}
              </div>

            </div>


            <div className="row mb-5">
              <div className="col-md-12 ">
                <div className="form-group">
                  <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                  <textarea className="form-control a1" name='preAddress' id="preAddress" rows="5"
                    value={preAddress}
                    onChange={handlePreAddress}
                    onBlur={handleBlur}></textarea>
                  {touched.preAddress && errors.preAddress ? (
                    <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.preAddress}</p>
                  ) : null}
                </div>
              </div>

            </div>

          </div>


          <div className="row mb-1">


            <div className='col-md-4'>

              <label htmlFor="doorType " className='' style={{ fontSize: '14px' }}>Types of Elevator</label>

              <input  type="text"  className="form-control a1"
                name='doorType'
                id='doorType'
                value={doorType}
                onChange={handleDoorType}
                onBlur={handleBlur} />
              
              {touched.doorType && errors.doorType ? (
                <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.doorType}</p>
              ) : null}
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quantity" className='' style={{ fontSize: '14px' }}>Quantity</label>
                <input type="text" name='quantity' className="form-control a1" id="quantity"
                  value={quantity}
                  onChange={handleQuantity}
                  onBlur={handleBlur} />
                {touched.quantity && errors.quantity ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.quantity}</p>
                ) : null}
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quotationAmt" className='' style={{ fontSize: '14px' }}>Quotation Amt:*</label>
                <input type="text" name='quotationAmt' className="form-control a1" id="quotationAmt"
                  value={quotationAmt}
                  onChange={handleQuotationAmt}
                  onBlur={handleBlur} />
                {touched.quotationAmt && errors.quotationAmt ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.quotationAmt}</p>
                ) : null}
              </div>
            </div>
          </div>





          <div className="row mb-1">

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="amcStartDate" className='' style={{ fontSize: '14px' }}>AMC Start Date:*</label>
                <input type="date" name='amcStartDate' className="form-control a1" id="amcStartDate"
                  value={amcStartDate}
                  onChange={handleAmcStartDate}
                  onBlur={handleBlur} />
                {touched.amcStartDate && errors.amcStartDate ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.amcStartDate}</p>
                ) : null}
              </div>
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="amcEndDate" className='' style={{ fontSize: '14px' }}>AMC End Date</label>
                <input type="date" name='amcEndDate' className="form-control a1" id="amcEndDate"
                  value={amcEndDate}
                  onChange={handleAmcEndDate}
                  onBlur={handleBlur} />
                {touched.amcEndDate && errors.amcEndDate ? (
                  <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.amcEndDate}</p>
                ) : null}
              </div>
            </div>

          </div>


          <div className='row mb-1'>
            <div className='col-md-4'>

              <label htmlFor="amcWorkStatus" className='' style={{ fontSize: '14px' }}>Status</label>

              <select className="form-select border border-dark" aria-label="Default select example"
                name='amcWorkStatus'
                id='amcWorkStatus'
                value={amcWorkStatus}
                onChange={handleAmcWorkStatus}
                onBlur={handleBlur}>
                <option selected style={{ dispaly: 'none' }}></option>
                <option value="Site Visit" style={{ background: '#dddddd', color: "blue" }}>Site Visit</option>
                <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
              </select>

              {touched.amcWorkStatus && errors.amcWorkStatus ? (
                <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.status}</p>
              ) : null}
            </div>

            <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="callBackDate" className='name_label stops' >Follow up Date</label>
                  <input type="date" name='callBackDate' className="form-control a1 stops_input" id="callBackDate"
                    value={callBackDate}
                    onChange={handleCallBackDate}
                    onBlur={handleBlur} />
                  {touched.callBackDate && errors.callBackDate ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.callBackDate}</p>
                  ) : null}

                </div>
              </div>


          </div>

          <div className='d-flex justify-content-end'>

            <div className="btn-group my-3 ">
              {/* <button type="button" className="btn btn-primary mx-2">Preview</button>
              <button type="button" className="btn btn-primary mx-3">Print</button> */}
             <button onClick={() => storeUpdateQuoAmcData(data.amc_quo_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>
            </div>

          </div>

        </form>

      </div>

    </>
  )
}

export default UpdateAmcQuotationAdd