import * as Yup from "yup";

export const signUpSchema = Yup.object({
  userName: Yup.string().min(2).max(25).required("Please enter your name"),
  role: Yup.string().required("Please select role"),
  inputEmail: Yup.string().email().required("Please enter your email"),
  status: Yup.string().required("Please select status."),
  // mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
  
  mobileNo: Yup.string()
  .matches(/^(0\d{10}|[1-9]\d{9})$/, 'Mobile number must be 10 digits')
  .required('Please enter your mobile number'),

  password: Yup.string().min(6).required("Please enter your password"),
  preAddress: Yup.string().required("Please enter address"),

//   confirm_password: Yup.string()
    // .required()
    // .oneOf([Yup.ref("password"), null], "Password must match"),
});
