import * as Yup from "yup";



export const newLiftSchema = Yup.object({

    liftQuotationNo:Yup.string().required("Please enter your Lift Quotation No."),
    // subject: Yup.string().required("Please enter your Subject "), 
    invoiceDate:Yup.string().required("Please enter date"),
    paidAmt:Yup.number().required("Please enter Paid Amount"),
    liftStatus:Yup.string().required("Please select status"),
    // followUpDate:Yup.string().required("Please select follow up date"),
    // description: Yup.string().required("Please enter your description "), 
    // newLiftNumber:Yup.string().required("Please enter your New Lift Number."),
    // customerName: Yup.string().min(2).max(25).required("Please enter your name"),
    // gstNo: Yup.string().required("Please enter your GST No."),
    // panNo: Yup.string().required("Please enter your PAN No."),
    // inputEmail: Yup.string().email().required("Please enter your email"),
    // mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
    // quotationNo:Yup.string().required("Please enter your Lift Quotation No."), 
    // preAddres: Yup.string().required("Please enter Your Address "),
    
    // requireTo: Yup.string().required("Please enter Require To "),
    // liftType: Yup.string().required("Please enter lift type"),
    // capacity: Yup.string().required("Please enter Capacity"),
    // powerPackLocationNo:Yup.string().required("Please enter Your Power Pack Location No"),
    // motorMachine:Yup.string().required("Please enter Your Motor Machine"),
    // ropes:Yup.string().required("Please enter Your Ropes"),
    // speed:Yup.string().required("Please enter Your Speed"),
    // controller:Yup.string().required("Please enter Your controller"),
    // drive:Yup.string().required("Please enter Drive"),
    // signals:Yup.string().required("Please enter Signals"),
    // emergencySignals:Yup.string().required("Please enter Emergency Signals"),
    // liftTravel: Yup.string().required("Please enter lift travel "),
    // landingAndOpening:Yup.string().required("Please enter your landing And Opening"),
    // stops:Yup.string().required("Please enter your stops "),
    // carEncloser:Yup.string().required("Please enter your Car Encloser"),
    // flooring:Yup.string().required("Please enter your Flooring"),
    // doorType:Yup.string().required("Please Select Door type"),
    // landingDoor:Yup.string().required("Please enter your Landing Door"),
    
    // quotationAmt: Yup.number().integer().positive().required("Please enter your Quotation Amount"),
    previousBal: Yup.number().required("Please enter your Previous Balance"),

    // totalBalance:Yup.number().integer().positive().required("Please enter Total Balance"),

  });