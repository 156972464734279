import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom'
import { logout } from '../auth/auth';
import './Nav.modules.css';
import { colors } from '@mui/material'
import './Nav.css';

const Nav = ({ children, userRole }) => {
    const [show, setShow] = useState(false);
    const [showRoughMasters, setShowRoughMasters] = useState(false);
    const [showMastersDropdown, setShowMastersDropdown] = useState(false);
    const [showReportsDropdown, setShowReportsDropdown] = useState(false);
    const [showPaymentsDropdown, setShowPaymentsDropdown] = useState(false);
    const [showOtherDropdown, setShowOtherDropdown] = useState(false);
    const [showFinalReportDropdown, setshowFinalReportDropdown] = useState(false);


    const [showAdminDropdown1, setShowAdminDropdown1] = useState(false);
    const [showAdminDropdown2, setShowAdminDropdown2] = useState(false);
    const [showAdminDropdown3, setShowAdminDropdown3] = useState(false);
    const [showAdminDropdown4, setShowAdminDropdown4] = useState(false);
    const [showAdminDropdown5, setShowAdminDropdown5] = useState(false);


    const showSideBar = () => {
        setShow(!show)
    }



    const toggleRoughtMasters = () => {
        setShowRoughMasters(!showRoughMasters);
    };


    const toggleMastersDropdown = () => {
        setShowMastersDropdown(!showMastersDropdown);
    };

    const toggleReportsDropdown = () => {
        setShowReportsDropdown(!showReportsDropdown);
    };

    const togglePaymentsDropdown = () => {
        setShowPaymentsDropdown(!showPaymentsDropdown);
    };

    const toggleOtherDropdown = () => {
        setShowOtherDropdown(!showOtherDropdown);
    };

    const toggleFinalReportDropdown = () => {
        setshowFinalReportDropdown(!showFinalReportDropdown);
    }




    const newAdminMasterLink = [

        {
            url: '/app/dashboard',
            i_class: 'bx bxs-dashboard',
            link_title: 'Admin Dashboard',
            key: 0,
        },

        {
            url: "/app/quotation",
            i_class: "bx bx-file",
            link_title: "Quotation",
            key: 3
        },

    ]


    const newUserMasterLink = [

        {
            url: "/app/quotation",
            i_class: "bx bx-file",
            link_title: "Quotation",
            key: 3
        },

    ]

    const newFinalAdminMasterLink = [

        {
            url: "/app/new_lift",
            i_class: "bx bx-edit",
            link_title: "New Lift",
            key: 4
        },
        {
            url: "/app/new_amc",
            i_class: "bx bxs-cog",
            link_title: "New AMC",
            key: 5
        },
        {
            url: "/app/new_modulation",
            i_class: "bx bxs-wrench",
            link_title: "New Modulation",
            key: 6
        },

    ]


    const newFinalUserMasterLink = [

        {
            url: "/app/new_lift",
            i_class: "bx bx-edit",
            link_title: "New Lift",
            key: 4
        },
        {
            url: "/app/new_amc",
            i_class: "bx bxs-cog",
            link_title: "New AMC",
            key: 5
        },
        {
            url: "/app/new_modulation",
            i_class: "bx bxs-wrench",
            link_title: "New Modulation",
            key: 6
        },

    ]



    const adminPaymentLinks = [

        {
            url: "/app/receive_payment",
            i_class: "bx bxl-paypal",
            link_title: "Receive Payment",
            key: 7
        },

        {
            url: "/app/pending_payment",
            i_class: "bx bx-rupee",
            link_title: "Payment History",
            key: 9
        },

    ]



    const finalReportLink = [

        {
            url: "/app/rough_lift_report",
            i_class: "bx bx-line-chart",
            link_title: "Lift Report",
            key: 12
        },

        {
            url: "/app/rough_amc_report",
            i_class: "bx bx-line-chart",
            link_title: "AMC Report",
            key: 12
        },

        {
            url: "/app/rough_modulation_report",
            i_class: "bx bx-line-chart",
            link_title: "Modulation Report",
            key: 12
        },
    ]



    const adminReportLink = [

        {
            url: "/app/lift_report",
            i_class: "bx bx-line-chart",
            link_title: "Lift Report",
            key: 12
        },

        {

            url: "/app/amc_report",
            i_class: "bx bx-line-chart",
            link_title: "AMC Report",
            key: 13
        },

        {

            url: "/app/second_installment_amc_report",
            i_class: "bx bx-line-chart",
            link_title: "Second Installment AMC Report",
            key: 13
        },


        {
            url: "/app/modulation_report",
            i_class: "bx bx-line-chart",
            link_title: "Modulation Report",
            key: 14
        },

        {
            url: "/app/enquiry_report",
            i_class: "bx bx-line-chart",
            link_title: "Enquiry Report",
            key: 15
        },

        {
            url: "/app/breakdown_report",
            i_class: "bx bx-line-chart",
            link_title: "Breakdown Report",
            key: 15
        },

    ]

    const userReportLinks = [

        {
            url: "/app/lift_report",
            i_class: "bx bx-line-chart",
            link_title: "Lift Report",
            key: 12
        },

        {

            url: "/app/amc_report",
            i_class: "bx bx-line-chart",
            link_title: "AMC Report",
            key: 13
        },

        {

            url: "/app/second_installment_amc_report",
            i_class: "bx bx-line-chart",
            link_title: "Second Installment AMC Report",
            key: 13
        },


        {
            url: "/app/modulation_report",
            i_class: "bx bx-line-chart",
            link_title: "Modulation Report",
            key: 14
        },

        {
            url: "/app/breakdown_report",
            i_class: "bx bx-line-chart",
            link_title: "Breakdown Report",
            key: 15
        },


    ];


    const otherLinks = [

        {
            url: "/app/amc_expiry",
            i_class: "bx bx-detail",
            link_title: "AMC Expiry",
            key: 10
        },

        {
            url: "/app/breakdown",
            i_class: "bx bx-group",
            link_title: "Breakdown",
            key: 11
        },

        {
            url: "/app/service",
            i_class: "bx bx-line-chart",
            link_title: "Services",
            key: 12
        },

        {
            url: "/app/users",
            i_class: "bx bx-line-chart",
            link_title: "Users",
            key: 13
        },

    ]


    return (
        <>
            {/* Header code... */}

            <header className={`header ${show ? 'add_body_padding' : ' '}`} id="admin-dash-header">
                <div className='header_toggle '>
                    <i style={{ paddingLeft: '80px' }} className={`bx bx-menu ${show ? "bx-x" : " "}`} id="header-toggle" onClick={showSideBar}></i>
                </div>
                <div className="dropdown sidebar-profile">
                    <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://img.icons8.com/color/48/000000/circled-user-male-skin-type-4--v1.png" alt="avatar" className="avatar rounded-circle" />
                    </span>
                    <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                        <li>
                            <a className="dropdown-item" href="/app/profile_page" >  {/*/my-account*/}
                                Profile
                            </a>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <button className="dropdown-item" onClick={() => logout()}>
                                Sign out
                            </button>
                        </li>
                    </ul>

                </div>
            </header>

            <aside className={`sidebar ${show ? 'review' : ' '} scrollbar`} id="admin-dash-nav">
                <nav className="admin-dash-nav">
                    <NavLink to={"/"} className="nav_logo">
                        {" "}
                        <img src="/img/logo.png" alt="logo" className="logo" /> <span className="nav_logo-name">Lift App</span>{" "}
                    </NavLink>

                    <div className="nav_list">
                        {/* Common Links */}
                        <NavLink to="/app/dashboard" className={`nav_link ${userRole === 'admin' ? '' : 'hidden'}`}>
                            <i className=""><img className='' src="/img/ad.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Admin Dashboard</span>
                        </NavLink>


                        <NavLink to="/app/enquiry" className={`nav_link ${userRole === 'technician' ? '' : 'hidden'}`}>
                            <i className=""><img className='' src="/img/en.png" alt="" style={{ width: "25px", height: "25px" }} /></i><span className="nav_name">Enquiry</span>
                        </NavLink>

                        <NavLink to="/app/customer" className={`nav_link ${userRole === 'admin' ? '' : 'hidden'}`}>
                            <i className=""><img className='' src="/img/cm.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Customer Master</span>
                        </NavLink>

                        <NavLink to="/app/customer_report" className={`nav_link ${userRole === 'site engg' ? '' : 'hidden'}`}>
                            <i className=""><img className='' src="/img/cr.png" alt="" style={{ width: "25px", height: "25px" }} /></i><span className="nav_name">Customer Report</span>
                        </NavLink>

                        {/* Admin Links */}
                        {userRole === 'admin' && (
                            <>
                                {/* <NavLink to="/app/receive_payment" className="nav_link">
                                    <i className="bx bx-link nav_icon"></i> <span className="nav_name">Receive Payment</span>
                        </NavLink> */}

                                <NavLink to="/app/users" className="nav_link">
                                    <i className=""><img className='' src="/img/us.png" alt="" style={{ width: "25px", height: "25px" }} /></i>  <span className="nav_name">Users</span>
                                </NavLink>
                                <NavLink to="/app/Quotation" className="nav_link">
                                    <i className=""><img className='' src="/img/rqm.png" alt="" style={{ width: "25px", height: "25px" }} /></i>  <span className="nav_name">RQ Quotation</span>
                                </NavLink>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown1 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown1(!showAdminDropdown1)}>
                                        <i className=""><img className='' src="/img/rrm.png" alt="" style={{ width: "25px", height: "25px" }} /></i>  <span className="nav_name">Rough Reports ▼</span>
                                    </span>

                                    {showAdminDropdown1 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown1 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/rough_lift_report" className="nav_link">
                                                <i className=""><img className='' src="/img/lr.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Lift Report</span>
                                            </NavLink>

                                            <NavLink to="/app/rough_amc_report" className="nav_link">
                                                <i className=""><img className='' src="/img/ar.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">AMC  Report</span>
                                            </NavLink>

                                            <NavLink to="/app/rough_modulation_report" className="nav_link">
                                                <i className=""><img className='' src="/img/mr.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Modu Report</span>
                                            </NavLink>


                                        </div>
                                    )}
                                </div>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown2 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown2(!showAdminDropdown2)}>
                                        <i className=""><img className='' src="/img/fm.png" alt="" style={{ width: "25px", height: "25px" }} /></i>  <span className="nav_name">Final Quo ▼</span>
                                    </span>

                                    {showAdminDropdown2 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown2 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/new_lift" className="nav_link">
                                                <i className=""><img className='' src="/img/nl.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Final Lift Quo</span>
                                            </NavLink>

                                            <NavLink to="/app/new_amc" className="nav_link">
                                                <i className=""><img className='' src="/img/na.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Final AMC Quo</span>
                                            </NavLink>

                                            <NavLink to="/app/new_modulation" className="nav_link">
                                                <i className=""><img className='' src="/img/nm.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Final Modu Quo</span>
                                            </NavLink>

                                        </div>
                                    )}
                                </div>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown3 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown3(!showAdminDropdown3)}>
                                        <i className=""><img className='' src="/img/fr.png" alt="" style={{ width: "25px", height: "25px" }} /></i>  <span className="nav_name">Final Reports ▼</span>
                                    </span>

                                    {showAdminDropdown3 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown3 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/lift_report" className="nav_link">
                                                <i className=""><img className='' src="/img/lr.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Lift Report</span>
                                            </NavLink>

                                            <NavLink to="/app/amc_report" className="nav_link">
                                                <i className=""><img className='' src="/img/ar.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">AMC  Report</span>
                                            </NavLink>

                                            <NavLink to="/app/modulation_report" className="nav_link">
                                                <i className=""><img className='' src="/img/mr.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Modu Report</span>
                                            </NavLink>

                                            <NavLink to="/app/breakdown_report" className="nav_link">
                                                <i className=""><img className='' src="/img/br.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Breakdown  Report</span>
                                            </NavLink>

                                        </div>
                                    )}
                                </div>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown4 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown4(!showAdminDropdown4)}>
                                    <i className=""><img className='' src="/img/rupees.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Payment ▼</span>
                                    </span>

                                    {showAdminDropdown4 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown3 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/receive_payment" className="nav_link">
                                                <i className=""><img className='' src="/img/rp.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Receive Payment</span>
                                            </NavLink>

                                            <NavLink to="/app/pending_payment" className="nav_link">
                                                <i className=""><img className='' src="/img/ph.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Payment History</span>
                                            </NavLink>

                                            {/* <NavLink to="/app/second_installment_amc_report" className="nav_link">
                                                <i className=""><img className='' src="/img/2nd.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">2nd Install</span>
                                            </NavLink> */}

                                            <NavLink to="/app/amc_expiry" className="nav_link">
                                                <i className=""><img className='' src="/img/ae.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">AMC Expiry</span>
                                            </NavLink>


                                        </div>
                                    )}
                                </div>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown5 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown5(!showAdminDropdown5)}>
                                        <i className=""><img className='' src="/img/ot.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Other ▼</span>
                                    </span>

                                    {showAdminDropdown5 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown5 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/breakdown" className="nav_link">
                                                <i className=""><img className='' src="/img/bd.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Breakdown</span>
                                            </NavLink>

                                            <NavLink to="/app/service" className="nav_link">
                                                <i className=""><img className='' src="/img/se.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Services</span>
                                            </NavLink>
                                        </div>
                                    )}
                                </div>

                            </>
                        )}

                        {/* Technician Links */}
                        {userRole === 'office accountant' && (
                            <>

                                {/* <NavLink to="/app/users" className="nav_link">
                                <i className=""><img className='' src="/img/us.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Users</span>
                                </NavLink> */}
                                <NavLink to="/app/Quotation" className="nav_link">
                                <i className=""><img className='' src="/img/rqm.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">RQ Quotation</span>
                                </NavLink>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown1 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown1(!showAdminDropdown1)}>
                                    <i className=""><img className='' src="/img/rrm.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Rough Reports ▼</span>
                                    </span>

                                    {showAdminDropdown1 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown1 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/rough_lift_report" className="nav_link">
                                            <i className=""><img className='' src="/img/lr.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Lift Report</span>
                                            </NavLink>

                                            <NavLink to="/app/rough_amc_report" className="nav_link">
                                            <i className=""><img className='' src="/img/ar.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">AMC  Report</span>
                                            </NavLink>

                                            <NavLink to="/app/rough_modulation_report" className="nav_link">
                                            <i className=""><img className='' src="/img/mr.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Modu Report</span>
                                            </NavLink>


                                        </div>
                                    )}
                                </div>

                                {/* <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown2 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown2(!showAdminDropdown2)}>
                                    <i className=""><img className='' src="/img/fm.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Final Quo ▼</span>
                                    </span>

                                    {showAdminDropdown2 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown2 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/new_lift" className="nav_link">
                                            <i className=""><img className='' src="/img/nl.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">New Lift</span>
                                            </NavLink>

                                            <NavLink to="/app/new_amc" className="nav_link">
                                            <i className=""><img className='' src="/img/na.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">New AMC</span>
                                            </NavLink>

                                            <NavLink to="/app/new_modulation" className="nav_link">
                                            <i className=""><img className='' src="/img/nm.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">New Modu</span>
                                            </NavLink>

                                        </div>
                                    )}
                                </div> */}

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown3 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown3(!showAdminDropdown3)}>
                                    <i className=""><img className='' src="/img/fr.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Final Reports ▼</span>
                                    </span>

                                    {showAdminDropdown3 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown3 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/lift_report" className="nav_link">
                                            <i className=""><img className='' src="/img/lr.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Lift Report</span>
                                            </NavLink>

                                            <NavLink to="/app/amc_report" className="nav_link">
                                            <i className=""><img className='' src="/img/ar.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">AMC  Report</span>
                                            </NavLink>

                                            <NavLink to="/app/modulation_report" className="nav_link">
                                            <i className=""><img className='' src="/img/mr.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Modu Report</span>
                                            </NavLink>

                                            <NavLink to="/app/breakdown_report" className="nav_link">
                                            <i className=""><img className='' src="/img/br.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Breakdown  Report</span>
                                            </NavLink>

                                        </div>
                                    )}
                                </div>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown4 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown4(!showAdminDropdown4)}>
                                    <i className=""><img className='' src="/img/rupees.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Payment ▼</span>
                                    </span>

                                    {showAdminDropdown4 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown3 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/receive_payment" className="nav_link">
                                            <i className=""><img className='' src="/img/rp.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Receive Payment</span>
                                            </NavLink>

                                            <NavLink to="/app/pending_payment" className="nav_link">
                                            <i className=""><img className='' src="/img/ph.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Payment History</span>
                                            </NavLink>

                                            {/* <NavLink to="/app/second_installment_amc_report" className="nav_link">
                                            <i className=""><img className='' src="/img/cm.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">2nd Install</span>
                                            </NavLink> */}

                                            <NavLink to="/app/amc_expiry" className="nav_link">
                                            <i className=""><img className='' src="/img/ae.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">AMC Expiry</span>
                                            </NavLink>


                                        </div>
                                    )}
                                </div>

                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown5 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown5(!showAdminDropdown5)}>
                                    <i className=""><img className='' src="/img/ot.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Other ▼</span>
                                    </span>

                                    {showAdminDropdown5 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown5 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/breakdown" className="nav_link">
                                            <i className=""><img className='' src="/img/bd.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Breakdown</span>
                                            </NavLink>

                                            <NavLink to="/app/service" className="nav_link">
                                            <i className=""><img className='' src="/img/se.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Services</span>
                                            </NavLink>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {/* Site Engg Links */}
                        {userRole === 'site engg' && (
                            <>
                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown5 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown5(!showAdminDropdown5)}>
                                    <i className=""><img className='' src="/img/ot.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Other ▼</span>
                                    </span>

                                    {showAdminDropdown5 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown5 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/breakdown" className="nav_link">
                                            <i className=""><img className='' src="/img/bd.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Breakdown</span>
                                            </NavLink>

                                            <NavLink to="/app/service" className="nav_link">
                                            <i className=""><img className='' src="/img/se.png" alt="" style={{ width: "20px", height: "20px" }} /></i>  <span className="nav_name">Services</span>
                                            </NavLink>
                                        </div>
                                    )}
                                </div>

                            </>
                        )}

                        {userRole === 'technician' && (
                            <>

                                <NavLink to="/app/Quotation" className="nav_link">
                                    <i className=""><img className='' src="/img/rqm.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">RQ Quotation</span>
                                </NavLink>


                                <div className="nav_dropdown">
                                    <span className={`nav_link ${showAdminDropdown5 ? 'nav_link-open' : ''}`} onClick={() => setShowAdminDropdown5(!showAdminDropdown5)}>
                                        <i className=""><img className='' src="/img/ot.png" alt="" style={{ width: "25px", height: "25px" }} /></i> <span className="nav_name">Other ▼</span>
                                    </span>

                                    {showAdminDropdown5 && (
                                        <div className={`nav_dropdown-content ${showAdminDropdown5 ? 'content-open' : ''}`}>
                                            <NavLink to="/app/breakdown" className="nav_link">
                                                <i className=""><img className='' src="/img/bd.png" alt="" style={{ width: "20px", height: "20px" }} /></i> <span className="nav_name">Breakdown</span>
                                            </NavLink>

                                            <NavLink to="/app/service" className="nav_link">
                                                <i className=""><img className='' src="/img/se.png" alt="" style={{ width: "20px", height: "20px" }} /></i><span className="nav_name">Services</span>
                                            </NavLink>
                                        </div>
                                    )}
                                </div>

                            </>
                        )}
                    </div>

                    <span className="nav_link" onClick={() => logout()}>
                        {" "}
                        <i className="bx bx-log-out bx-sm nav_icon"  ></i> <span className="nav_name">SignOut</span>{" "}
                    </span>
                    {/* </div> */}
                </nav>
            </aside >

            <main className={` ${show ? 'add_body_padding' : 'main'} `}>{children}</main>
        </>
    );
};

export default Nav;

