import React, { useEffect, useState } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from "react-bootstrap"
import { amcExpirySchema } from '../Schema/AmcExpiryIndex';


const initialValues = {
    startDate: "",
    endDate: "",
    customerName: "",


};
const AmcExpiryMaster = () => {

    const navigate = useNavigate();
    const [selCustomerNames, setSelCustomerNames] = useState([]);
    const [customerName, setCustomerName] = useState("");

    const [selectedCustomerId, setSelectedCustomerId] = useState(''); /// for an dropdown
    const [customerDetails, setCustomerDetails] = useState([]);

    const [searchValue, setSearchValue] = useState('');

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: amcExpirySchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                action.resetForm();
                // navigate("/app/breakdown")

            },
        });


    const openPrint = () => {

        navigate("/app/amc_expiry_data_print", {

            state: { customerDetails },
        });
    }

    const openPrintData = (item) => {

        navigate("/app/amc_expiry_print", { state: { selectedItem: item } });
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
      };


    const filterData = (searchValue) => {
    return customerDetails.filter((item) => {
      const lowerCaseSearch = searchValue.toLowerCase();
      return (
        item.amc_quo_name.toLowerCase().includes(lowerCaseSearch) ||
        item.amc_quo_mob_no.toString().includes(lowerCaseSearch) ||
        item.amc_working_status.toLowerCase().includes(lowerCaseSearch)

        
        
        // Add other fields for search if required
        // ...
        // For other radioSelected values, add the relevant fields for filtering
      );
    });
  };
      




///// api call details are here////////



    useEffect(() => {
        getData();

    }, []);

  

    function getData() {

        async function fetchCustomerNames() {
            try {
                const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_amc_customer_name');
                console.log("response:", response);
                if (!response.ok) {
                    throw new Error('Failed to fetch customer names.');
                }

                const data = await response.json();
                console.log("data:", data);
                const customerArray = Object.entries(data);
                console.log("customerArray:", customerArray);
                setSelCustomerNames(customerArray);
            } catch (error) {
                console.error('Error fetching customer names:', error);
            }
        }

        fetchCustomerNames();
    }



 const handleSearchClick = async (event) => {
        event.preventDefault();

        const startDate = values.startDate;
        const endDate = values.endDate;
        try {
            let response;

            // if (selectedCustomerId) {
            //     console.log("Fetching AMC expiry details with selectedCustomerId:", selectedCustomerId);
            //     response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_amc_expiry_details?customerId=${selectedCustomerId}&startDate=${startDate}&endDate=${endDate}`);
            // } else if (!selectedCustomerId) {
            //     console.log("Fetching AMC expiry details without selectedCustomerId");
            //     response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_amc_expiry_details?startDate=${startDate}&endDate=${endDate}`);
            // }


            if (startDate && endDate) {
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_amc_expiry_details?startDate=${startDate}&endDate=${endDate}`);
              } else {
                // If startDate and endDate are not present, make the API request without them
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_amc_expiry_details`);
              }

            if (response.ok) {
                const data = await response.json();
                console.log("AMC expiry details:", data);
                if (data.length === 0) {
                    // No data found, display a message to the user
                    console.log("No data found.");
                    window.alert("No data found.");
                    // You can update the state or display a message to the user here.
                } else {
                    setCustomerDetails(data);
                }
            } else {
                console.error('Error fetching AMC expiry details:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching AMC expiry details:', error);
        }
    };






    return (
        <>
            <div className='container'>

                <form onSubmit={handleSubmit}>


                    <div className='page__main'>

                        <h4>Expiring AMC'S</h4>


                        <hr />

                    </div>
                    <div className="d-md-flex justify-content-between">

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="startDate" className='' style={{ fontSize: '12px' }}>Start</label>
                                <input type="date" className="form-control a1" id="startDate" name='startDate'
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.startDate && errors.startDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="endDate" className='' style={{ fontSize: '12px' }}>End</label>
                                <input type="date" className="form-control a1" id="endDate" name='endDate'
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.endDate && errors.endDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="searchValue" className='' style={{ fontSize: '12px' }}></label>
                                <input type="text" className="form-control a1" id="searchValue" name='searchValue' placeholder="Search"
                                    value={searchValue}
                                    onChange={handleSearch}
                                />

                            </div>
                        </div>


                        {/* <div className='col-md-3'>

                            <label htmlFor="customerName " className='' ></label>

                            <select className="form-select border border-dark" defaultValue={customerDetails.amc_quo_name} style={{ boxShadow: 'none' }}
                                name='customerName'
                                id='customerName'
                                // value={customerName}
                                onChange={(event) => setSelectedCustomerId(event.target.value)}>

                                <option value="" style={{ background: '#dddddd' }}>Select Name</option>

                                { selCustomerNames.map(([amc_fl_cu_unique_id, amc_quo_name]) => (
                                    <option key={amc_fl_cu_unique_id} value={amc_fl_cu_unique_id} style={{ background: '#dddddd' }}>{amc_quo_name}</option>
                                ))}

                                {touched.customerName && errors.customerName ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.customerName}</p>
                                ) : null}

                            </select>

                        </div> */}


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='submit' className='btn btn-primary' onClick={handleSearchClick}>Search</button>
                            </div>
                        </div>


                    </div>

                    <div className="">
                        <div className="form-group  mt-4">
                            <button className='btn btn-primary' onClick={openPrint}>Print</button>
                        </div>
                    </div>


                    <Table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">Job Id</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Mobile No</th>
                                <th scope="col">Site Address</th>
                                <th scope="col">Bal</th>
                                <th scope="col">status</th>
                                <th scope="col">Calling Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {customerDetails.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.amc_quo_start_date}</td>
                                    <td>{item.amc_quo_end_date}</td>
                                    <td>{item.amc_quo_name}</td>
                                    <td>{item.amc_fl_cu_unique_id}</td>
                                    <td>{item.amc_quo_total_bal}</td>
                                    <td>{item.amc_working_status}</td>
                                 
                                </tr>
                                ))} */}

                            {filterData(searchValue).map((item, index) => {
                                if (item.amc_working_status === 'Expired') {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.amc_fl_cu_unique_id}</td>
                                            <td>{item.amc_quo_start_date}</td>
                                            <td>{item.amc_quo_end_date}</td>
                                            <td>{item.amc_quo_name}</td>
                                            <td>{item.amc_quo_mob_no}</td>
                                            <td>{item.amc_quo_address}</td>
                                            <td>{item.amc_quo_total_bal}</td>
                                            <td>{item.amc_working_status}</td>
                                            <td className='d-flex justify-content-evenly'><a href={`https://wa.me/${item.amc_quo_mob_no}`} target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                                                <a href={`tel:${item.amc_quo_mob_no}`}><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                                            <td><button
                                                type="button"
                                                onClick={() => openPrintData(item)} // Pass the item data to the function
                                                className="btn btn-primary mx-3"
                                            >
                                                Print
                                            </button></td>
                                        </tr>
                                    );
                                }

                                return null; // Skip rendering this row if it doesn't match the criteria
                            })}

                        </tbody>

                    </Table>

                </form>

            </div>
        </>
    )
}

export default AmcExpiryMaster