import React, { useState, useEffect } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap';
import { pendingPaymentSchema } from '../Schema/PendingPaymentIndex';
// import { Table } from 'reactstrap';




const initialValues = {
  gender: "",
  customerName: "",


};

const PendingPaymentMaster = () => {


  const navigate = useNavigate();
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [radioSelected, setRadioSelected] = useState(false);

  // const [paymentDetails, setPaymentDetails] = useState([]);
  const [selCustomerNames, setSelCustomerNames] = useState([]);

  const [customerName, setCustomerName] = useState("");

  const [searchValue, setSearchValue] = useState('');

  const [customerDetails, setCustomerDetails] = useState([]);


  const onclickHandleChange = (event) => {
    setSelectedCustomer(event.target.value);
    handleChange(event);
  };

  // const handleRadioChange = (event) => {
  //   // Update the radioSelected state based on the radio button value
  //   setRadioSelected(event.target.value === 'lift');
  //   handleChange(event);
  // };


  const handleRadioChange = (event) => {
    // Update the radioSelected state based on the radio button value
    setRadioSelected(event.target.value);
    // Reset the selected customer and customer details when the radio button changes
    setSelectedCustomer('');
    setCustomerDetails([]);
    fetchCustomerNames(event.target.value);
  };


  async function fetchCustomerNames(paymentType) {
    try {
      const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_${paymentType}_cust_name`);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${paymentType} customer names.`);
      }

      const data = await response.json();
      const customerArray = Object.entries(data);
      setSelCustomerNames(customerArray);
    } catch (error) {
      console.error(`Error fetching ${paymentType} customer names:`, error);
    }
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchCustomerNames(radioSelected);
  }, [radioSelected]);






  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      validationSchema: pendingPaymentSchema,
      onSubmit: (values, action) => {
        console.log("values:", values);

        action.resetForm();
        // navigate("/app/breakdown")

      },
    });


////////////////////////////////////////////////////////////////////////////////////////////////////


    const filterData = (searchValue, category) => {
      return customerDetails.filter((item) => {
          const lowerCaseSearch = searchValue.toLowerCase();
    
          if (category === 'lift') {
              return (
                  (item.li_pay_cu_name && item.li_pay_cu_name.toLowerCase().includes(lowerCaseSearch)) ||
                  (item.li_pay_unique_id && item.li_pay_unique_id.toString().includes(lowerCaseSearch)) ||
                  (item.li_pay_work_status && item.li_pay_work_status.toLowerCase().includes(lowerCaseSearch)) ||
                  (item.li_pay_date && item.li_pay_date.includes(lowerCaseSearch)) 
              );
          } else if (category === 'amc') {
              return (
                  (item.amc_pay_cu_name && item.amc_pay_cu_name.toLowerCase().includes(lowerCaseSearch)) ||
                  (item.amc_pay_unique_id && item.amc_pay_unique_id.toString().includes(lowerCaseSearch)) ||
                  (item.amc_pay_work_status && item.amc_pay_work_status.toLowerCase().includes(lowerCaseSearch)) ||
                  (item.amc_pay_date && item.amc_pay_date.includes(lowerCaseSearch)) 
              );
          } else if (category === 'mod') {
              return (
                  (item.mod_pay_cu_name && item.mod_pay_cu_name.toLowerCase().includes(lowerCaseSearch)) ||
                  (item.mod_pay_unique_id && item.mod_pay_unique_id.toString().includes(lowerCaseSearch)) ||
                  (item.mod_pay_work_status && item.mod_pay_work_status.toLowerCase().includes(lowerCaseSearch)) ||
                  (item.mod_pay_date && item.mod_pay_date.includes(lowerCaseSearch)) 
              );
          }else if (category === 'other') {
            return (
                (item.other_pay_cu_name && item.other_pay_cu_name.toLowerCase().includes(lowerCaseSearch)) ||
                (item.other_pay_unique_id && item.other_pay_unique_id.toString().includes(lowerCaseSearch)) ||
                (item.other_pay_work_status && item.other_pay_work_status.toLowerCase().includes(lowerCaseSearch)) ||
                (item.other_follow_date && item.other_follow_date.includes(lowerCaseSearch)) 
            );
        }
    
    
          return false;
      });
    }; 

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

/////////////////////////////////////////////////////////////////////////////////////////////////////////

  // const handleSearchClick = async (event) => {
  //   event.preventDefault();

  //   let apiEndpoint = ''; // Set the API endpoint based on the selected radio button
  //   if (radioSelected === 'lift') {
  //     apiEndpoint = 'get_lift_pay_bal_details';
  //   } else if (radioSelected === 'amc') {
  //     apiEndpoint = 'get_amc_pay_bal_details';
  //   } else if (radioSelected === 'mod') {
  //     apiEndpoint = 'get_mod_pay_bal_details';
  //   }

  //   try {
  //     let response;

  //     if (selectedCustomer) {
  //       response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/${apiEndpoint}?uniqueId=${selectedCustomer}`);
  //     } else {
  //       response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/${apiEndpoint}`);
  //     }

  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data.length === 0) {

  //         window.alert('Data not found.');
  //       } else {
  //         setCustomerDetails(data);
  //       }
  //     } else {
  //       console.error('Error fetching payment details:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching payment details:', error);
  //   }
  // };


  //////////////////////////////////////   ///////////////////////////////////////////////


  const handleSearchClick = async (event) => {
    event.preventDefault();

    let apiEndpoint = ''; // Set the API endpoint based on the selected radio button
    if (radioSelected === 'lift') {
      apiEndpoint = 'get_lift_pay_bal_details';
    } else if (radioSelected === 'amc') {
      apiEndpoint = 'get_amc_pay_bal_details';
    } else if (radioSelected === 'mod') {
      apiEndpoint = 'get_mod_pay_bal_details';
    }

    try {
      let response;
      const startDate = values.startDate; // Replace with your from date value
      const endDate = values.endDate; // Replace with your to date value

      if (selectedCustomer) {
        response = await fetch(
          `http://liftapp.infysky.in/liftapp_api/api/${apiEndpoint}?uniqueId=${selectedCustomer}&startDate=${startDate}&endDate=${endDate}`
        );
      } else if (startDate && endDate) {
        response = await fetch(
          `http://liftapp.infysky.in/liftapp_api/api/${apiEndpoint}?startDate=${startDate}&endDate=${endDate}`
        );
      } else {
        response = await fetch(
          `http://liftapp.infysky.in/liftapp_api/api/${apiEndpoint}`
        );
      }

      if (response.ok) {
        const data = await response.json();
        if (data.length === 0) {
          // Data not found, show an alert
          window.alert('Data not found.');
        } else {
          setCustomerDetails(data);
        }
      } else {
        console.error('Error fetching payment details:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching payment details:', error);
    }
  };


  //////////////////////////////////////////

  // const openPrint = () => {

  //   navigate("/app/pending_payment_print", {

  //     state: { customerDetails, radioSelected },
  //   });
  // }


  const openPrint = () => {
    const filteredData = filterData(searchValue, radioSelected);
    navigate("/app/pending_payment_print", {
      state: { customerDetails: filteredData, radioSelected },
    });
  };

  /////////////////////////////////////////////

  return (
    <>
      <div className='container'>
        <form onSubmit={handleSubmit} >

          <div className='page__main'>

            <h4>Pending Payments</h4>
            <hr />

          </div>
          <div className="d-md-flex justify-content-between">
            <div className="form-check mt-4 ">
              <input className="form-check-input border border-3 border-primary" type="radio" name="gender" id="lift"
                value="lift" onChange={handleRadioChange} onBlur={handleBlur} />
              <label className="form-check-label fw-bold ms-1" for="flexRadioDefault1">
                Lift Payment
              </label>
              {touched.gender && errors.gender ? (
                <p className='form-error d-none d-md-block' style={{ color: "red", fontSize: "12px" }}>{errors.gender}</p>
              ) : null}
            </div>

            <div className="form-check mt-4 ">
              <input className="form-check-input border border-3 border-primary" type="radio" name="gender" id="amc"
                value="amc" onChange={handleRadioChange} onBlur={handleBlur} />
              <label className="form-check-label fw-bold ms-1" for="flexRadioDefault1">
                AMC Payment
              </label>
            </div>

            <div className="form-check mt-4 ">
              <input className="form-check-input border border-3 border-primary" type="radio" name="gender" id="mod"
                value="mod" onChange={handleRadioChange} onBlur={handleBlur} />
              <label className="form-check-label fw-bold ms-1 " for="flexRadioDefault1">
                Modulation Payment
              </label>
            </div>
            {touched.gender && errors.gender ? (
              <p className='form-error d-md-none' style={{ color: "red", fontSize: "12px" }}>{errors.gender}</p>
            ) : null}


            {/* <div className='form-check ' >

              <label htmlFor="customerName " className=''></label>

              <select className="form-select border border-dark" defaultValue={customerDetails.li_pay_cu_name} style={{ boxShadow: 'none' }}
                name='customerName'
                id='customerName'
               
                onChange={onclickHandleChange}
              >

                <option value="" style={{ background: '#dddddd' }}>Select Name</option>

                {selCustomerNames.map(([uniqueId, customerName]) => (
                  <option key={uniqueId} value={uniqueId} style={{ background: '#dddddd' }}>{customerName}</option>
                ))}
              </select>
              {touched.customerName && errors.customerName ? (
                <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.customerName}</p>
              ) : null}

            </div> */}





            {/* <div className="form-check ">
              <div className="form-group  mt-4">
                <button type="submit" className='btn btn-primary' onClick={handleSearchClick} >Search</button>
              </div>
            </div> */}

          </div>


          <div className="d-md-flex justify-content-between my-5">

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="startDate" className='' style={{ fontSize: '12px' }}>Start Date</label>
                <input type="date" className="form-control a1" id="startDate" name='startDate'
                  value={values.startDate}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                {touched.startDate && errors.startDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                ) : null}
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="endDate" className='' style={{ fontSize: '12px' }}>End Date</label>
                <input type="date" className="form-control a1" id="endDate" name='endDate'
                  value={values.endDate}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                {touched.endDate && errors.endDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                ) : null}
              </div>
            </div>


            {/* <div className='form-check ' >

              <label htmlFor="customerName " className=''></label>

              <select className="form-select border border-dark" defaultValue={customerDetails.li_pay_cu_name} style={{ boxShadow: 'none' }}
                name='customerName'
                id='customerName'
               
                onChange={onclickHandleChange}
              >

                <option value="" style={{ background: '#dddddd' }}>Select Name</option>

                {selCustomerNames.map(([uniqueId, customerName]) => (
                  <option key={uniqueId} value={uniqueId} style={{ background: '#dddddd' }}>{customerName}</option>
                ))}
              </select>
              {touched.customerName && errors.customerName ? (
                <p className='form-error' style={{ color: "red", fontSize: "12px" }}>{errors.customerName}</p>
              ) : null}

            </div> */}

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="searchValue" className='' style={{ fontSize: '12px' }}></label>
                <input type="text" className="form-control a1" id="searchValue" name='searchValue' placeholder="Search"
                  value={searchValue}
                  onChange={handleSearch}
                />

              </div>
            </div>


            <div className="form-check ">
              <div className="form-group  mt-4">
                <button type="submit" className='btn btn-primary' onClick={handleSearchClick} >Search</button>
              </div>
            </div>

          </div>



          <div className="">
            <div className="form-group ms-md-0 ms-4  mt-4">
              <button className='btn btn-primary' onClick={openPrint}>Print</button>
            </div>
          </div>


          <Table className="table table-bordered mt-5">
            <thead>
              <tr>
                <th scope="col">Sl No</th>
                <th scope="col">Job Id</th>
                <th scope="col">Customer Name</th>            
                {/* <th scope="col">Mobile No</th> */}
                <th scope="col">Quotation Amt</th>
                 <th scope="col">Pre Bal </th>
                {/* <th scope="col">Site Address</th> */}
                <th scope="col"> Recently Paid Amt</th>
                <th scope="col">Total Bal </th>
                <th scope="col"> Received Payment Date</th>              
                <th scope="col">Payment Mode </th>              
                
                {/* <th scope="col">Call Back Date</th> */}
                <th scope="col">Status</th>
                <th scope="col">Calling Option</th>
              </tr>
            </thead>

            <tbody>
              {radioSelected === 'lift' && (
                // Render lift payment data
                // customerDetails.map((item, index) => (
                filterData(searchValue,radioSelected).map((item, index) => (
                  <tr key={item.li_pay_id}>
                    <td>{index + 1}</td>
                    <td>{item.li_pay_unique_id}</td>                   
                    <td>{item.li_pay_cu_name}</td>
                    {/* <td>{item.li_pay_mobile_no}</td> */}
                    <td>{item.li_pay_quo_amt}</td>
                    {/* <td>{item.li_pay_address}</td> */}
                    <td>{item.li_pay_pre_bal}</td>
                    <td>{item.li_pay_paid_amt}</td>
                    <td>{item.li_pay_total_bal}</td>
                    <td>{item.li_pay_date}</td>
                    <td>{item.li_payment_mode}</td>
                    
                    {/* <td>{item.li_follow_date}</td> */}
                    <td>{item.li_pay_work_status}</td>
                    <td className='d-flex justify-content-evenly'><a href="https://wa.me/" target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                      <a href="tel:9743719587"><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                  </tr>
                ))
              )}
              {radioSelected === 'amc' && (
                // Render amc payment data
                // customerDetails.map((item, index) => (
                  filterData(searchValue,radioSelected).map((item, index) => (
                  <tr key={item.amc_pay_id}>
                    <td>{index + 1}</td>
                   
                    <td>{item.amc_pay_unique_id}</td>
                    <td>{item.amc_pay_cu_name}</td>
                    {/* <td>{item.amc_pay_mobile_no}</td> */}
                    <td>{item.amc_pay_quo_amt}</td>
                    {/* <td>{item.amc_pay_address}</td> */}
                    <td>{item.amc_pay_pre_bal}</td>
                    <td>{item.amc_pay_paid_amt}</td>
                    <td>{item.amc_pay_total_bal}</td>
                    <td>{item.amc_pay_date}</td>
                    <td>{item.amc_payment_mode}</td>
                   
                    {/* <td>{item.amc_follow_date}</td> */}
                    <td>{item.amc_pay_work_status}</td>
                    <td className='d-flex justify-content-evenly'><a href="https://wa.me/" target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                      <a href="tel:9743719587"><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                  </tr>
                ))
              )}
              {radioSelected === 'mod' && (
                // Render amc payment data
                // customerDetails.map((item, index) => (
                  filterData(searchValue,radioSelected).map((item, index) => (
                  <tr key={item.mod_pay_id}>
                    <td>{index + 1}</td>
                    <td>{item.mod_pay_date}</td>
                    <td>{item.mod_pay_unique_id}</td>
                    <td>{item.mod_pay_cu_name}</td>
                    {/* <td>{item.mod_pay_mobile_no}</td> */}
                    <td>{item.mod_pay_quo_amt}</td>
                    {/* <td>{item.mod_pay_address}</td> */}
                    <td>{item.mod_pay_pre_bal}</td>
                    <td>{item.mod_pay_paid_amt}</td>
                    <td>{item.mod_pay_total_bal}</td>
                    <td>{item.mod_pay_date}</td>
                    <td>{item.mod_pay_work_status}</td>
                    <td className='d-flex justify-content-evenly'><a href="https://wa.me/" target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                      <a href="tel:9743719587"><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </form>
      </div>

    </>
  )
}

export default PendingPaymentMaster






