import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../pagination.css';


const BreakdownMaster = () => {

  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const [data, setData] = useState([])

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Total number of pages

  function openAdd() {

    navigate("/app/breakdown_add")
  }

  const handleUpdate = (break_id) => {
    // Navigate two steps back
    navigate(`/app/update_breakdown_add/${break_id}`);
  };


  ////////////////////////////////// Delete Api /////////////////////////////////

  useEffect(() => {

      getData()

    }, [currentPage])


    async function deleteOperation(id) {
      // alert(id)
      let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_break/" + id, {
        method: 'DELETE'
      });
      result = await result.json();
      // console.log(result)
      if (result.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        alert(result.message);

      } else {
        alert("User not found.");
      }
      getData();
    }


  /////////////////////////////// Api Call///////////////////////////////

  useEffect(() => {

    getData()

  }, [currentPage])




  function getData() {

    async function fetchData() {
      try {
        let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_breakdown_data?page=${currentPage}&perPage=${itemsPerPage}`);
        const { data: fetchedData, last_page: total } = await result.json();
        // console.log("data", result);
        setData(fetchedData);
        setTotalPages(total);
      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchData();

  }

  // Filtering logic
  const filteredData = data.filter(item => {
    const cuIdString = item.break_cu_unique_id.toString();
    const lowercaseSearch = searchValue.toLowerCase();
    return cuIdString.includes(lowercaseSearch) || item.break_cust_name.toLowerCase().includes(lowercaseSearch);
  });


  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Calculate the range of page numbers to display in the pagination UI
  const getPageRange = () => {
    const rangeSize = 5; // Number of page numbers to display
    const totalPagesInRange = Math.min(rangeSize, totalPages);
    let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
    let endPage = startPage + totalPagesInRange - 1;

    // Adjust endPage if it exceeds totalPages
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - totalPagesInRange + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };



  return (
    <>

      <div className='container'>
        <div className="row ">
          <div className='col-md-10 mt-4 mt-md-0'>
            <h5>Breakdown Quotation Details</h5>
          </div>

          <div className='col-md-2'>

            <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
              onChange={e => setSearchValue(e.target.value)} />

          </div>


        </div>

        <hr />
        <button onClick={openAdd} className="btn btn-primary buttonMargin">+Add Breakdown</button>

        <Table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Sl Id</th>
              <th scope="col">Date</th>
              <th scope="col">Job Id</th>
              <th scope="col">Customer Name</th>
              <th scope="col">Mobile No.</th>
              <th scope="col">Site address</th>
              <th scope="col">Landmark</th>
              {/* <th scope="col">Lift type</th> */}
              <th scope="col">Breakdown Description</th>
              <th scope="col">Status</th>
              <th scope="col">Contact No</th>
              <th scope="col">Action</th>

            </tr>
          </thead>
          <tbody>
            {
              filteredData.map((item) =>
                <tr key={item.break_id}>
                  {/* <th scope="row">1</th> */}
                  <td>{item.break_id}</td>
                  <td>{item.break_cu_unique_id}</td>
                  <td>{item.break_date}</td>
                  <td>{item.break_cust_name}</td>
                  <td>{item.break_cu_mob_no}</td>
                  <td>{item.break_site_adress}</td>
                  <td>{item.break_landmark}</td>
                  {/* <td>{item.break_lift_type}</td> */}
                  <td>{item.break_break_desc}</td>
                  <td>{item.break_status}</td>
                  <td>{item.break_con_no}</td>

                  <td> <span className='update' onClick={() => handleUpdate(item.break_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.break_id) }} >{<DeleteIcon style={{ color: 'red' }} />}</span></td>
                </tr>
              )
            }

          </tbody>
        </Table>


        {/* Pagination */}
        <div className='pagination'>
          {/* Show previous arrow icon */}
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
          )}

          {/* Show page numbers */}
          {getPageRange().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}

          {/* Show next arrow icon */}
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
          )}
        </div>


      </div>

    </>
  )
}

export default BreakdownMaster