import { useFormik } from "formik";
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { newLiftSchema } from "../Schema/NewliftAddindex";


const initialValues = {
    liftQuotationNo: "",
    newLiftNumber: "",
    customerName: "",
    gstNo: "",
    panNo: "",
    inputEmail: "",
    mobileNo: "",
    quotationNo: "",
    preAddres: "",
    subject: "",
    requireTo: "",
    liftType: "",
    capacity: "",
    powerPackLocationNo: "",
    motorMachine: "",
    ropes: "",
    speed: "",
    controller: "",
    drive: "",
    signals: "",
    emergencySignals: "",
    liftTravel: "",
    landingAndOpening: "",
    stops: "",
    carEncloser: "",
    flooring: "",
    doorType: "",
    landingDoor: "",
    powerSupply: "",
    quotationAmt: "",
    previousBal: "",
    paidAmt: "",
    totalBalance: "",
    liftStatus: "",
    invoiceDate: "",
    // followUpDate: "",


};


function NewliftAdd() {
    const navigate = useNavigate();
    const [liftQuotationNo, setLiftQuotationNo] = useState('');
    const [liftQuotationData, setLiftQuotationData] = useState(null);

    const [uniqueId, setUniqueId] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [quotationNo, setQuotationNo] = useState("");
    const [preAddress, setPreAddress] = useState("");
    const [subject, setSubject] = useState("");
    const [requireTo, setRequireTo] = useState("");
    const [toBeInstalledAt, setToBeInstalledAt] = useState("");
    const [liftType, setLiftType] = useState("");
    const [capacity, setCapacity] = useState("");
    const [powerPackLocationNo, setPowerPackLocationNo] = useState("");
    const [motorMachine, setMotorMachine] = useState("");
    const [ropes, setRopes] = useState("");
    const [speed, setSpeed] = useState("");
    const [controller, setController] = useState("");
    const [drive, setDrive] = useState("");
    const [signals, setSignals] = useState("");
    const [emergencySignals, setEmergencySignals] = useState("");
    const [mmBrand, setMmBrand] = useState("");
    const [ropesBrand, setRopesBrand] = useState("");
    const [note, setNote] = useState("");
    const [gst, setGst] = useState("");
    const [amtToWord, setAmtToWord] = useState("");
    const [controllerBrand, setControllerBrand] = useState("");
    const [doorTypeBrand, setDoorTypeBrand] = useState("");
    const [landingDoorBrand, setLandingDoorBrand] = useState("");

    const [liftTravel, setLiftTravel] = useState("");
    const [landingAndOpening, setLandingAndOpening] = useState("");
    const [stops, setStops] = useState("");
    const [carEncloser, setCarEncloser] = useState("");
    const [flooring, setFlooring] = useState("");
    const [doorType, setDoorType] = useState("");
    const [landingDoor, setLandingDoor] = useState("");
    const [powerSupply, setPowerSupply] = useState("");
    const [quotationAmt, setQuotationAmt] = useState("");
    const [previousBal, setPreviousBal] = useState(0);
    const [paidAmt, setPaidAmt] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [liftStatus, setLiftStatus] = useState("");
    const [description, setDescription] = useState("");




    const { values, errors, touched, handleBlur, handleChange, onKeyDown, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: newLiftSchema,
            onSubmit: async (values, action) => {
                console.log("values:", values);
                await addNewFinalLiftQuotation();
                paymentPrint();
                // navigate("/app/new_lift")
            },
        });
    console.log(errors);


    function saveData() {
        navigate("/app/new_lift")
    }



    function paymentPrint() {
        // navigate("/app/quotation_print")

        const confirmPrint = window.confirm('Do you want to proceed with printing?');

        if (confirmPrint) {
            navigate("/app/new_lift_print", {

                state: {

                    customerName: customerName,
                    uniqueId: uniqueId,
                    invoiceDate: invoiceDate,
                    quotationNo: quotationNo,
                    mobileNo: mobileNo,
                    panNo: panNo,
                    gstNo: gstNo,
                    inputEmail: inputEmail,
                    preAddress: preAddress,
                    subject: subject,
                    requireTo: requireTo,
                    toBeInstalledAt: toBeInstalledAt,
                    liftType: liftType,
                    capacity: capacity,
                    powerPackLocationNo: powerPackLocationNo,
                    motorMachine: motorMachine,
                    ropes: ropes,
                    speed: speed,
                    controller: controller,
                    drive: drive,
                    signals: signals,
                    emergencySignals: emergencySignals,
                    liftTravel: liftTravel,
                    landingAndOpening: landingAndOpening,
                    stops: stops,
                    carEncloser: carEncloser,
                    flooring: flooring,
                    doorType: doorType,
                    landingDoor: landingDoor,
                    powerSupply: powerSupply,
                    quotationAmt: quotationAmt,
                    note: note,
                    gst: gst,
                    amtToWord: amtToWord,
                    mmBrand: mmBrand,
                    ropesBrand: ropesBrand,
                    controllerBrand: controllerBrand,
                    doorTypeBrand: doorTypeBrand,
                    landingDoorBrand: landingDoorBrand,
                    description: description,


                },
            });
        }
    }

    /////////////////// to get data from from input field tos tore in databse//////////////////

    const handleUniqueId = (event) => {

        setUniqueId(event.target.value);
        handleChange(event);
    }

    const handleNote = (event) => {

        setNote(event.target.value);
        handleChange(event);
    }

    const handleGst = (event) => {

        setGst(event.target.value);
        handleChange(event);
    }

    const handleAmtToWord = (event) => {

        setAmtToWord(event.target.value);
        handleChange(event);
    }


    const handleLiftQuotationNo = (event) => {

        setLiftQuotationNo(event.target.value);
        handleChange(event);
    }

    const handleInvoiceDate = (event) => {

        setInvoiceDate(event.target.value);
        handleChange(event);
    }

    const handleCustomerName = (event) => {

        setCustomerName(event.target.value);
        handleChange(event);
    }


    const handleGstNo = (event) => {
        // console.log("On Change");

        setGstNo(event.target.value);
        handleChange(event);
    }
    const handlePanNo = (event) => {
        // console.log("On Change");

        setPanNo(event.target.value);
        handleChange(event);
    }
    const handleInputEmail = (event) => {
        // console.log("On Change");

        setInputEmail(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }


    const handleQuotationNo = (event) => {
        // console.log("On Change");

        setQuotationNo(event.target.value);
        handleChange(event);
    }

    const handlePreAddress = (event) => {
        // console.log("On Change");

        setPreAddress(event.target.value);
        handleChange(event);
    }

    const handleSubject = (event) => {
        // console.log("On Change");

        setSubject(event.target.value);
        handleChange(event);
    }

    const handleRequireTo = (event) => {
        // console.log("On Change");

        setRequireTo(event.target.value);
        handleChange(event);
    }

    const handleToBeInstalledAt = (event) => {
        // console.log("On Change");

        setToBeInstalledAt(event.target.value);
        handleChange(event);
    }

    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }

    const handleCapacity = (event) => {
        // console.log("On Change");

        setCapacity(event.target.value);
        handleChange(event);
    }

    const handlePowerPackLocationNo = (event) => {
        // console.log("On Change");

        setPowerPackLocationNo(event.target.value);
        handleChange(event);
    }

    const handleMotorMachine = (event) => {
        // console.log("On Change");

        setMotorMachine(event.target.value);
        handleChange(event);
    }

    const handleMMBrand = (event) => {
        // console.log("On Change");

        setMmBrand(event.target.value);
        handleChange(event);
    }


    const handleRopesBrand = (event) => {
        // console.log("On Change");

        setRopesBrand(event.target.value);
        handleChange(event);
    }


    const handleControllerBrand = (event) => {
        // console.log("On Change");

        setControllerBrand(event.target.value);
        handleChange(event);
    }

    const handleDoorTypeBrand = (event) => {
        // console.log("On Change");

        setDoorTypeBrand(event.target.value);
        handleChange(event);
    }

    const handleLandingDoorBrand = (event) => {
        // console.log("On Change");

        setLandingDoorBrand(event.target.value);
        handleChange(event);
    }


    const handleRopes = (event) => {
        // console.log("On Change");

        setRopes(event.target.value);
        handleChange(event);
    }

    const handleSpeed = (event) => {
        // console.log("On Change");

        setSpeed(event.target.value);
        handleChange(event);
    }

    const handleController = (event) => {
        // console.log("On Change");

        setController(event.target.value);
        handleChange(event);
    }

    const handleDrive = (event) => {
        // console.log("On Change");

        setDrive(event.target.value);
        handleChange(event);
    }

    const handleSignals = (event) => {
        // console.log("On Change");

        setSignals(event.target.value);
        handleChange(event);
    }

    const handleEmergencySignals = (event) => {
        // console.log("On Change");

        setEmergencySignals(event.target.value);
        handleChange(event);
    }

    const handleLiftTravel = (event) => {
        // console.log("On Change");

        setLiftTravel(event.target.value);
        handleChange(event);
    }

    const handleLandingAndOpening = (event) => {
        // console.log("On Change");

        setLandingAndOpening(event.target.value);
        handleChange(event);
    }

    const handleStops = (event) => {
        // console.log("On Change");

        setStops(event.target.value);
        handleChange(event);
    }

    const handleCarEncloser = (event) => {
        // console.log("On Change");

        setCarEncloser(event.target.value);
        handleChange(event);
    }

    const handleFlooring = (event) => {
        // console.log("On Change");

        setFlooring(event.target.value);
        handleChange(event);
    }

    const handleDoorType = (event) => {
        // console.log("On Change");

        setDoorType(event.target.value);
        handleChange(event);
    }

    const handleLandingDoor = (event) => {
        // console.log("On Change");

        setLandingDoor(event.target.value);
        handleChange(event);
    }

    const handlePowerSupply = (event) => {
        // console.log("On Change");

        setPowerSupply(event.target.value);
        handleChange(event);
    }

    const handleLiftStatus = (event) => {

        setLiftStatus(event.target.value);
        handleChange(event);
    }

    const handleQuotationAmt = (event) => {
        // console.log("On Change");

        setQuotationAmt(event.target.value);
        handleChange(event);
    }

    const handlePreviousBal = (event) => {
        // console.log("On Change");

        setPreviousBal(event.target.value);
        handleChange(event);
    }

    const handleSetDescription = (event) => {
        // console.log("On Change");

        setDescription(event.target.value);
        handleChange(event);
    }


    const handlePaidAmt = (event) => {
        // console.log("On Change");

        setPaidAmt(event.target.value);
        handleChange(event);
        const newTotalBalance = parseInt(quotationAmt || 0) + parseInt(previousBal || 0) - parseInt(event.target.value || 0);
        setTotalBalance(newTotalBalance);
    }


    const handleTotalBalance = (event) => {
        // console.log("On Change");

        setTotalBalance(event.target.value);
        // handleChange(event);
    }



    //// handle onclcik function//////////////////////////

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            console.log('Enter key pressed');
            console.log('Previous Balance:', previousBal);
            console.log('Paid Amount:', paidAmt);

            const newTotalBalance = previousBal - paidAmt;
            console.log('New Total Balance:', newTotalBalance);

            setTotalBalance(newTotalBalance);
        }
    };





    ///////////////////////////////api call///////////////////////////////
    ///////////////////////////////api call for getting data by quotation no.///////////////////////////////


    const handleSearchClick = async () => {
        try {

            const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_newlift_data/${values.liftQuotationNo}`);



            // if (!response.ok) {
            //     throw new Error('Error fetching data');
            // }

            const data = await response.json();

            if (data.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data.message);

            } else {



                console.log("data", data);
                setLiftQuotationData(data);
                setUniqueId(data.li_cu_unique_id)
                setCustomerName(data.li_quo_cu_name)
                setGstNo(data.li_quo_cu_gst_no)
                setPanNo(data.li_quo_cu_pan_no)
                setInputEmail(data.li_quo_cu_email)
                setMobileNo(data.li_quo_cu_mobNo)
                setPreAddress(data.li_quo_cu_address)
                setSubject(data.li_quo_sub)
                setRequireTo(data.li_quo_req_to)
                setToBeInstalledAt(data.li_quo_installed)
                setLiftType(data.li_quo_lift_type)
                setCapacity(data.li_quo_capacity)
                setPowerPackLocationNo(data.li_quo_location_no)
                setMotorMachine(data.li_quo_mo_machine)
                setRopes(data.li_quo_ropes)
                setSpeed(data.li_quo_speed)
                setController(data.li_quo_controller)
                setDrive(data.li_quo_drive)
                setSignals(data.li_quo_signal)
                setEmergencySignals(data.li_quo_em_signal)
                setLiftTravel(data.li_quo_travel)
                setLandingAndOpening(data.li_quo_land_open)
                setStops(data.li_quo_stops)
                setCarEncloser(data.li_quo_car_encloser)
                setFlooring(data.li_quo_flooring)
                setDoorType(data.li_quo_cardoor)
                setLandingDoor(data.li_quo_land_door)
                setPowerSupply(data.li_quo_pow_sply)
                setQuotationAmt(data.li_quo_amount)
                setPreviousBal(data.li_quo_lift_pre_bal)
                setDescription(data.li_quo_description)
                setMmBrand(data.li_quo_mo_machine_brand)
                setRopesBrand(data.li_quo_ropes_brand)
                setControllerBrand(data.li_quo_controller_brand)
                setDoorTypeBrand(data.li_quo_cardoor_brand)
                setLandingDoorBrand(data.li_quo_land_door_brand)
                setNote(data.li_quo_taxes)
                setGst(data.li_quo_gst)
                setAmtToWord(data.li_quo_amt_word)

            }
        } catch (error) {
            console.error('Error fetching lift quotation data:', error);
        }
    };


    ////////////////////////// increment lift quotation no increment  api//////////////////////////////

    useEffect(() => {
        // Fetch the next quotation number when the component mounts
        const fetchNextQuotationNumber = async () => {
            try {
                const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/newlift_quono_incre');
                const data = await response.json();
                console.log(data);
                setQuotationNo(data.newId.toString());
            } catch (error) {
                console.error('Error fetching next quotation number:', error);
            }
        };

        fetchNextQuotationNumber();
    }, []); // Empty dependency array to ensure this runs only once when the component mounts

    /////////////////////////////////// store new lift data in databse/////////////////////////

    async function addNewFinalLiftQuotation() {
        // console.log(name,file,price,description)

        const confirmPrint = window.confirm('Do you want save?');
        if (confirmPrint) {

            const formData = new FormData();
            formData.append("uniqueId", uniqueId)
            formData.append("liftQuotationNo", liftQuotationNo)
            formData.append("invoiceDate", invoiceDate)
            formData.append("customerName", customerName)
            formData.append("panNo", panNo)
            formData.append("gstNo", gstNo)
            formData.append("inputEmail", inputEmail)
            formData.append("mobileNo", mobileNo)
            formData.append("quotationNo", quotationNo)
            formData.append("preAddress", preAddress)
            formData.append("subject", subject)
            formData.append("requireTo", requireTo)
            formData.append("toBeInstalledAt", toBeInstalledAt)
            formData.append("liftType", liftType)
            formData.append("capacity", capacity)
            formData.append("powerPackLocationNo", powerPackLocationNo)
            formData.append("motorMachine", motorMachine)
            formData.append("ropes", ropes)
            formData.append("speed", speed)
            formData.append("controller", controller)
            formData.append("drive", drive)
            formData.append("signals", signals)
            formData.append("emergencySignals", emergencySignals)
            formData.append("liftTravel", liftTravel)
            formData.append("landingAndOpening", landingAndOpening)
            formData.append("stops", stops)
            formData.append("carEncloser", carEncloser)
            formData.append("flooring", flooring)
            formData.append("doorType", doorType)
            formData.append("landingDoor", landingDoor)
            formData.append("powerSupply", powerSupply)
            formData.append("liftStatus", liftStatus)
            formData.append("quotationAmt", quotationAmt)
            formData.append("previousBal", previousBal)
            formData.append("paidAmt", paidAmt)
            formData.append("totalBalance", totalBalance)
            formData.append("description", description)
            formData.append("mmBrand", mmBrand)
            formData.append("ropesBrand", ropesBrand)
            formData.append("controllerBrand", controllerBrand)
            formData.append("doorTypeBrand", doorTypeBrand)
            formData.append("landingDoorBrand", landingDoorBrand)
            formData.append("note", note)
            formData.append("gst", gst)
            formData.append("amtToWord", amtToWord)

            try {
                let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/newlift_store_data', {
                    method: 'POST',
                    body: formData
                });

                let data = await response.json(); // Parse the response body as JSON

                console.log("api data", data);

                if (data.message) {
                    // If the response status is within the 2xx range, it means the request was successful.
                    alert(data.message);

                } else {
                    alert("An error occurred while saving the data.");
                }


            } catch (error) {
                console.error('Error while adding customer:', error);
                alert("An error occurred while saving the data2.");

            }
        }
    }









    return (
        <>
            <div className='container'>

                <form onSubmit={handleSubmit}>

                    <div className="row mb-1 mt-5">
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Add New Lift Details</h4>
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                                <input type="tex" className="form-control a1" id="uniqueId" name="uniqueId"
                                    value={uniqueId}
                                    onChange={handleUniqueId}
                                    onBlur={handleBlur} disabled="true"
                                />
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="invoiceDate" className='' style={{ fontSize: '14px' }}>Date</label>
                                <input type="date" className="form-control a1" name="invoiceDate" id="invoiceDate"

                                    value={values.invoiceDate}
                                    onChange={handleInvoiceDate}
                                    onBlur={handleBlur}
                                />
                                {touched.invoiceDate && errors.invoiceDate ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.invoiceDate}</p>
                                ) : null}
                            </div>
                        </div>


                    </div>

                    <hr />

                    <div className="row mb-1">
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="liftQuotationNo" className='' style={{ fontSize: '14px' }}>Lift Quotation Number</label>
                                <input type="text" name="liftQuotationNo" className="form-control a1" id="liftQuotationNo"
                                    value={values.liftQuotationNo}
                                    onChange={handleLiftQuotationNo}
                                    onBlur={handleBlur} />
                                {touched.liftQuotationNo && errors.liftQuotationNo ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.liftQuotationNo}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 d-grid ">
                            <div className=" form-group ">
                                <button className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
                            </div>
                        </div>




                    </div>

                    <div className="row mb-1 ">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                                <input type="text" name="customerName" className="form-control a1" id="customerName"
                                    value={customerName}
                                    onChange={handleCustomerName}
                                    onBlur={handleBlur} />
                                {/* {touched.customerName && errors.customerName ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.customerName}</p>
                                ) : null} */}
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No.</label>
                                <input type="text" name="gstNo" className="form-control a1" id="gstNo"
                                    value={gstNo}
                                    onChange={handleGstNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.gstNo && errors.gstNo ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.gstNo}</p>
                                ) : null} */}
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN</label>
                                <input type="text" name="panNo" className="form-control a1" id="panNo"
                                    value={panNo}
                                    onChange={handlePanNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.panNo && errors.panNo ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.panNo}</p>
                                ) : null} */}
                            </div>
                        </div>


                    </div>

                    <div className="row ">
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="inputEmail" className='' style={{ fontSize: '14px' }}>Email</label>
                                <input type="email" className="form-control a1" id="inputEmail"
                                    value={inputEmail}
                                    onChange={handleInputEmail}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.inputEmail && errors.inputEmail ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.inputEmail}</p>
                                ) : null} */}
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile</label>
                                <input type="text" name="mobileNo" className="form-control a1" id="mobileNo"
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.mobileNo && errors.mobileNo ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.mobileNo}</p>
                                ) : null} */}
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quotation No</label>
                                <input type="text" name="quotationNo" className="form-control a1" id="quotationNo"
                                    value={quotationNo}
                                    onChange={handleQuotationNo}
                                    onBlur={handleBlur} />
                                {/* {touched.quotationNo && errors.quotationNo ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.quotationNo}</p>
                                ) : null} */}
                            </div>
                        </div>


                        <div className="row mt-4">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                                    <textarea className="form-control a1" name="preAddress" id="preAddress" rows="5"
                                        value={preAddress}
                                        onChange={handlePreAddress}
                                        onBlur={handleBlur} disabled="true"></textarea>
                                    {/* {touched.preAddres && errors.preAddres ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.preAddres}</p>
                                    ) : null} */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='page__main'>

                        <h4>Lift Details</h4>
                        <hr />

                        <div className="row mb-1">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="subject" className='' style={{ fontSize: '14px' }}>Subject</label>
                                    <input type="text" name="subject" className="form-control a1" id="subject"
                                        value={subject}
                                        onChange={handleSubject}
                                        onBlur={handleBlur} />
                                    {/* {touched.subject && errors.subject ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.subject}</p>
                                ) : null} */}
                                </div>
                            </div>

                        </div>

                        <div className="row mb-1">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="description" className='' style={{ fontSize: '14px' }}>Description</label>
                                    <input type="text" name="description" className="form-control a1" id="description"
                                        value={description}
                                        onChange={handleSetDescription}
                                        onBlur={handleBlur} />

                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">

                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="signals" className='' style={{ fontSize: '14px' }}>Signals</label>
                                    <input type="text" name="signals" className="form-control a1" id="signals"
                                        value={signals}
                                        onChange={handleSignals}
                                        onBlur={handleBlur} disabled="true" />
                                    {/* {touched.signals && errors.signals ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.signals}</p>
                                    ) : null} */}
                                </div>
                            </div>

                        </div>

                        <div className="row mb-1">

                            <div className="col-md-3 ">
                                <div className="form-group">
                                    <label htmlFor="requireTo" className='' style={{ fontSize: '14px' }}>Required To</label>
                                    <input type="text" name="requireTo" className="form-control a1" id="requireTo"
                                        value={requireTo}
                                        onChange={handleRequireTo}
                                        onBlur={handleBlur} />
                                    {/* {touched.requireTo && errors.requireTo ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.requireTo}</p>
                                    ) : null} */}
                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="toBeInstalledAt" className='' style={{ fontSize: '14px' }}>To be Installed at</label>
                                    <input type="text" className="form-control a1" id="toBeInstalledAt"
                                        value={toBeInstalledAt}
                                        onChange={handleToBeInstalledAt}
                                        onBlur={handleBlur} />

                                </div>
                            </div>



                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="motorMachine" className='' style={{ fontSize: '14px' }}>Motor Machines </label>
                                    <select type="text" name='motorMachine' className="form-select border border-dark" id="motorMachine"
                                        value={motorMachine}
                                        onChange={handleMotorMachine}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="5HP MR Geared" style={{ background: '#eaeaea' }}>5HP MR Geared</option>
                                        <option value="7.5HP MR Geared" style={{ background: '#eaeaea' }}>7.5HP MR Geared</option>
                                        <option value="10HP MR Geared" style={{ background: '#eaeaea' }}>10HP MR Geared</option>
                                        <option value="5HP MRL Gearless" style={{ background: '#eaeaea' }}>5HP MRL Gearless</option>
                                        <option value="7.5HP MRL Gearless" style={{ background: '#eaeaea' }}>7.5HP MRL Gearless</option>
                                        <option value="10HP MRL Gearless" style={{ background: '#eaeaea' }}>10HP MRL Gearless</option>

                                    </select>




                                    {/* {touched.motorMachine && errors.motorMachine ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.motorMachine}</p>
                  ) : null} */}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="mmBrand" className='' style={{ fontSize: '14px' }}>MM Brands</label>
                                    <select type="text" name='mmBrand' className="form-select border border-dark" id="mmBrand"
                                        value={mmBrand}
                                        onChange={handleMMBrand}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="(Sharp)" style={{ background: '#eaeaea' }}>Sharp</option>
                                        <option value="(Montanari)" style={{ background: '#eaeaea' }}>Montanari</option>
                                        <option value="(Bharat Bijali)" style={{ background: '#eaeaea' }}>Bharat Bijali</option>

                                    </select>

                                </div>
                            </div>




                            {/* <div className='col-md-4'>

                                <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Lift Type</label>

                                <select className="form-select border border-dark " style={{ boxShadow: 'none' }} aria-label="Default select example"
                                    name="liftType"
                                    id="liftType"
                                    value={liftType}
                                    onChange={handleLiftType}
                                    onBlur={handleBlur}>

                                    <option selected style={{ display: 'none' }}></option>
                                    <option value="Site Visit" style={{ background: '#dddddd', color: "blue" }}>Site Visit</option>
                                    <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                                    <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                                    <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                                    <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                                    <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                                    <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                                </select>

                            
                            </div> */}
                        </div>

                        <div className="row mb-1">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="capacity" className='' style={{ fontSize: '14px' }}>Capacity*</label>
                                    <select type="text" name='capacity' className="form-select border border-dark" id="capacity"
                                        value={capacity}
                                        onChange={handleCapacity}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="2 Person's (136 Kgs)" style={{ background: '#eaeaea' }}>2 Person's (136 Kgs)</option>
                                        <option value="3 Person's (204 Kgs)" style={{ background: '#eaeaea' }}>3 Person's (204 Kgs)</option>
                                        <option value="4 Person's (272 Kgs)" style={{ background: '#eaeaea' }}>4 Person's (272 Kgs)</option>
                                        <option value="5 Person's (340 Kgs)" style={{ background: '#eaeaea' }}>5 Person's (340 Kgs)</option>
                                        <option value="6 Person's (408 Kgs)" style={{ background: '#eaeaea' }}>6 Person's (408 Kgs)</option>
                                        <option value="8 Person's (544 Kgs)" style={{ background: '#eaeaea' }}>8 Person's (544 Kgs)</option>
                                        <option value="10 Person's (680 Kgs)" style={{ background: '#eaeaea' }}>10 Person's (680 Kgs)</option>
                                        <option value="13 Person's (884 Kgs)" style={{ background: '#eaeaea' }}>13 Person's (884 Kgs)</option>
                                        <option value="16 Person's (1088 Kgs)" style={{ background: '#eaeaea' }}>16 Person's (1088 Kgs)</option>

                                    </select>


                                    {/* {touched.capacity && errors.capacity ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.capacity}</p>
                  ) : null} */}
                                </div>
                            </div>

                            <div className='col-md-3'>

                                <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Type Lift:</label>

                                <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                    name='liftType'
                                    id='liftType'
                                    value={liftType}
                                    onChange={handleLiftType}
                                    onBlur={handleBlur}>
                                    {/* <option value="" style={{ display: 'none' }}></option> */}
                                    <option selected style={{ dispaly: 'none' }}></option>
                                    <option value="Passanger Lift" style={{ background: '#eaeaea' }}>Passanger Lift</option>
                                    <option value="Hospital Lift" style={{ background: '#eaeaea' }}>Hospital Lift</option>
                                    <option value="Hydrolic Lift" style={{ background: '#eaeaea' }}>Hydrolic Lift</option>
                                    <option value="Good's Lift" style={{ background: '#eaeaea' }}>Good's Lift</option>
                                    <option value="Good's with Passanger Lift" style={{ background: '#eaeaea' }}>Good's with Passanger Lift</option>
                                    <option value="Scissor Lift" style={{ background: '#eaeaea' }}>Scissor Lift</option>

                                </select>



                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ropes" className='' style={{ fontSize: '14px' }}>Ropes</label>
                                    <select type="text" name='ropes' className="form-select border border-dark" id="ropes"
                                        value={ropes}
                                        onChange={handleRopes}
                                        onBlur={handleBlur} >
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option value="10 mm dia 3nos" style={{ background: '#eaeaea' }}>10 mm dia 3nos</option>
                                        <option value="13 mm dia 3nos" style={{ background: '#eaeaea' }}>13 mm dia 3nos</option>
                                        <option value="8 mm dia 4nos" style={{ background: '#eaeaea' }}>8 mm dia 4nos</option>
                                        <option value="6 mm dia 5nos" style={{ background: '#eaeaea' }}>6 mm dia 5nos</option>
                                        <option value="6 mm dia 6nos" style={{ background: '#eaeaea' }}>6 mm dia 6nos</option>
                                        <option value="8 mm dia 6nos" style={{ background: '#eaeaea' }}>8 mm dia 6nos</option>

                                    </select>


                                    {/* {touched.ropes && errors.ropes ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.ropes}</p>
                  ) : null} */}
                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ropesBrand" className='' style={{ fontSize: '14px' }}>Ropes Brands</label>
                                    <select type="text" name='ropesBrand' className="form-select border border-dark" id="ropesBrand"
                                        value={ropesBrand}
                                        onChange={handleRopesBrand}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="(Usha Martine)" style={{ background: '#eaeaea' }}>Usha Martine</option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="speed" className='' style={{ fontSize: '14px' }}>Speed</label>
                                    <select type="text" name='speed' className="form-select border border-dark" id="speed"
                                        value={speed}
                                        onChange={handleSpeed}
                                        onBlur={handleBlur} >
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option value="0.68 mtr/sec" style={{ background: '#eaeaea' }}>0.68 mtr/sec</option>
                                        <option value="1 mtr/sec" style={{ background: '#eaeaea' }}>1 mtr/sec</option>

                                    </select>

                                    {/* {touched.speed && errors.speed ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.speed}</p>
                  ) : null} */}
                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="drive" className='' style={{ fontSize: '14px' }}>Drive Brand</label>
                                    <select type="text" name='drive' className="form-select border border-dark" id="drive"
                                        value={drive}
                                        onChange={handleDrive}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="Monarch" style={{ background: '#eaeaea' }}>Monarch</option>
                                        <option value="INVT" style={{ background: '#eaeaea' }}>INVT</option>
                                        <option value="FUJI" style={{ background: '#eaeaea' }}>FUJI</option>
                                        <option value="Close Loop" style={{ background: '#eaeaea' }}>Close Loop</option>

                                    </select>

                                    {/* {touched.drive && errors.drive ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.drive}</p>
                  ) : null} */}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="controller" className='' style={{ fontSize: '14px' }}>Controller </label>

                                    <input type="text" name='controller' className="form-control a1" id="controller"
                                        value={controller}
                                        onChange={handleController}
                                        onBlur={handleBlur} disabled="true" />



                                    {/* {touched.controller && errors.controller ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.controller}</p>
                  ) : null} */}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="controllerBrand" className='' style={{ fontSize: '14px' }}>Controller Brands</label>
                                    <select type="text" name='controllerBrand' className="form-select border border-dark" id="controllerBrand"
                                        value={controllerBrand}
                                        onChange={handleControllerBrand}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="(ADCO)" style={{ background: '#eaeaea' }}>ADCO</option>
                                        <option value="(RIDHI)" style={{ background: '#eaeaea' }}>RIDHI</option>
                                        <option value="(MONARCH)" style={{ background: '#eaeaea' }}>MONARCH</option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="emergencySignals" className='' style={{ fontSize: '14px' }}>Emergency Signals </label>
                                    <input type="text" name="emergencySignals" className="form-control a1" id="emergencySignals"
                                        value={emergencySignals}
                                        onChange={handleEmergencySignals}
                                        onBlur={handleBlur} disabled="true" />
                                    {/* {touched.emergencySignals && errors.emergencySignals ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.emergencySignals}</p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="liftTravel" className='' style={{ fontSize: '14px' }}>Travel </label>
                                    <select type="text" name='liftTravel' className="form-select border border-dark" id="liftTravel"
                                        value={liftTravel}
                                        onChange={handleLiftTravel}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="G+1 (G 1)" style={{ background: '#eaeaea' }}>G+1 (G 1)</option>
                                        <option value="G+2 (G 1 2)" style={{ background: '#eaeaea' }}>G+2 (G 1 2)</option>
                                        <option value="G+3 (G 1 2 3)" style={{ background: '#eaeaea' }}>G+3 (G 1 2 3)</option>
                                        <option value="G+4 (G 1 2 3 4)" style={{ background: '#eaeaea' }}>G+4 (G 1 2 3 4)</option>

                                    </select>
                                </div>
                            </div>

                            <div className='col-md-3'>

                                <label htmlFor="doorType" className='' style={{ fontSize: '14px' }}>Car Door</label>
                                <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                    name='doorType'
                                    id='doorType'
                                    value={doorType}
                                    onChange={handleDoorType}
                                    onBlur={handleBlur}>
                                    {/* <option value="" style={{ display: 'none' }}></option> */}
                                    <option selected style={{ dispaly: 'none' }}></option>
                                    <option value="S.S Automatic Door" style={{ background: '#dddddd' }}>S.S Automatic Door</option>
                                    <option value="M.S Automatic Door" style={{ background: '#dddddd' }}>M.S Automatic Door</option>
                                    <option value="S.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>S.S Automatic Big Vision Door</option>
                                    <option value="M.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>M.S Automatic Big Vision Door</option>
                                    <option value="M.S Swing Door's" style={{ background: '#dddddd' }}>M.S Swing Door's</option>
                                    <option value="M.S IMPERFORATED Door's" style={{ background: '#dddddd' }}>M.S IMPERFORATED Door's</option>

                                </select>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="doorTypeBrand" className='' style={{ fontSize: '14px' }}>CD Brands</label>
                                    <select type="text" name='doorTypeBrand' className="form-select border border-dark" id="doorTypeBrand"
                                        value={doorTypeBrand}
                                        onChange={handleDoorTypeBrand}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="(EC India)" style={{ background: '#eaeaea' }}>EC India</option>
                                        <option value="(BST)" style={{ background: '#eaeaea' }}>BST</option>
                                        <option value="(Fermator)" style={{ background: '#eaeaea' }}>Fermator</option>
                                        <option value="(Genisis)" style={{ background: '#eaeaea' }}>Genisis</option>
                                    </select>

                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="landingAndOpening" className='' style={{ fontSize: '14px' }}>Landings and Opening</label>
                                    <select type="text" name='landingAndOpening' className="form-select border border-dark" id="landingAndOpening"
                                        value={landingAndOpening}
                                        onChange={handleLandingAndOpening}
                                        onBlur={handleBlur} >

                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="2 Landing's 2 Opening's" style={{ background: '#eaeaea' }}>2 Landing's 2 Opening's</option>
                                        <option value="3 Landing's 3 Opening's" style={{ background: '#eaeaea' }}>3 Landing's 3 Opening's</option>
                                        <option value="4 Landing's 4 Opening's" style={{ background: '#eaeaea' }}>4 Landing's 4 Opening's</option>
                                        <option value="5 Landing's 5 Opening's" style={{ background: '#eaeaea' }}>5 Landing's 5 Opening's</option>
                                        <option value="6 Landing's 6 Opening's" style={{ background: '#eaeaea' }}>6 Landing's 6 Opening's</option>
                                        <option value="7 Landing's 7 Opening's" style={{ background: '#eaeaea' }}>7 Landing's 7 Opening's</option>
                                        <option value="8 Landing's 8 Opening's" style={{ background: '#eaeaea' }}>8 Landing's 8 Opening's</option>

                                    </select>



                                    {/* {touched.landingAndOpening && errors.landingAndOpening ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.landingAndOpening}</p>
                  ) : null} */}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                                    <select type="text" className="form-select border border-dark" id="stops"
                                        value={stops}
                                        onChange={handleStops}
                                        onBlur={handleBlur} >
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option value="2" style={{ background: '#eaeaea' }}>2</option>
                                        <option value="3" style={{ background: '#eaeaea' }}>3</option>
                                        <option value="4" style={{ background: '#eaeaea' }}>4</option>
                                        <option value="5" style={{ background: '#eaeaea' }}>5</option>
                                        <option value="6" style={{ background: '#eaeaea' }}>6</option>
                                        <option value="7" style={{ background: '#eaeaea' }}>7</option>
                                        <option value="8" style={{ background: '#eaeaea' }}>8</option>

                                    </select>



                                    {/* {touched.stops && errors.stops ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.stops}</p>
                  ) : null} */}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="landingDoor" className='' style={{ fontSize: '14px' }}>Landing Door </label>
                                    <select type="text" name='landingDoor' className="form-select border border-dark" id="landingDoor"
                                        value={landingDoor}
                                        onChange={handleLandingDoor}
                                        onBlur={handleBlur} >
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option value="S.S Automatic Door" style={{ background: '#dddddd' }}>S.S Automatic Door</option>
                                        <option value="M.S Automatic Door" style={{ background: '#dddddd' }}>M.S Automatic Door</option>
                                        <option value="S.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>S.S Automatic Big Vision Door</option>
                                        <option value="M.S Automatic Big Vision Door" style={{ background: '#dddddd' }}>M.S Automatic Big Vision Door</option>
                                        <option value="M.S Swing Door's" style={{ background: '#dddddd' }}>M.S Swing Door's</option>
                                        <option value="M.S IMPERFORATED Door's" style={{ background: '#dddddd' }}>M.S IMPERFORATED Door's</option>

                                    </select>




                                    {/* {touched.landingDoor && errors.landingDoor ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.landingDoor}</p>
                  ) : null} */}
                                </div>
                            </div>

                            <div className="col-md-3">

                                <div className="form-group">
                                    <label htmlFor="landingDoorBrand" className='' style={{ fontSize: '14px' }}>LD Brands</label>
                                    <select type="text" name='landingDoorBrand' className="form-select border border-dark" id="landingDoorBrand"
                                        value={landingDoorBrand}
                                        onChange={handleLandingDoorBrand}
                                        onBlur={handleBlur} >
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="(EC India)" style={{ background: '#eaeaea' }}>EC India</option>
                                        <option value="(BST)" style={{ background: '#eaeaea' }}>BST</option>
                                        <option value="(Fermator)" style={{ background: '#eaeaea' }}>Fermator</option>
                                        <option value="(Genisis)" style={{ background: '#eaeaea' }}>Genisis</option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="carEncloser " className='' style={{ fontSize: '14px' }}>Car Encloser </label>
                                    <select type="text" name='carEncloser' className="form-select border border-dark" id="carEncloser"
                                        value={carEncloser}
                                        onChange={handleCarEncloser}
                                        onBlur={handleBlur} >

                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        <option value="S.S Hairline Finish Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Hairline Finish Cabin with Spotlight Fall Ceilling & Bloor</option>
                                        <option value="S.S Designer Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Designer Cabin with Spotlight Fall Ceilling & Bloor</option>
                                        <option value="M.S Designer Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>M.S Designer Cabin with Spotlight Fall Ceilling & Bloor</option>
                                        <option value="S.S Glass Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>S.S Glass Cabin with Spotlight Fall Ceilling & Bloor</option>
                                        <option value="M.S Glass Cabin with Spotlight Fall Ceilling & Bloor" style={{ background: '#eaeaea' }}>M.S Glass Cabin with Spotlight Fall Ceilling & Bloor</option>

                                    </select>
                                    {/* {touched.carEncloser && errors.carEncloser ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.carEncloser}</p>
                  ) : null} */}


                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="flooring" className='' style={{ fontSize: '14px' }}>Flooring</label>
                                    <select type="text" name='flooring' className="form-select border border-dark" id="flooring"
                                        value={flooring}
                                        onChange={handleFlooring}
                                        onBlur={handleBlur} >
                                        <option selected style={{ dispaly: 'none' }}></option>
                                        {/* <option value="" style={{ display: 'none' }}></option> */}
                                        <option value="Antiskid PVC Designer Flooring" style={{ background: '#eaeaea' }}>Antiskid PVC Designer Flooring</option>
                                        <option value="Chequer Plate Flooring" style={{ background: '#eaeaea' }}>Chequer Plate Flooring</option>


                                    </select>
                                    {/* {touched.flooring && errors.flooring ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.flooring}</p>
                  ) : null} */}

                                </div>
                            </div>



                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="powerSupply" className='' style={{ fontSize: '14px' }}>Power Supply</label>
                                    <input type="text" className="form-control a1" id="powerSupply"
                                        value={powerSupply}
                                        onChange={handlePowerSupply}
                                        onBlur={handleBlur} disabled="true" />

                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="quotationAmt" className='' style={{ fontSize: '14px' }}>Quotation Amount</label>
                                    <input type="text" name="quotationAmt" className="form-control a1" id="quotationAmt"
                                        value={quotationAmt}
                                        onChange={handleQuotationAmt}
                                        onBlur={handleBlur} disabled="true" />
                                    {/* {touched.quotationAmt && errors.quotationAmt ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.quotationAmt}</p>
                                    ) : null} */}
                                </div>
                            </div>

                        </div>

                        <div className="row mb-1">

                        <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="amtToWord" className='' style={{ fontSize: '14px' }}>Amt in Words</label>
                                    <input type="text" name='amtToWord' className="form-control a1" id="amtToWord"
                                        value={amtToWord}
                                        onChange={handleAmtToWord}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="previousBal" className='' style={{ fontSize: '14px' }}>Previous Balance </label>
                                    <input type="number" name="previousBal" className="form-control a1" id="previousBal"
                                        value={previousBal}
                                        onChange={handlePreviousBal}
                                    // onBlur={handleBlur} 
                                    />
                                    {touched.previousBal && errors.previousBal ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.previousBal}</p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="paidAmt" className='' style={{ fontSize: '14px' }}>Paid Amount</label>
                                    <input type="number" name="paidAmt" className="form-control a1" id="paidAmt"
                                        value={paidAmt}
                                        onChange={handlePaidAmt}
                                        onKeyDown={handleKeyDown}
                                    // onBlur={handleBlur}
                                    />
                                    {touched.paidAmt && errors.paidAmt ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.paidAmt}</p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="totalBalance" className='' style={{ fontSize: '14px' }}>Total Balance</label>
                                    <input type="number" name="totalBalance" className="form-control a1" id="totalBalance"
                                        value={totalBalance}
                                        onChange={handleTotalBalance}
                                        // onBlur={handleBlur} 
                                        readOnly
                                    />

                                </div>
                            </div>

                          

                        </div>

                        <div className="row mb-1">




                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="note" className='' style={{ fontSize: '14px' }}>Note</label>
                                    <input type="text" name='note' className="form-control a1" id="note"
                                        value={note}
                                        onChange={handleNote}
                                    // onBlur={handleBlur} 
                                    />

                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="gst" className='' style={{ fontSize: '14px' }}>Gst</label>
                                    <input type="text" name='gst' className="form-control a1" id="gst"
                                        value={gst}
                                        onChange={handleGst}
                                    // onBlur={handleBlur} 
                                    />

                                </div>
                            </div>



                            <div className='col-md-3'>

                            <label htmlFor="liftStatus" className='' style={{ fontSize: '14px' }}>Status</label>

                            <select className="form-select border border-dark" aria-label="Default select example"
                                name='liftStatus'
                                id='liftStatus'
                                value={liftStatus}
                                onChange={handleLiftStatus}
                                onBlur={handleBlur}>
                                <option selected style={{ dispaly: 'none' }}></option>

                                <option value="Ongoing" style={{ background: '#dddddd', color: "blue" }}>Ongoing</option>
                                <option value="Pending" style={{ background: '#dddddd', color: "red" }}>Pending</option>
                                <option value="Completed" style={{ background: '#dddddd', color: "green" }}>Completed</option>
                            </select>


                            </div>

                        </div>



                        <div className="row mb-1">




                            {/* <div className="col-md-4 ">
                                <div className="form-group">
                                    <label htmlFor="followUpDate" className='name_label stops' >Follow up Date</label>
                                    <input type="date" name='followUpDate' className="form-control a1 stops_input" id="followUpDate"
                                        value={values.followUpDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur} />
                                    {touched.followUpDate && errors.followUpDate ? (
                                        <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.followUpDate}</p>
                                    ) : null}

                                </div>
                            </div> */}
                        </div>


                        <div className='d-flex justify-content-end'>

                            <div className="btn-group my-3 ">
                                {/* <button type="button" className="btn btn-primary mx-2">Preview</button> */}
                                {/* <button type="button" onClick={paymentPrint} className="btn btn-primary mx-3">Print</button> */}
                                <button type="submit" className="btn btn-primary mx-3">Save</button>
                            </div>

                        </div>

                    </div>

                </form>

            </div>
        </>




    )
}

export default NewliftAdd
