import React, { useState } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap';
import { RoughLiftReportSchema } from '../Schema/RoughLiftReportIndex';


const initialValues = {
    startDate: "",
    endDate: "",
    customerName: "",


};

const RoughLiftReportMaster = () => {


    const navigate = useNavigate();

    const [customerDetails, setCustomerDetails] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [selectedDates, setSelectedDates] = useState({});
    const [statusByItemId, setStatusByItemId] = useState({});


    /////////////////////// Update onselect Date change ////////////////////////////////
    const handleDateChange = (li_quo_cu_id, newDate) => {
        setSelectedDates((prevSelectedDates) => ({
            ...prevSelectedDates,
            [li_quo_cu_id]: newDate,
        }));

        // Send the date value to the backend here
        updateDateInDatabase(li_quo_cu_id, newDate);
    };


    const updateDateInDatabase = async (li_quo_cu_id, newDate) => {
        try {
            const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/updateLiftdate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    li_quo_cu_id: li_quo_cu_id, // Use the ID of the record you want to update
                    newDate: newDate, // Use the selected date
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Handle the response from the server (e.g., show a success message)
            console.log(data.message);
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error(error);
        }
    };






    ////////////////////// To select an Status //////////////////////////

    async function updateStatusInAPI(li_quo_cu_id, newStatus) {
        try {
            const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/updateLiftStatus", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    li_quo_cu_id: li_quo_cu_id,
                    newStatus: newStatus,
                }),
            });

            if (!response.ok) {
                // Handle non-successful responses (e.g., server error)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Handle the response from the server (e.g., show a success message)
            console.log(data.message);

            // Return the updated status or response data if needed
            return data.status; // You may replace 'status' with the actual property name from your API response
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error(error);

            // Return null or an error indicator if needed
            return null;
        }
    }


    //////////////////////////////////Select an Status/////////////////////////////////////////////////

    // Usage within your component
    const handleStatusChange = async (li_quo_cu_id, newStatus) => {
        // Update the status in the state immediately for a smooth user experience

        console.log("Item ID:", li_quo_cu_id);
        console.log("New Status:", newStatus);

        setStatusByItemId((prevStatus) => ({
            ...prevStatus,
            [li_quo_cu_id]: newStatus,
        }));

        // Make the API call to update the status in the backend
        const updatedStatus = await updateStatusInAPI(li_quo_cu_id, newStatus);

        if (updatedStatus !== null) {
            setCustomerDetails((prevCustomerDetails) =>
                prevCustomerDetails.map((item) =>
                    item.li_quo_cu_id === li_quo_cu_id ? { ...item, li_quo_work_status: updatedStatus } : item
                )
            );
            // fetchDataFromAPI();

        } else {
            // Handle errors, e.g., revert the state to the previous value
            setStatusByItemId((prevStatus) => ({
                ...prevStatus,
                [li_quo_cu_id]: prevStatus[li_quo_cu_id], // Revert to the previous status
            }));

        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////



    ///////////////////////////////////////////////////////////////////////////////////////  

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: RoughLiftReportSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                action.resetForm();
                // navigate("/app/breakdown")

            },
        });


    /////////////////////////////////////////////////////////////

    const openPrint = () => {

        navigate("/app/rough_lift_report_print", {

            state: { customerDetails, searchValue },
        });
    }


/////////////////////////////// Api Call////////////////////////////////


    const handleSearchClick = async (event) => {
        event.preventDefault();

        const startDate = values.startDate;
        const endDate = values.endDate;

        try {
            let response;

            // response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/rough-lift-report?startDate=${startDate}&endDate=${endDate}`);

            if (startDate && endDate) {
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/rough-lift-report?startDate=${startDate}&endDate=${endDate}`);
              } else {
                // If startDate and endDate are not present, make the API request without them
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/rough-lift-report`);
              }


            if (response.ok) {
                const data = await response.json();
                console.log("Enquiry report details:", data);



                const filteredData = data.filter((item) => {
                    const workStatus = item.li_quo_work_status ? item.li_quo_work_status.toLowerCase() : '';
                    const customerName = item.li_quo_cu_name ? item.li_quo_cu_name.toLowerCase() : '';
                    const followUpDate = item.li_call_back_date ? item.li_call_back_date : '';
                    return (
                        workStatus.includes(searchValue.toLowerCase()) ||
                        customerName.includes(searchValue.toLowerCase()) || 
                        followUpDate.includes(searchValue)
                    );
                });

                if (filteredData.length === 0) {
                    // No data found, display a message to the user
                    console.log("No data found.");
                    window.alert("No data found.");
                    // You can update the state or display a message to the user here.
                } else {
                    setCustomerDetails(filteredData);

                    const statusData = filteredData.reduce((acc, item) => {
                        acc[item.li_quo_cu_id] = item.li_quo_work_status;
                        return acc;
                    }, {});
                    setStatusByItemId(statusData);

                    const statusDate = filteredData.reduce((acc, item) => {
                        acc[item.li_quo_cu_id] = item.li_call_back_date;
                        return acc;
                    }, {});
                    setSelectedDates(statusDate);
                }
            } else {
                console.error('Error Enquiry report details:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching Enquiry report details:', error);
        }

        // fetchDataFromAPI();
    };


    return (
        <>
            <div className='container'>

                <form onSubmit={handleSubmit}>


                    <div className="row ">
                        <div className='col-md-10 mt-4 mt-md-0'>
                            <h5>Rough Lift Report</h5>
                        </div>


                        <div className='col-md-2'>

                            <input
                                type="text"
                                className="form-control"
                                style={{ borderColor: 'black' }}
                                placeholder="Search by Customer ID or Name"
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />

                        </div>

                    </div>

                    <hr />


                    <div className="d-md-flex justify-content-between">

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="startDate" className='' style={{ fontSize: '12px' }}>Start Date</label>
                                <input type="date" className="form-control a1" id="startDate" name='startDate'
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.startDate && errors.startDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="endDate" className='' style={{ fontSize: '12px' }}>End Date</label>
                                <input type="date" className="form-control a1" id="endDate" name='endDate'
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.endDate && errors.endDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='submit' className='btn btn-primary' onClick={(event) => { handleSearchClick(event); setSearchValue(''); }}>Search</button>
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='button' onClick={openPrint} className='btn btn-primary '>Print</button>
                            </div>
                        </div>

                    </div>

                    <Table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">Date</th>
                                <th scope="col">Cust Name</th>
                                <th scope="col">Job Id</th>
                                <th scope="col">Quo No</th>
                                <th scope="col">Mobile No</th>
                                <th scope="col">Quo Amt</th>
                                <th scope="col">Status</th>
                                <th scope="col">Callback date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerDetails
                                .filter(item => {
                                    const workStatus = item.li_quo_work_status ? item.li_quo_work_status.toLowerCase() : '';
                                    const customerName = item.li_quo_cu_name ? item.li_quo_cu_name.toLowerCase() : '';
                                    const followUpDate = item.li_call_back_date ? item.li_call_back_date : '';
                                    return (
                                        workStatus.includes(searchValue.toLowerCase()) ||
                                        customerName.includes(searchValue.toLowerCase()) ||
                                        followUpDate.includes(searchValue)
                                    );
                                })
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.li_quo_date}</td>
                                        <td>{item.li_quo_cu_name}</td>
                                        <td>{item.li_cu_unique_id}</td>
                                        <td>{item.li_quo_no}</td>
                                        <td>{item.li_quo_cu_mobNo}</td>
                                        <td>{item.li_quo_amount}</td>
                                        {/* <td>{item.li_quo_work_status}</td> */}
                                        <td><select className="form-select border border-dark form-control a1"
                                            style={{ boxShadow: 'none', width: "170px" }}
                                            aria-label="Default select example"
                                            name='liftStatus'
                                            id='liftStatus'
                                            value={statusByItemId[item.li_quo_cu_id] || ''} // Set the value of the select element to the state variable
                                            onChange={(e) => handleStatusChange(item.li_quo_cu_id, e.target.value)} // Update the state when the user changes the value
                                        >
                                            <option value="Site Visit" style={{ background: '#dddddd', color: "blue" }}>Site Visit</option>
                                            <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                                            <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                                            <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                                            <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                                            <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                                            <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                                        </select></td>
                                        {/* <td>{item.li_call_back_date}</td> */}
                                        <td><input
                                            type="date"
                                            className="form-control a1"
                                            id={`quoDate-${item.li_quo_cu_id}`}
                                            name="quoDate"
                                            value={selectedDates[item.li_quo_cu_id] || ""}
                                            onChange={(e) => handleDateChange(item.li_quo_cu_id, e.target.value)}
                                        /></td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </form>

            </div>

        </>
    )
}

export default RoughLiftReportMaster