import * as Yup from "yup";


export const amcModulationSchema = Yup.object({

    // customerName: Yup.string().min(2).max(25).required("Please enter name"),
    // gstNo: Yup.string().required("Please enter  GST no."),
    // panNo: Yup.string().required("Please enter  PAN no."),
    // inputEmail: Yup.string().email().required("Please enter email address"),
    // mobileNo: Yup.number().integer().positive().required("Please enter mobile number"),
    // quotationNo:Yup.string().required("Please enter quotation no."), 
    // preAddress: Yup.string().required("Please enter address "),
    amcWorkStatus: Yup.string().required("Please select status type"),
    // liftType: Yup.string().required("Please select lift type"),
    // stops:Yup.string().required("Please enter stops "),
    doorType:Yup.string().required("Please select door type"),
    // make:Yup.string().required("Please enter make "),
    quantity:Yup.string().required("Please enter quantity "),
    quotationAmt: Yup.number().integer().positive().required("Please enter quotation amount"),
    quoDate: Yup.string().required("Please select date"),
    amcStartDate: Yup.string().required("Please select amc start date"),
    amcEndDate: Yup.string().required("Please select amc end date"),
    uniqueId:Yup.string().required("Please enter site id "),


  });
  