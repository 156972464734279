
import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
// import UpdateIcon from '@mui/icons-material/Update';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function QuotationMaster() {

  const navigate = useNavigate();
  function openAdd() {

    navigate("/app/quotation_add")
  }

  function openLift() {

    navigate("/app/lift_data")
  }

  function openAmc() {

    navigate("/app/amc_data")

  }

  function openModulation(){

    navigate("/app/modulation_data")
  }

  function deleteOperation() {
    alert("Item has been deleted");
  }


  /////// api call to get data //////////
  

  return (

    <>
      <div className=" ">
        <h5>Quotation Details</h5>
        <hr />
        < button onClick={openAdd} className="btn btn-primary buttonMargin col-2 d-grip w-auto">+Add Quotation</button>
      </div>

      <div className='conatiner'>


      <div className='row d-flex justify-content-around'>
        <button onClick={openLift} className='btn btn-primary col-md-3 mt-4'>Lift Data</button>
        <button onClick={openAmc} className='btn btn-primary col-md-3 mt-4'>AMC Data</button>
        <button onClick={openModulation} className='btn btn-primary col-md-3 mt-4'>Modulation Data</button>
      </div>

        
      </div>
    </>
  )
}

export default QuotationMaster