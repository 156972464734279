import React, { useState } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap';
import { breakdownReportSchema } from '../Schema/BreakdownReportIndex';

const initialValues = {
    startDate: "",
    endDate: "",
    customerName: "",


};

const BreakdownReportMaster = () => {

    const navigate = useNavigate();

    const [customerDetails, setCustomerDetails] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: breakdownReportSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                action.resetForm();
                navigate("/app/breakdown")

            },
        });

    ///////////////////////////////////////////////////////////////////////////

    const openPrint = () => {

        navigate("/app/breakdown_report_print", {

            state: { customerDetails, searchValue },
        });
    }


    /////////////////////////////// Api Call////////////////////////////////


    const handleSearchClick = async (event) => {
        event.preventDefault();

        const startDate = values.startDate;
        const endDate = values.endDate;

        try {
            let response;

            // response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_breakdown_report?startDate=${startDate}&endDate=${endDate}`);

            if (startDate && endDate) {
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_breakdown_report?startDate=${startDate}&endDate=${endDate}`);
            } else {
                // If startDate and endDate are not present, make the API request without them
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_breakdown_report`);
            }


            if (response.ok) {
                const data = await response.json();
                console.log("Lift report details:", data);

                // Apply search filtering to the data
                const filteredData = data
                    .filter(item =>
                        item.break_cu_unique_id.includes(searchValue) ||
                        item.break_cust_name.toLowerCase().includes(searchValue.toLowerCase())
                    );

                if (filteredData.length === 0) {
                    // No data found, display a message to the user
                    console.log("No data found.");
                    window.alert("Please select Start date and End date.");
                    // You can update the state or display a message to the user here.
                } else {
                    setCustomerDetails(filteredData);
                }


            } else {
                console.error('Error Lift report details:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching Lift report details:', error);
        }
    };



    return (
        <>


            <div className='container'>

                <form onSubmit={handleSubmit}>

                    <div className="row ">
                        <div className='col-md-10 mt-4 mt-md-0'>
                            <h5>Breakdown Report</h5>
                        </div>

                        <div className='col-md-2'>


                            <input
                                type="text"
                                className="form-control"
                                style={{ borderColor: 'black' }}
                                placeholder="Search by Customer ID or Name"
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />

                        </div>

                    </div>

                    <hr />



                    <div className="d-md-flex justify-content-between">

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="startDate" className='' style={{ fontSize: '12px' }}>Start Date</label>
                                <input type="date" className="form-control a1" id="startDate" name='startDate'
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.startDate && errors.startDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="endDate" className='' style={{ fontSize: '12px' }}>End Date</label>
                                <input type="date" className="form-control a1" id="endDate" name='endDate'
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />

                                {touched.endDate && errors.endDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='submit' className='btn btn-primary' onClick={(event) => { handleSearchClick(event); setSearchValue(''); }}>Search</button>
                            </div>
                        </div>

                        <div className="">
                            <div className="form-group  mt-4">
                                <button className='btn btn-primary ' onClick={openPrint}>Print</button>
                            </div>
                        </div>

                    </div>


                    <Table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">Job Id</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Site Address</th>
                                <th scope="col">Landmark</th>
                                <th scope="col">Technitian Name</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerDetails
                                .filter(item =>
                                    item.break_cu_unique_id.includes(searchValue) ||
                                    item.break_cust_name.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.break_cu_unique_id}</td>
                                        <td>{item.break_cust_name}</td>
                                        <td>{item.break_date}</td>
                                        <td>{item.break_site_adress}</td>
                                        {/* <td>{item.break_lift_type}</td> */}
                                        <td>{item.break_landmark}</td>
                                        <td>{item.break_tech_name}</td>
                                        <td>{item.break_status}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>

                </form>

            </div>

        </>
    )
}

export default BreakdownReportMaster