import * as Yup from "yup";


export const updateLiftQuotationAddSchema = Yup.object({
 
    customerName:  Yup.string().required("Please Select Customer Name"),
    gstNo: Yup.string().required("Please enter your GST No."),
    panNo: Yup.string().required("Please enter your PAN No."),
    inputEmail: Yup.string().email().required("Please enter your email"),
    mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
    quotationNo:Yup.string().required("Please enter your AMC Quotation No."),
    quoDate: Yup.string().required("Please select date "),
    subject: Yup.string().required("Please enter your Subject "),
    requireTo: Yup.string().required("Please enter Require To "),
    toBeInstalledAt: Yup.string().required("Please enter installed at "),
    liftType: Yup.string().required("Please Select lift type"),
    capacity: Yup.string().required("Please enter Capacity"),
    powerPackLocationNo:Yup.string().required("Please enter Your Power Pack Location No"),
    motorMachine:Yup.string().required("Please enter Your Motor Machine"),
    ropes:Yup.string().required("Please enter Your Ropes"),
    speed:Yup.string().required("Please enter Your Speed"),
    controller:Yup.string().required("Please enter Your controller"),
    drive:Yup.string().required("Please enter Drive"),
    signals:Yup.string().required("Please enter Signals"),
    emergencySignals:Yup.string().required("Please enter Emergency Signals"),
    liftTravel:Yup.string().required("Please enter your Lift Travel"),
    landingAndOpening:Yup.string().required("Please enter your landing And Opening"),
    stops:Yup.string().required("Please enter your stops "),
    carEncloser:Yup.string().required("Please enter your Car Encloser"),
    flooring:Yup.string().required("Please enter your Flooring"),
    doorType:Yup.string().required("Please Select Door type"),
    landingDoor:Yup.string().required("Please enter your Landing Door"),
    powerSupply:Yup.string().required("Please enter Power Supply"),
    quotationAmt: Yup.number().integer().positive().required("Please enter your Quotation Amount"),
    quoDate: Yup.string().required("Please Select Date"),    

  });