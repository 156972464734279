import React, { useRef } from 'react'
import {Table} from "react-bootstrap"
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";



const LiftReportPrint = () => {

    const componentPDF = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const { customerDetails , searchValue } = location.state;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    ///////////////////////////////////////////////////////////////

    function backtodata() {
        navigate("/app/lift_report");
    }


  return (
    <>
    

    <div className='container d-flex justify-content-center align-items-center text-dark' ref={componentPDF} style={{ width: "100%" }}>
                <div className='row  '>

                      
                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com</p>
                
              </div>

                    <div className='text-center mt-2'><h5><b>Lift Report:</b></h5></div>

                    <div className='d-flex justify-content-center '>

                        <Table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Job Id</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Lift No</th>
                                    <th scope="col">Quotation Amt</th>
                                    <th scope="col">Previous Bal</th>
                                    <th scope="col">Paid Amt</th>
                                    <th scope="col">Total Bal</th>
                                    <th scope="col">Status</th>

                                </tr>
                            </thead>
                            <tbody>
                            {customerDetails
                                .filter(item =>
                                    item.li_fl_cu_unique_id.includes(searchValue) ||
                                   
                                    item.li_customer_name.toLowerCase().includes(searchValue.toLowerCase()) || 
                                    item.li_working_status.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.li_date}</td>
                                        <td>{item.li_customer_name}</td>
                                        <td>{item.li_fl_cu_unique_id}</td>
                                        <td>{item.li_quotation_no}</td>
                                        <td>{item.li_amount}</td>
                                        <td>{item.li_pre_bal}</td>
                                        <td>{item.li_paid_amt}</td>
                                        <td>{item.li_total_bal}</td>
                                        <td>{item.li_working_status}</td>

                                    </tr>
                                ))}


                            </tbody>
                        </Table>

                    </div>


                </div>
            </div>

            <div className='mt-4 float-end'>

            <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata}>Cancel</button>
            <button className='btn btn-primary' onClick={generatePDF} style={{width:"100px"}}>Ok</button><br /><br /><br /><br />

            </div>

    
    
    </>
  )
}

export default LiftReportPrint


