import React, { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useReactToPrint } from 'react-to-print';
import { updateNewAmcAddSchema } from '../Schema/UpdateNewAmcAddIndex';



const initialValues = {
  amcQuotationNo: "",
  customerName: "",
  gstNo: "",
  panNo: "",
  inputEmail: "",
  mobileNo: "",
  preAddress: "",
  liftType: "",
  stops: "",
  doorType: "",
  make: "",
  quantity: "",
  quotationAmt: "",
  previousBal: "",
  paidAmt: "",
  totalBalance: "",
  invoiceDate: "",
  amcStartDate: "",
  amcEndDate: "",
  followUpDate: "",
};



const UpdateNewAmcAdd = () => {


  const componentPDF = useRef();
  const navigate = useNavigate();

  const { id } = useParams();

  const [data, setData] = useState([])
  const [amcquotationNo, setAmcquotationNo] = useState(""); //search bu quotation no
  const [amcQuotationData, setAmcQuotationData] = useState(null);

  const [uniqueId, setUniqueId] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const [preAddress, setPreAddress] = useState("");
  const [quotationNo, setQuotationNo] = useState(''); //new final amc quo no
  const [liftType, setLiftType] = useState("");
  const [stops, setStops] = useState("");
  const [doorType, setDoorType] = useState("");
  const [make, setMake] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quotationAmt, setQuotationAmt] = useState("");
  const [amcStartDate, setAmcStartDate] = useState("");
  const [amcEndDate, setAmcEndDate] = useState("");
  const [previousBal, setPreviousBal] = useState(0);
  const [paidAmt, setPaidAmt] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [amcStatus, setAmcStatus] = useState("");

  const [customerDetails, setCustomerDetails] = useState({
    gstNo: '',
    panNo: '',
    inputEmail: '',
    mobileNo: '',
    preAddress: ''
  });


  function saveData() {
    navigate("/app/new_Amc")
  }



  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      // validationSchema: updateNewAmcAddSchema,
      onSubmit: (values, action) => {
        console.log("values:", values);
        // action.resetForm();
        navigate("/app/new_Amc")
      },
    });
  console.log(errors);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Userdata",

  });


  const handleUniqueId = (event) => {

    setUniqueId(event.target.value);
    handleChange(event);
  }

  const handleAmcQuotationNo = (event) => {  //search bu quotation no

    setAmcquotationNo(event.target.value);
    handleChange(event);
  }

  const handleQuotationNo = (event) => {   //new final amc quo no
    setQuotationNo(event.target.value);
    handleChange(event);
  }

  const handleInvoiceDate = (event) => {

    setInvoiceDate(event.target.value);
    handleChange(event);
  }

  const handleStartDate = (event) => {

    setAmcStartDate(event.target.value);
    handleChange(event);
  }

  const handleEndDate = (event) => {

    setAmcEndDate(event.target.value);
    handleChange(event);
  }

  const handleCustomerName = (event) => {

    setCustomerName(event.target.value);
    handleChange(event);
  }


  const handleGstNo = (event) => {
    // console.log("On Change");

    setGstNo(event.target.value);
    handleChange(event);
  }
  const handlePanNo = (event) => {
    // console.log("On Change");

    setPanNo(event.target.value);
    handleChange(event);
  }
  const handleInputEmail = (event) => {
    // console.log("On Change");

    setInputEmail(event.target.value);
    handleChange(event);
  }

  const handleMobileNo = (event) => {
    // console.log("On Change");

    setMobileNo(event.target.value);
    handleChange(event);
  }



  const handlePreAddress = (event) => {
    // console.log("On Change");

    setPreAddress(event.target.value);
    handleChange(event);
  }



  const handleLiftType = (event) => {
    // console.log("On Change");

    setLiftType(event.target.value);
    handleChange(event);
  }

  const handleStops = (event) => {
    // console.log("On Change");

    setStops(event.target.value);
    handleChange(event);
  }

  const handleDoorType = (event) => {
    // console.log("On Change");

    setDoorType(event.target.value);
    handleChange(event);
  }


  const handleMake = (event) => {
    // console.log("On Change");

    setMake(event.target.value);
    handleChange(event);
  }

  const handleQuantity = (event) => {
    // console.log("On Change");

    setQuantity(event.target.value);
    handleChange(event);
  }

  const handleAmcStatus = (event) => {
    // console.log("On Change");

    setAmcStatus(event.target.value);
    handleChange(event);
  }

  const handleQuotationAmt = (event) => {
    // console.log("On Change");

    setQuotationAmt(event.target.value);
    handleChange(event);
  }


  const handlePreviousBal = (event) => {
    // console.log("On Change");

    setPreviousBal(event.target.value);
    handleChange(event);
  }


  const handlePaidAmt = (event) => {
    // console.log("On Change");

    setPaidAmt(event.target.value);
    handleChange(event);
    const newTotalBalance = parseInt(quotationAmt || 0) + parseInt(previousBal || 0) - parseInt(event.target.value || 0);
    setTotalBalance(newTotalBalance);
  }


  const handleTotalBalance = (event) => {
    // console.log("On Change");

    setTotalBalance(event.target.value);
    // handleChange(event);
  }



  ///////////////////////////////////////////// Api  Call /////////////////////////////////////

  useEffect(() => {

    async function fetchresult() {
      try {
        const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_newamc_update/${id}`);
        const result = await response.json();
        console.log("result:", result);
        setData(result);
        setAmcquotationNo(result.amc_quo_no)
        setQuotationNo(result.amc_quo_final_quo_no)
        setUniqueId(result.amc_fl_cu_unique_id)
        setInvoiceDate(result.amc_quo_date)
        setCustomerName(result.amc_quo_name)
        setGstNo(result.amc_quo_gst_no)
        setPanNo(result.amc_quo_pan_no)
        setInputEmail(result.amc_quo_email_id)
        setMobileNo(result.amc_quo_mob_no)
        setPreAddress(result.amc_quo_address)
        setLiftType(result.amc_quo_lift_type)
        setStops(result.amc_quo_stops)
        setDoorType(result.amc_quo_door_type)
        setMake(result.amc_quo_make)
        setQuantity(result.amc_quo_quantity)
        setQuotationAmt(result.amc_quo_amt)
        setAmcStartDate(result.amc_quo_start_date)
        setAmcEndDate(result.amc_quo_end_date)
        setPreviousBal(result.amc_quo_pre_bal)
        setPaidAmt(result.amc_quo_paid_amt)
        setTotalBalance(result.amc_quo_total_bal)
        setAmcStatus(result.amc_working_status)


      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchresult();

  }, [id]);


  ///////////////////// api to store updated data ///////////////////////////////////////////

  async function storeUpdatedAmcData(id) {
    const formData = new FormData();
    formData.append("uniqueId", uniqueId)
    formData.append("amcquotationNo", amcquotationNo)
    formData.append("invoiceDate", invoiceDate)
    formData.append("customerName", customerName)
    formData.append("panNo", panNo)
    formData.append("gstNo", gstNo)
    formData.append("inputEmail", inputEmail)
    formData.append("mobileNo", mobileNo)
    formData.append("quotationNo", quotationNo)
    formData.append("preAddress", preAddress)
    formData.append("liftType", liftType)
    formData.append("stops", stops)
    formData.append("doorType", doorType)
    formData.append("make", make)
    formData.append("quantity", quantity)
    formData.append("quotationAmt", quotationAmt)
    formData.append("amcStartDate", amcStartDate)
    formData.append("amcEndDate", amcEndDate)
    formData.append("amcStatus", amcStatus)
    formData.append("quotationAmt", quotationAmt)
    formData.append("previousBal", previousBal)
    formData.append("paidAmt", paidAmt)
    formData.append("totalBalance", totalBalance)

    try {
      let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/amc_update_store/' + id + '?method=PUT', {
        method: 'POST',
        body: formData
      });

      let data1 = await response.json(); // Parse the response body as JSON

      if (data1.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        alert(data1.message);


      } else {
        alert("An error occurred while saving the data.");
      }


    } catch (error) {
      console.error('Error while adding customer:', error);
      alert("An error occurred while saving the data2.");

    }
  }




  return (
    <>

      <div className='container'>
        <div ref={componentPDF} style={{ width: "100%" }}>
          <form onSubmit={handleSubmit}>
            <div className='row mb-1 mt-5'>

              <div className="col-md-6 ">
                <div className="form-group">
                  <h4 className='mt-4'>Add New AMC Details</h4>
                </div>
              </div>


              <div className="col-md-3 ">
                <div className="form-group">
                  <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                  <input type="tex" className="form-control a1" id="uniqueId" name="uniqueId"
                    value={uniqueId}
                    onChange={handleUniqueId}
                    onBlur={handleBlur} disabled="true"
                  />
                </div>
              </div>

              <div className="col-md-3 ">
                <div className="form-group">
                  <label htmlFor="invoiceDate" className='name_label date_label select_date' style={{ fontSize: '14px' }}>Select Date</label>
                  <input type="date" className="form-control a1 select_date_input" name="invoiceDate" id="invoiceDate"
                    value={invoiceDate}
                    onChange={handleInvoiceDate}
                    onBlur={handleBlur} 
                  />
                </div>
              </div>


            </div>

            <hr />
            <div className="row mb-1">

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="amcquotationNo" className='name_label AMC_Quotation' style={{ fontSize: '14px' }} >AMC Quotation number</label>
                  <input type="text" name='amcquotationNo' className="form-control AMC_Quotation_input" id="amcquotationNo" style={{ border: '2px solid Gray' }}
                    value={amcquotationNo}
                    onChange={handleAmcQuotationNo}
                    onBlur={handleBlur} disabled="true" />

                </div>
              </div>
            </div>

            <div className="row mb-1 ">

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                  <input type="text" name="customerName" className="form-control a1" id="customerName"
                    value={customerName}
                    onChange={handleCustomerName}
                    onBlur={handleBlur} disabled="true" />

                </div>
              </div>

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="gstNo" className='name_label gst_no' style={{ fontSize: '14px' }} >GST No.</label>
                  <input type="text" name='gstNo' className="form-control a1 gst_input" id="gstNo"
                    value={gstNo}
                    onChange={handleGstNo}
                    onBlur={handleBlur} disabled="true" />
                  {/* {touched.gstNo && errors.gstNo ? (
                    <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.gstNo}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="panNo" className='name_label pan_no' style={{ fontSize: '14px' }} >PAN No.</label>
                  <input type="text" name='panNo' className="form-control a1 pan_input" id="panNo"
                    value={panNo}
                    onChange={handlePanNo}
                    onBlur={handleBlur} disabled="true" />
                  {/* {touched.panNo && errors.panNo ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.panNo}</p>
                  ) : null} */}
                </div>
              </div>

            </div>


            <div className="row mb-1">
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="inputEmail" className='name_label E-mail' style={{ fontSize: '14px' }} >E-mail:*</label>
                  <input type="email" name='inputEmail' className="form-control a1 inputEmail_input" id="inputEmail"
                    value={inputEmail}
                    onChange={handleInputEmail}
                    onBlur={handleBlur} disabled="true" />
                  {/* {touched.inputEmail && errors.inputEmail ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.inputEmail}</p>
                  ) : null} */}

                </div>
              </div>
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="mobileNo" className='name_label mobile_no' style={{ fontSize: '14px' }} >Mobile No:*</label>
                  <input type="text" name='mobileNo' maxLength={10} className="form-control a1 mobileNo_input" id="mobileNo"
                    value={mobileNo}
                    onChange={handleMobileNo}
                    onBlur={handleBlur} disabled="true" />
                  {/* {touched.mobileNo && errors.mobileNo ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.mobileNo}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="quotationNo" className='name_label amc_no' style={{ fontSize: '14px' }}>AMC No.:*</label>
                  <input type="text" name='quotationNo' className="form-control a1 amcNo_input" id="quotationNo"
                    value={quotationNo}
                    onChange={handleQuotationNo}
                    onBlur={handleBlur} disabled="true" />
                  {/* {touched.amcNo && errors.amcNo ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.amcNo}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="row mb-5">
                <div className="col-md-12 ">
                  <div className="form-group">
                    <label htmlFor="preAddress" className='name_label address' style={{ fontSize: '14x' }} >Address</label>
                    <textarea className="form-control a1 address_input" name='preAddress' id="preAddress" rows="5"
                      value={preAddress}
                      onChange={handlePreAddress}
                      onBlur={handleBlur} disabled="true"></textarea>
                    {/* {touched.preAddress && errors.preAddress ? (
                      <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.preAddress}</p>
                    ) : null} */}
                  </div>
                </div>

              </div>

            </div>

            <div className="row mb-1">


              {/* <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="liftType" className='name_label amc_no' style={{ fontSize: '14px' }}>Lift Type </label>
                  <input type="text" name='liftType' className="form-control a1 amcNo_input" id="liftType"
                    value={liftType}
                    onChange={handleLiftType}
                    onBlur={handleBlur} disabled="true" />
                  
                </div>
              </div> */}




              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="doorType" className='name_label doorType' style={{ fontSize: '14px' }} >Types of Elevator</label>
                  <input type="text" name='doorType' className="form-control a1 stops_input" id="doorType"
                    value={doorType}
                    onChange={handleDoorType}
                    onBlur={handleBlur} />
                </div>
              </div>

              {/* <div className='col-md-4'>

                <label htmlFor="doorType " className='name_label door_type' style={{ fontSize: '14px' }} >Types of Elevator</label>

                <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                  name='doorType'
                  id='doorType'
                  value={doorType}
                  onChange={handleDoorType}
                  onBlur={handleBlur} disabled="true" >

                  <option value="Door Type 1" style={{ background: '#eaeaea' }}>Door Type 1</option>
                  <option value="Door Type 2" style={{ background: '#eaeaea' }}>Door Type 2</option>
                  <option value="Door Type 3" style={{ background: '#eaeaea' }}>Door Type 3</option>
                  <option value="Door Type 4" style={{ background: '#eaeaea' }}>Door Type 4</option>
                  <option value="Door Type 5" style={{ background: '#eaeaea' }}>Door Type 5</option>
                  <option value="Door Type 6" style={{ background: '#eaeaea' }}>Door Type 6</option>

                </select>


              </div> */}

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="quantity" className='name_label quantity' style={{ fontSize: '14px' }} >Quantity</label>
                  <input type="text" name='quantity' className="form-control a1" id="quantity"
                    value={quantity}
                    onChange={handleQuantity}
                    onBlur={handleBlur} />

                </div>
              </div>

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="quotationAmt" className='name_label quotation_amt' style={{ fontSize: '14px' }}  >Quotation Amt:*</label>
                  <input type="text" name='quotationAmt' className="form-control a1" id="quotationAmt"
                    value={quotationAmt}
                    onChange={handleQuotationAmt}
                    onBlur={handleBlur} />

                </div>
              </div>

            </div>







            <div className="row mb-1">
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="previousBal" className='name_label previous_balance ' style={{ fontSize: '14px' }} >Previous balance:*</label>
                  <input type="text" name='previousBal' className="form-control a1" id="previousBal"
                    value={previousBal}
                    onChange={handlePreviousBal}
                    onBlur={handleBlur} />
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="paidAmt" className='name_label paid_amt' style={{ fontSize: '14px' }} >Paid Amt:*</label>
                  <input type="text" name='paidAmt' className="form-control a1" id="paidAmt"
                    value={paidAmt}
                    onChange={handlePaidAmt}
                    onBlur={handleBlur} />
                  {/* {touched.paidAmt && errors.paidAmt ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.paidAmt}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="totalBalance" className='name_label total_balance' style={{ fontSize: '14px' }}>Total balance:*</label>
                  <input type="number1" name='totalBalance' className="form-control a1" id="totalBalance"
                    value={totalBalance}
                    onChange={handleTotalBalance}
                    onBlur={handleBlur} />
                  {/* {touched.totalBalance && errors.totalBalance ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.totalBalance}</p>
                  ) : null} */}
                </div>
              </div>

            </div>


            <div className="row mb-1">


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="amcStatus" className='name_label total_balance' style={{ fontSize: '14px' }}>Amc Status</label>
                  <input type="number1" name='amcStatus' className="form-control a1" id="amcStatus"
                    value={amcStatus}
                    onChange={handleAmcStatus}
                    onBlur={handleBlur} />
                  {/* {touched.totalBalance && errors.totalBalance ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.totalBalance}</p>
                  ) : null} */}
                </div>
              </div>



              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="amcStartDate" className='name_label amc_start_date' style={{ fontSize: '14px' }} >AMC Start Date:*</label>
                  <input type="date" name='amcStartDate' className="form-control a1" id="amcStartDate"
                    value={amcStartDate}
                    onChange={handleStartDate}
                    onBlur={handleBlur} />

                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="amcEndDate" className='name_label amc_end_date' style={{ fontSize: '14px' }}>AMC End Date</label>
                  <input type="date" name='amcEndDate' className="form-control a1" id="amcEndDate"
                    value={amcEndDate}
                    onChange={handleEndDate}
                    onBlur={handleBlur} />

                </div>
              </div>

            </div>

            {/* <div className='row mb-1'>

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="followUpDate" className='name_label stops' >Follow up Date</label>
                  <input type="date" name='followUpDate' className="form-control a1 stops_input" id="followUpDate"
                    value={values.followUpDate}
                    onChange={handleChange}
                    onBlur={handleBlur} />
                

                </div>
              </div>
            </div> */}





            <div className='d-flex justify-content-end'>

              <div className="btn-group my-3 ">
                {/* <button type="button" className="btn btn-primary mx-2">Preview</button> */}
                {/* <button type="button" onClick={generatePDF} className="btn btn-primary mx-3">Print</button> */}
                <button onClick={() => storeUpdatedAmcData(data.amc_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>
              </div>

            </div>

          </form>

        </div>

      </div>


    </>
  )
}

export default UpdateNewAmcAdd