import React, { useState, useEffect } from 'react'
import './Dashboard.css';

const Dashboard = () => {


  const [liftStatistics, setLiftStatistics] = useState({
    total_lift: 0,
    ongoing_lift: 0,
    pending_lift: 0,
    completed_lift: 0,
  })

  const [amcStatistics, setAmcStatistics] = useState({
    new_amc_count: 0,
    ongoing_amc: 0,
    expired_amc: 0,
    expirind_soon_amc: 0,
  })


  const [modStatistics, setModStatistics] = useState({
    total_mod: 0,
    ongoing_mod: 0,
    pending_mod: 0,
    completed_mod: 0,
  })

  const [leadStatistics, setLeadStatistics] = useState({

    total_leads: 0,
    followed_leads: 0,
    pending_leads: 0,

  })


  //////////////////////// Lift  Api call  /////////////////////////////////////

  useEffect(() => {
    async function fetchLiftStatistics() {
      try {
        const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/lift-statistics');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setLiftStatistics(data);
      } catch (error) {
        console.error('Error fetching AMC statistics:', error);
      }
    }

    fetchLiftStatistics();
  }, []);


  //////////////////////// Amc  Api call  /////////////////////////////////////

  useEffect(() => {
    async function fetchAmcStatistics() {
      try {
        const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/amc-statistics');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setAmcStatistics(data);
      } catch (error) {
        console.error('Error fetching AMC statistics:', error);
      }
    }

    fetchAmcStatistics();
  }, []);


  //////////////////////// Modulation Api call  /////////////////////////////////////

  useEffect(() => {
    async function fetchModStatistics() {
      try {
        const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/mod-statistics');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setModStatistics(data);
      } catch (error) {
        console.error('Error fetching AMC statistics:', error);
      }
    }

    fetchModStatistics();
  }, []);


  //////////////////////// Leads Api call  /////////////////////////////////////

  useEffect(() => {
    async function fetchModStatistics() {
      try {
        const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/leads-statistics');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setLeadStatistics(data);
      } catch (error) {
        console.error('Error fetching AMC statistics:', error);
      }
    }

    fetchModStatistics();
  }, []);




  return (
    <>


      <div className='container -fluid'>

        <div className=''>
          <h5>Dashboard</h5>
          <hr />



          <div className='card-body-heading ' style={{ marginLeft: "6rem" }} ><h4 style={{ fontWeight: "bold" }}>Lifts</h4></div>

          <div className='row text-white mb-5 justify-content-around  d-md-flex  ' >





            <div className="card  col-md-3 mb-2" style={{ width: "17rem", background: "#408EC6" }}>

              <div class="card-body">

                <div class="card-body-icon mb-2"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Total</h4>

                <div className='display-4'>{liftStatistics.total_lift}</div>

              </div>

            </div>



            <div className="card  col-md-3 mb-2" style={{ width: "17rem", background: "#408EC6" }}>

              <div class="card-body">

                <div class="card-body-icon mb-2"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>On-going</h4>

                <div className='display-4'>{liftStatistics.ongoing_lift}</div>

              </div>

            </div>



            <div className="card  col-md-3 mb-2" style={{ width: "17rem", background: "#408EC6" }}>

              <div class="card-body">

                <div class="card-body-icon mb-2"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Pending</h4>

                <div className='display-4'>{liftStatistics.pending_lift}</div>

              </div>

            </div>


            <div class="card  col-md-3. mb-2" style={{ width: "17rem", background: "#408EC6" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Completed</h4>

                <div className='display-4'>{liftStatistics.completed_lift}</div>

              </div>

            </div>

          </div>

          <div className='card-body-heading' style={{ marginLeft: "6rem" }}><h4 style={{ fontWeight: "bold" }}>Amc</h4></div>

          <div className='row text-white mb-5 justify-content-around d-md-flex'>

            <div className="card col-md-4 mb-2" style={{ width: "17rem", background: "#7A2048" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-wrench' ></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Total</h4>

                <div className='display-4'>{amcStatistics.new_amc_count}</div>

              </div>

            </div>


            <div className="card col-md-4 mb-2" style={{ width: "17rem", background: "#7A2048" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-wrench' ></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>On-going</h4>

                <div className='display-4'>{amcStatistics.ongoing_amc}</div>

              </div>

            </div>


            <div className="card col-md-4 mb-2" style={{ width: "17rem", background: "#7A2048" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-wrench' ></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Expiring Soon</h4>

                <div className='display-4'>{amcStatistics.expirind_soon_amc}</div>

              </div>

            </div>



            <div className="card col-md-4 mb-2" style={{ width: "17rem", background: "#7A2048" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-wrench' ></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Expired</h4>

                <div className='display-4'>{amcStatistics.expired_amc}</div>

              </div>

            </div>

          </div>


          <div className='card-body-heading ' style={{ marginLeft: "6rem" }}><h4 style={{ fontWeight: "bold" }}>Modulation</h4></div>

          <div className='row  text-white mb-5 justify-content-around d-md-flex '>

            <div className="card col-md-4 mb-2" style={{ width: "17rem", background: "#1E2761" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-cog' ></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Total</h4>

                <div className='display-4'>{modStatistics.total_mod}</div>

              </div>

            </div>

            <div class="card col-md-4 mb-2 h-100" style={{ width: "17rem", background: "#1E2761" }}>

              <div class="card-body h-100">

                <div class="card-body-icon h-100"><i class='bx bxs-cog' ></i></div>

                <h4 class="card-title text-white h-100" style={{ fontSize: "21px", fontWeight: "700" }}>On-going</h4>

                <div className='display-4 h-100'>{modStatistics.ongoing_mod}</div>

              </div>

            </div>

            <div class="card col-md-4 mb-2 h-100" style={{ width: "17rem", background: "#1E2761" }}>

              <div class="card-body h-100">

                <div class="card-body-icon h-100"><i class='bx bxs-cog' ></i></div>

                <h4 class="card-title text-white h-100" style={{ fontSize: "21px", fontWeight: "700" }}>Pending</h4>

                <div className='display-4 h-100'>{modStatistics.pending_mod}</div>

              </div>

            </div>




            <div class="card col-md-4 mb-2 h-100" style={{ width: "17rem", background: "#1E2761" }}>

              <div class="card-body h-100">

                <div class="card-body-icon h-100"><i class='bx bxs-cog' ></i></div>

                <h4 class="card-title text-white h-100" style={{ fontSize: "22px", fontWeight: "700" }}>Completed</h4>

                <div className='display-4 h-100'>{modStatistics.completed_mod}</div>

              </div>

            </div>

          </div>

          <div className='card-body-heading ' style={{ marginLeft: "6rem" }}><h4 style={{ fontWeight: "bold" }}>Leads</h4></div>

          <div className='row text-white mb-5 justify-content-around   d-md-flex  ' style={{}} >

            <div className="card col-md-4 mb-2" style={{ width: "17rem", background: "#408EC6" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Total</h4>

                <div className='display-4'>{leadStatistics.total_leads}</div>

              </div>

            </div>


            <div className="card  col-md-4 mb-2" style={{ width: "17rem", background: "#408EC6" }}>

              <div class="card-body">

                <div class="card-body-icon mb-2"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Followed</h4>

                <div className='display-4'>{leadStatistics.followed_leads}</div>

              </div>

            </div>


            <div class="card  col-md-4 mb-2" style={{ width: "17rem", background: "#408EC6" }}>

              <div class="card-body">

                <div class="card-body-icon"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Pending</h4>

                <div className='display-4'>{leadStatistics.pending_leads}</div>

              </div>

            </div>



            <div className=" col-md-4 mb-2" style={{ width: "17rem",  }}>

              <div class="card-body">

                {/* <div class="card-body-icon"><i class='bx bxs-user'></i></div>

                <h4 class="card-title text-white " style={{ fontWeight: "700" }}>Total</h4>

                <div className='display-4'>{leadStatistics.total_leads}</div> */}

              </div>

            </div>

          </div>




        </div>



      </div>
    </>
  )
}

export default Dashboard