import * as Yup from "yup";



export const updateNewModulationSchema = Yup.object({
    

    quotationNo:Yup.string().required("Please enter your  Quotation No."), 
    modulationNo:Yup.string().required("Please enter your  Modulation No."), 
    customerName: Yup.string().min(2).max(25).required("Please enter your name"),
    mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
    liftType: Yup.string().required("Please enter lift type"),
    panNo: Yup.string().required("Please enter your PAN No."),
    gstNo: Yup.string().required("Please enter your GST No."),
    stops:Yup.string().required("Please enter your stops "),
    make:Yup.string().required("Please enter your Make "),
    perticular:Yup.string().required("Please enter your Perticular "),
    quantity:Yup.number().integer().positive().required("Please enter your Quantity"),
    rate:Yup.number().integer().positive().required("Please enter your Quantity"),
    preBalance: Yup.string().required("Please enter previous balance"),
    paidAmt:Yup.number().integer().positive().required("Please enter Paid Amount"),
    totalBalance:Yup.number().integer().positive().required("Please enter Total Balance"),
    moduDate: Yup.string().required("Please select Date"),


  });