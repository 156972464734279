import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { newModulationSchema } from '../Schema/newModulationAddindex';


const initialValues = {
    quotationNo: "",

    customerName: "",
    mobileNo: "",
    liftType: "",
    panNo: "",
    gstNo: "",
    stops: "",
    make: "",
    perticular: "",
    quantity: "",
    rate: "",
    previousBal: "",
    paidAmt: "",
    totalBalance: "",
    moduDate: "",
    inputEmail: "",
    moduQuotationNo: "",
    tableData: [],
    workingStatus: "",
    // followUpDate: "",
    termsAndConditions: "",

};

// let grandTotal = 0;

const NewModulationAdd = () => {

    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [formData, setFormData] = useState(initialValues);

    const [moduQuotationNo, setModuQuotationNo] = useState('');
    const [quotationNo, setQuotationNo] = useState("");
    const [moduQuotationData, setModuQuotationData] = useState(null);

    const [uniqueId, setUniqueId] = useState("");
    const [moduDate, setModuDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [mobileNo, setMobileNo] = useState("");

    const [preAddress, setPreAddress] = useState("");
    const [liftType, setLiftType] = useState("");
    const [stops, setStops] = useState("");
    const [previousBal, setPreviousBal] = useState(0);
    const [paidAmt, setPaidAmt] = useState("");
    const [totalBalance, setTotalBalance] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [workingStatus, setWorkingStatus] = useState(0);


    const [perticular, setPerticular] = useState("");
    const [make, setMake] = useState("");
    const [quantity, setQuantity] = useState("");
    const [rate, setRate] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState("");

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: newModulationSchema,
            onSubmit: async (values, action) => {
                // console.log("values:", values);
                // action.resetForm();
                await handleFormSubmit();
                modulationPrint();
                // grandTotal = 0;
                // setGrandTotal(grandTotal);
                // navigate("/app/new_modulation")
            },
        });
    console.log(errors);


    function saveData() {
        navigate("/app/new_modulation")
    }

    function modulationPrint() {

        const confirmPrint = window.confirm('Do you want to proceed with printing?');

        if (confirmPrint) {


        navigate("/app/new_modulation_print", {

            state: {
                uniqueId: uniqueId,
                grandTotal: grandTotal,
                moduDate: moduDate,
                customerName:customerName,
                preAddress: preAddress,
                tableData: tableData,
                quotationNo: quotationNo,
                termsAndConditions:termsAndConditions,
            },
        });
    }
    }

    ///////////////////////////////////////////////////////

    const handleUniqueId = (event) => {

        setUniqueId(event.target.value);
        handleChange(event);
    }


    const handleModuQuotationNo = (event) => {

        setModuQuotationNo(event.target.value);
        handleChange(event);
    }

    const handleCustomerName = (event) => {

        setCustomerName(event.target.value);
        handleChange(event);
    }

    const handleWorkingStatus = (event) => {

        setWorkingStatus(event.target.value);
        handleChange(event);
    }


    const handleGstNo = (event) => {
        // console.log("On Change");

        setGstNo(event.target.value);
        handleChange(event);
    }
    const handlePanNo = (event) => {
        // console.log("On Change");

        setPanNo(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }

    const handleQuotationNo = (event) => {
        // console.log("On Change");

        setQuotationNo(event.target.value);
        handleChange(event);
    }

    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }

    const handleStops = (event) => {
        // console.log("On Change");

        setStops(event.target.value);
        handleChange(event);
    }

    const handlePerticular = (event) => {
        // console.log("On Change");

        setPerticular(event.target.value);
        handleChange(event);
    }

    const handleMake = (event) => {
        // console.log("On Change");

        setMake(event.target.value);
        handleChange(event);
    }


    const handleQuantity = (event) => {
        // console.log("On Change");

        setQuantity(event.target.value);
        handleChange(event);
    }

    const handleRate = (event) => {
        // console.log("On Change");

        setRate(event.target.value);
        handleChange(event);
    }

    const handleDate = (event) => {
        // console.log("On Change");

        setModuDate(event.target.value);
        handleChange(event);
    }

    const handlePreAddress = (event) => {
        // console.log("On Change");

        setPreAddress(event.target.value);
        handleChange(event);
    }

    const handlePreviousBal = (event) => {

        setPreviousBal(event.target.value);
        handleChange(event);
    }

    const handlePaidAmt = (event) => {
        // console.log("On Change");

        setPaidAmt(event.target.value);
        handleChange(event);
        // const newTotalBalance = parseInt(quotationAmt || 0) + parseInt(previousBal || 0) - parseInt(event.target.value || 0);
        const newTotalBalance = parseInt(grandTotal || 0) + parseInt(previousBal || 0) - parseInt(event.target.value || 0);
        setTotalBalance(newTotalBalance);
        // const newGrandTotal = parseInt(grandTotal || 0) ;

        //  setGrandTotal(newGrandTotal);

        const newGrandTotal = Math.abs(parseInt(grandTotal || 0));
        setGrandTotal(newGrandTotal);
    }

    const handleTotalBalance = (event) => {
        // console.log("On Change");

        setTotalBalance(event.target.value);
        // handleChange(event);
    }

    const handleTermsAndConditions = (event) => {
        // console.log("On Change");

        setTermsAndConditions(event.target.value);
        // handleChange(event);
    }

    //// handle onclcik function//////////////////////////

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            console.log('Enter key pressed');
            console.log('Previous Balance:', previousBal);
            console.log('Paid Amount:', paidAmt);

            const newTotalBalance = previousBal - paidAmt;
            console.log('New Total Balance:', newTotalBalance);

            setTotalBalance(newTotalBalance);
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////

    //////////////edit checkbox function checkbox/////////////

    const handleCheckboxChange = (event, index) => {
        // Update the checkbox state for the corresponding row
        const newData = [...tableData];
        newData[index].isChecked = event.target.checked;
        setTableData(newData);
    };


    ///////////////////handle deleet function checkbox//////////////////

    //    const handleDelete = (index) => {
    //     // Implement the logic for deleting a row
    //     const newData = tableData.filter((_, i) => i !== index);
    //     setTableData(newData);
    //     let total = values.quantity * values.rate;

    //     const newGrandTotal = grandTotal - total;
    //     setGrandTotal(newGrandTotal);
    // };

    const handleDelete = (index) => {
        // Calculate the total for the row to be deleted
        const rowToDelete = tableData[index];
        const totalToDelete = rowToDelete.quantity * rowToDelete.rate;

        // Subtract the total to be deleted from the current grandTotal
        const newGrandTotal = grandTotal - totalToDelete;

        // Remove the row from tableData
        const newData = tableData.filter((_, i) => i !== index);

        // Update both grandTotal and tableData
        setGrandTotal(newGrandTotal);
        setTableData(newData);
    };



    //////////////////////////////////////////////////////////////////

    const handleAdd = () => {
        // Create a new data row object
        const newRow = {
            slNo: tableData.length + 1, // Calculate the new serial number
            moduDate: values.moduDate, // Use current date for demonstration
            perticular: values.perticular,
            make: values.make,
            quantity: values.quantity,
            rate: values.rate,
            total: values.quantity * values.rate,


        };

        let total = values.quantity * values.rate;
        // grandTotal += total;
        const newGrandTotal = grandTotal + total;

        setGrandTotal(newGrandTotal);
        // console.log("grandtotal",grandTotal);

        // console.log("newRow1",newRow);

        // Update the tableData state
        setTableData([...tableData, newRow]);


    };

    const handleFormSubmit = async (event) => {

        const confirmPrint = window.confirm('Do you want save?');
        if (confirmPrint) {


        const requestData = {

            // selectedCustomerId: selectedCustomerId,
            customerName: customerName,
            uniqueId: uniqueId,
            gstNo: gstNo,
            panNo: panNo,
            mobileNo: mobileNo,
            moduQuotationNo: moduQuotationNo,
            quotationNo: quotationNo,
            termsAndConditions:termsAndConditions,
            liftType: liftType,
            stops: stops,
            rate: rate,
            moduDate: moduDate,
            preAddress: preAddress,
            previousBal: previousBal,
            paidAmt: paidAmt,
            totalBalance: totalBalance,
            workingStatus: workingStatus,
            grandTotal: grandTotal,
            // ... other customer details
            tableData: tableData,
        };

        console.log("requestData", requestData);

        try {
            const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/newmodu_store_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log("table data1", data)
            if (data.message) {
                alert(data.message);

            } else {
                alert("ccccc2222 An error occurred while saving the data1.");
            }
        } catch (error) {
            console.error('Error while saving data:', error);
            alert("An error occurred while saving the data1.");
        }


        if (parseInt(paidAmt) < 0) {
            // Display an alert if paidAmt is negative
            setShowAlert(true);
        } else {
            // Continue with form submission
            setShowAlert(false);
            // ... your submission logic
        }
    }
    };




    ///////////////////////////////api call for getting data by quotation no.///////////////////////////////


    const handleSearchClick = async () => {
        try {

            const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_newmodu_data/${values.moduQuotationNo}`);
            const data = await response.json();

            if (data.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data.message);

            } else {

                console.log("data", data);
                setModuQuotationData(data);

                setUniqueId(data.mod_cu_unique_id)
                setCustomerName(data.mod_quo_cu_name)
                setGstNo(data.mod_quo_cu_gst)
                setPanNo(data.mod_quo_cu_pan)
                setMobileNo(data.mod_quo_cu_mob)
                setPreAddress(data.mod_quo_cu_address)
                setLiftType(data.mod_quo_lift_type)
                setStops(data.mod_quo_stops)
                setPreviousBal(data.mod_quo_pre_bal)
                setTermsAndConditions(data.mod_quo_term_con)

            }
        } catch (error) {
            console.error('Error fetching lift quotation data:', error);
        }
    };

    ////////////////////////// increment modulation quotation no increment  api//////////////////////////////

    useEffect(() => {
        // Fetch the next quotation number when the component mounts
        const fetchNextQuotationNumber = async () => {
            try {
                const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/newmodu_quono_incre');
                const data = await response.json();
                console.log(data);
                setQuotationNo(data.newId.toString());
            } catch (error) {
                console.error('Error fetching next quotation number:', error);
            }
        };

        fetchNextQuotationNumber();
    }, []); // Empty dependency array to ensure this runs only once when the component mounts

    /////////////////////////////////// store new lift data in databse/////////////////////////




    return (
        <>
            <div className='container'>

                <form onSubmit={handleSubmit}>
                    <div className="row mb-1 mt-5">
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'> New Modulation </h4>
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                                <input type="tex" className="form-control a1" id="uniqueId" name="uniqueId"
                                    value={uniqueId}
                                    onChange={handleUniqueId}
                                    onBlur={handleBlur} disabled="true"
                                />
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="moduDate" className='' style={{ fontSize: '14px' }}>Date:</label>
                                <input type="date" name='moduDate' className="form-control a1" id="moduDate"
                                    value={moduDate}
                                    onChange={handleDate}
                                    onBlur={handleBlur} />
                                {touched.moduDate && errors.moduDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.moduDate}</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="moduQuotationNo" className='' style={{ fontSize: '14px' }}>Quotation number</label>
                                <input type="text" name='moduQuotationNo' className="form-control a1" id="moduQuotationNo"
                                    value={values.moduQuotationNo}
                                    onChange={handleModuQuotationNo}
                                    onBlur={handleBlur} />
                                {touched.moduQuotationNo && errors.moduQuotationNo ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.moduQuotationNo}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 d-grid ">
                            <div className=" form-group ">
                                <button className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
                            </div>
                        </div>

                    </div>

                    <div className="row mb-1">
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                                <input type="text" name='customerName' className="form-control a1" id="customerName"
                                    value={customerName}
                                    onChange={handleCustomerName}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.customerName && errors.customerName ?(
                                <p className='form-error' style={{color:'red', fontSize:'14px'}}>{errors.customerName}</p>
                            ):null} */}
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.:*</label>
                                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo"
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.mobileNo && errors.mobileNo ?(
                                <p className='form-error' style={{color:'red', fontSize:'14px'}}>{errors.mobileNo}</p>
                            ):null} */}
                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="liftType" className='' style={{ fontSize: '14px' }}>Lift Type</label>
                                <input type="text" name='liftType' className="form-control a1" id="liftType"
                                    value={liftType}
                                    onChange={handleLiftType}
                                    onBlur={handleBlur} />
                                {touched.liftType && errors.liftType ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.liftType}</p>
                                ) : null}
                            </div>
                        </div>

                    </div>

                    <div className='row mb-1'>

                        <div className="col-md-2 ">
                            <div className="form-group">
                                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN No.</label>
                                <input type="email" name='panNo' className="form-control a1" id="panNo"
                                    value={panNo}
                                    onChange={handlePanNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.panNo && errors.panNo ?(
                                <p className='form-error' style={{color:'red', fontSize:'14px'}}>{errors.panNo}</p>
                            ):null } */}
                            </div>
                        </div>

                        <div className="col-md-2 ">
                            <div className="form-group">
                                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No</label>
                                <input type="text" name='gstNo' className="form-control a1" id="gstNo"
                                    value={gstNo}
                                    onChange={handleGstNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.gstNo && errors.gstNo ?(
                                <p className='form-error' style={{color:'red', fontSize:'14px'}}>{errors.gstNo}</p>
                            ):null } */}
                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Modulation number:*</label>
                                <input type="text" name='quotationNo' className="form-control a1" id="quotationNo"
                                    value={quotationNo}
                                    onChange={handleQuotationNo}
                                    onBlur={handleBlur} />
                                {/* {touched.moduQuotationNo && errors.moduQuotationNo ?(
                                <p className='form-error' style={{color:'red', fontSize:'14px'}}>{errors.moduQuotationNo}</p>
                            ):null} */}
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                                <input type="text" name='stops' className="form-control a1" id="stops"
                                    value={stops}
                                    onChange={handleStops}
                                    onBlur={handleBlur} />
                                {touched.stops && errors.stops ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.stops}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className='col-md-2'>

                            <label htmlFor="workingStatus " className='name_label door_type' >Mod Status:*</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='workingStatus'
                                id='workingStatus'
                                value={workingStatus}
                                onChange={handleWorkingStatus}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                <option value="Ongoing" style={{ background: '#eaeaea' }}>Ongoing</option>
                                <option value="Pending" style={{ background: '#eaeaea' }}>Pending</option>
                                <option value="Completed" style={{ background: '#eaeaea' }}>Completed</option>
                            </select>

                            {touched.workingStatus && errors.workingStatus ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.workingStatus}</p>
                            ) : null}
                        </div>

                    </div>





                    <div classname="row mb-1">
                        <div className="row mt-4">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                                    <textarea className="form-control a1" name="preAddress" id="preAddress" rows="5"
                                        value={preAddress}
                                        onChange={handlePreAddress}
                                        onBlur={handleBlur} disabled="true"></textarea>
                                    {/* {touched.preAddres && errors.preAddres ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.preAddres}</p>
                                    ) : null} */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12 ">
                        <div className="form-group">
                            <h5 className='mt-4'>Perticular Data</h5>
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="perticular" className='' style={{ fontSize: '14px' }}>Perticular</label>
                                <input type="text" name='perticular' className="form-control a1" id="perticular"
                                    value={values.perticular}
                                    onChange={handlePerticular}
                                    onBlur={handleBlur} />
                                {touched.perticular && errors.perticular ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.perticular}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="make" className='' style={{ fontSize: '14px' }}>Make</label>
                                <input type="text" name='make' className="form-control a1" id="make"
                                    value={values.make}
                                    onChange={handleMake}
                                    onBlur={handleBlur} />
                                {touched.make && errors.make ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.make}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="quantity" className='' style={{ fontSize: '14px' }}>Quantity</label>
                                <input type="text" name='quantity' className="form-control a1" id="quantity"
                                    value={values.quantity}
                                    onChange={handleQuantity}
                                    onBlur={handleBlur} />
                                {touched.quantity && errors.quantity ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.quantity}</p>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="rate" className='' style={{ fontSize: '14px' }}>Rate</label>
                                <input type="text" name='rate' className="form-control a1" id="rate"
                                    value={values.rate}
                                    onChange={handleRate}
                                    onBlur={handleBlur} />
                                {touched.rate && errors.rate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.rate}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <button type="button" className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleAdd}>+Add Perticulars</button>
                            </div>
                        </div>


                    </div>

                    <div className='row mb-1'>

                        {/* <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="followUpDate" className='name_label stops' style={{ fontSize: '14px' }} >Follow up Date</label>
                                <input type="date" name='followUpDate' className="form-control a1 stops_input" id="followUpDate"
                                    value={values.followUpDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.followUpDate && errors.followUpDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.followUpDate}</p>
                                ) : null}

                            </div>
                        </div> */}

                        <div className='col-md-4'>

                            <label htmlFor="termsAndConditions" className='name_label door_type' style={{ fontSize: '14px' }} >Terms and Conditions</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='termsAndConditions'
                                id='termsAndConditions'
                                value={termsAndConditions}
                                onChange={handleTermsAndConditions}
                                onBlur={handleBlur}>
                                {/* <option selected style={{ display: 'none' }}></option> */}
                                {/* <option value="Ongoing" style={{ background: '#eaeaea' }}>80 % Advance Payment</option> */}
                                <option value="Pending" style={{ background: '#eaeaea' }}>GST @18% Extra at Actual</option>
                                {/* <option value="Completed" style={{ background: '#eaeaea' }}>Type Manually</option> */}
                            </select>

                            {/* {touched.termsAndConditions && errors.termsAndConditions ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.termsAndConditions}</p>
                            ) : null} */}
                        </div>



                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="grandTotal" className='' style={{ fontSize: '14px' }}>Quotation Amt</label>
                                <input type="number" name='grandTotal' className="form-control a1" id="grandTotal"
                                    value={grandTotal}
                                    //   onChange={handleGrandTotal}
                                    onBlur={handleBlur} />

                            </div>
                        </div>


                    </div>


                    <div className="row mb-1">


                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="previousBal" className='' style={{ fontSize: '14px' }}>Previous balance</label>
                                <input type="number" name='previousBal' className="form-control a1" id="previousBal"
                                    value={previousBal}
                                    onChange={handlePreviousBal}
                                    onBlur={handleBlur} />
                                     {touched.previousBal && errors.previousBal ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.previousBal}</p>
                                ) : null}

                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="paidAmt" className='' style={{ fontSize: '14px' }}>Paid Amt</label>
                                <input type="number" name='paidAmt' className="form-control a1 " id="paidAmt"
                                    value={paidAmt}
                                    onChange={handlePaidAmt}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleBlur} />
                                {touched.paidAmt && errors.paidAmt ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.paidAmt}</p>
                                ) : null}
                            </div>
                        </div>



                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="totalBalance" className='' style={{ fontSize: '14px' }}>Total balance:*</label>
                                <input type="number" name='totalBalance' className="form-control a1" id="totalBalance"
                                    value={totalBalance}
                                    onChange={handleTotalBalance}
                                    onBlur={handleBlur} />


                            </div>
                        </div>

                    </div>



                    <div className='d-flex justify-content-center'>
                        <div className='  mt-5 col-md-8'>
                            <Table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col">Sl No</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Particulars</th>
                                        <th scope="col">Make</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Unit Rate</th>
                                        <th scope="col">Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {tableData.map((row, index) => {

                                        // const total = row.quantity * row.rate;
                                        // grandTotal = total + grandTotal;
                                        // console.log("grandtotal",grandTotal);

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        value={index}
                                                        onChange={(event) => handleCheckboxChange(event, index)}
                                                    />
                                                </td>
                                                {/* <th><input className="form-check-input" type="checkbox" value="" /></th> */}
                                                <th scope="row">{row.slNo}</th>
                                                <td>{row.moduDate}</td>
                                                <td>{row.perticular}</td>
                                                <td>{row.make}</td>
                                                <td>{row.quantity}</td>
                                                <td>{row.rate}</td>
                                                <td>{row.quantity * row.rate}</td>
                                                <td>
                                                    {/* <button
                                                type="button"
                                                className="btn btn-sm btn-primary mx-1"
                                                onClick={() => handleEdit(index)}
                                            >
                                                Edit
                                            </button> */}
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger mx-1"
                                                        onClick={() => handleDelete(index)}
                                                    >
                                                        Delete
                                                    </button></td>


                                            </tr>
                                        );

                                    })}
                                    <tr>
                                        <td colSpan="7" className="text-right">Grand Total:</td>
                                        <td>{grandTotal}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                    </div>

                    {/* <div className="d-inline-flex w-25 mt-4 ">
                    <button type="button" className="btn btn-primary mx-2">Edit</button>
                    <button type="button" className="btn btn-primary mx-3">Delete</button>
                </div> */}

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                        {/* <button type="button" className="btn btn-primary mx-2 ms-5 ">Preview</button> */}
                        {/* <button type="button" onClick={modulationPrint} className="btn btn-primary mx-3">Print</button> */}
                        <button type="submit" className="btn btn-primary mx-3">Save</button>
                    </div>

                </form>

            </div>
        </>
    )
}

export default NewModulationAdd