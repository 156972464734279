import React from 'react'
import { useNavigate } from 'react-router-dom'

const ReceivePayment = () => {


  const navigate = useNavigate();
  function openReceivePayment() {

    navigate("/app/receive_payment_types")
  }

  function openPaidLift() {

    navigate("/app/lift_paid_data")
  }

  function openPaidAmc() {

    navigate("/app/amc_paid_data")

  }

  function openPaidModulation(){

    navigate("/app/modulation_paid_data")
  }

  function openPaidOther(){

    navigate("/app/other_paid_data")
  }




  return (
    <>

<div className=" ">
        <h5>Receive Payment Details</h5>
        <hr />
        <button onClick={openReceivePayment} className="btn btn-primary buttonMargin col-3 d-grip w-auto">Add Payment</button>
      </div>

      <div className='conatiner'>


      <div className='row d-flex justify-content-around'>
        <button onClick={openPaidLift} className='btn btn-primary col-md-2 mt-4'>Lift Pending Payment</button>
        <button onClick={openPaidAmc} className='btn btn-primary col-md-2 mt-4'>AMC Pending Payment</button>
        <button onClick={openPaidModulation} className='btn btn-primary col-md-2 mt-4'>Modulation Pending Payment</button>
        <button onClick={openPaidOther} className='btn btn-primary col-md-2 mt-4'>Other Pending Payment</button>
      </div>

      </div>

    </>
  )
}

export default ReceivePayment