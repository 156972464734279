import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { amcModulationSchema } from '../Schema/AmcQuotationAddIndex';


const initialValues = {

  customerName: "",
  gstNo: "",
  panNo: "",
  inputEmail: "",
  mobileNo: "",
  quotationNo: "",
  preAddress: "",
  liftType: "",
  stops: "",
  doorType: "",
  make: "",
  quantity: "",
  quotationAmt: "",
  previousBal: "",
  quoDate: "",
  amcStartDate: "",
  amcEndDate: "",
  uniqueId: "",
  amcWorkStatus: "",

};



const AmcModulationAdd = () => {

  const navigate = useNavigate();
  const [inputVisible, setInputVisible] = useState(false);

  const [selCustomerNames, setSelCustomerNames] = useState([]);

  const [uniqueId, setUniqueId] = useState("");
  const [quoDate, setQuoDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [preAddress, setPreAddress] = useState("");

  const [liftType, setLiftType] = useState("");
  const [stops, setStops] = useState("");
  const [doorType, setDoorType] = useState("");
  const [make, setMake] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quotationAmt, setQuotationAmt] = useState("");
  const [amcStartDate, setAmcStartDate] = useState("");
  const [amcEndDate, setAmcEndDate] = useState("");
  const [amcWorkStatus, setAmcWorkStatus] = useState("");
  const [callBackDate, setCallBackDate] = useState("");

  const [customerDetails, setCustomerDetails] = useState({
    gstNo: '',
    panNo: '',
    inputEmail: '',
    mobileNo: '',
    preAddress: ''
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      validationSchema: amcModulationSchema,
      onSubmit: async (values, action) => {
        console.log("values:", values);
        await addAmcQuotation();
        openPrint();
        // action.resetForm();
        // navigate("/app/amc_data")
      },
    });

  console.log(errors);

  /////////////////////////////////////////////////////////////////////
  function saveData() {
    navigate("/app/quotation")
  }

  function openAmcAdd() {
    navigate("/app/amc_quotation_add")
  }

  function openAdd() {
    navigate("/app/lift_quotation_add")
  }

  function openModuleAdd() {
    navigate("/app/modulation_quotation_add")
  }



  function openPrint() {
    // navigate("/app/quotation_print")

    const confirmPrint = window.confirm('Do you want to proceed with printing?');

    if (confirmPrint) {
      navigate("/app/amc_quotation_print", {



        state: {

          customerName: customerName,
          uniqueId: uniqueId,
          quoDate: quoDate,
          quotationNo: quotationNo,
          mobileNo: mobileNo,
          panNo: panNo,
          gstNo: gstNo,
          inputEmail: inputEmail,
          preAddress: preAddress,
          liftType: liftType,
          quotationNo: quotationNo,
          stops: stops,
          doorType: doorType,
          make: make,
          quantity: quantity,
          quotationAmt: quotationAmt,
          amcStartDate: amcStartDate,
          amcEndDate: amcEndDate,
          amcWorkStatus: amcWorkStatus


        },

      });
    }
  }


  ////////////////////////////////////////////////////////////////////////

  const handleChangeUniqueId = (event) => {

    setUniqueId(event.target.value);
    handleChange(event);
  }




  const handleQuoDate = (event) => {

    setQuoDate(event.target.value);
    handleChange(event);
  }

  const handleCustomerName = (event) => {

    setCustomerName(event.target.value);
    handleChange(event);
  }

  const handleGstNo = (event) => {
    // console.log("On Change");

    setGstNo(event.target.value);
    handleChange(event);
  }
  const handlePanNo = (event) => {
    // console.log("On Change");

    setPanNo(event.target.value);
    handleChange(event);
  }
  const handleInputEmail = (event) => {
    // console.log("On Change");

    setInputEmail(event.target.value);
    handleChange(event);
  }

  const handleMobileNo = (event) => {
    // console.log("On Change");

    setMobileNo(event.target.value);
    handleChange(event);
  }


  const handleQuotationNo = (event) => {
    // console.log("On Change");

    setQuotationNo(event.target.value);
    handleChange(event);
  }

  const handlePreAddress = (event) => {
    // console.log("On Change");

    setPreAddress(event.target.value);
    handleChange(event);
  }


  // const handleLiftType = (event) => {
  //   // console.log("On Change");

  //   setLiftType(event.target.value);
  //   handleChange(event);
  // }

  // const handleStops = (event) => {
  //   // console.log("On Change");

  //   setStops(event.target.value);
  //   handleChange(event);
  // }

  const handleDoorType = (event) => {
    // console.log("On Change");

    setDoorType(event.target.value);
    handleChange(event);
  }
  // const handleMake = (event) => {
  //   // console.log("On Change");

  //   setMake(event.target.value);
  //   handleChange(event);
  // }
  const handleQuantity = (event) => {
    // console.log("On Change");

    setQuantity(event.target.value);
    handleChange(event);
  }
  const handleQuotationAmt = (event) => {
    // console.log("On Change");

    setQuotationAmt(event.target.value);
    handleChange(event);
  }
  const handleAmcStartDate = (event) => {
    // console.log("On Change");

    setAmcStartDate(event.target.value);
    handleChange(event);
  }
  const handleAmcEndDate = (event) => {
    // console.log("On Change");

    setAmcEndDate(event.target.value);
    handleChange(event);
  }

  const handleAmcWorkStatus = (event) => {
    // console.log("On Change");

    setAmcWorkStatus(event.target.value);
    handleChange(event);
  }

  const handleCallBackDate = (event) => {
    // console.log("On Change");

    setCallBackDate(event.target.value);
    handleChange(event);
  }


  ////////////////////////////////// get customer data by unique id when click on search//////////////

  const handleSearchClick = async () => {
    try {

      const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_data/${values.uniqueId}`);



      // if (!response.ok) {
      //     throw new Error('Error fetching data');
      // }

      const data = await response.json();

      if (data.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        alert(data.message);

      } else {



        console.log("data", data);
        setCustomerDetails(data);
        setCustomerName(data.cu_name)
        setGstNo(data.cu_gst)
        setPanNo(data.cu_pan)
        setInputEmail(data.cu_email)
        setMobileNo(data.cu_mobile)
        setPreAddress(data.cu_address)
        setUniqueId(data.cu_unique_id)
        // setPreviousAmcAmt(data.cu_amc_pre_bal)

      }
    } catch (error) {
      console.error('Error fetching lift quotation data:', error);
    }
  };


  /////////////////////////////api call//////////////////////

  // useEffect(() => {
  //   getData();

  // }, []);



  // function getData() {

  //   async function fetchCustomerNames() {
  //     try {
  //       const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_customer_name');
  //       console.log("response:", response);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch customer names.');
  //       }

  //       const data = await response.json();
  //       console.log("data:", data);
  //       const customerArray = Object.entries(data);
  //       console.log("customerArray:", customerArray);
  //       setSelCustomerNames(customerArray);
  //     } catch (error) {
  //       console.error('Error fetching customer names:', error);
  //     }
  //   }

  //   fetchCustomerNames();
  // }



  // const handleChangeCustomer = async (event) => {
  //   const selectedCustomerId = event.target.value;

  //   setCustomerName(event.target.options[event.target.selectedIndex].text);



  //   if (selectedCustomerId) {
  //     // console.log('Selected customer ID:', selectedCustomerId)
  //     try {
  //       const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_data/${selectedCustomerId}`);

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch customer details.');
  //       }

  //       const data1 = await response.json();
  //       console.log("selected", data1);

  //       setCustomerDetails(data1);
  //       setGstNo(data1.cu_gst)
  //       setPanNo(data1.cu_pan)
  //       setInputEmail(data1.cu_email)
  //       setMobileNo(data1.cu_mobile)
  //       setPreAddress(data1.cu_address)
  //       setUniqueId(data1.cu_unique_id)
  //       setPreviousAmcAmt(data1.cu_amc_pre_bal)
  //       console.log('Customer details:', data1)
  //     } catch (error) {
  //       console.error('Error fetching customer details:', error);
  //     }
  //   }
  //   else {
  //     setCustomerDetails({
  //       gstNo: '',
  //       panNo: '',
  //       inputEmail: '',
  //       mobileNo: '',
  //       preAddress: ''
  //     });
  //   }
  // }


  /////increment amc quotation no api call///////////////////////
  useEffect(() => {
    // Fetch the next quotation number when the component mounts
    const fetchNextQuotationNumber = async () => {
      try {
        const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/increment-amc-quotation');
        const data = await response.json();
        console.log(data);
        setQuotationNo(data.newId.toString());
      } catch (error) {
        console.error('Error fetching next quotation number:', error);
      }
    };

    fetchNextQuotationNumber();
  }, []); // Empty dependency array to ensure this runs only once when the component mounts



  ///////////////////////api to store data////////////////////////
  async function addAmcQuotation() {
    // console.log(name,file,price,description)
    const confirmPrint = window.confirm('Do you want save?');
    if (confirmPrint) {

      const formData = new FormData();

      formData.append("quoDate", quoDate)
      formData.append("uniqueId", uniqueId)
      formData.append("customerName", customerName)
      formData.append("panNo", panNo)
      formData.append("gstNo", gstNo)
      formData.append("inputEmail", inputEmail)
      formData.append("mobileNo", mobileNo)
      formData.append("quotationNo", quotationNo)
      formData.append("preAddress", preAddress)
      // formData.append("liftType", liftType)
      // formData.append("stops", stops)
      formData.append("doorType", doorType)
      // formData.append("make", make)
      formData.append("quantity", quantity)
      formData.append("quotationAmt", quotationAmt)
      formData.append("amcStartDate", amcStartDate)
      formData.append("amcEndDate", amcEndDate)
      formData.append("amcWorkStatus", amcWorkStatus)
      formData.append("callBackDate", callBackDate)

      try {
        let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/amc_quo_store', {
          method: 'POST',
          body: formData
        });

        let data = await response.json(); // Parse the response body as JSON

        if (data.message) {
          // If the response status is within the 2xx range, it means the request was successful.
          alert(data.message);

        } else {
          alert("An error occurred while saving the data.");
        }


      } catch (error) {
        console.error('Error while adding customer:', error);
        alert("An error occurred while saving the data2.");

      }
    }
  }


  return (
    <>
      <div className='row d-flex justify-content-around'>
        <button onClick={openAdd} className='btn btn-primary col-md-3 mt-4'>Lift Quotation</button>
        <button onClick={openAmcAdd} className='btn btn-primary col-md-3 mt-4'>AMC Quotation</button>
        <button onClick={openModuleAdd} className='btn btn-primary col-md-3 mt-4'>Modulation Quotation</button>
      </div>


      <div className='container'>

        <form onSubmit={handleSubmit}>


          <div className="row mb-1 mt-5">
            <div className="col-md-6 ">
              <div className="form-group">
                <h4 className='mt-4'>AMC Quotation</h4>
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Site Id</label>
                <input type="text" className="form-control a1" defaultValue={customerDetails.cu_unique_id} name="uniqueId" id="uniqueId"
                  onChange={handleChangeUniqueId}
                  disabled="true"
                />

              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date</label>
                <input type="date" className="form-control a1" name="quoDate" id="quoDate"
                  value={quoDate}
                  onChange={handleQuoDate}
                />
                {touched.quoDate && errors.quoDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.quoDate}</p>
                ) : null}
              </div>
            </div>


          </div>

          <hr />
          <div className="row mb-1">


            {/* <div className="col-md-4 ">
              <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>

              <select className="form-select border border-dark" defaultValue={customerDetails.cu_name} style={{ boxShadow: 'none' }}
                name='customerName'
                id='customerName'
                
                onChange={handleChangeCustomer}>

                <option value="1" style={{ background: '#dddddd' }}>Select Name</option>

                {selCustomerNames.map(([cu_unique_id, cu_name]) => (
                  <option key={cu_unique_id} value={cu_unique_id} style={{ background: '#dddddd' }}>{cu_name}</option>
                ))}

                {touched.customerName && errors.customerName ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.customerName}</p>
                ) : null}

              </select>

            </div> */}

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Site Id:*</label>
                <input type="text" name="uniqueId" className="form-control a1" id="uniqueId"
                  value={uniqueId}
                  onChange={handleChangeUniqueId}
                  onBlur={handleBlur} />
                {touched.uniqueId && errors.uniqueId ? (
                  <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.uniqueId}</p>
                ) : null}
              </div>
            </div>

            <div className="col-md-4 d-grid ">
              <div className=" form-group ">
                <button className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
              </div>
            </div>

          </div>



          <div className='row mb-1'>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                <input type="text" name="customerName" className="form-control a1" defaultValue={customerDetails.cu_name} id="customerName"

                  onChange={handleCustomerName}
                  onBlur={handleBlur} disabled="true" />

              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No.</label>
                <input type="text" name='gstNo' className="form-control a1" defaultValue={customerDetails.cu_gst} id="gstNo"
                  value={gstNo}
                  onChange={handleGstNo}
                  onBlur={handleBlur} disabled="true" />

                {touched.gstNo && errors.gstNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.gstNo}</p>
                ) : null}


              </div>
            </div>



            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN No.</label>
                <input type="text" name='panNo' className="form-control a1" defaultValue={customerDetails.cu_pan} id="panNo"
                  value={panNo}
                  onChange={handlePanNo}
                  onBlur={handleBlur} disabled="true" />

                {touched.panNo && errors.panNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.panNo}</p>
                ) : null}

              </div>
            </div>






          </div>

          <div className="row mb-1">




            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.</label>
                <input type="text" name='mobileNo' className="form-control a1" defaultValue={customerDetails.cu_gst} id="mobileNo"
                  value={mobileNo}
                  onChange={handleMobileNo}
                  onBlur={handleBlur} disabled="true" />
                {touched.mobileNo && errors.mobileNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.mobileNo}</p>
                ) : null}
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="inputEmail" className='' style={{ fontSize: '14px' }}>Email:*</label>
                <input type="text" name='inputEmail' className="form-control a1" defaultValue={customerDetails.cu_email} id="inputEmail"
                  value={inputEmail}
                  onChange={handleInputEmail}
                  onBlur={handleBlur} disabled="true" />
                {touched.inputEmail && errors.inputEmail ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.inputEmail}</p>
                ) : null}
              </div>
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quotation No:*</label>
                <input type="text" name='quotationNo' className="form-control a1" id="quotationNo"
                  value={quotationNo}
                  onChange={handleQuotationNo}
                  onBlur={handleBlur} disabled="true" />

                {touched.quotationNo && errors.quotationNo ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.quotationNo}</p>
                ) : null}
              </div>

            </div>


            <div className="row mb-5">
              <div className="col-md-12 ">
                <div className="form-group">
                  <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                  <textarea className="form-control a1" name='preAddress' defaultValue={customerDetails.cu_address} id="preAddress" rows="5"
                    value={preAddress}
                    onChange={handlePreAddress}
                    onBlur={handleBlur} disabled="true"></textarea>

                </div>
              </div>

            </div>

          </div>

          <div className="row mb-1">

            {/* <div className='col-md-4'>

              <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Type Lift:</label>

              <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                name='liftType'
                id='liftType'
                value={values.liftType}
                onChange={handleLiftType}
                onBlur={handleBlur}
              >
                <option selected style={{ dispaly: 'none' }}></option>
                <option value="Auto Lift" style={{ background: '#dddddd' }}>Auto Lift</option>
                <option value="Manual Lift" style={{ background: '#dddddd' }}>Manual Lift</option>
                <option value="Hydraulic Lift" style={{ background: '#dddddd' }}>Hydraulic Lift</option>
                <option value="SS Auto Lift" style={{ background: '#dddddd' }}>SS Auto Lift</option>

              </select>

              {touched.liftType && errors.liftType ? (
                <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.liftType}</p>
              ) : null}

            </div> */}


            {/* <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                <input type="text" name='stops' className="form-control a1" id="stops"
                  value={values.stops}
                  onChange={handleStops}
                  onBlur={handleBlur} />

                {touched.stops && errors.stops ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.stops}</p>
                ) : null}

              </div>
            </div> */}


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="doorType" className='' style={{ fontSize: '14px' }}>Types of Elevator</label>
                <input type="text" name='doorType' className="form-control a1" id="doorType"
                  value={values.doorType}
                  onChange={handleDoorType}
                  onBlur={handleBlur} />
                {touched.doorType && errors.doorType ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.doorType}</p>
                ) : null}
              </div>
            </div>


            {/* <div className='col-md-4'>

              <label htmlFor="doorType " className='' style={{ fontSize: '14px' }}>Types of Elevator:*</label>

              <select className="form-select border border-dark" aria-label="Default select example"
                name='doorType'
                id='doorType'
                value={values.doorType}
                onChange={handleDoorType}
                onBlur={handleBlur}>
                <option selected style={{ dispaly: 'none' }}></option>
                <option value="Door Type 1" style={{ background: '#dddddd' }}>Door Type 1</option>
                <option value="Door Type 2" style={{ background: '#dddddd' }}>Door Type 2</option>
                <option value="Door Type 3" style={{ background: '#dddddd' }}>Door Type 3</option>
                <option value="Door Type 4" style={{ background: '#dddddd' }}>Door Type 4</option>
                <option value="Door Type 5" style={{ background: '#dddddd' }}>Door Type 5</option>
                <option value="Door Type 6" style={{ background: '#dddddd' }}>Door Type 6</option>


              </select>

              {touched.doorType && errors.doorType ? (
                <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.doorType}</p>
              ) : null}
            </div> */}

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quantity" className='' style={{ fontSize: '14px' }}>Quantity</label>
                <input type="number" name='quantity' className="form-control a1" id="quantity"
                  value={values.quantity}
                  onChange={handleQuantity}
                  onBlur={handleBlur} />
                {touched.quantity && errors.quantity ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.quantity}</p>
                ) : null}
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quotationAmt" className='' style={{ fontSize: '14px' }}>Quatation Amt:*</label>
                <input type="number" name='quotationAmt' className="form-control a1" id="quotationAmt"
                  value={values.quotationAmt}
                  onChange={handleQuotationAmt}
                  onBlur={handleBlur} />
                {touched.quotationAmt && errors.quotationAmt ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.quotationAmt}</p>
                ) : null}
              </div>
            </div>



          </div>

          <div className="row mb-1">

            {/* <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="make" className='' style={{ fontSize: '14px' }}>Make</label>
                <input type="text" name='make' className="form-control a1" id="make"
                  value={values.make}
                  onChange={handleMake}
                  onBlur={handleBlur} />
                {touched.make && errors.make ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.make}</p>
                ) : null}

              </div>

            </div> */}



          </div>





          <div className="row mb-1">

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="amcStartDate" className='' style={{ fontSize: '14px' }}>AMC Start Date:*</label>
                <input type="date" className="form-control a1" id="amcStartDate"
                  value={values.amcStartDate}
                  onChange={handleAmcStartDate}
                  onBlur={handleBlur}
                />
                {touched.amcStartDate && errors.amcStartDate ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.amcStartDate}</p>
                ) : null}
              </div>
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="amcEndDate" className='' style={{ fontSize: '14px' }}>AMC End Date</label>
                <input type="date" className="form-control a1" id="amcEndDate"
                  value={values.amcEndDate}
                  onChange={handleAmcEndDate}
                  onBlur={handleBlur}
                />
                {touched.amcEndDate && errors.amcEndDate ? (
                  <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.amcEndDate}</p>
                ) : null}
              </div>
            </div>


            <div className='col-md-4'>

              <label htmlFor="amcWorkStatus" className='' style={{ fontSize: '14px' }}>Status</label>

              <select className="form-select border border-dark" aria-label="Default select example"
                name='amcWorkStatus'
                id='amcWorkStatus'
                value={amcWorkStatus}
                onChange={handleAmcWorkStatus}
                onBlur={handleBlur}>
                <option selected style={{ dispaly: 'none' }}></option>
                <option value="Visit Site" style={{ background: '#dddddd', color: "blue" }}>Visit Site</option>
                <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
              </select>

              {touched.amcWorkStatus && errors.amcWorkStatus ? (
                <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.amcWorkStatus}</p>
              ) : null}
            </div>


            {/* {inputVisible && ( */}
            {/* <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="previousAmcAmt" className='' style={{ fontSize: '14px' }}>Previous Amc Amt</label>
                <input type="number" name='previousAmcAmt' defaultValue={customerDetails.cu_amc_pre_bal} className="form-control a1" id="previousAmcAmt"
                  // value={previousAmcAmt}
                  onChange={handlePreAmcBal}
                  onBlur={handleBlur} disabled="true" />
               
              </div>
            </div> */}
            {/* )} */}

          </div>


          <div className='row mb-1'>



            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="callBackDate" className='name_label stops' >Follow up Date</label>
                <input type="date" name='callBackDate' className="form-control a1 stops_input" id="callBackDate"
                  value={callBackDate}
                  onChange={handleCallBackDate}
                  onBlur={handleBlur} />
                {touched.callBackDate && errors.callBackDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.callBackDate}</p>
                ) : null}

              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end'>

            <div className="btn-group my-3 ">
              {/* <button type="button" className="btn btn-primary mx-2">Preview</button> */}
              {/* <button type="button" onClick={openPrint} className="btn btn-primary mx-3">Print</button> */}
              <button type="submit" className="btn btn-primary mx-3">Save</button>
            </div>

          </div>

        </form>

      </div>
    </>
  )
}

export default AmcModulationAdd