import "./ProfileP.css"
// import React from 'react'
import React, { useEffect, useState } from 'react';
import DashboardLayout from "../../Layouts/DashboardLayout"


const ProfileP = () => {

    const [userData, setUserData] = useState({
        us_name: '',
        us_email: '',
        us_mobile: '',
    });

    const [userName, setUserName] = useState(""); // Initialize userName with user's name
    const [inputEmail, setInputEmail] = useState("");
    const [mobileNo, setMobileNo] = useState("");



    // const handleUserName = (event)=>{
    //     // console.log("On Change");
        
    //     setUserName(event.target.value);
    //   }

/// api call//////

useEffect(() => {
    const fetchData = async () => {
    //   let token = '22|iBkdvPc8nwq0NTxDvVNp6S3PIGinL44YnZDE31wn';
    //   localStorage.setItem('token' , token);
    const token = localStorage.getItem('token');
      console.log("profile token",token);
  
      if (token) {
        try {
          const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/loggeduser', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          console.log("response",response);
  
          if (response.ok) {
            const result = await response.json();
            console.log("result",result);
            setUserData(result);
            setUserName(result.us_name);
            setInputEmail(result.us_email);
            setMobileNo(result.us_mobile);
          
           
          } else {
            throw new Error('Failed to fetch user data');
          }
        } catch (error) {
          // Handle errors, e.g., unauthorized or network errors
          console.error(error);
        }
      }
    };
  
    fetchData();
  }, []);
 


    return (
        <>
        <DashboardLayout/>
            
            <div className='container1'>
                <div className="w-100 text-center">
                    <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none">
                        <img src="https://img.icons8.com/color/48/000000/circled-user-male-skin-type-4--v1.png" alt="avatar" className="avatar rounded-circle" />
                    </span>

                    <h6 style={{ fontWeight: "600" }}>{userData.us_name}</h6>
                    <hr />
                </div>

                <div className="Personal-info w-100"> 
                 <div className="mb-3 m-2"> 
                       <label for="userName"  className="form-label text-dark">Name:</label>
                        <input type="text"  className="form-control" name="userName"  id="userName" value={userData.us_name} 
                         />
                    </div>

                

                    <div className="mb-3 m-2"> 
                        <label for="inputEmail"  className="form-label text-dark">Email address:</label>
                        <input type="email"  className="form-control"  name="inputEmail" id="inputEmail" value={userData.us_email} 
                         />
                    </div>
                    <div className="mb-3 m-2">
                        <label for="mobileNo" className="form-label text-dark">Mobile No:</label>
                        <input type="text"  className="form-control"   name="mobileNo" id="mobileNo" value={userData.us_mobile} />
                    </div>

                    <div className="button d-flex justify-content-center mt-2">
                        <button className="btn btn-primary"  >Update Password</button>
                       
                    </div>
                </div>



        </div> 
{/* 
      <div>
        <h1>Welcome, {userData.us_name}</h1>
        <p>Email: {inputEmail}</p>
        <p>Mobile No: {mobileNo}</p>
      </div> */}

            
            
        </>
    )
}

export default ProfileP