import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { breakdownAddSchema } from '../Schema/BreakdownAddindex';




const initialValues = {
    customerName: "",
    mobileNo: "",
    siteAddress: "",
    liftType: "",
    liftTravel: "",
    status: "",
    contactPersonName: "",
    contactNo: "",
    technicianName: "",
    technicianRemarks: "",
    breakdownDesc: "",
    quoDate: "",
    // uniqueId: "",
    breakStatus: "",
    landmark: ""

};


const BreakdownAdd = () => {

    const navigate = useNavigate();
    const [selCustomerNames, setSelCustomerNames] = useState([]);

    const [uniqueId, setUniqueId] = useState("");
    const [quoDate, setQuoDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [siteAddress, setSiteAddress] = useState("");
    const [liftType, setLiftType] = useState("");
    const [liftTravel, setLiftTravel] = useState("");
    const [breakStatus, setBreakStatus] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [technicianName, setTechnicianName] = useState("");
    const [technicianRemarks, setTechnicianRemarks] = useState("");
    const [breakdownDesc, setBreakdownDesc] = useState("");
    const [customerDetails, setCustomerDetails] = useState("");
    const [landmark, setLandmark] = useState("");
    const [customerNameSuggestions, setCustomerNameSuggestions] = useState([]);
    const [customerNameList, setCustomerNameList] = useState([]);



    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: breakdownAddSchema,
            onSubmit: async (values, action) => {
                console.log("values:", values);
                await addBreakdownData();
                action.resetForm();
                navigate("/app/breakdown")
            },
        });
    console.log(errors);

    ///////////////////////////////////////////////////////////////

    const handleChangeUniqueId = (event) => {

        setUniqueId(event.target.value);
        handleChange(event);
    }


    const handleQuoDate = (event) => {

        setQuoDate(event.target.value);
        handleChange(event);
    }


    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }


    const handleSiteAddress = (event) => {
        // console.log("On Change");

        setSiteAddress(event.target.value);
        handleChange(event);
    }


    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }



    const handleLiftTravel = (event) => {
        // console.log("On Change");

        setLiftTravel(event.target.value);
        handleChange(event);
    }
    const handleBreakStatus = (event) => {
        // console.log("On Change");

        setBreakStatus(event.target.value);
        handleChange(event);
    }
    const handleContactPersonName = (event) => {
        // console.log("On Change");

        setContactPersonName(event.target.value);
        handleChange(event);
    }
    const handleContactNo = (event) => {
        // console.log("On Change");

        setContactNo(event.target.value);
        handleChange(event);
    }
    const handleTechnicianName = (event) => {
        // console.log("On Change");

        setTechnicianName(event.target.value);
        handleChange(event);
    }
    const handleTechnicianRemarks = (event) => {
        // console.log("On Change");

        setTechnicianRemarks(event.target.value);
        handleChange(event);
    }

    const handleBreakdownDesc = (event) => {
        // console.log("On Change");

        setBreakdownDesc(event.target.value);
        handleChange(event);
    }

    const handleLandmark = (event) => {

        setLandmark(event.target.value);
        handleChange(event);

    }

    ////////////////////////////////////// Api Call /////////////////////////////////////


    //////////////////////////////////// api call details are below////////////////////////////
    //     useEffect(() => {
    //         getData();

    //     }, []);



    //     function getData() {

    //         async function fetchCustomerNames() {
    //             try {
    //                 const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_customer_name');
    //                 console.log("response:", response);
    //                 if (!response.ok) {
    //                     throw new Error('Failed to fetch customer names.');
    //                 }

    //                 const data = await response.json();
    //                 console.log("data:", data);
    //                 const customerArray = Object.entries(data);
    //                 console.log("customerArray:", customerArray);
    //                 setSelCustomerNames(customerArray);
    //             } catch (error) {
    //                 console.error('Error fetching customer names:', error);
    //             }
    //         }

    //         fetchCustomerNames();
    //     }




    // const handleChangeCustomer = async (event) => {
    //     const selectedCustomerId = event.target.value;
    //     handleChange(event);
    //     setCustomerName(event.target.options[event.target.selectedIndex].text);



    //     if (selectedCustomerId) {
    //         // console.log('Selected customer ID:', selectedCustomerId)
    //         try {
    //             const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_data/${selectedCustomerId}`);

    //             if (!response.ok) {
    //                 throw new Error('Failed to fetch customer details.');
    //             }

    //             const data1 = await response.json();
    //             console.log("selected", data1);

    //             setCustomerDetails(data1);
    //             setUniqueId(data1.cu_unique_id)
    //             setMobileNo(data1.cu_mobile)
    //             setSiteAddress(data1.cu_address)
    //             setLandmark(data1.cu_landmark)


    //             console.log('Customer details:', data1)
    //         } catch (error) {
    //             console.error('Error fetching customer details:', error);
    //         }
    //     }
    //     else {

    //     }
    // }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    // const fetchCustomerNames = async () => {
    //     try {
    //       const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_customer_data');
    //       const data = await response.json();
    //       setCustomerNameList(data); // Set the list of customer names in state
    //     } catch (error) {
    //       console.error('Error fetching customer names:', error);
    //     }
    //   };

    //   // Call the fetch function in a useEffect hook
    // useEffect(() => {
    //   fetchCustomerNames();
    // }, []);


    const handleChangeCustomer = (e) => {
        const { value } = e.target;
        const updatedSuggestions = customerNameList.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setCustomerName(value);
        setCustomerNameSuggestions(updatedSuggestions);
    };

  


    /////////////////////////////////////////////////////////////////////////////////////////////////

    const handleSearchClick = async () => {
        try {
            let response;
            if (uniqueId) {
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_cust_name_by_id/${uniqueId}`);
            } else if (customerName) {
                const formattedCustomerName = customerName.toLowerCase();
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_cust_name_by_name/${formattedCustomerName}}`);
            }

            const data1 = await response.json();

            if (data1.message) {
                alert(data1.message);
            } else {
                console.log('data', data1);
                setCustomerDetails(data1);

                setCustomerDetails(data1);
                setUniqueId(data1.cu_unique_id)
                setMobileNo(data1.cu_mobile)
                setCustomerName(data1.cu_name)
                setSiteAddress(data1.cu_address)
                setLandmark(data1.cu_landmark)
                console.log('Customer details:', data1)

            }
        } catch (error) {
            console.error('Error fetching lift quotation data:', error);
        }
    };






    ///////////////////////////////////////////store api/////////////////////////////

    ///////////////////////api to store data////////////////////////
    async function addBreakdownData() {
        // console.log(name,file,price,description)
        const formData = new FormData();

        formData.append("quoDate", quoDate)
        formData.append("uniqueId", uniqueId)
        formData.append("customerName", customerName)
        formData.append("mobileNo", mobileNo)
        formData.append("siteAddress", siteAddress)
        formData.append("liftType", liftType)
        formData.append("liftTravel", liftTravel)
        formData.append("breakStatus", breakStatus)
        formData.append("contactPersonName", contactPersonName)
        formData.append("contactNo", contactNo)
        formData.append("technicianName", technicianName)
        formData.append("technicianRemarks", technicianRemarks)
        formData.append("breakdownDesc", breakdownDesc)
        formData.append("landmark", landmark)


        try {
            let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/store_breakdown_data', {
                method: 'POST',
                body: formData
            });

            let data = await response.json(); // Parse the response body as JSON

            if (data.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data.message);

            } else {
                alert("An error occurred while saving the data.");
            }


        } catch (error) {
            console.error('Error while adding customer:', error);
            alert("An error occurred while saving the data2.");

        }
    }


    return (
        <>

            <div className='container'>
                <form onSubmit={handleSubmit}>


                    <div className='row mb-1 mt-5'>

                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Add Breakdown Details</h4>
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                                <input type="text" className="form-control a1" defaultValue={customerDetails.cu_unique_id} id="uniqueId"
                                    onChange={handleChangeUniqueId}
                                    onBlur={handleBlur} disabled="true"
                                />
                                {touched.uniqueId && errors.uniqueId ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.uniqueId}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date:</label>
                                <input type="date" className="form-control a1" id="quoDate" name='quoDate'
                                    value={quoDate}
                                    onChange={handleQuoDate}
                                    onBlur={handleBlur} />
                                {touched.quoDate && errors.quoDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.quoDate}</p>
                                ) : null}
                            </div>
                        </div>

                    </div>

                    <hr />


                    <div className="row mb-1">

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="uniqueId" style={{ fontSize: '14px' }}>
                                    Site Id /Cust Name:
                                </label>
                                <input
                                    type="text"
                                    name="uniqueId"
                                    className="form-control a1"
                                    id="uniqueId"
                                    value={uniqueId}
                                    onChange={handleChangeUniqueId}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        {/* <div className='col-4'>

                            <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>

                            <select className="form-select border border-dark" defaultValue={customerDetails.cu_name} style={{ boxShadow: 'none' }}
                                name='customerName'
                                id='customerName'
                                // value={customerName}
                                onChange={handleChangeCustomer}>

                                <option value="1" style={{ background: '#dddddd' }}>Select Name</option>

                                {selCustomerNames.map(([cu_unique_id, cu_name]) => (
                                    <option key={cu_unique_id} value={cu_unique_id} style={{ background: '#dddddd' }}>{cu_name}</option>
                                ))}



                            </select>
                            {touched.customerName && errors.customerName ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.customerName}</p>
                            ) : null}

                        </div> */}

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <button className='btn btn-primary col-3 mt-4 w-auto' onClick={handleSearchClick} >Search</button>
                            </div>
                        </div>

                    </div>

                    <div className='row mb-1'>

                     <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name</label>
                                <input type="text" className="form-control a1" defaultValue={customerDetails.cu_name} id="customerName"
                                    value={customerName}
                                    onChange={handleChangeCustomer}
                                    onBlur={handleBlur} disabled="true" />
                               
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile Number</label>
                                <input type="text" className="form-control a1" defaultValue={customerDetails.cu_mobile} id="mobileNo"
                                    // value={values.mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur} disabled="true" />
                               
                            </div>
                        </div>

                        <div className='col-md-3'>

                            <label htmlFor="breakStatus " className='' style={{ fontSize: '14px' }}>Status</label>

                            <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='breakStatus'
                                id='breakStatus'
                                value={breakStatus}
                                onChange={handleBreakStatus}
                                onBlur={handleBlur}>

                                <option value=""></option>
                                <option value="New" style={{ background: '#eaeaea' }}>New</option>
                                <option value="Pending" style={{ background: '#eaeaea' }}>Pending</option>
                                <option value="Completed" style={{ background: '#eaeaea' }}>Completed</option>
                                <option value="Mod" style={{ background: '#eaeaea' }}>Mod</option>
                                <option value="Follow" style={{ background: '#eaeaea' }}>Follow</option>
                                <option value="Stop" style={{ background: '#eaeaea' }}>Stop</option>

                            </select>

                            {/* {touched.breakStatus && errors.breakStatus ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.breakStatus}</p>
                            ) : null} */}
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="landmark" className='' style={{ fontSize: '14px' }}>Landmark</label>
                                <input type="text" className="form-control a1" defaultValue={customerDetails.cu_landmark} id="landmark" name="landmark"
                                    // value={landmark}
                                    onChange={handleLandmark}
                                    onBlur={handleBlur} />
                                
                            </div>
                        </div>


                    </div>


                    <div className="row mb-1">
                        <div className="col-12 ">
                            <div className="form-group">
                                <label htmlFor="siteAddress" className='' style={{ fontSize: '14px' }}>Site Address</label>
                                <textarea className="form-control a1" name='siteAddress' defaultValue={customerDetails.cu_address} id="siteAddress" rows="5"
                                    // value={values.siteAddress}
                                    onChange={handleSiteAddress}
                                    onBlur={handleBlur} disabled="true"></textarea>
                                {/* {touched.siteAddress && errors.siteAddress ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.siteAddress}</p>
                                ) : null} */}
                            </div>
                        </div>

                    </div>


                    <div className='page__main'>



                        <div className="row mb-1">


                            {/* <div className='col-4'>

                                <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Lift Type</label>

                                <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                    name='liftType'
                                    id='liftType'
                                    value={liftType}
                                    onChange={handleLiftType}
                                    onBlur={handleBlur}>
                                    <option value=""></option>
                                    <option value="Auto Lift" style={{ background: '#eaeaea' }}>Auto Lift</option>
                                    <option value="Manual Lift" style={{ background: '#eaeaea' }}>Manual Lift</option>
                                    <option value="Hydraulic Lift" style={{ background: '#eaeaea' }}>Hydraulic Lift</option>
                                    <option value="SS Auto Lift" style={{ background: '#eaeaea' }}>SS Auto Lift</option>

                                </select>
                                {touched.liftType && errors.liftType ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.liftType}</p>
                                ) : null}

                            </div> */}


                            {/* <div className="col-4 ">
                                <div className="form-group">
                                    <label htmlFor="liftTravel" className='' style={{ fontSize: '14px' }}>Lift Travel</label>
                                    <input type="text" name='liftTravel' className="form-control a1" id="liftTravel"
                                        value={liftTravel}
                                        onChange={handleLiftTravel}
                                        onBlur={handleBlur} />
                                    {touched.liftTravel && errors.liftTravel ? (
                                        <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.liftTravel}</p>
                                    ) : null}
                                </div>
                            </div> */}



                        </div>

                        <div className="row mb-1">

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="contactPersonName" className='' style={{ fontSize: '14px' }}>Contact Person Name</label>
                                    <input type="text" name='contactPersonName' className="form-control a1" id="contactPersonName"
                                        value={contactPersonName}
                                        onChange={handleContactPersonName}
                                        onBlur={handleBlur} />
                                    {/* {touched.contactPersonName && errors.contactPersonName ? (
                                        <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.contactPersonName}</p>
                                    ) : null} */}
                                </div>
                            </div>


                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="contactNo" className='' style={{ fontSize: '14px' }}>Contact Number</label>
                                    <input type="number" name='contactNo' className="form-control a1" id="contactNo"
                                        value={contactNo}
                                        onChange={handleContactNo}
                                        onBlur={handleBlur} />
                                    {/* {touched.contactNo && errors.contactNo ? (
                                        <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.contactNo}</p>
                                    ) : null} */}
                                </div>
                            </div>

                        </div>

                        <div className="row mb-1">

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="technicianName" className='' style={{ fontSize: '14px' }}>Technician name</label>
                                    <input type="text" name='technicianName' className="form-control a1" id="technicianName"
                                        value={technicianName}
                                        onChange={handleTechnicianName}
                                        onBlur={handleBlur} />
                                    {/* {touched.technicianName && errors.technicianName ? (
                                        <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.technicianName}</p>
                                    ) : null} */}
                                </div>
                            </div>


                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="technicianRemarks" className='' style={{ fontSize: '14px' }}>Technician Remarks</label>
                                    <input type="text" name='technicianRemarks' className="form-control a1" id="technicianRemarks"
                                        value={technicianRemarks}
                                        onChange={handleTechnicianRemarks}
                                        onBlur={handleBlur} />
                                    {/* {touched.technicianRemarks && errors.technicianRemarks ? (
                                        <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.technicianRemarks}</p>
                                    ) : null} */}
                                </div>
                            </div>

                            <div className="row mt- ">
                                <div className="col-12 ">
                                    <div className="form-group">
                                        <label htmlFor="breakdownDesc" className='' style={{ fontSize: '14px' }}>Breakdown Description</label>
                                        <textarea className="form-control a1" name='breakdownDesc' id="breakdownDesc" rows="5"
                                            value={breakdownDesc}
                                            onChange={handleBreakdownDesc}
                                            onBlur={handleBlur}></textarea>
                                        {/* {touched.breakdownDesc && errors.breakdownDesc ? (
                                            <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.breakdownDesc}</p>
                                        ) : null} */}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="d-flex justify-content-end my-3 ">
                            <button type="submit" className="btn btn-primary mx-3">Save</button>
                        </div>


                    </div>

                </form>
            </div>

        </>
    )
}

export default BreakdownAdd