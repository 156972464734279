import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../pagination.css';


const EnquiryMaster = () => {

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');


  const [data, setData] = useState([])

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [statusByItemId, setStatusByItemId] = useState({});


  const openAdd = () => {

    navigate("/app/enquiry_add")
  }

  const handleUpdate = (en_id) => {
    // Navigate two steps back
    navigate(`/app/update_enquiry_add/${en_id}`);
  };



  ////////////////////// To select an Status //////////////////////////

  async function updateStatusInAPI(en_id, newStatus) {
    try {
      const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/enquiry_status", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          en_id: en_id,
          newStatus: newStatus,
        }),
      });

      if (!response.ok) {
        // Handle non-successful responses (e.g., server error)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Handle the response from the server (e.g., show a success message)
      console.log(data.message);

      // Return the updated status or response data if needed
      return data.status; // You may replace 'status' with the actual property name from your API response
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(error);

      // Return null or an error indicator if needed
      return null;
    }
  }


  //////////////////////////////////Select an Status/////////////////////////////////////////////////

  // Usage within your component
  const handleStatusChange = async (en_id, newStatus) => {
    // Update the status in the state immediately for a smooth user experience
    setStatusByItemId((prevStatus) => ({
      ...prevStatus,
      [en_id]: newStatus,
    }));

    // Make the API call to update the status in the backend
    const updatedStatus = await updateStatusInAPI(en_id, newStatus);

    if (updatedStatus !== null) {
      // Handle the updated status if needed
    } else {
      // Handle errors, e.g., revert the state to the previous value
      setStatusByItemId((prevStatus) => ({
        ...prevStatus,
        [en_id]: prevStatus[en_id], // Revert to the previous status
      }));
    }
  };



  ///////////////////////////////////api call////////////////////////////////

  async function deleteOperation(id) {
    // alert(id)
    const confirmDelete = window.confirm('Do you want delete?');
    if (confirmDelete) {
      let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete/" + id, {
        method: 'DELETE'
      });
      result = await result.json();
      // console.log(result)
      if (result.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        if (result.message) {
          // alert(result.message);
          alert('Deletion confirmed. Deleting...');
        } else {
          alert('Deletion cancelled.');
        }

      } else {
        alert("Customer not found.");
      }

      getData();
    }
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {

    getData()

  }, [currentPage])


  function getData() {

    async function fetchData() {
      try {
        let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/lift-enquiry?page=${currentPage}&perPage=${itemsPerPage}`);
        const { data: fetchedData, last_page: total } = await result.json();
          //  console.log("Fetched Data", fetchedData);
        // console.log("data", result);
        const statusData = {};
        fetchedData.forEach((item) => {
            statusData[item.en_id] = item.en_status; // Replace 'li_quo_status' with the actual property name from your API response
        });

        setData(fetchedData);
        setStatusByItemId(statusData)
        setTotalPages(total);
      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchData();

  }


  const filteredData = data.filter(item => {
    const cuIdString = item.en_cu_name.toLowerCase();
    const lowercaseSearch = searchValue.toLowerCase();
    const status = item.en_status.toLowerCase(); // Assuming the status field exists

    return cuIdString.toLowerCase().includes(lowercaseSearch) ||
        item.en_type_status.toLowerCase().includes(lowercaseSearch) ||
        status.includes(lowercaseSearch);
});


 


  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Calculate the range of page numbers to display in the pagination UI
  const getPageRange = () => {
    const rangeSize = 5; // Number of page numbers to display
    const totalPagesInRange = Math.min(rangeSize, totalPages);
    let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
    let endPage = startPage + totalPagesInRange - 1;

    // Adjust endPage if it exceeds totalPages
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - totalPagesInRange + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };

  return (
    <>


      <div className='conatiner'>

        <div className="row ">
          <div className='col-md-10 mt-4 mt-md-0'>
            <h5>Enquiry</h5>
          </div>

          <div className='col-md-2'>

            <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
              onChange={e => setSearchValue(e.target.value)} />

          </div>


        </div>

        <hr />

        <button onClick={openAdd} className="btn btn-primary buttonMargin">+Add</button>

        <Table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Sl No</th>
              <th scope="col"> Enquiry Date</th>
              <th scope="col">Customer Name</th>
              <th scope="col">Mobile No.</th>
              <th scope="col">Lift type</th>
              <th scope="col">Lift Travel</th>
              {/* <th scope="col">Status Enquiry</th> */}
              <th scope="col"><label htmlFor="enquiryStatus " className='' >Status Enquiry:*</label></th>
              <th scope="col">Type of Enquiry</th>
              <th scope="col">Calling</th>
              <th scope="col">Action</th>

            </tr>
          </thead>
          <tbody>
            {
              filteredData.map((item) =>
                <tr key={item.en_id}>
                  {/* <th scope="row">1</th> */}
                  <td>{item.en_id}</td>
                  <td>{item.en_date}</td>
                  <td>{item.en_cu_name}</td>
                  <td>{item.en_mob_no}</td>
                  <td>{item.en_lift_type}</td>
                  <td>{item.en_lift_travel}</td>
                  

                  <td><select className="form-select border border-dark form-control"
                    style={{ boxShadow: 'none' }}
                    aria-label="Default select example"
                    name='enquiryStatus'
                    id='enquiryStatus'
                    value={statusByItemId[item.en_id] || ''} // Set the value of the select element to the state variable
                    onChange={(e) => handleStatusChange(item.en_id, e.target.value)} // Update the state when the user changes the value
                  >
                    <option value="Pending" style={{ background: '#dddddd', color: "blue" }}>Pending</option>
                    <option value="Finalised" style={{ background: '#dddddd', color: "red" }}>Finalised</option>
                    <option value="Quotation Raised" style={{ background: '#dddddd', color: "green" }}>Quotation Raised</option>
                    <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                    <option value="Follow Up" style={{ background: '#dddddd', color: "brown" }}>Follow Up</option>

                  </select></td>
                  {/* <td>{item.en_status}</td> */}

                  <td>{item.en_type_status}</td>
                  {/* <td></td> */}
                  {/* <td></td> */}


                  <td className='d-flex justify-content-between'><a href={`https://wa.me/${item.en_mob_no}`} target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                    <a href={`tel:${item.en_mob_no}`}><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>

                  <td><span className='update' onClick={() => handleUpdate(item.en_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.en_id) }} >{<DeleteIcon style={{ color: 'red' }} />}</span></td>

                  {/* <td><span onClick={()=>{deleteOperation(item.id)}} className='delete'>Delete</span></td> */}
                  {/* <td><Link to={"app/update_customer/"+item.cu_id}><span  className='update'>{<UpdateIcon/>}</span></Link><span onClick={()=>{deleteOperation(item.cu_id)}} >{<DeleteIcon style={{color:'red'}}/>}</span></td> */}

                </tr>

              )

            }

          </tbody>

        </Table>

        {/* Pagination */}
        <div className='pagination'>
          {/* Show previous arrow icon */}
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
          )}

          {/* Show page numbers */}
          {getPageRange().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}

          {/* Show next arrow icon */}
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
          )}
        </div>

      </div>

    </>
  )
}

export default EnquiryMaster