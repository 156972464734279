import React, { useState } from 'react'
import { Navigate, useNavigate , useLocation } from 'react-router-dom'
import './ConfirmForgetPassword.css';



const ConfirmForgetPassword = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    // const [email, setEmail] = useState('');
    const [userOtp, setUserOtp] = useState('');
    const [message, setMessage] = useState('');

    // function saveData(){
    //     navigate("/reset_password")
    // }

    const saveData = async (e) => {
        e.preventDefault();
      
        try {
          const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/verifyotp?email=${email}&userOtp=${userOtp}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log('response',response);
      
          if (response.ok) {
            const data = await response.json();
            console.log('data',data);
            handleResponse(data);
          } else {
            handleResponseError('Something went wrong. Please try again.');
          }
        } catch (error) {
          handleResponseError('An error occurred. Please try again later.');
        }
      };


      const handleResponse = (data) => {
        if (data.message === 'OTP verified Successfully') {
        //   setMessage('OTP verified Successfully');
        window.alert('OTP verified Successfully.');
        const emailValue  = email;
        navigate(`/reset_password?email=${encodeURIComponent(emailValue )}`)
        
        } else {
        //   setMessage('User not found/Invalid code');
          window.alert('User not found/Invalid code.');
        }
      };
    
      const handleResponseError = (errorMessage) => {
        console.error('Error:', errorMessage);
        setMessage(errorMessage);
      };



    return (
        <>

            <div className='container4 '>


                <div className=" border p-5 bg-white rounded ">
                    <div className='heading  mb-4' style={{ margin: "0px auto" }}>
                        <h1 className='text-primary ' style={{ fontWeight: "700" }}>Forget Password?</h1>
                    </div>

                    <form onSubmit={saveData} >
                        <div className="form-group mb-3 w-100" style={{ margin: "0px auto" }}>
                            <label htmlFor="email" className='mb-2'>Email address</label>
                            <input type="email" className="form-control" id="email" name="email" value={email} style={{ border: '2px solid Gray' }}  />

                        </div>
                        <div className="form-group mb-3 " style={{ margin: "0px auto" }}>
                            <label htmlFor="userOtp" className='mb-2'>Enter OTP</label>
                            <input type="text" className="form-control mb-4" id="userOtp" name="userOtp" value={userOtp} onChange={(e) => setUserOtp(e.target.value)} style={{ border: '2px solid Gray' }} />
                        </div>



                        <div className=' d-grid ' style={{ margin: "0px auto" }}>
                            <button type="submit" onClick={saveData} className="btn btn-lg btn-primary ">Submit</button>
                        </div>
                    </form>
                </div>


            </div>

        </>
    )
}

export default ConfirmForgetPassword