import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import UpdateIcon from '@mui/icons-material/Update';
// import UpdateIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../pagination.css';


const LiftData = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([])

    const [selectedDates, setSelectedDates] = useState({});
 
    const [searchValue, setSearchValue] = useState('');

    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    // const [selectedDate, setSelectedDate] = useState('');
   
    const [statusByItemId, setStatusByItemId] = useState({});
    


/////////////////////// Update onselect Date change ////////////////////////////////
const handleDateChange = (li_quo_cu_id, newDate) => {
    setSelectedDates((prevSelectedDates) => ({
        ...prevSelectedDates,
        [li_quo_cu_id]: newDate,
    }));

    // Send the date value to the backend here
    updateDateInDatabase(li_quo_cu_id, newDate);
};


const updateDateInDatabase = async (li_quo_cu_id, newDate) => {
    try {
        const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/updateLiftdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                li_quo_cu_id: li_quo_cu_id, // Use the ID of the record you want to update
                newDate: newDate, // Use the selected date
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Handle the response from the server (e.g., show a success message)
        console.log(data.message);
    } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error(error);
    }
};


////////////////////// To select an Status //////////////////////////

    async function updateStatusInAPI(li_quo_cu_id, newStatus) {
        try {
            const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/updateLiftStatus", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    li_quo_cu_id: li_quo_cu_id,
                    newStatus: newStatus,
                }),
            });
    
            if (!response.ok) {
                // Handle non-successful responses (e.g., server error)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
    
            // Handle the response from the server (e.g., show a success message)
            console.log(data.message);
    
            // Return the updated status or response data if needed
            return data.status; // You may replace 'status' with the actual property name from your API response
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error(error);
    
            // Return null or an error indicator if needed
            return null;
        }
    }
    

//////////////////////////////////Select an Status/////////////////////////////////////////////////

// Usage within your component
const handleStatusChange = async (li_quo_cu_id, newStatus) => {
    // Update the status in the state immediately for a smooth user experience
    setStatusByItemId((prevStatus) => ({
        ...prevStatus,
        [li_quo_cu_id]: newStatus,
    }));

    // Make the API call to update the status in the backend
    const updatedStatus = await updateStatusInAPI(li_quo_cu_id, newStatus);

    if (updatedStatus !== null) {
        // Handle the updated status if needed
    } else {
        // Handle errors, e.g., revert the state to the previous value
        setStatusByItemId((prevStatus) => ({
            ...prevStatus,
            [li_quo_cu_id]: prevStatus[li_quo_cu_id], // Revert to the previous status
        }));
    }
};



///////////////////////////////////////////////////////////////////////////////////////


    // function openAdd() {

    //     navigate("/app/new_amc_add")
    // }

    const handleUpdate = (li_quo_cu_id) => {
        // Navigate two steps back
        navigate(`/app/update_lift_quotation_add/${li_quo_cu_id}`);
    };



    function openLift() {

        navigate("/app/lift_data")
    }

    function openAmc() {
        navigate("/app/amc_data")
    }

    function openModulation() {
        navigate("/app/modulation_data")
    }

    // function openAdd() {
    //     navigate("/app/lift_quotation_view_print")
    // }

    function openAdd(item) {
        // Construct the URL with route parameters
        navigate(`/app/lift_quotation_view_print/${item.li_quo_cu_id}`, {
          state: { data: item }, // Pass the data as part of the route state
        });
      }



    /////// api call to delete data //////////

    async function deleteOperation(id) {
        // alert(id)
        const confirmDelete = window.confirm('Do you want delete?');
        if (confirmDelete) {
            let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_liftdata/" + id, {
                method: 'DELETE'
            });
            result = await result.json();
            // console.log(result)
            if (result.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                if (result.message) {
                    // alert(result.message);
                    alert('Deletion confirmed. Deleting...');
                } else {
                    alert('Deletion cancelled.');
                }

            } else {
                alert("Customer not found.");
            }

            getData();

        }
    }


    /////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        getData()

    }, [currentPage])


    function getData() {

        async function fetchData() {
            try {
                let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_lift_data?page=${currentPage}&perPage=${itemsPerPage}`);
                const { data: fetchedData, last_page: total } = await result.json();
                // console.log("data", result);

                // Populate the status for each item based on fetched data
            const statusData = {};
            fetchedData.forEach((item) => {
                statusData[item.li_quo_cu_id] = item.li_quo_work_status; // Replace 'li_quo_status' with the actual property name from your API response
            });

            const statusDate = {};
            fetchedData.forEach((item) => {
                statusDate[item.li_quo_cu_id] = item.li_call_back_date; // Replace 'li_quo_status' with the actual property name from your API response
            });

                setData(fetchedData);
                setStatusByItemId(statusData); 
                setSelectedDates(statusDate)
                setTotalPages(total);
            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchData();

    }


    const filteredData = data.filter(item => {
        const cuIdString = item.li_cu_unique_id.toString();
        const lowercaseSearch = searchValue.toLowerCase();
        const status = item.li_quo_work_status.toLowerCase(); // Assuming the status field exists
        const date = item.li_quo_date.toLowerCase(); 
    
        return cuIdString.includes(lowercaseSearch) ||
            item.li_quo_cu_name.toLowerCase().includes(lowercaseSearch) ||
            status.includes(lowercaseSearch) ||
            date.includes(lowercaseSearch) ;
    });


    // // Filtering logic to serach by id and customer name
    // const filteredData = data.filter(item => {
    //     const cuIdString = item.li_cu_unique_id.toString();
    //     const lowercaseSearch = searchValue.toLowerCase();
    //     return cuIdString.includes(lowercaseSearch) || item.li_quo_cu_name.toLowerCase().includes(lowercaseSearch);
    // });


    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Calculate the range of page numbers to display in the pagination UI
    const getPageRange = () => {
        const rangeSize = 5; // Number of page numbers to display
        const totalPagesInRange = Math.min(rangeSize, totalPages);
        let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
        let endPage = startPage + totalPagesInRange - 1;

        // Adjust endPage if it exceeds totalPages
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - totalPagesInRange + 1, 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };


    return (
        <>

            <div className='d-flex justify-content-around '>
                <button onClick={openLift} className='btn btn-primary col-3 mt-4'>Lift Data</button>
                <button onClick={openAmc} className='btn btn-primary col-3 mt-4'>AMC Data</button>
                <button onClick={openModulation} className='btn btn-primary col-3 mt-4'>Modulation Data</button>
            </div>

            <div className='mt-5'>

                <div className="row ">
                    <div className='col-md-10 '>
                        <h5>Lift Data</h5>
                    </div>

                    <div className='col-md-2'>

                        <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
                            onChange={e => setSearchValue(e.target.value)} />

                    </div>


                </div>


                <hr />

                <Table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Sl No</th>
                            <th scope="col">Job Id</th>
                            <th scope="col">Quotation No.</th>
                            <th scope="col">Quotation date</th>
                            <th scope="col">Customer name</th>
                            <th scope="col" ><label htmlFor="liftStatus " className='' >Lift Status:*</label>

                                {/* <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                    name='liftStatus'
                                    id='liftStatus'
                                >
                                    <option value="Ongoing" style={{ background: '#eaeaea' }}>Ongoing</option>
                                    <option value="Completed" style={{ background: '#eaeaea' }}>Completed</option>
                                    <option value="Pending" style={{ background: '#eaeaea' }}>Pending</option>
                                </select> */}
                            </th>
                            <th scope="col">Quo Amt.</th>
                            <th scope="col">Follow Up Date</th>
                            <th scope="col">Calling Option</th>
                            <th scope="col">View Quo</th>
                            <th scope="col">Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        {

                            filteredData.map((item) =>
                                <tr key={item.li_quo_cu_id}>
                                    <td>{item.li_quo_cu_id}</td>
                                    <td>{item.li_cu_unique_id}</td>
                                    <td>{item.li_quo_no}</td>
                                    <td>{item.li_quo_date}</td>
                                    <td>{item.li_quo_cu_name}</td>

                                    <td><select className="form-select border border-dark form-control a1"
                                        style={{ boxShadow: 'none',width:"170px" }}
                                        aria-label="Default select example"
                                        name='liftStatus'
                                        id='liftStatus'
                                        value={statusByItemId[item.li_quo_cu_id] || ''} // Set the value of the select element to the state variable
                                        onChange={(e) => handleStatusChange(item.li_quo_cu_id, e.target.value)} // Update the state when the user changes the value
                                    >
                                        <option value="Site Visit" style={{ background: '#dddddd', color: "blue" }}>Site Visit</option>
                                        <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                                        <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                                        <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                                        <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                                        <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                                        <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                                    </select></td>

                                    <td>{item.li_quo_amount}</td>

                                    <td><input
                                        type="date"
                                        className="form-control a1"
                                        id={`quoDate-${item.li_quo_cu_id}`}
                                        name="quoDate"
                                        value={selectedDates[item.li_quo_cu_id] || ""}
                                        onChange={(e) => handleDateChange(item.li_quo_cu_id, e.target.value)}
                                    /></td>

                                    <td className='d-flex justify-content-evenly'><a href={`https://wa.me/${item.li_quo_cu_mobNo}`} target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>

                                        <a href={`tel:${item.li_quo_cu_mobNo}`}><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                                    {/* <td><button type='button' className='btn btn-primary mx-3' onClick={openAdd}>View</button></td> */}

                                     <td>
                                        <button
                                            type='button'
                                            className='btn btn-primary mx-3'
                                            onClick={() => openAdd(item)} // Pass the item data to the openAdd function
                                        >
                                            View
                                        </button>
                                        </td>

                                    <td><span className='update' onClick={() => handleUpdate(item.li_quo_cu_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.li_quo_cu_id) }}>{<DeleteIcon style={{ color: 'red' }} />}</span></td>

                                </tr>
                            )
                        }

                    </tbody>
                </Table>


                {/* Pagination */}
                <div className='pagination'>
                    {/* Show previous arrow icon */}
                    {currentPage > 1 && (
                        <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
                    )}

                    {/* Show page numbers */}
                    {getPageRange().map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}

                    {/* Show next arrow icon */}
                    {currentPage < totalPages && (
                        <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                    )}

                </div>

            </div>


        </>
    )
}

export default LiftData