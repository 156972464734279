
import React from 'react'
import { useNavigate } from 'react-router-dom'

function QuotationAdd() {

  const navigate = useNavigate();
  

  function openAdd(){
    navigate("/app/lift_quotation_add")
  }

  function openAmcAdd(){
    navigate("/app/amc_quotation_add")
  }

  function openModuleAdd(){ 
    navigate("/app/modulation_quotation_add")
  }

  
  return (
    <div className='row d-flex justify-content-around'>
      <button onClick={openAdd} className='btn btn-primary col-md-3 mt-4'>Lift Quotation</button>
      <button onClick={openAmcAdd} className='btn btn-primary col-md-3 mt-4'>AMC Quotation</button>
      <button onClick={openModuleAdd} className='btn btn-primary col-md-3 mt-4'>Modulation Quotation</button>
    </div>
  )
}

export default QuotationAdd
