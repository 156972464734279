import * as Yup from "yup";

export const signUpSchema = Yup.object({
  customerName: Yup.string().min(2).max(25).required("Please enter your name"),
  // firmName: Yup.string().min(2).max(25).required("Please enter firm name"),
  // inputEmail: Yup.string().email().required("Please enter your email"),
  // gstNo: Yup.string().required("Please enter your GST No."),
  // panNo: Yup.string().required("Please enter your PAN No."),
  mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
  // mobileNo: Yup.string().max(10).required("Please enter your mobile number"),
  // alterMobNo: Yup.number().integer().positive().required("Please enter your alternate mob no"),
  // alterMobNo: Yup.string().max(10).required("Please enter your alternate mob no"),
  // liftType: Yup.string().required("Please enter lift type"),
  // preBalance: Yup.string().required("Please enter previous balance"),
  // liftTravel: Yup.string().required("Please enter lift travel "),
  // exampleFormControlTextarea1: Yup.string().required("Please enter textarea "),
//   password: Yup.string().min(6).required("Please enter your password"),

//   confirm_password: Yup.string()
    // .required()
    // .oneOf([Yup.ref("password"), null], "Password must match"),
});
