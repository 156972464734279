    import React, { useState, useEffect } from 'react'
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { customerSchema } from "../Schema/CustomerIndex";



const initialValues = {
    customerName: "",
    invoiceDate: "",
    firmName: "",
    gstNo: "",
    panNo: "",
    inputEmail: "",
    mobileNo: "",
    alterMobNo: "",
    typeOfEnquiry: "",
    // address:"",
    // preBalance: "",
    liftTravel: "",
    uniqueId: "",
    exampleFormControlTextarea1: "",
    landmark:""
    // // img: "",
};


function CustomerAdd() {


    const navigate = useNavigate()


    const [customerName, setCustomerName] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [firmName, setFirmName] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [alterMobNo, setAlterMobNo] = useState("");
    const [typeOfEnquiry, setTypeOfEnquiry] = useState("");
    const [landmark, setLandmark] = useState("");

    const [liftTravel, setLiftTravel] = useState("");
    const [exampleFormControlTextarea1, setAddress] = useState("");
    // const [liftPreBal, setLiftPreBal] = useState(0);
    // const [amcPreBal, setAmcPreBal] = useState(0);
    // const [modPreBal, setModPreBal] = useState(0);
    const [uniqueId, setUniqueId] = useState("");
    const [typingEnquiryInput, setTypingEnquiryInput] = useState('');
  

    

    const handleEnquiryManuallyTyping = (e) => {
        const newValue = e.target.value;
        setTypingEnquiryInput(newValue);
    };

    const handleCustomerUniqueId = (event) => {
        // console.log("On Change");

        setUniqueId(event.target.value);
    }

    const handleCustomerName = (event) => {
        // console.log("On Change");

        setCustomerName(event.target.value);
    }

    const handleFirmName = (event) => {
        // console.log("On Change");
        setFirmName(event.target.value);
    }

    const handleGst = (event) => {
        // console.log("On Change");
        setGstNo(event.target.value);
    }

    const handlePanNo = (event) => {
        // console.log("On Change");
        setPanNo(event.target.value);
    }


    const handleEmail = (event) => {
        // console.log("On Change");
        setInputEmail(event.target.value);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");
        setMobileNo(event.target.value);
    }

    const handleAlMob = (event) => {
        // console.log("On Change");
        setAlterMobNo(event.target.value);
    }

    const handleTypeOfEnquiry = (event) => {
        // console.log("On Change");
        setTypeOfEnquiry(event.target.value);
    }





    const handleLiftTravel = (event) => {
        // console.log("On Change");
        setLiftTravel(event.target.value);
    }

    const handleAddress = (event) => {
        // console.log("On Change");
        setAddress(event.target.value);
    }

    //   const handleLiftPreBal = (event)=>{
    //     // console.log("On Change");
    //     setLiftPreBal(event.target.value);
    //   }

    //   const handleAmcPreBal = (event)=>{
    //     // console.log("On Change");
    //     setAmcPreBal(event.target.value);
    //   }

    //   const handleModPreBal = (event)=>{
    //     // console.log("On Change");
    //     setModPreBal(event.target.value);
    //   }

    const handleInvoiceDate = (event) => {

        setInvoiceDate(event.target.value);

    }

    const handleLandmark = (event) => {

        setLandmark(event.target.value);
        handleChange(event);

    }

    //   const handleAvatar = (event)=>{
    //     // console.log("On Change");
    //     setAvatar(event.target.value);
    //   }


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: customerSchema,
            onSubmit: async (values, action) => {
                console.log("values:", values);
                await addCustomer()
                // action.resetForm();
                // navigate("/app/customer")


            },
        });
    console.log(errors);




    const handleCustomChange = (event) => {

        handleCustomerName(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };


    const handleCustomChange1 = (event) => {

        handleFirmName(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };


    const handleCustomChange2 = (event) => {

        handleGst(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };

    const handleCustomChange3 = (event) => {

        handlePanNo(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };

    const handleCustomChange4 = (event) => {

        handleEmail(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };

    const handleCustomChange5 = (event) => {

        handleMobileNo(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };

    const handleCustomChange6 = (event) => {

        handleAlMob(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };

    const handleCustomChange7 = (event) => {

        handleTypeOfEnquiry(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };




    const handleCustomChange9 = (event) => {

        handleLiftTravel(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };

    const handleCustomChange10 = (event) => {

        handleAddress(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };



    //   const handleCustomChange12 = (event) => {

    //     handleLiftPreBal(event); // Call the second custom onChange function
    //     handleChange(event); // Call the useFormik handleChange function
    //   };


    //   const handleCustomChange13 = (event) => {

    //     handleAmcPreBal(event); // Call the second custom onChange function
    //     handleChange(event); // Call the useFormik handleChange function
    //   };

    //   const handleCustomChange14 = (event) => {

    //     handleModPreBal(event); // Call the second custom onChange function
    //     handleChange(event); // Call the useFormik handleChange function
    //   };

    const handleCustomChange15 = (event) => {

        handleCustomerUniqueId(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };

    const handleCustomChange16 = (event) => {

        handleInvoiceDate(event); // Call the second custom onChange function
        handleChange(event); // Call the useFormik handleChange function
    };



    ////////////////////////// increment lift quotation no increment  api//////////////////////////////

    // useEffect(() => {
       
    //     const fetchNextUniqueNumber = async () => {
    //         try {
    //             const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/customer_unique_id');
    //             const data = await response.json();
    //             console.log(data);
    //             setUniqueId(data.newId.toString());
    //         } catch (error) {
    //             console.error('Error fetching next quotation number:', error);
    //         }
    //     };

    //     fetchNextUniqueNumber();
    // }, []);


    ///// adding customer data in database

    async function addCustomer() {
        // console.log(name,file,price,description)
        const formData = new FormData();

        formData.append("customerName1", customerName)
        formData.append("firmName", firmName)
        formData.append("panNo", panNo)
        formData.append("gstNo", gstNo)
        formData.append("inputEmail", inputEmail)
        formData.append("mobileNo", mobileNo)
        formData.append("alterMobNo", alterMobNo)
        formData.append("typeOfEnquiry", typeOfEnquiry)
        formData.append("landmark", landmark)

        if (typeOfEnquiry === 'Manually Typing') {
            formData.append("typingEnquiryInput", typingEnquiryInput);
        }

        formData.append("liftTravel", liftTravel)
        // formData.append("liftPreBal",liftPreBal)
        // formData.append("amcPreBal",amcPreBal)
        // formData.append("modPreBal",modPreBal)
        // formData.append("img",Avatar)
        formData.append("exampleFormControlTextarea1", exampleFormControlTextarea1)
        // formData.append("uniqueId", uniqueId)
        formData.append("invoiceDate", invoiceDate)

        try {

            const responseUniqueId = await fetch('http://liftapp.infysky.in/liftapp_api/api/customer_unique_id');
            const dataUniqueId = await responseUniqueId.json();

            formData.append("uniqueId", dataUniqueId.newId.toString());

            let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/add_customer', {
                method: 'POST',
                body: formData
            });

            let data = await response.json(); // Parse the response body as JSON

            if (data.msg) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data.msg);
                navigate("/app/add")


            } else if (data.message) {

                navigate("/app/customer")
                alert(data.message);

            } else {
                alert("An error occurred while saving the data.");
            }


        } catch (error) {
            console.error('Error while adding customer:', error);
            alert("An error occurred while saving the data2.");

        }
    }


    return (

        <div className='container'>
            <form onSubmit={handleSubmit}>
                <div className="row mb-1">
                    <div className="col-md-8">
                        <div className="row mb-1">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="customerName" className='form-label' style={{ fontSize: "14px" }}> Customer Name:*</label>
                                    <input type="text" style={{ textTransform: 'capitalize' }} name="customerName" className="form-control a1" id="customerName"
                                        value={values.customerName}
                                        onChange={handleCustomChange}
                                        onBlur={handleBlur} />
                                    {touched.customerName && errors.customerName ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.customerName}</p>
                                    ) : null}
                                </div>
                            </div>
                           
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="invoiceDate" className='form-label' style={{ fontSize: "14px" }}>Date:</label>
                                    <input type="date" name="invoiceDate" className="form-control a1" id="invoiceDate"
                                        value={values.invoiceDate}
                                        onChange={handleCustomChange16}
                                        onBlur={handleBlur} />

                                    {touched.invoiceDate && errors.invoiceDate ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.invoiceDate}</p>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                        <div className="row mb-1">
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="gstNo" className='form-label' style={{ fontSize: "14px" }}>GST No.</label>
                                    <input type="text" name="gstNo" className="form-control a1" id="gstNo"
                                        value={values.gstNo}
                                        onChange={handleCustomChange2}
                                        onBlur={handleBlur} />
                                    {touched.gstNo && errors.gstNo ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.gstNo}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="panNo" className='form-label' style={{ fontSize: "14px" }}>PAN No.</label>
                                    <input type="text" name="panNo" className="form-control a1" id="panNo"
                                        value={values.panNo}
                                        onChange={handleCustomChange3}
                                        onBlur={handleBlur} />

                                    {touched.panNo && errors.panNo ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.panNo}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="inputEmail" className='form-label' style={{ fontSize: "14px" }}>Email:*</label>
                                    <input type="email" name="inputEmail" className="form-control a1" id="inputEmail"
                                        value={values.inputEmail}
                                        onChange={handleCustomChange4}
                                        onBlur={handleBlur} />
                                    {touched.inputEmail && errors.inputEmail ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.inputEmail}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="mobileNo" className='form-label' style={{ fontSize: "14px" }}>Mobile No:*</label>
                                    <input type="number" name="mobileNo" maxLength={10} className="form-control a1" id="mobileNo"
                                        value={values.mobileNo}
                                        onChange={handleCustomChange5}
                                        onBlur={handleBlur} />
                                    {touched.mobileNo && errors.mobileNo ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.mobileNo}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="alterMobNo" className='form-label' style={{ fontSize: "14px" }}>Alternate Mobile No:*</label>
                                    <input type="number" name="alterMobNo" maxLength={10} className="form-control a1" id="alterMobNo"
                                        value={values.alterMobNo}
                                        onChange={handleCustomChange6}
                                        onBlur={handleBlur} />
                                    {touched.alterMobNo && errors.alterMobNo ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.alterMobNo}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="typeOfEnquiry" className='form-label' style={{ fontSize: "14px" }}>Type of Enquiry:*</label>
                                    <div className="dropdown-select">
                                        <select
                                            name="typeOfEnquiry"
                                            className="form-control a1"
                                            id="typeOfEnquiry"
                                            value={values.typeOfEnquiry}
                                            onChange={handleCustomChange7}
                                            onBlur={handleBlur}
                                        >

                                            <option value="">Select Type</option>
                                            <option value="New Lift">New Lift</option>
                                            <option value="AMC">AMC</option>
                                            <option value="Modulation">Modulation</option>
                                            <option value="Call Base">Call Base</option>
                                            <option value="Manually Typing">Manually Typing</option>
                                        </select>

                                        {typeOfEnquiry === 'Manually Typing' && (
                                            <input
                                                type="text"
                                                className="form-control a1"
                                                name="typingEnquiryInput"
                                                id="typingEnquiryInput"
                                                value={typingEnquiryInput}
                                                onChange={handleEnquiryManuallyTyping}
                                                onBlur={handleBlur}
                                            />
                                        )}


                                        <i className="dropdown-icon fas fa-caret-down"></i>
                                    </div>
                                    {touched.typeOfEnquiry && errors.typeOfEnquiry ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.typeOfEnquiry}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="firmName" className='form-label' style={{ fontSize: "14px" }}>Firm Name</label>
                                    <input type="text" name="firmName" className="form-control a1" id="firmName"
                                        value={values.firmName}
                                        onChange={handleCustomChange1}
                                        onBlur={handleBlur} />
                                    {touched.firmName && errors.firmName ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.firmName}</p>
                                    ) : null}

                                </div>
                            </div>

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="landmark" className='form-label' style={{ fontSize: "14px" }}>Landmark</label>
                                    <input type="text" name="landmark" className="form-control a1" id="landmark"
                                        value={values.landmark}
                                        onChange={handleLandmark}
                                        onBlur={handleBlur} />
                                    {/* {touched.landmark && errors.landmark ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.landmark}</p>
                                    ) : null} */}

                                </div>
                            </div>


                        </div>


                    </div>


                    {/* <div className="col-4">
                        <div className="form-group">
                           
                            <img style={{ width: '200px', height: '200px', padding: '10px', borderRadius: '50%' }} className="profile-pic" src={Avatar || values.img} alt="Avatar" /><br />
                            <input  type="file" id="img" name="img" accept="image/*"
                                onChange={handleCustomChange11} />
                        </div>
                    </div> */}

                </div>



                <div className="row mb-3">
                    <div className="col-12 ">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{ fontSize: "14px" }}>Address</label>
                            <textarea className="form-control a1" name="exampleFormControlTextarea1" id="exampleFormControlTextarea1" rows="5"
                                value={values.exampleFormControlTextarea1}
                                onChange={handleCustomChange10}
                                onBlur={handleBlur} ></textarea>
                            {touched.exampleFormControlTextarea1 && errors.exampleFormControlTextarea1 ? (
                                <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.exampleFormControlTextarea1}</p>
                            ) : null}
                        </div>
                    </div>

                </div>


                <div className="container ">
                    <div className="row  ">
                        <div className="col-12 ">
                            <div className="form-group">
                                <button className="btn btn-primary btn-lg float-right " type="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>


        </div>
    )
}

export default CustomerAdd













