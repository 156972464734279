import React from 'react'
import { useNavigate } from 'react-router-dom'

const ServiceTypes = () => {

    const navigate = useNavigate();
  

    function openLiftPayment(){
      navigate("/app/lift_service")
    }
  
    function openAmcPayment(){
      navigate("/app/amc_service")
    }
  
    function openCallBase(){
      navigate("/app/callbase_service")
    }


  return (
    <>
    
    
    <div className='row d-flex justify-content-around'>
      <button onClick={openLiftPayment} className='btn btn-primary col-md-3 mt-4'>Free Service</button>
      <button onClick={openAmcPayment} className='btn btn-primary col-md-3 mt-4'>AMC Service</button>
      <button onClick={openCallBase} className='btn btn-primary col-md-3 mt-4'>Callbase Service</button>
      {/* <button onClick={openModulePayment} className='btn btn-primary col-3 mt-4'>Modulation Service</button> */}
    </div>
    
    </>
  )
}

export default ServiceTypes