import React , { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import './ForgetPassword.css';



const ForgetPassword = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // function saveData() {
    //     navigate("/confirm_forget_password")
    // }


    const saveData = async (e) => {
        e.preventDefault();

        if (!email) {
          alert("Please enter  email .");
          return;
      }
    
        try {
          const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/forgotpassword', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
          });
    
          if (response.ok) {
            const data = await response.json();
            handleResponse(data);

          } else {
            handleResponseError('Something went wrong. Please try again.');
          }
        } catch (error) {
          handleResponseError('An error occurred. Please try again later.');
        }
      };


      const handleResponse = (data) => {
        if (data.message) {
          // setMessage(data.message);
          window.alert(data.message);
          const emailValue  = email;
          navigate(`/confirm_forget_password?email=${encodeURIComponent(emailValue)}`)
       
        } else {
          setMessage('Something went wrong. Please try again.');
        }
      };
    
      const handleResponseError = (errorMessage) => {
        console.error('Error:', errorMessage);
        setMessage(errorMessage);
      };
  
    return (
        <>
        <div className='container3 '>
                <div className="row g-o ">
                    <div className="col-md-6  d-md-flex mb-5 ">
                        <div className='ImageBox h-60' >
                            <img src="https://www.coincent.ai/images/forgot.png" alt="" />
                        </div>
                    </div>

                    <div className="col-md-6 form-field  ">
                        <div className='heading col-9 ' style={{ margin: "0px auto" }}>
                            <h1 className='text-primary' style={{ fontWeight: "700" }}>Forget Password?</h1>
                        </div>

                        <form onSubmit={saveData}>
                            <div className="form-group mb-3 col-9" style={{ margin: "0px auto" }}>
                                <label htmlFor="floatingInput" className='mb-2'>Email address:</label>
                                <input type="email" className="form-control mb-4" id="exampleInputEmail1" style={{ border: '2px solid Gray' }} name="email"
                                 value={email} 
                                 onChange={(e) => setEmail(e.target.value)}
                                 />

                            </div>



                            <div className=' d-grid col-9 mb-4' style={{ margin: "0px auto" }}>
                                <button type="submit" className="btn btn-lg btn-primary " >continue</button>
                            </div>

                            <div className='form-group mb-4 col-9 text-center' style={{ margin: "0px auto" }}>
                                <Link to={'/'}>Return to sign </Link>
                            </div>
                            {message && <div className='text-center'>{message}</div>}
                        </form>
                    </div>

                </div>
            </div>


        </>
    )
}

export default ForgetPassword