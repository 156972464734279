import React, { useState, useEffect, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import { useFormik } from "formik";
import { useNavigate , useParams} from "react-router-dom";


const initialValues = {
    customerName: "",
    mobileNo: "",
    quotationNo: "",
    preAddress: "",
    quoDate: "",
    remark: "",
    technicianName: "",
    uniqueId: "",

};


const UpdateAmcServiceAdd = () => {

    const navigate = useNavigate();
    const sigCanvas = useRef({});

    const { id } = useParams();
    const [data, setData] = useState([]);
    const [uniqueId, setUniqueId] = useState("");
    const [quoDate, setQuoDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [quotationNo, setQuotationNo] = useState("");
    const [technicianName, setTechnicianName] = useState("");
    const [remark, setRemark] = useState("");
    const [preAddress, setPreAddress] = useState("");
    const [mobileNo, setMobileNo] = useState("");
   
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,


            onSubmit: (values, action) => {
                console.log("values:", values);
                // action.resetForm();
                navigate("/app/amc_service_data")
            },
        });
    console.log(errors);



    const clear = () => {
        sigCanvas.current.clear();
    };


    ////////////////////////////////////////////////////////////////////////////////////////////

    const handleChangeCustomer = (event) => {

        handleChange(event);
        setCustomerName(event.target.value);

    }

    const handleChangeUniqueId = (event) => {

        handleChange(event);
        setUniqueId(event.target.value);

    }


    const handleQuoDate = (event) => {

        setQuoDate(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");
        handleChange(event);
        setMobileNo(event.target.value);

    }

    const handleQuotationNo = (event) => {
        // console.log("On Change");
        handleChange(event);
        setQuotationNo(event.target.value);

    }

    const handlePreAddress = (event) => {
        // console.log("On Change");
        handleChange(event);
        setPreAddress(event.target.value);

    }

    const handleTechnicianName = (event) => {
        // console.log("On Change");

        setTechnicianName(event.target.value);
        handleChange(event);
    }

    const handleRemark = (event) => {
        // console.log("On Change");

        setRemark(event.target.value);
        handleChange(event);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////


    function openLiftPayment() {
        navigate("/app/lift_service")
    }

    function openAmcPayment() {
        navigate("/app/amc_service")
    }

    function openCallBase() {
        navigate("/app/callbase_service")
    }


//////////////////////////////////////////////////////////////////////////////////////////////////

useEffect(() => {

    async function fetchData() {
        try {
            const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/update_service_amcdata/${id}`);
            const result = await response.json();
            console.log("data:", result);
            setData(result);
            setUniqueId(result.amc_ser_cust_id)
            setCustomerName(result.amc_ser_cust_name)
            setMobileNo(result.amc_ser_mob_no)
            setPreAddress(result.amc_ser_address)
            setRemark(result.amc_ser_remark)
            setQuotationNo(result.amc_ser_quo_no)
            setTechnicianName(result.amc_ser_tech_name)
            setQuoDate(result.amc_ser_date)

            const signatureData = result.amc_ser_sign_data;

            if (sigCanvas.current) {
                sigCanvas.current.fromDataURL(signatureData);
            }

        } catch (error) {
            console.error('Error', error);
        }
    }

    fetchData();

}, [id]);


//////////////////////////////////////////////////////////////////////////////////////////////////

async function editProduct(id) {
    const formData = new FormData();
    formData.append("uniqueId", uniqueId)
    formData.append("quoDate", quoDate)
    formData.append("customerName", customerName)
    formData.append("mobileNo", mobileNo)
    formData.append("quotationNo", quotationNo)
    formData.append("preAddress", preAddress)
    formData.append("technicianName", technicianName)
    formData.append("remark", remark)

    const canvasData = sigCanvas.current.toDataURL();
    formData.append("signatureData", canvasData);

    console.log()

    try {
        let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/amc_service_update_store/' + id + '?method=PUT', {
            method: 'POST',
            body: formData
        });

        let data1 = await response.json(); // Parse the response body as JSON

        if (data1.message) {
            // If the response status is within the 2xx range, it means the request was successful.
            alert(data1.message);


        } else {
            alert("An error occurred while saving the data.");
        }


    } catch (error) {
        console.error('Error while adding customer:', error);
        alert("An error occurred while saving the data2.");

    }
}


//////////////////////////////////////////////////////////////////////////////////////////////////





    return (
        <div>

            <div className='row d-flex justify-content-around'>
                <button onClick={openLiftPayment} className='btn btn-primary col-md-3 mt-4'>Free Service</button>
                <button onClick={openAmcPayment} className='btn btn-primary col-md-3 mt-4'>AMC Service</button>
                <button onClick={openCallBase} className='btn btn-primary col-md-3 mt-4'>Callbase Service Data</button>
                {/* <button onClick={openPaidModulation} className='btn btn-primary col-md-3 mt-4'>Modulation Service Data</button> */}
            </div>


            <div className='container'>

                <form onSubmit={handleSubmit}>

                    <div className="row mb-1 mt-5">
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>AMC Service</h4>
                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" style={{ fontSize: '14px' }}>Site Id</label>
                                <input type="text" className="form-control a1" defaultValue={data.amc_ser_cust_id} id="uniqueId" name="uniqueId"
                                    // value={uniqueId}
                                    onChange={handleChangeUniqueId}
                                    disabled="true"
                                />



                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="quoDate" style={{ fontSize: '14px' }}>Date</label>
                                <input type="date" name='quoDate' className="form-control a1" defaultValue={data.amc_ser_date} id="quoDate"
                                    // value={values.quoDate}
                                    onBlur={handleBlur}
                                    onChange={handleQuoDate}
                                />

                            </div>
                        </div>

                    </div>

                    <hr />

                    <div className="row mb-1">

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name</label>
                                <input type="text" className="form-control a1" id="customerName" defaultValue={data.amc_ser_cust_name}
                                    // value={values.customerName}
                                    onChange={handleChangeCustomer}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quatation No</label>
                                <input type="text" name='quotationNo' className="form-control a1" id="quotationNo" defaultValue={data.amc_ser_quo_no}
                                    // value={values.quotationNo}
                                    onBlur={handleBlur}
                                    onChange={handleQuotationNo}
                                    disabled="true"
                                />


                            </div>

                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="technicianName" className='' style={{ fontSize: '14px' }}>Technician Name</label>
                                <input type="text" name='technicianName' className="form-control a1" id="technicianName" defaultValue={data.amc_ser_tech_name}
                                    // value={values.technicianName}
                                    onBlur={handleBlur}
                                    onChange={handleTechnicianName}
                                />
                                {touched.technicianName && errors.technicianName ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.technicianName}</p>
                                ) : null}

                            </div>
                        </div>


                    </div>



                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="remark" className='' style={{ fontSize: '14px' }}>Remarks</label>
                                <input type="text" name='remark' className="form-control a1" id="remark" defaultValue={data.amc_ser_remark}
                                    // value={values.remark}
                                    onBlur={handleBlur}
                                    onChange={handleRemark}
                                />
                                {touched.remark && errors.remark ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.remark}</p>
                                ) : null}

                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No</label>
                                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo" defaultValue={data.amc_ser_mob_no}
                                    // value={values.mobileNo}
                                    onBlur={handleBlur}
                                    onChange={handleMobileNo} disabled="true"
                                    
                                />


                            </div>
                        </div>

                    </div>



                    <div className="row mb-5">
                        <div className="col-md-12 ">
                            <div className="form-group">
                                <label htmlFor="preAddress" className='name_label address' style={{ fontSize: '14px' }} >Address</label>
                                <textarea className="form-control a1 address_input" name='preAddress' id="preAddress" rows="5"  defaultValue={data.amc_ser_address}
                                    // value={values.preAddress}
                                    onBlur={handleBlur}
                                    onChange={handlePreAddress} disabled="true"></textarea>



                            </div>
                        </div>

                    </div>

                    <div className="row mb-5 my-5">
                        <div className="col-md-4 ">
                            <SignatureCanvas
                                penColor="black"
                                canvasProps={{ width: 500, height: 200, className: 'sigCanvas', style: { border: '1px solid #000' } }}
                                ref={sigCanvas}
                            />
                            <button type="button" onClick={clear}>Clear</button>

                        </div>
                    </div>

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                    <button onClick={() => editProduct(data.amc_ser_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>

                    </div>

                </form>

            </div>


        </div>
    )
}

export default UpdateAmcServiceAdd