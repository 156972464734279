import React, { useState } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { liftReportSchema } from '../Schema/LiftReportIndex';
import { Table } from 'react-bootstrap';



const initialValues = {
    startDate: "",
    endDate: "",
    customerName: "",


};

const LiftReportMaster = () => {

    const navigate = useNavigate();

    // const [selCustomerNames, setSelCustomerNames] = useState([]);
    // const [selectedCustomerId, setSelectedCustomerId] = useState(''); /// for an dropdown
    const [customerDetails, setCustomerDetails] = useState([]);
    const [searchValue, setSearchValue] = useState('');


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            
            onSubmit: (values, action) => {
                console.log("values:", values);
                action.resetForm();
                // navigate("/app/breakdown")

            },
        });


  
        // const openPrint=()=>{
        //     navigate("/app/lift_report_print")
        // }


        const openPrint = () => {
       
            navigate("/app/lift_report_print", {
              
                state : { customerDetails , searchValue  },
              });
        }


    ///// api call details are here////////


    const handleSearchClick = async (event) => {
        event.preventDefault();

        const startDate = values.startDate;
        const endDate = values.endDate;

        try {
            let response;

            // response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_lift_report?startDate=${startDate}&endDate=${endDate}`);

            if (startDate && endDate) {
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_lift_report?startDate=${startDate}&endDate=${endDate}`);
              } else {
                // If startDate and endDate are not present, make the API request without them
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_lift_report`);
              }


            if (response.ok) {
                const data = await response.json();
                console.log("Lift report details:", data);

                 // Apply search filtering to the data
                const filteredData = data
                .filter(item =>
                    item.li_working_status.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.li_customer_name.toLowerCase().includes(searchValue.toLowerCase())
                );
                if (filteredData.length === 0) {
                    // No data found, display a message to the user
                    console.log("No data found.");
                    window.alert("No data found.");
                    // You can update the state or display a message to the user here.
                } else {
                    setCustomerDetails(filteredData);
                }

            } else {
                console.error('Error Lift report details:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching Lift report details:', error);
        }
    };


    return (
        <>

            <div className='container'>

                <form onSubmit={handleSubmit}>

                <div className="row ">
                        <div className='col-md-10 mt-4 mt-md-0'>
                            <h5>Final Lift Report</h5>
                        </div>

                        <div className='col-md-2'>

                        <input
                                    type="text"
                                    className="form-control"
                                    style={{ borderColor: 'black' }}
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={e => setSearchValue(e.target.value)}
                                />

                        </div>

                    </div>

                    <hr />
                 
                    <div className="d-md-flex justify-content-between">

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="startDate" className='' style={{ fontSize: '12px' }}>Start Date</label>
                                <input type="date" className="form-control a1" id="startDate" name='startDate'
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.startDate && errors.startDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="endDate" className='' style={{ fontSize: '12px' }}>End Date</label>
                                <input type="date" className="form-control a1" id="endDate" name='endDate'
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.endDate && errors.endDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='submit' className='btn btn-primary' onClick={(event) => {handleSearchClick(event); setSearchValue('');}}>Search</button>
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button className='btn btn-primary'  onClick={openPrint}>Print</button>
                            </div>
                        </div>

                    </div>

                    <Table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">Date</th>
                                <th scope="col">Cust Name</th>
                                <th scope="col">Job Id</th>
                                <th scope="col">Lift No</th>
                                <th scope="col">Quo Amt</th>
                                <th scope="col">Pre Bal</th>
                                <th scope="col">Paid Amt</th>
                                <th scope="col">Bal</th>
                                <th scope="col">Status</th>

                             

                            </tr>
                        </thead>
                        <tbody>
                            {/* {customerDetails.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.li_date}</td>
                                    <td>{item.li_customer_name}</td>
                                    <td>{item.li_fl_cu_unique_id}</td>
                                    <td>{item.li_quotation_no}</td>
                                    <td>{item.li_amount}</td>
                                    <td>{item.li_pre_bal}</td>
                                    <td>{item.li_paid_amt}</td>
                                    <td>{item.li_total_bal}</td>
                                    <td>status</td>
                                                             
                                </tr>
                                ))} */}
                            {customerDetails
                                .filter(item =>
                                    item.li_working_status.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.li_customer_name.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.li_date}</td>
                                        <td>{item.li_customer_name}</td>
                                        <td>{item.li_fl_cu_unique_id}</td>
                                        <td>{item.li_quotation_no}</td>
                                        <td>{item.li_amount}</td>
                                        <td>{item.li_pre_bal}</td>
                                        <td>{item.li_paid_amt}</td>
                                        <td>{item.li_total_bal}</td>
                                        <td>{item.li_working_status}</td>

                                    </tr>
                                ))}
                        </tbody>

                    </Table>
                </form>
            </div>
        </>
    )
}

export default LiftReportMaster