import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { updateNewAmcAddSchema } from '../Schema/NewAmcAddIndex';
import { useReactToPrint } from 'react-to-print';
import './NewAmcAdd.css';



const initialValues = {
  amcquotationNo: "",
  quotationNo: "",
  customerName: "",
  gstNo: "",
  panNo: "",
  inputEmail: "",
  mobileNo: "",
  amcquotationNo: "",
  preAddress: "",
  liftType: "",
  stops: "",
  doorType: "",
  make: "",
  quantity: "",
  quotationAmt: "",
  previousBal: "",
  paidAmt: "",
  totalBalance: "",
  amcStatus: "",
  invoiceDate: "",
  followUpDate: "",
};


const NewAmcAdd = () => {


  const componentPDF = useRef();
  const navigate = useNavigate();


  const [amcquotationNo, setAmcquotationNo] = useState(""); //search bu quotation no
  const [amcQuotationData, setAmcQuotationData] = useState(null);

  const [uniqueId, setUniqueId] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const [preAddress, setPreAddress] = useState("");
  const [quotationNo, setQuotationNo] = useState(''); //new final amc quo no
  // const [liftType, setLiftType] = useState("");
  // const [stops, setStops] = useState("");
  const [doorType, setDoorType] = useState("");
  // const [make, setMake] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quotationAmt, setQuotationAmt] = useState("");
  const [amcStartDate, setAmcStartDate] = useState("");
  const [amcEndDate, setAmcEndDate] = useState("");
  const [previousBal, setPreviousBal] = useState(0);
  const [paidAmt, setPaidAmt] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [amcStatus, setAmcStatus] = useState("");

  const [customerDetails, setCustomerDetails] = useState({
    gstNo: '',
    panNo: '',
    inputEmail: '',
    mobileNo: '',
    preAddress: ''
  });




  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      validationSchema: updateNewAmcAddSchema,
      onSubmit: async (values, action) => {
        console.log("values:", values);
        await addNewFinalAmcQuotation();
        openPrint();
        // action.resetForm();
        // navigate("/app/new_Amc")
      },
    });
  console.log(errors);

  // const generatePDF = useReactToPrint({
  //   content: () => componentPDF.current,
  //   documentTitle: "Userdata",

  // });


  // const openPrint=() =>{
  //   navigate("/app/amc_quotation_print")
  // }

  function openPrint() {

    const confirmPrint = window.confirm('Do you want to proceed with printing?');

    if (confirmPrint) {
      navigate("/app/new_amc_print", {

        state: {

          customerName: customerName,
          uniqueId: uniqueId,
          invoiceDate: invoiceDate,
          quotationNo: quotationNo,
          mobileNo: mobileNo,
          panNo: panNo,
          gstNo: gstNo,
          inputEmail: inputEmail,
          preAddress: preAddress,
          // liftType: liftType,
          quotationNo: quotationNo,
          // stops: stops,
          doorType: doorType,
          // make: make,
          quantity: quantity,
          quotationAmt: quotationAmt,
          amcStartDate: amcStartDate,
          amcEndDate: amcEndDate



        },
      });
    }
  }


  const handleUniqueId = (event) => {

    setUniqueId(event.target.value);
    handleChange(event);
  }

  const handleAmcQuotationNo = (event) => {  //search bu quotation no

    setAmcquotationNo(event.target.value);
    handleChange(event);
  }

  const handleQuotationNo = (event) => {   //new final amc quo no
    setQuotationNo(event.target.value);
    handleChange(event);
  }

  const handleInvoiceDate = (event) => {

    setInvoiceDate(event.target.value);
    handleChange(event);
  }

  const handleStartDate = (event) => {

    setAmcStartDate(event.target.value);
    handleChange(event);
  }

  const handleEndDate = (event) => {

    setAmcEndDate(event.target.value);
    handleChange(event);
  }

  const handleCustomerName = (event) => {

    setCustomerName(event.target.value);
    handleChange(event);
  }


  const handleGstNo = (event) => {
    // console.log("On Change");

    setGstNo(event.target.value);
    handleChange(event);
  }
  const handlePanNo = (event) => {
    // console.log("On Change");

    setPanNo(event.target.value);
    handleChange(event);
  }
  const handleInputEmail = (event) => {
    // console.log("On Change");

    setInputEmail(event.target.value);
    handleChange(event);
  }

  const handleMobileNo = (event) => {
    // console.log("On Change");

    setMobileNo(event.target.value);
    handleChange(event);
  }



  const handlePreAddress = (event) => {
    // console.log("On Change");

    setPreAddress(event.target.value);
    handleChange(event);
  }



  // const handleLiftType = (event) => {
  //   // console.log("On Change");

  //   setLiftType(event.target.value);
  //   handleChange(event);
  // }

  // const handleStops = (event) => {
  //   // console.log("On Change");

  //   setStops(event.target.value);
  //   handleChange(event);
  // }

  const handleDoorType = (event) => {
    // console.log("On Change");

    setDoorType(event.target.value);
    handleChange(event);
  }


  // const handleMake = (event) => {
  //   // console.log("On Change");

  //   setMake(event.target.value);
  //   handleChange(event);
  // }

  const handleQuantity = (event) => {
    // console.log("On Change");

    setQuantity(event.target.value);
    handleChange(event);
  }

  const handleAmcStatus = (event) => {
    // console.log("On Change");

    setAmcStatus(event.target.value);
    handleChange(event);
  }

  const handleQuotationAmt = (event) => {
    // console.log("On Change");

    setQuotationAmt(event.target.value);
    handleChange(event);
  }


  const handlePreviousBal = (event) => {
    // console.log("On Change");

    setPreviousBal(event.target.value);
    handleChange(event);
  }


  const handlePaidAmt = (event) => {
    // console.log("On Change");

    setPaidAmt(event.target.value);
    handleChange(event);
    const newTotalBalance = parseInt(quotationAmt || 0) + parseInt(previousBal || 0) - parseInt(event.target.value || 0);
    setTotalBalance(newTotalBalance);
  }


  const handleTotalBalance = (event) => {
    // console.log("On Change");

    setTotalBalance(event.target.value);
    // handleChange(event);
  }

  //// handle onclcik function//////////////////////////

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed');
      console.log('Previous Balance:', previousBal);
      console.log('Paid Amount:', paidAmt);

      const newTotalBalance = previousBal - paidAmt;
      console.log('New Total Balance:', newTotalBalance);

      setTotalBalance(newTotalBalance);
    }
  };




  ///////////////////////////////api call///////////////////////////////
  ///////////////////////////////api call for getting data by quotation no.///////////////////////////////


  const handleSearchClick = async () => {
    try {

      const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_newamc_data/${values.amcquotationNo}`);



      // if (!response.ok) {
      //     throw new Error('Error fetching data');
      // }

      const data = await response.json();

      if (data.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        alert(data.message);

      } else {



        console.log("data", data);
        setAmcQuotationData(data);
        setUniqueId(data.amc_cu_unique_id)
        setCustomerName(data.amc_quo_cu_name)
        setGstNo(data.amc_quo_cu_gst_no)
        setPanNo(data.amc_quo_cu_pan_no)
        setInputEmail(data.amc_quo_cu_email)
        setMobileNo(data.amc_quo_cu_mobno)
        setPreAddress(data.amc_quo_cu_address)
        // setLiftType(data.amc_quo_lift_type)
        // setStops(data.amc_quo_stops)
        setDoorType(data.amc_quo_elev_type)
        // setMake(data.amc_quo_make)
        setQuantity(data.amc_quo_quantity)
        setQuotationAmt(data.amc_quo_quo_amount)
        setAmcStartDate(data.amc_quo_start_date)
        setAmcEndDate(data.amc_quo_end_date)
        setPreviousBal(data.amc_quo_pre_bal)


      }
    } catch (error) {
      console.error('Error fetching lift quotation data:', error);
    }
  };


  ////////////////////////// increment amc quotation no increment  api//////////////////////////////

  useEffect(() => {
    // Fetch the next quotation number when the component mounts
    const fetchNextQuotationNumber = async () => {
      try {
        const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/newamc_quono_incre');
        const data = await response.json();
        console.log(data);
        setQuotationNo(data.newId.toString());
      } catch (error) {
        console.error('Error fetching next quotation number:', error);
      }
    };

    fetchNextQuotationNumber();
  }, []); // Empty dependency array to ensure this runs only once when the component mounts



  /////////////////////////////////// store new lift data in databse/////////////////////////

  async function addNewFinalAmcQuotation() {
    // console.log(name,file,price,description)
    const confirmPrint = window.confirm('Do you want save?');
    if (confirmPrint) {

      const formData = new FormData();
      formData.append("uniqueId", uniqueId)
      formData.append("amcquotationNo", amcquotationNo)
      formData.append("invoiceDate", invoiceDate)
      formData.append("customerName", customerName)
      formData.append("panNo", panNo)
      formData.append("gstNo", gstNo)
      formData.append("inputEmail", inputEmail)
      formData.append("mobileNo", mobileNo)
      formData.append("quotationNo", quotationNo)
      formData.append("preAddress", preAddress)
      // formData.append("liftType", liftType)
      // formData.append("stops", stops)
      formData.append("doorType", doorType)
      // formData.append("make", make)
      formData.append("quantity", quantity)
      formData.append("quotationAmt", quotationAmt)
      formData.append("amcStartDate", amcStartDate)
      formData.append("amcEndDate", amcEndDate)
      formData.append("amcStatus", amcStatus)
      formData.append("quotationAmt", quotationAmt)
      formData.append("previousBal", previousBal)
      formData.append("paidAmt", paidAmt)
      formData.append("totalBalance", totalBalance)

      // formData.append("previousBal", previousBal)
      // formData.append("paidAmt", paidAmt)
      // formData.append("totalBalance", totalBalance)


      try {
        let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/newamc_store_data', {
          method: 'POST',
          body: formData
        });

        let data = await response.json(); // Parse the response body as JSON

        console.log("api data", data);

        if (data.message) {
          // If the response status is within the 2xx range, it means the request was successful.
          alert(data.message);

        } else {
          alert("An error occurred while saving the data.");
        }


      } catch (error) {
        console.error('Error while adding customer:', error);
        alert("An error occurred while saving the data2.");

      }
    }
  }



  return (
    <>
      <div className='container'>
        <div ref={componentPDF} style={{ width: "100%" }}>
          <form onSubmit={handleSubmit}>


            <div className='row mb-1 mt-5'>

              <div className="col-md-6 ">
                <div className="form-group">
                  <h4 className='mt-4'>Add New AMC Details</h4>
                </div>
              </div>


              <div className="col-md-3 ">
                <div className="form-group">
                  <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                  <input type="tex" className="form-control a1" id="uniqueId" name="uniqueId"
                    value={uniqueId}
                    onChange={handleUniqueId}
                    onBlur={handleBlur} disabled="true"
                  />
                </div>
              </div>

              <div className="col-md-3 ">
                <div className="form-group">
                  <label htmlFor="invoiceDate" className='name_label date_label select_date' >Select Date</label>
                  <input type="date" className="form-control a1 select_date_input" name="invoiceDate" id="invoiceDate"
                    value={values.invoiceDate}
                    onChange={handleInvoiceDate}
                    onBlur={handleBlur}
                  />
                  {touched.invoiceDate && errors.invoiceDate ? (
                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.invoiceDate}</p>
                  ) : null}
                </div>
              </div>


            </div>

            <hr />

            <div className="row mb-1">

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="amcquotationNo" className='name_label AMC_Quotation' >AMC Quotation number</label>
                  <input type="text" name='amcquotationNo' className="form-control AMC_Quotation_input" id="amcquotationNo"
                    value={values.amcquotationNo}
                    onChange={handleAmcQuotationNo}
                    onBlur={handleBlur} />
                  {touched.amcquotationNo && errors.amcquotationNo ? (
                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.amcquotationNo}</p>
                  ) : null}
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <button className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
                </div>
              </div>

            </div>

            <div className="row mb-1 table11">

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                  <input type="text" name="customerName" className="form-control a1" id="customerName"
                    value={customerName}
                    onChange={handleCustomerName}
                    onBlur={handleBlur} />
                  {touched.customerName && errors.customerName ? (
                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.customerName}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="gstNo" className='name_label gst_no' >GST:</label>
                  <input type="text" name='gstNo' className="form-control a1 gst_input" id="gstNo"
                    value={gstNo}
                    onChange={handleGstNo}
                    onBlur={handleBlur} />
                  {/* {touched.gstNo && errors.gstNo ? (
                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.gstNo}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="panNo" className='name_label pan_no' >PAN:</label>
                  <input type="text" name='panNo' className="form-control a1 pan_input" id="panNo"
                    value={panNo}
                    onChange={handlePanNo}
                    onBlur={handleBlur} />
                  {/* {touched.panNo && errors.panNo ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.panNo}</p>
                  ) : null} */}
                </div>
              </div>

            </div>


            <div className="row mb-1">
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="inputEmail" className='name_label E-mail' >E-mail:*</label>
                  <input type="email" name='inputEmail' className="form-control a1 inputEmail_input" id="inputEmail"
                    value={inputEmail}
                    onChange={handleInputEmail}
                    onBlur={handleBlur} disabled="true" />
                  {/* {touched.inputEmail && errors.inputEmail ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.inputEmail}</p>
                  ) : null} */}

                </div>
              </div>
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="mobileNo" className='name_label mobile_no' >Mobile No:*</label>
                  <input type="text" name='mobileNo' className="form-control a1 mobileNo_input" id="mobileNo"
                    value={mobileNo}
                    onChange={handleMobileNo}
                    onBlur={handleBlur} />
                  {/* {touched.mobileNo && errors.mobileNo ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.mobileNo}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="quotationNo" className='name_label amc_no' >AMC No.:*</label>
                  <input type="text" name='quotationNo' className="form-control a1 amcNo_input" id="quotationNo"
                    value={quotationNo}
                    onChange={handleQuotationNo}
                    onBlur={handleBlur} />
                  {/* {touched.amcNo && errors.amcNo ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.amcNo}</p>
                  ) : null} */}
                </div>
              </div>


              <div className="row mb-5">
                <div className="col-md-12 ">
                  <div className="form-group">
                    <label htmlFor="preAddress" className='name_label address' >Address</label>
                    <textarea className="form-control a1 address_input" name='preAddress' id="preAddress" rows="5"
                      value={preAddress}
                      onChange={handlePreAddress}
                      onBlur={handleBlur}></textarea>
                    {/* {touched.preAddress && errors.preAddress ? (
                      <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.preAddress}</p>
                    ) : null} */}
                  </div>
                </div>

              </div>

            </div>

            <div className="row mb-1">

              {/* <div className='col-md-4'>

                <label htmlFor="liftType " className='name_label lift_type' >Lift Type</label>

                <select className="form-select border border-dark lift_type_input form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                  name='liftType'
                  id='liftType'
                  value={liftType}
                  onChange={handleLiftType}
                  onBlur={handleBlur}>
                  <option selected style={{ display: 'none' }}></option>
                  <option value="Auto Lift" style={{ background: '#eaeaea' }}>Auto Lift</option>
                  <option value="Manual Lift" style={{ background: '#eaeaea' }}>Manual Lift</option>
                  <option value="Hydraulic Lift" style={{ background: '#eaeaea' }}>Hydraulic Lift</option>
                  <option value="SS Auto Lift" style={{ background: '#eaeaea' }}>SS Auto Lift</option>

                </select>
               

              </div> */}


              {/* <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="stops" className='name_label stops' >Stops</label>
                  <input type="text" name='stops' className="form-control a1 stops_input" id="stops"
                    value={stops}
                    onChange={handleStops}
                    onBlur={handleBlur} />
              

                </div>
              </div> */}


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="doorType" className='name_label door_type' >Types of Elevator</label>
                  <input type="text" name='doorType' className="form-control a1" id="doorType"
                    value={doorType}
                    onChange={handleDoorType}
                    onBlur={handleBlur} />
                </div>
              </div>
     
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="quantity" className='name_label quantity' >Quantity</label>
                  <input type="number" name='quantity' className="form-control a1" id="quantity"
                    value={quantity}
                    onChange={handleQuantity}
                    onBlur={handleBlur} />

                </div>
              </div>

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="quotationAmt" className='name_label quotation_amt' >Quotation Amt:*</label>
                  <input type="number" name='quotationAmt' className="form-control a1" id="quotationAmt"
                    value={quotationAmt}
                    onChange={handleQuotationAmt}
                    onBlur={handleBlur} />

                </div>
              </div>


            </div>


            <div className="row mb-1">
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="previousBal" className='name_label previous_balance ' >Previous balance:*</label>
                  <input type="number" name='previousBal' className="form-control a1" id="previousBal"
                    value={previousBal}
                    onChange={handlePreviousBal}
                    onBlur={handleBlur}
                  />
                  {touched.previousBal && errors.previousBal ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.previousBal}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="paidAmt" className='name_label paid_amt' >Paid:*</label>
                  <input type="number" name='paidAmt' className="form-control a1" id="paidAmt"
                    value={paidAmt}
                    onChange={handlePaidAmt}
                    onKeyDown={handleKeyDown}
                  />
                  {touched.paidAmt && errors.paidAmt ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.paidAmt}</p>
                  ) : null}
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="totalBalance" className='name_label total_balance' >Total balance:*</label>
                  <input type="number" name='totalBalance' className="form-control a1" id="totalBalance"
                    value={totalBalance}
                    onChange={handleTotalBalance}
                    onBlur={handleBlur} />
                  {/* {touched.totalBalance && errors.totalBalance ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.totalBalance}</p>
                  ) : null} */}
                </div>
              </div>

            </div>


            <div className="row mb-1">

              <div className='col-md-4'>

                <label htmlFor="amcStatus " className='name_label door_type' >Status:*</label>

                <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                  name='amcStatus'
                  id='amcStatus'
                  value={amcStatus}
                  onChange={handleAmcStatus}
                  onBlur={handleBlur}>
                  <option selected style={{ display: 'none' }}></option>
                  <option value="Ongoing" style={{ background: '#eaeaea' }}>Ongoing</option>
                </select>

                {touched.amcStatus && errors.amcStatus ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.amcStatus}</p>
                ) : null}


              </div>

              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="amcStartDate" className='name_label amc_start_date' >AMC Start Date:*</label>
                  <input type="date" className="form-control a1" name="amcStartDate" id="amcStartDate"
                    value={amcStartDate}
                    onChange={handleStartDate}
                    onBlur={handleBlur}
                  />
                </div>
              </div>


              <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="amcEndDate" className='name_label amc_end_date' >AMC End Date</label>
                  <input type="date" className="form-control a1" id="amcEndDate" name="amcEndDate"
                    value={amcEndDate}
                    onChange={handleEndDate}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

            </div>

            <div className='row mb-1'>

              {/* <div className="col-md-4 ">
                <div className="form-group">
                  <label htmlFor="followUpDate" className='name_label stops' >Follow up Date</label>
                  <input type="date" name='followUpDate' className="form-control a1 stops_input" id="followUpDate"
                    value={values.followUpDate}
                    onChange={handleChange}
                    onBlur={handleBlur} />
                  {touched.followUpDate && errors.followUpDate ? (
                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.followUpDate}</p>
                  ) : null}

                </div>
              </div> */}

            </div>




            <div className='d-flex justify-content-end'>

              <div className="btn-group my-3 ">
                {/* <button type="button" className="btn btn-primary mx-2">Preview</button> */}
                {/* <button type="button" onClick={openPrint} className="btn btn-primary mx-3">Print</button> */}
                <button type="submit" className="btn btn-primary mx-3">Save</button>
              </div>

            </div>

          </form>

        </div >

      </div >
    </>
  )
}

export default NewAmcAdd