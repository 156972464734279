import React, { useRef } from 'react'
import { Table } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const PendingPaymentPrint = () => {

  const navigate = useNavigate();

  const componentPDF = useRef();
  const location = useLocation();

  const { customerDetails, radioSelected } = location.state;

  // console.log("customerDetails",location.state.customerDetails);
  // console.log("radioSelected",location.state.radioSelected);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Userdata",

  });

  function backtodata() {
    navigate("/app/pending_payment");
  }

  return (
    <>


      <div className='container-fluid d-flex justify-content-center align-items-center text-dark' ref={componentPDF}>

        <div className='row  '>

        <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com</p>
                
              </div>


          <div className='text-center mt-2'><h5><b> {radioSelected} Payment:</b></h5></div>
          <div className='d-flex justify-content-center '>

            <Table className="table table-bordered ">
              <thead>
                <tr>
                  <th scope="col">Sl No.</th>
                  
                  <th scope="col">Cust Id</th>
                  <th scope="col">Customer name</th>
                  <th scope="col">Previous Bal</th>
                  <th scope="col">Paid Amt</th>
                  <th scope="col">Total Bal</th>
                  <th scope="col">Last Paid Date</th>
                  <th scope="col">Payment Mode</th>
                </tr>
              </thead>
              <tbody>

                {radioSelected === 'lift' && (
                  // Render lift payment data
                  customerDetails.map((item, index) => (
                    <tr key={item.li_pay_id}>
                      <td>{index + 1}</td>
            
                      <td>{item.li_pay_unique_id}</td>
                      <td>{item.li_pay_cu_name}</td>
                      <td>{item.li_pay_pre_bal}</td>
                      <td>{item.li_pay_paid_amt}</td>
                      <td>{item.li_pay_total_bal}</td>
                      <td>{item.li_pay_date}</td>                    
                      <td>{item.li_payment_mode}</td>

                    </tr>
                  ))
                )}
                {radioSelected === 'amc' && (
                  // Render amc payment data

                  customerDetails.map((item, index) => (
                    <tr key={item.amc_pay_id}>
                      <td>{index + 1}</td>
                     
                      <td>{item.amc_pay_unique_id}</td>
                      <td>{item.amc_pay_cu_name}</td>
                      <td>{item.amc_pay_pre_bal}</td>
                      <td>{item.amc_pay_paid_amt}</td>
                      <td>{item.amc_pay_total_bal}</td>
                      <td>{item.amc_pay_date}</td>                     
                      <td>{item.amc_payment_mode}</td>

                    </tr>
                  ))
                )}
                {radioSelected === 'mod' && (
                  // Render amc payment data
                  customerDetails.map((item, index) => (
                    <tr key={item.mod_pay_id}>
                      <td>{index + 1}</td>
                      
                      <td>{item.mod_pay_unique_id}</td>
                      <td>{item.mod_pay_cu_name}</td>
                      <td>{item.mod_pay_pre_bal}</td>
                      <td>{item.mod_pay_paid_amt}</td>
                      <td>{item.mod_pay_total_bal}</td>
                      <td>{item.mod_pay_date}</td>                      
                      <td>{item.mod_payment_mode}</td>

                    </tr>
                  ))
                )}

                {radioSelected === 'other' && (
                  // Render amc payment data
                  customerDetails.map((item, index) => (
                    <tr key={item.mod_pay_id}>
                      <td>{index + 1}</td>
                     
                      <td>{item.other_pay_unique_id}</td>
                      <td>{item.other_pay_cu_name}</td>
                      <td>{item.other_pay_pre_bal}</td>
                      <td>{item.other_pay_paid_amt}</td>
                      <td>{item.other_pay_total_bal}</td>
                      <td>{item.other_pay_date}</td>
                      

                    </tr>
                  ))
                )}
              </tbody>
            </Table>

          </div>

        </div>
      </div>

      <div className='mt-4 float-end'>
        <button type="button" className="btn btn-primary mx-2 ms-5" onClick={backtodata}>Cancel</button>
        {/* <button className='btn btn-primary' onClick={generatePDF} style={{ width: "100px" }}>Ok</button><br /><br /><br /><br /> */}
        <button type="button" onClick={generatePDF} style={{ width: "80px" }} className="btn btn-primary mx-3">Ok</button>

      </div>


    </>
  )
}

export default PendingPaymentPrint