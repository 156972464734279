import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { liftServiceSchema } from '../Schema/LiftServiceAddIndex';
import SignatureCanvas from 'react-signature-canvas';


const initialValues = {
  customerName: "",
  mobileNo: "",
  quotationNo: "",
  preAddress: "",
  quoDate: "",
  remark: "",
  technicianName: "",
  uniqueId: "",

};

const LiftServiceAdd = () => {

  const navigate = useNavigate();
  const sigCanvas = useRef({});

  const [selCustomerNames, setSelCustomerNames] = useState([]);

  const [uniqueId, setUniqueId] = useState("");
  const [quoDate, setQuoDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [technicianName, setTechnicianName] = useState("");
  const [remark, setRemark] = useState("");
  const [preAddress, setPreAddress] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [customerNameSuggestions, setCustomerNameSuggestions] = useState([]);
  const [customerNameList, setCustomerNameList] = useState([]);

  const [customerDetails, setCustomerDetails] = useState("")

  ///////////////////////////// Digital Signature ////////////////////////////////
  const clear = () => {
    sigCanvas.current.clear();
  };

 


  //////////////////////////////////////////////////////////////////////////////////////

  const handleChangeUniqueId = (event) => {

    handleChange(event);
    setUniqueId(event.target.value);

  }

  const handleQuoDate = (event) => {

    setQuoDate(event.target.value);
    handleChange(event);
  }

  const handleMobileNo = (event) => {
    // console.log("On Change");
    handleChange(event);
    setMobileNo(event.target.value);

  }

  const handleQuotationNo = (event) => {
    // console.log("On Change");
    handleChange(event);
    setQuotationNo(event.target.value);

  }

  const handlePreAddress = (event) => {
    // console.log("On Change");
    handleChange(event);
    setPreAddress(event.target.value);

  }

  const handleTechnicianName = (event) => {
    // console.log("On Change");

    setTechnicianName(event.target.value);
    handleChange(event);
  }

  const handleRemark = (event) => {
    // console.log("On Change");

    setRemark(event.target.value);
    handleChange(event);
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      validationSchema: liftServiceSchema,
      onSubmit: async (values, action) => {
        console.log("values:", values);
        await addLiftServiceDetails();
        // action.resetForm();
        navigate("/app/lift_service_data")
      },
    });
  console.log(errors);


  /////////////////////////////////////////////////////////////////////
  function openLiftPayment() {
    navigate("/app/lift_service")
  }

  function openAmcPayment() {
    navigate("/app/amc_service")
  }

  function openCallBase() {
    navigate("/app/callbase_service")
  }
  ///////////////////////////////////////////////////////////////////////



  ////////////////////////////////////// Api call//////////////////////////////////////



  // useEffect(() => {
  //   getData();

  // }, []);



  // function getData() {

  //   async function fetchCustomerNames() {
  //     try {
  //       const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_lift_service_cust');
  //       console.log("response:", response);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch customer names.');
  //       }

  //       const data = await response.json();
  //       console.log("data:", data);
  //       const customerArray = Object.entries(data);
  //       console.log("customerArray:", customerArray);
  //       setSelCustomerNames(customerArray);
  //     } catch (error) {
  //       console.error('Error fetching customer names:', error);
  //     }
  //   }

  //   fetchCustomerNames();
  // }


  // const handleChangeCustomer = async (event) => {
  //   const selectedCustomerId = event.target.value;
  //   handleChange(event);

  //   setCustomerName(event.target.options[event.target.selectedIndex].text);



  //   if (selectedCustomerId) {
  //     // console.log('Selected customer ID:', selectedCustomerId)
  //     try {
  //       const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_lift_service_customer/${selectedCustomerId}`);

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch customer details.');
  //       }

  //       const data1 = await response.json();
  //       console.log("selected", data1);

  //       setCustomerDetails(data1);
  //       setUniqueId(data1.li_fl_cu_unique_id)
  //       setQuotationNo(data1.li_quotation_no)
  //       setMobileNo(data1.li_mobile)
  //       setPreAddress(data1.li_address)


  //       console.log('Customer details:', data1)
  //     } catch (error) {
  //       console.error('Error fetching customer details:', error);
  //     }
  //   }

  // }


  /////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChangeCustomer = (e) => {
    const { value } = e.target;
    const updatedSuggestions = customerNameList.filter((name) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    setCustomerName(value);
    setCustomerNameSuggestions(updatedSuggestions);
  };


  const handleSearchClick = async () => {
    try {
      let response;
      if (uniqueId) {
        response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_lift_ser_cust_data_by_id/${uniqueId}`);
      } else if (customerName) {
        const formattedCustomerName = customerName.toLowerCase();
        response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_lift_ser_cust_data_by_name/${formattedCustomerName}}`);
      }

      const data1 = await response.json();

      if (data1.message) {
        alert(data1.message);
      } else {
        console.log('data', data1);
        setCustomerDetails(data1);

        setUniqueId(data1.li_fl_cu_unique_id)
        setQuotationNo(data1.li_quotation_no)
        setMobileNo(data1.li_mobile)
        setPreAddress(data1.li_address)
        setCustomerName(data1.li_customer_name)

        console.log('Customer details:', data1)

      }
    } catch (error) {
      console.error('Error fetching lift quotation data:', error);
    }
  };


  ////////////////////////////////////////////////////////////////////////

  async function addLiftServiceDetails() {
    // console.log(name,file,price,description)
    const formData = new FormData();
    formData.append("uniqueId", uniqueId)
    formData.append("quoDate", quoDate)
    formData.append("customerName", customerName)
    formData.append("mobileNo", mobileNo)
    formData.append("quotationNo", quotationNo)
    formData.append("preAddress", preAddress)
    formData.append("technicianName", technicianName)
    formData.append("remark", remark)

    const canvasData = sigCanvas.current.toDataURL();
    formData.append("signatureData", canvasData);



    try {
      let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/store_lift_service_data', {
        method: 'POST',
        body: formData
      });

      let data = await response.json(); // Parse the response body as JSON

      console.log("api data", data);

      if (data.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        alert(data.message);

      } else {
        alert("An error occurred while saving the data.");
      }


    } catch (error) {
      console.error('Error while adding customer:', error);
      alert("An error occurred while saving the data2.");

    }
  }




  return (
    <>



      <div className='row d-flex justify-content-around'>
        <button onClick={openLiftPayment} className='btn btn-primary col-md-3 mt-4'>Free Service</button>
        <button onClick={openAmcPayment} className='btn btn-primary col-md-3 mt-4'>AMC Service</button>
        <button onClick={openCallBase} className='btn btn-primary col-md-3 mt-4'>Callbase Service Data</button>
        {/* <button onClick={openPaidModulation} className='btn btn-primary col-md-3 mt-4'>Modulation Service Data</button> */}
      </div>

      <div className=''>

        <form onSubmit={handleSubmit}>

          <div className="row mb-1 mt-5">
            <div className="col-md-6 ">
              <div className="form-group">
                <h4 className='mt-4'>Free Service</h4>
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Site Id</label>
                <input type="text" className="form-control a1" name="uniqueId" defaultValue={customerDetails.li_fl_cu_unique_id} id="uniqueId"
                  // value={uniqueId}
                  onChange={handleChangeUniqueId}
                  onBlur={handleBlur}
                  disabled="true"

                />

                {/* {touched.uniqueId && errors.uniqueId ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.uniqueId}</p>
                                ) : null} */}

              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date</label>
                <input type="date" name='quoDate' className="form-control a1" id="quoDate"
                  value={quoDate}
                  onBlur={handleBlur}
                  onChange={handleQuoDate}
                />
                {touched.quoDate && errors.quoDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.quoDate}</p>
                ) : null}

              </div>
            </div>
          </div>

          <hr />

          <div className="row mb-1">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="uniqueId" style={{ fontSize: '14px' }}>
                  Site Id /Cust Name:
                </label>
                <input
                  type="text"
                  name="uniqueId"
                  className="form-control a1"
                  id="uniqueId"
                  value={uniqueId}
                  onChange={handleChangeUniqueId}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="col-md-4 d-grid ">
              <div className=" form-group ">
                <button className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
              </div>
            </div>
          </div>

          <div className="row mb-1">

            {/* <div className='col-md-4'>

              <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>

              <select className="form-select border border-dark" defaultValue={customerDetails.li_customer_name} style={{ boxShadow: 'none' }}
                name='customerName'
                id='customerName'

                onChange={handleChangeCustomer}>

                <option value="1" style={{ background: '#dddddd' }}>Select Name</option>

                {selCustomerNames.map(([li_fl_cu_unique_id, li_customer_name]) => (
                  <option key={li_fl_cu_unique_id} value={li_fl_cu_unique_id} style={{ background: '#dddddd' }}>{li_customer_name}</option>
                ))}

              </select>

              {touched.customerName && errors.customerName ? (
                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.customerName}</p>
              ) : null}
              <i className="dropdown-icon fas fa-caret-down"></i>

            </div> */}

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name</label>
                <input type="text" className="form-control a1" defaultValue={customerDetails.li_customer_name} id="customerName"
                  value={customerName}
                  onChange={handleChangeCustomer}
                  onBlur={handleBlur} disabled="true" />

              </div>
            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quatation No</label>
                <input type="text" name='quotationNo' className="form-control a1" defaultValue={customerDetails.li_quotation_no} id="quotationNo"
                  value={quotationNo}
                  onBlur={handleBlur}
                  onChange={handleQuotationNo}
                  disabled="true"
                />
                {/* {touched.quotationNo && errors.quotationNo ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.quotationNo}</p>
                                ) : null} */}
              </div>

            </div>

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="technicianName" className='' style={{ fontSize: '14px' }}>Technician Name</label>
                <input type="text" name='technicianName' className="form-control a1" id="technicianName"
                  value={technicianName}
                  onBlur={handleBlur}
                  onChange={handleTechnicianName}
                />
                {touched.technicianName && errors.technicianName ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.technicianName}</p>
                ) : null}
              </div>
            </div>

          </div>

          <div className="row mb-1">

            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="remark" className='' style={{ fontSize: '14px' }}>Remarks</label>
                <input type="text" name='remark' className="form-control a1" id="remark"
                  value={remark}
                  onBlur={handleBlur}
                  onChange={handleRemark}
                />
                {touched.remark && errors.remark ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.remark}</p>
                ) : null}
              </div>
            </div>


            <div className="col-md-4 ">
              <div className="form-group">
                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No</label>
                <input type="text" name='mobileNo' className="form-control a1" defaultValue={customerDetails.li_mobile} id="mobileNo"
                  value={mobileNo}
                  onBlur={handleBlur}
                  onChange={handleMobileNo} disabled="true" />
                {/* {touched.mobileNo && errors.mobileNo ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.mobileNo}</p>
                                ) : null} */}
              </div>
            </div>

          </div>



          <div className="col-md-12 ">
            <div className="form-group">
              <label htmlFor="preAddress" className='name_label address' style={{ fontSize: '14px' }} >Address</label>
              <textarea className="form-control a1 address_input" name='preAddress' defaultValue={customerDetails.li_address} id="preAddress" rows="5"
                value={preAddress}
                onBlur={handleBlur}
                onChange={handlePreAddress} disabled="true"
              ></textarea>
            </div>
          </div>

          <div className="row mb-5 my-5">
            <div className="col-md-4 ">
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas', style: { border: '1px solid #000' } }}
                ref={sigCanvas}
              />
              <button  type="submit" onClick={clear}>Clear</button>

            </div>
          </div>


          {/* </div> */}


          <div className=' d-flex justify-content-end w-50 float-end mt-4'>

            <button type="submit" className="btn btn-primary mx-3">Save</button>

          </div>
        </form >

      </div >

    </>
  )
}

export default LiftServiceAdd