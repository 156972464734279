import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { modulationPaymentSchema } from '../Schema/ModulationPaymentIndex';


const initialValues = {

    customerName: "",
    payDate: "",
    paidAmt: "",
    paymentMode: "",
    modPayStatus: "",
    followUpDate: "",
    receivedBy: "",
    // remarks: "",
};

const ModulationPaymentAdd = () => {

    const navigate = useNavigate();

    const [selCustomerNames, setSelCustomerNames] = useState([]);
    const [uniqueId, setUniqueId] = useState("");
    const [payDate, setPayDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [previousBal, setPreviousBal] = useState(0);
    const [paidAmt, setPaidAmt] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [paymentMode, setPaymentMode] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [siteAddress, setSiteAddress] = useState("");
    const [modPayStatus, setModPayStatus] = useState("");
    const [remarks, setRemarks] = useState("");
    const [receivedBy, setReceivedBy] = useState("");
    const [followUpDate, setFollowUpDate] = useState("");
    const [customerNameSuggestions, setCustomerNameSuggestions] = useState([]);
    const [customerNameList, setCustomerNameList] = useState([]);

    const [customerDetails, setCustomerDetails] = useState({
        gstNo: '',
        panNo: '',
        inputEmail: '',
        mobileNo: '',
        preAddress: ''
    });


    function openLiftPayment() {
        navigate("/app/lift_payment")
    }

    function openAmcPayment() {
        navigate("/app/amc_payment")
    }

    function openModulePayment() {
        navigate("/app/modulation_payment")
    }

    function openOther() {
        navigate("/app/other_payment")
    }

   

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: modulationPaymentSchema,
            onSubmit: async (values, action) => {
                console.log("values:", values);
                await addModPaymentData()
                // action.resetForm();
                navigate("/app/modulation_paid_data")
            },
        });
    console.log(errors);



    const handleChangeUniqueId = (event) => {

        setUniqueId(event.target.value);
        handleChange(event);
    }
    const handlePreviousLiftAmt = (event) => {

        setPreviousBal(event.target.value);
        handleChange(event);
    }


    const handlePayDate = (event) => {

        setPayDate(event.target.value);
        handleChange(event);
    }


    const handlePaymentMode = (event) => {

        setPaymentMode(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {

        setMobileNo(event.target.value);
        handleChange(event);
    }

    const handleAddress = (event) => {

        setSiteAddress(event.target.value);
        handleChange(event);
    }


    const handlePaidAmt = (event) => {
        // console.log("On Change");

        setPaidAmt(event.target.value);
        handleChange(event);
        const newTotalBalance = parseInt(previousBal || 0) - parseInt(event.target.value || 0);
        setTotalBalance(newTotalBalance);
    }


    const handleTotalBalance = (event) => {
        // console.log("On Change");

        setTotalBalance(event.target.value);
        // handleChange(event);
    }


    const handleModPayStatus = (event) => {

        setModPayStatus(event.target.value);
        handleChange(event);
    }

    const handleRemarks = (event) => {

        setRemarks(event.target.value);
        handleChange(event);
    }

    const handleReceivedBy = (event) => {

        setReceivedBy(event.target.value);
        handleChange(event);
    }


    const handleFollowDate = (event) => {

        setFollowUpDate(event.target.value);
        handleChange(event);
    }

    //// handle onclcik function to calculate amount//////////////////////////

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            console.log('Enter key pressed');
            console.log('Previous Balance:', previousBal);
            console.log('Paid Amount:', paidAmt);

            const newTotalBalance = previousBal - paidAmt;
            console.log('New Total Balance:', newTotalBalance);

            setTotalBalance(newTotalBalance);
        }
    };


    //////////////////////////////////// api call details are below////////////////////////////

    // useEffect(() => {
    //     getData();

    // }, []);



    // function getData() {

    //     async function fetchCustomerNames() {
    //         try {
    //             const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_new_mod_name');
    //             console.log("response:", response);
    //             if (!response.ok) {
    //                 throw new Error('Failed to fetch customer names.');
    //             }

    //             const data = await response.json();
    //             console.log("data:", data);
    //             const customerArray = Object.entries(data);
    //             console.log("customerArray:", customerArray);
    //             setSelCustomerNames(customerArray);
    //         } catch (error) {
    //             console.error('Error fetching customer names:', error);
    //         }
    //     }

    //     fetchCustomerNames();
    // }



    // const handleChangeCustomer = async (event) => {
    //     const selectedCustomerId = event.target.value;
    //     handleChange(event);

    //     setCustomerName(event.target.options[event.target.selectedIndex].text);



    //     if (selectedCustomerId) {
    //         // console.log('Selected customer ID:', selectedCustomerId)
    //         try {
    //             const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_new_mod_name/${selectedCustomerId}`);

    //             if (!response.ok) {
    //                 throw new Error('Failed to fetch customer details.');
    //             }

    //             const data1 = await response.json();
    //             console.log("selected", data1);

    //             setCustomerDetails(data1);
    //             setUniqueId(data1.mod_unique_id)
    //             setPreviousBal(data1.mod_total_bal)
    //             setMobileNo(data1.mod_mob_no)
    //             setSiteAddress(data1.mod_address)
    //             console.log('Customer details:', data1)
    //         } catch (error) {
    //             console.error('Error fetching customer details:', error);
    //         }
    //     }
    //     else {
    //         setCustomerDetails({

    //         });
    //     }
    // }

    ////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const fetchCustomerNames = async () => {
        try {
            const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_newmod_cu_name');
            const data = await response.json();
            setCustomerNameList(data); // Set the list of customer names in state
        } catch (error) {
            console.error('Error fetching customer names:', error);
        }
    };

    // Call the fetch function in a useEffect hook
    useEffect(() => {
        fetchCustomerNames();
    }, []);


    const handleChangeCustomer = (e) => {
        const { value } = e.target;
        const updatedSuggestions = customerNameList.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setCustomerName(value);
        setCustomerNameSuggestions(updatedSuggestions);
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////

    const handleSearchClick = async () => {
        try {
            let response;
            if (uniqueId) {
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_mod_cust_data_by_id/${uniqueId}`);
            } else if (customerName) {
                const formattedCustomerName = customerName.toLowerCase();
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_mod_cust_data_by_name/${formattedCustomerName}}`);
            }

            const data1 = await response.json();

            if (data1.message) {
                alert(data1.message);
            } else {
                console.log('data', data1);
                setCustomerDetails(data1);

                setUniqueId(data1.mod_unique_id)
                setPreviousBal(data1.mod_total_bal)
                setMobileNo(data1.mod_mob_no)
                setSiteAddress(data1.mod_address)
                setCustomerName(data1.mod_cu_name)

                console.log('Customer details:', data1)

            }
        } catch (error) {
            console.error('Error fetching lift quotation data:', error);
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////

    async function addModPaymentData() {
        // console.log(name,file,price,description)
        const formData = new FormData();
        formData.append("uniqueId", uniqueId)
        formData.append("payDate", payDate)
        formData.append("customerName", customerName)
        formData.append("previousBal", previousBal)
        formData.append("paidAmt", paidAmt)
        formData.append("totalBalance", totalBalance)
        formData.append("mobileNo", mobileNo)
        formData.append("siteAddress", siteAddress)
        formData.append("modPayStatus", modPayStatus)
        formData.append("paymentMode", paymentMode)
        formData.append("remarks", remarks)
        formData.append("receivedBy", receivedBy)
        formData.append("followUpDate", followUpDate)


        try {
            let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/store_mod_payment_data', {
                method: 'POST',
                body: formData
            });

            let data = await response.json(); // Parse the response body as JSON

            console.log("api data", data);

            if (data.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data.message);

            } else {
                alert("An error occurred while saving the data.");
            }


        } catch (error) {
            console.error('Error while adding customer:', error);
            alert("An error occurred while saving the data2.");

        }
    }


    return (
        <>

            <div className='row d-flex justify-content-around'>
                <button onClick={openLiftPayment} className='btn btn-primary col-md-2 mt-4'>Lift Payment</button>
                <button onClick={openAmcPayment} className='btn btn-primary col-md-2 mt-4'>AMC Payment</button>
                <button onClick={openModulePayment} className='btn btn-primary col-md-2 mt-4'>Modulation Payment</button>
                <button onClick={openOther} className='btn btn-primary col-md-2 mt-4'>Other Pending Payment</button>
            </div>

            <div className='container'>

                <form onSubmit={handleSubmit}>


                    <div className="row mb-1 mt-5">
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Modulation Recieve Payment</h4>
                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '12px' }}>Job Id</label>
                                <input type="text" className="form-control a1" name="uniqueId" id="uniqueId" defaultValue={customerDetails.mod_unique_id}
                                    disabled="true"
                                    // value={uniqueId}
                                    onChange={handleChangeUniqueId}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="payDate" className='' style={{ fontSize: '12px' }}>Date</label>
                                <input type="date" className="form-control a1" id="payDate" name="payDate"
                                    value={payDate}
                                    onChange={handlePayDate}
                                    onBlur={handleBlur}
                                />
                                {touched.payDate && errors.payDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.payDate}</p>
                                ) : null}

                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-1">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="uniqueId" style={{ fontSize: '14px' }}>
                                    Cust Id /Cust Name:
                                </label>
                                <input
                                    type="text"
                                    name="uniqueId"
                                    className="form-control a1"
                                    id="uniqueId"
                                    value={uniqueId}
                                    onChange={handleChangeUniqueId}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 d-grid ">
                            <div className=" form-group ">
                                <button className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-1">

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="customerName" style={{ fontSize: '14px' }}>
                                    Customer Name:
                                </label>
                                <input
                                    type="text"
                                    name="customerName"
                                    className="form-control a1"
                                    id="customerName" defaultValue={customerDetails.mod_cu_name}
                                    // value={customerName}
                                    onChange={handleChangeCustomer}
                                    onBlur={handleBlur} disabled="true"
                                />

                            </div>
                        </div>

                        {/* <div className='col-md-4'>

                            <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>

                            <select className="form-select border border-dark" defaultValue={customerDetails.cu_name} style={{ boxShadow: 'none' }}
                                name='customerName'
                                id='customerName'
                                // value={customerName}
                                onChange={handleChangeCustomer}
                            >

                                <option value="1" style={{ background: '#dddddd' }}>Select Name</option>

                                {selCustomerNames.map(([cu_unique_id, cu_name]) => (
                                    <option key={cu_unique_id} value={cu_unique_id} style={{ background: '#dddddd' }}>{cu_name}</option>
                                ))}

                            </select>

                            {touched.customerName && errors.customerName ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.customerName}</p>
                            ) : null}
                            <i className="dropdown-icon fas fa-caret-down"></i>

                            <div>

                            </div>
                        </div> */}

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '12px' }}>Mobile No</label>
                                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo" defaultValue={customerDetails.mod_mob_no}
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur} disabled="true"

                                />

                            </div>
                        </div>



                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="paymentMode" className='' style={{ fontSize: '12px' }}>Payment Mode</label>
                                <input type="text" name='paymentMode' className="form-control a1" id="paymentMode"

                                    value={paymentMode}
                                    onChange={handlePaymentMode}
                                    onBlur={handleBlur}
                                />
                                {touched.paymentMode && errors.paymentMode ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.paymentMode}</p>
                                ) : null}


                            </div>
                        </div>

                    </div>



                    <div className="row mb-1">


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="previousBal" className='' style={{ fontSize: '12px' }}>Previous Balance</label>
                                <input type="text" name='previousBal' className="form-control a1" defaultValue={customerDetails.mod_total_bal} id="previousBal"
                                    disabled="true"
                                    value={previousBal}
                                    onChange={handlePreviousLiftAmt}
                                    onBlur={handleBlur}
                                />

                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="paidAmt" className='' style={{ fontSize: '12px' }}>Paid Amount</label>
                                <input type="text" name='paidAmt' className="form-control a1" id="paidAmt"
                                    value={paidAmt}
                                    onChange={handlePaidAmt}

                                    onKeyDown={handleKeyDown}
                                />

                                {touched.paidAmt && errors.paidAmt ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.paidAmt}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="totalBalance" className='' style={{ fontSize: '12px' }}>Total</label>
                                <input type="text" name='totalBalance' className="form-control a1" id="totalBalance"
                                    value={totalBalance}
                                    onChange={handleTotalBalance}
                                    onBlur={handleBlur}

                                />

                            </div>
                        </div>

                    </div>

                    <div className="row mb-1">

                        <div className='col-md-4'>

                            <label htmlFor="modPayStatus" className='' style={{ fontSize: '14px' }}>Status</label>

                            <select className="form-select border border-dark" aria-label="Default select example"
                                name='modPayStatus'
                                id='modPayStatus'
                                value={modPayStatus}
                                onChange={handleModPayStatus}
                                onBlur={handleBlur}>
                                <option selected style={{ dispaly: 'none' }}></option>
                                <option value="Payment Follow" style={{ background: '#dddddd' }}>Payment Follow</option>
                                <option value="Work In Process" style={{ background: '#dddddd' }}>Work In Process</option>
                                <option value="Issue" style={{ background: '#dddddd' }}>Issue</option>
                                <option value="Completed" style={{ background: '#dddddd' }}>Completed</option>
                                <option value="Take Advance" style={{ background: '#dddddd' }}>Take Advance</option>


                            </select>

                            {touched.status && errors.status ? (
                                <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.status}</p>
                            ) : null}
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="receivedBy" className='' style={{ fontSize: '12px' }}>Received by</label>
                                <input type="text" name='receivedBy' className="form-control a1" id="receivedBy"
                                    value={receivedBy}
                                    onChange={handleReceivedBy}
                                    onBlur={handleBlur}

                                />
                                {touched.receivedBy && errors.receivedBy ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.receivedBy}</p>
                                ) : null}

                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="remarks" className='' style={{ fontSize: '12px' }}>Remarks</label>
                                <input type="text" name='remarks' className="form-control a1" id="remarks"
                                    value={remarks}
                                    onChange={handleRemarks}
                                    onBlur={handleBlur}

                                />
                                {/* {touched.remarks && errors.remarks ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.remarks}</p>
                                ) : null} */}

                            </div>
                        </div>
                    </div>

                    <div className='row mb-1'>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="followUpDate" className='' style={{ fontSize: '12px' }}>Follow Up Date</label>
                                <input type="date" className="form-control a1" id="followUpDate" name="followUpDate"
                                    value={followUpDate}
                                    onChange={handleFollowDate}
                                    onBlur={handleBlur}
                                />
                                {/* {touched.followUpDate && errors.followUpDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.followUpDate}</p>
                                ) : null} */}


                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-12 ">
                            <div className="form-group">
                                <label htmlFor="siteAddress" className="form-label">Address</label>
                                <textarea className="form-control a1" name="siteAddress" id="siteAddress" rows="5" defaultValue={customerDetails.mod_address}
                                    value={siteAddress}
                                    onChange={handleAddress}
                                    onBlur={handleBlur} ></textarea>

                            </div>
                        </div>

                    </div>

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                        {/* <button type="button" className="btn btn-primary mx-2 ms-5 ">Preview</button> */}
                        {/* <button type="button" className="btn btn-primary mx-3">Print</button> */}
                        <button type="submit" className="btn btn-primary mx-3">Save</button>
                    </div>

                </form>

            </div>


        </>
    )
}

export default ModulationPaymentAdd