import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import { Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';



const QuotationPrint = () => {
  const navigate = useNavigate();
  const componentPDF = useRef();
  const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState(false);
  const location = useLocation();
  const { customerName, invoiceDate, quotationNo, uniqueId, mobileNo, preAddress, subject, toBeInstalledAt, requireTo, liftType,
    capacity, powerPackLocationNo, motorMachine, ropes, speed, controller, drive, signals, emergencySignals,
    liftTravel, landingAndOpening, stops, carEncloser, flooring, doorType, landingDoor, powerSupply, note, mmBrand,
    ropesBrand, controllerBrand, doorTypeBrand, landingDoorBrand, gst,amtToWord, description,
    quotationAmt } = location.state;


  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Userdata",

  });

  ///////////////////////////////////////////////////////////////////

  const previousPage = () => {
    navigate("/app/lift_data")
  }


  return (
    <>

      <div ref={componentPDF} style={{  color: "black" }}>

        <div className='d-flex justify-content-center' >

          <div className='' style={{ width: "90%", color: "black", }}>
            <div className=' row'>

              <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

           
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com</p>
                
              </div>


              <div className='d-flex  justify-content-between'>
                <p className='mt-3 me-4 ' >Job Id:{uniqueId} 
                  {/* Quotation No:{quotationNo} */}
                </p>
                <p className='mt-3 me-4'>Quot No:{quotationNo}</p>
              </div>

             


              <div className=' mb-2'><h6>{invoiceDate}</h6></div>


              <div className='mb-4' style={{ fontFamily: "serif" }}><h4 className='' ><strong style={{ fontSize: "20px" }}>To,</strong></h4></div>

              <div className='mb-4 d-flex  justify-content-end' style={{ fontFamily: "serif" }}>
              <div className='' style={{width:"85%",marginLeft:"",textAlign:"justify"}}><h4 className=''><strong style={{ textTransform: "capitalize", fontSize: "20px", }}>{customerName}</strong></h4></div></div>

              <div className='mb-4 ' style={{ fontFamily: "serif" }}><h4 className=''><strong style={{ textTransform: "capitalize", fontSize: "20px" }} >Project at – {preAddress}</strong></h4></div>

              <div style={{ fontFamily: "serif" }}><h4 className=''><strong style={{ fontSize: "20px" }}>Dear sir</strong></h4></div>

              <div className='mb-4 d-flex justify-content-end  ' style={{ fontFamily: "serif" }}><div className=' test-start' style={{width:"85%",marginLeft:""}}> <h4 className=' '><strong className=' text-start' style={{ textTransform: "capitalize", fontSize: "20px",marginLeft:"",textAlign:"justify" }}>Sub: {subject} .</strong></h4></div></div>

              <div >
                <p className='mb-4'style={{textAlign:"justify"}}  >Further to the discussion had with you, we are pleased to submit our most competitive offer for the above work in the accompanying folder.</p>

                <p className='mb-4' style={{textAlign:"justify"}}>Our offer is valid for acceptance for a period of Thirty (30) days for its issue.</p>

                <p className='mb-4 ' style={{textAlign:"justify"}}>We trust that you will find our offer to your Entire satisfaction and you will consider it favorably. For any further details/ clarification, please do not hesitate to contact/ refer to us.</p>
              </div>


              <div className='mb-5' style={{ fontFamily: "serif", width: "80%" }}><h4><strong>Thanking you and assuring you our best services at all times, we remain.</strong></h4></div>

              <div className='mb- mt-5' style={{ width: "90%" }}>
                <div className='text-end me-5 mt-2' style={{ fontFamily: "serif" }}><h4><strong>Yours truly,</strong></h4></div>

                <div className='text-end ' style={{ fontFamily: "serif" }}><h4><strong>For  Je-N Elevators.</strong></h4></div>

              </div>




            </div>

            </div>

         

        </div><br /> <br /> <br /> <br />

        {/* /////////////////////////////////// Second page//////////////////////////// */}


        <div className=' d-flex justify-content-center align-items-center  mb-4  '>
          <div style={{width:"90%"}}>
          <div className='row'>

          <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              <div></div>
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com/www.jen.elevator.com</p>
                
              </div>

            <div className='text-center mt-2'><h5>SPECIFICATIONS:</h5></div>
            <div className='d-flex justify-content-center '>

              <Table className="table table-bordered mb-" style={{ width: "90%", border: "1px solid black" }}>
                <thead>
                  <tr>
                    <th scope="col" className='text-center' style={{ padding: "3px",width:"10px" }}>1</th>
                    <th scope="col" style={{ fontSize: "15px", padding: "3px",width:"30px" }}>REQUIRE TO</th>
                    <th scope="col" style={{ textTransform: "capitalize", padding: "px" ,width:"60px"}}>{customerName}</th>
                  </tr>
                </thead>
                <tbody>


                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>2</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>TO BE INSTALLED AT</th>
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>{toBeInstalledAt}</td>
                  </tr>
                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>3</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>TYPES OF LIFT</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{liftType}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {liftType}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>4</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>CAPACITY</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{capacity}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {capacity}
                    </td>
                  </tr>

                  {/* <tr>
                    <th scope="row">5</th>
                    <td>MACHINE LOCATION</td>
                    <td style={{ textTransform: "capitalize" }}>{powerPackLocationNo}</td>
                  </tr> */}

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>5</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>MOTOR MACHINE</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{motorMachine}{mmBrand}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {motorMachine}{mmBrand}
                    </td>

                  </tr>


                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>6</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>ROPES</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{ropes}{ropesBrand}</td> */}
                    {ropes}{ropesBrand}

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>7</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>SPEED</th>
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {speed}
                    </td>

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>8</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>CONTROLLER</th>
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {controller}{controllerBrand}
                    </td>

                  </tr>
                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>9</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>DRIVE</th>
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>VVVF Drive(V3F) ({drive})</td>

                  </tr>
                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>10</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>SIGNAL</th>
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>{signals}</td>

                  </tr>
                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>11</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>EMERGENCY SIGNAL’S</th>
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>{emergencySignals}</td>

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>12</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>TRAVEL</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{liftTravel}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {liftTravel}
                    </td>


                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>13</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>LANDINGS & OPENINGS</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{landingAndOpening}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {landingAndOpening}
                    </td>

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>14</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>STOPS</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{stops}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {stops}
                    </td>

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>15</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>CAR ENCLOSER</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{carEncloser}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {carEncloser}
                    </td>

                  </tr>

                  <tr>
                    <th scope="row"className='text-center'  style={{ padding: "3px" }}>16</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>FLOORING</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{flooring}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {flooring}
                    </td>

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>17</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>CAR DOOR</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{doorType}{doorTypeBrand}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>

                    {doorType}{doorTypeBrand}
                    </td>

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>18</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>LANDING DOOR</th>
                    {/* <td style={{ textTransform: "capitalize" }}>{landingDoor}{landingDoorBrand}</td> */}
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>
                    {landingDoor}{landingDoorBrand}
                     
                    </td>

                  </tr>

                  <tr>
                    <th scope="row" className='text-center'  style={{ padding: "3px" }}>19</th>
                    <th style={{ fontSize: "15px", padding: "3px" }}>POWER SUPPLY</th>
                    <td style={{ textTransform: "capitalize", fontSize: "15px", padding: "3px" }}>{powerSupply}</td>

                  </tr>


                </tbody>

              </Table>

            </div>


          
          </div>
          </div>
        </div>
        <br /> <br /> <br /> 



        {/* /////////////////////////////////// Third page//////////////////////////// */}

              


        <div className='container-fluid d-flex justify-content-center align-items-center mt-4  '>
        <div style={{width:"90%"}}>
          <div className='row'>

         
            <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              <div></div>
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com/www.jen.elevator.com</p>
                
              </div>


            <div className=''><h6>{invoiceDate}</h6></div>

            <div className='text-center '><h5>COMMERCIAL OFFER:</h5></div>




            <div className='d-flex justify-content-center '>


              <Table className="table table-bordered " style={{ width: "100%", border: "1px solid black" ,marginBottom:""}}>
                <thead>
                  <tr>
                    <th scope="col" style={{padding:"3px"}}>No.</th>
                    <th scope="col" style={{padding:"3px"}} >DESCRIPTION</th>
                    <th scope="col" style={{padding:"3px"}}>TOTAL PRICE</th>




                  </tr>
                </thead>
                <tbody>


                  <tr>
                    <th scope="row" style={{padding:"3px"}}>{quotationNo}</th>
                    <td style={{ textTransform: "capitalize",padding:"3px" }} >{description}</td>
                    <td style={{padding:"3px"}}>Rs {quotationAmt}/-  {gst} <br /> ({amtToWord})</td>


                  </tr>


                </tbody>

              </Table>


            </div>

            <div className='mt-3  ' style={{padding:""}}><strong  className=' '>Note- {note} </strong></div>

            <div className=' d-flex justify-content-center  ' >
              <div className='  ' style={{}}><p className=' m-0'><span className=' m-0' style={{ fontSize: "15px", fontWeight: "500" }}><strong className=' m-0'>Terms of payment :</strong></span> From the date you confirm your valued order till completion of work, the mode of payment of the contract will be as follows.</p></div>
            </div>

            <div className='d-flex  justify-content-center '>
              <ol className=' m-0'>
                <li style={{fontSize:"15px"}}>60% of the contract value as an advance along with work order.</li>
                <li style={{fontSize:"15px"}}>30% of the contract amount against delivery of the materials at site.</li>
                <li className='' style={{fontSize:"15px",marginBottom:"none"}}>10% of the contractor value against handing over the lift commissioning & For Next Process of Electrical permission.</li>

              </ol>
            </div>

            <div className=' d-flex align-items-center  mt-1 ' style={{ flexDirection: "column" }} >
              <div className='' style={{}}><p style={{fontSize:"14px",textAlign:"justify"}}><span style={{ fontSize: "15px", fontWeight: "500" }}><strong>DELIVERY</strong></span> Material will be delivered at site within 4 weeks ex-works from the date receipt of layout and drawings duly approved by you. We will not be bound to deliver unless & until payments are made as per agreed terms..</p></div>



              <div className='' style={{}}><p style={{fontSize:"14px",textAlign:"justify"}}><span style={{ fontSize: "15px", fontWeight: "500" }}><strong>ERECTION</strong></span> the erection of the lift will take 4 weeks for each lift after the lift well constructed by you is ready in all respect with scaffolding as per our requirements with power supply to unable our staff to work any hindrance. You should furnish us within 1 week (or sooner if required) from the date of acceptance of this proposal, all required data’s building & shaft drawing for the performance of this contract. You approve our drawings within a week from the date of submitting the same.</p></div>



              <div className='' style={{}}><p className=' m-0' style={{fontSize:"14px",textAlign:"justify"}}><span className=' m-0' style={{ fontSize: "15px", fontWeight: "500" }}><strong className=' m-0' style={{}}>WARRANTY</strong></span> Twelve Months (12) months from the date of commissioning. As per company’s rules & conditions and the buyer agreements that no third party will be allowed to interfere with the installation or its part in any manor during the said period. We guarantee our materials & workmanship for a period of Twelve Months after the lifts is put in operation but any damages to our materials during the storage period at you site is not guaranteed. Any defects / faults arising out of our materials & workmanship during normal operations & proper use will be correct & made good or the complete replacement of components/ part will be done in our opinion.</p></div>

            </div>

            <div className='    mt-5 '><p className='' style={{fontSize:"18px"}}>Agreed to the above terms & conditions.</p></div>


            <div className='d-flex justify-content-between mt'>
              <div><p style={{fontSize:"18px"}}>For and Behalf of Purchaser</p></div>
              <div><h4>For, Je-N Elevators</h4></div>
            </div>

          </div>
          </div>
        </div> <br /> <br />

                {/* /////////////////////////////////// Fourth page//////////////////////////// */}


        <div className='container-fluid d-flex justify-content-center align-items-center mt-'>
        <div style={{width:"90%"}} className=''>
          <div className='row'>
            
          <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              <div></div>
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com/www.jen.elevator.com</p>
                
              </div>

            <div className='ms-3'><h6>{invoiceDate}</h6></div>

            <div className='text-center mt-4'><h4>THE FOLLOWING WILL BE PROVIDED BY YOU AT YOUR COST</h4></div>

            <div className='d-flex  justify-content-center'>
              <ol >
                <li style={{textAlign:"justify"}}>A machine room above the lift well as per our drawing properly ventilated & lighted.</li>
                <li style={{textAlign:"justify"}}>The lift shaft be in reasonable plumb & completed in all respect.</li>
                <li style={{textAlign:"justify"}}>The lift pit should be waterproof with necessary concreting done & its depth should be as per our Drawing.</li>
                <li style={{textAlign:"justify"}}>The machine room slab should be cast incorporation the pockets as shown in our drawing.</li>
                <li style={{textAlign:"justify"}}>I-Beams in the machine room for the installation as per drawing.</li>
                <li style={{textAlign:"justify"}}>Enclosure around the lift shaft if necessary.</li>
                <li style={{textAlign:"justify"}}>Suitable weatherproof storage room with locking arrangement preferable at the ground floor level near the lift well for the safe custody of our materials.</li>
                <li style={{textAlign:"justify"}}>All painting except Je-N Elevators materials.</li>
                <li style={{textAlign:"justify"}}>Carrying out all minor Builders work as per requirements of our site engineer/ erector inclusive of Grouting I- beams, making pockets for landing boxes etc.</li>
                <li style={{textAlign:"justify"}}>To do all cutting of walls, floors or partition together with any repairs made necessary thereby.</li>
                <li style={{textAlign:"justify"}}>You have to complete your necessary work & running of mains obtaining power so as not cause any delay in the completion of our work.</li>
                <li style={{textAlign:"justify"}}>Scaffolding in the lift well as per our requirements.</li>
                <li style={{textAlign:"justify"}}>You should provide in the machine room there phase & single-phase power supply with light arrangements with suitable switches & starters as per <br /> requirements. All conductors should be copper 4-core armored cable (for three phase power supply).</li>
                <li style={{textAlign:"justify"}}>Lift shaft should 3 pin plug point, bulb holder & switches at every landing’s.</li>
              </ol>
            </div>


            <div className=' w-50  ms-1 mt-2  '><h5 className=' m-0'>I/We Confirm the Above.</h5></div>


            <div className='d-flex justify-content-between mt-5 mb-'>
              <div className='mt-3'><h4>For and Behalf of Purchaser</h4></div>
              <div className='mt-3'><h4><strong>For, Je-N Elevators</strong></h4></div>
            </div>



            </div>

          </div>


          </div>



        </div>

        <div className='mt-4 float-end'>
          <button type="button" onClick={previousPage} className="btn btn-primary mx-2 ms-5"  >Cancel</button>
          <button className='btn btn-primary' onClick={generatePDF} style={{ width: "100px" }} disabled={pdfGenerationInProgress} >Ok</button><br /><br /><br /><br />

        </div>








      </>
      )
}

      export default QuotationPrint