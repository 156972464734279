import React from 'react'
import { useNavigate } from 'react-router-dom'

const Service = () => {


    const navigate = useNavigate();
    function openReceivePayment() {
  
      navigate("/app/service_types")
    }
  
    function openPaidLift() {
  
      navigate("/app/lift_service_data")
    }
  
    function openPaidAmc() {
  
      navigate("/app/amc_service_data")
  
    }
  
    function openCallBase(){
  
      navigate("/app/callbase_service_data")
    }


  return (
    <>
    
    

    <div className=" ">
        <h5>Service Details</h5>
        <hr />
        <button onClick={openReceivePayment} className="btn btn-primary buttonMargin col-2 d-grip w-auto">Add Service</button>
      </div>

      <div className='conatiner'>


      <div className='row d-flex justify-content-around'>
        <button onClick={openPaidLift} className='btn btn-primary col-md-3 mt-4'>Free Service Data</button>
        <button onClick={openPaidAmc} className='btn btn-primary col-md-3 mt-4'>AMC Service Data</button>
        <button onClick={openCallBase} className='btn btn-primary col-md-3 mt-4'>Callbase Service Data</button>
        {/* <button onClick={openPaidModulation} className='btn btn-primary col-3 mt-4'>Modulation Service Data</button> */}
      </div>

      </div>
    
    </>
  )
}

export default Service