import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap'
import { updateModulationQuotationSchema } from '../Schema/UpdateModulationQuotationIndex';



const initialValues = {

    modulationQuotationNo: "",
    customerName: "",
    mobileNo: "",
    liftType: "",
    panNo: "",
    gstNo: "",
    stops: "",
    perticular: "",
    make: "",
    quantity: "",
    rate: "",
    quoDate: "",
    inputEmail: "",
};

const UpdateModulationQuotationAdd = () => {


    const navigate = useNavigate();

    const { id } = useParams();
    // const { uniqueId } = useParams();

    const [data, setData] = useState([])
    const [moduParticulars, setModuParticulars] = useState([]);

    const [tableData, setTableData] = useState([]);

    const [tablevalues, setTablevalues] = useState({
        perticular: '',
        make: '',
        quantity: '',
        unite_rate: '',
        isEditable: false, // Initialize as false
    });

    const [formData, setFormData] = useState(initialValues);

    const [editMode, setEditMode] = useState(false); // Example state for edit mode

    const [selCustomerNames, setSelCustomerNames] = useState([]);

    const [uniqueId, setUniqueId] = useState("");
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [modulationQuotationNo, setModulationQuotationNo] = useState("");
    const [liftType, setLiftType] = useState("");
    const [stops, setStops] = useState("");
    const [perticular, setPerticular] = useState("");
    const [make, setMake] = useState("");
    const [quantity, setQuantity] = useState("");
    const [rate, setRate] = useState("");
    const [moduDate, setModuDate] = useState("");
    // const [previousModAmt, setPreviousModAmt] = useState(0);
    const [preAddress, setPreAddress] = useState("");
    const [modWorkStatus, setModWorkStatus] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState("");
    const [grandTotal, setGrandTotal] = useState(0);
    const [callBackDate, setCallBackDate] = useState("");


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: updateModulationQuotationSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                // action.resetForm();
                // navigate("/app/new_Amc")
            },
        });


    console.log(errors);


    ///////////////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////////////////////


    function openAmcAdd() {
        navigate("/app/amc_quotation_add")
    }

    function openAdd() {
        navigate("/app/lift_quotation_add")
    }

    function openModuleAdd() {
        navigate("/app/modulation_quatation_add")
    }

    function saveData() {
        navigate("/app/new_modulation")
    }


    const handleEdit = (id) => {
        const updatedData = data.modu_perticulars.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    isEditable: true
                };
            } else {
                return {
                    ...item,
                    isEditable: false
                };
            }
        });
        // Set the updated data including the isEditable property
        setData({ ...data, modu_perticulars: updatedData });
    };


    const handleInputChange = (e, index, field) => {
        const { value } = e.target;
        setData(prevState => {
            const updatedData = [...prevState.modu_perticulars];
            updatedData[index] = {
                ...updatedData[index],
                [field]: value
            };
            return { ...prevState, modu_perticulars: updatedData };
        });
    };


    const handleDisableEdit = (index) => {
        setData(prevState => {
            const updatedData = [...prevState.modu_perticulars];
            updatedData[index] = {
                ...updatedData[index],
                isEditable: false
            };
            return { ...prevState, modu_perticulars: updatedData };
        });
    };

    const handleDelete = (index) => {
        setData(prevState => {
            const updatedData = prevState.modu_perticulars.filter((item, i) => i !== index);
            return { ...prevState, modu_perticulars: updatedData };
        });
    };








    ////////////////////////////////////////////////////////////


    const handleChangeUniqueId = (event) => {

        console.log(event.target.value)
        setUniqueId(event.target.value);
        handleChange(event);
    }

    // const handlePreModBal = (event) => {

    //     setPreviousModAmt(event.target.value);
    //     handleChange(event);
    // }

    const handleCustomerName = (event) => {

        setCustomerName(event.target.value);
        handleChange(event);
    }

    const handleGstNo = (event) => {
        // console.log("On Change");

        setGstNo(event.target.value);
        handleChange(event);
    }
    const handlePanNo = (event) => {
        // console.log("On Change");

        setPanNo(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }

    const handleQuotationNo = (event) => {
        // console.log("On Change");

        setModulationQuotationNo(event.target.value);
        handleChange(event);
    }

    const handlePreAddress = (event) => {
        // console.log("On Change");

        setPreAddress(event.target.value);
        handleChange(event);
    }

    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }

    const handleStops = (event) => {
        // console.log("On Change");

        setStops(event.target.value);
        handleChange(event);
    }

    const handleDate = (event) => {
        // console.log("On Change");

        setModuDate(event.target.value);
        handleChange(event);
    }

    const handleModWorkStatus = (event) => {
        // console.log("On Change");

        setModWorkStatus(event.target.value);
        handleChange(event);
    }

    const handleCallBackDate = (event) => {
        // console.log("On Change");

        setCallBackDate(event.target.value);
        handleChange(event);
    }



    ///////////////////////////////////////////// Api  Call /////////////////////////////////////

    useEffect(() => {

        async function fetchresult() {
            try {
                // const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_quomod_update/${id}`);
                const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/fetch_modulation_quotation_data/${id}`);
                const result = await response.json();
                console.log("result:", result);

                if (result.length > 0) {
                    setData(result[0]);
                    setModuParticulars(result[0].modu_perticulars);
                }

                // setData(result);
                // setModuParticulars(result.modu_perticulars);

                // setUniqueId(result.mod_cu_unique_id)
                // setCustomerName(result.mod_quo_cu_name)
                // setModuDate(result.mod_quo_date)
                // setGstNo(result.mod_quo_cu_gst)
                // setPanNo(result.mod_quo_cu_pan)
                // setMobileNo(result.mod_quo_cu_mob)
                // setPreAddress(result.mod_quo_cu_address)
                // setLiftType(result.mod_quo_lift_type)
                // setStops(result.mod_quo_stops)
                // setModulationQuotationNo(result.mod_quo_cu_quono)
                // setModWorkStatus(result.mod_quo_work_status)
                // setCallBackDate(result.mod_call_back_date)



            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchresult();

    }, [id]);


    ///////////////////// Api to enquiry updated data Store Api  ///////////////////////////////////////////

    async function storeUpdateQuoModData(mod_cu_unique_id) {

        console.log('ID:', mod_cu_unique_id); 
        console.log("customerName:", customerName);

        const formData = new FormData();
        console.log("moduDate:", moduDate);
        console.log("uniqueId:", uniqueId);
        console.log("customerName:", customerName);
        formData.append("moduDate", moduDate)
        formData.append("uniqueId", uniqueId)
        formData.append("customerName", customerName)
        formData.append("panNo", panNo)
        formData.append("gstNo", gstNo)
        formData.append("mobileNo", mobileNo)
        formData.append("modulationQuotationNo", modulationQuotationNo)
        formData.append("preAddress", preAddress)
        formData.append("liftType", liftType)
        formData.append("stops", stops)
        formData.append("modWorkStatus", modWorkStatus)
        formData.append("callBackDate", callBackDate)
        formData.append("tableData", JSON.stringify(data.modu_perticulars));

        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        try {
            let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/mod_quo_update_store/' + mod_cu_unique_id + '?method=PUT', {
                method: 'POST',
                body: formData
            });

            let data1 = await response.json(); 

            if (data1.message) {
                
                alert(data1.message);
                


            } else {
                alert("An error occurred while saving the data.");
            }


        } catch (error) {
            console.error('Error while adding customer:', error);
            alert("An error occurred while saving the data2.");

        }
    }

    return (
        <>

            <div className='row d-flex justify-content-around'>
                <button onClick={openAdd} className='btn btn-primary col-md-3 mt-4'>Lift Quotation</button>
                <button onClick={openAmcAdd} className='btn btn-primary col-md-3 mt-4'>AMC Quotation</button>
                <button onClick={openModuleAdd} className='btn btn-primary col-md-3 mt-4'>Modulation Quotation</button>
            </div>


            <div className='container'>

                <form onSubmit={handleSubmit}>

                    <div className="row mb-1 mt-5">
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Modulation Quotation</h4>
                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                                <input type="text" className="form-control a1" id="uniqueId" name='uniqueId' defaultValue={data.mod_cu_unique_id}
                                    // value={uniqueId}
                                    onChange={handleChangeUniqueId}
                                    onBlur={handleBlur}
                                    
                                />
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="moduDate" className='' style={{ fontSize: '14px' }}>Date</label>
                                <input type="date" className="form-control a1" id="moduDate" name="moduDate" defaultValue={data.mod_quo_date}
                                    // value={moduDate}
                                    onChange={handleDate}
                                    onBlur={handleBlur}
                                />
                                {touched.moduDate && errors.moduDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.moduDate}</p>
                                ) : null}
                            </div>

                        </div>

                    </div>

                    <hr />

                    <div className="row mb-1">
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Site Id:*</label>
                                <input type="text" name="uniqueId" className="form-control a1" id="uniqueId" defaultValue={data.mod_cu_unique_id}

                                    onChange={handleChangeUniqueId}
                                    onBlur={handleBlur} />
                                {touched.uniqueId && errors.uniqueId ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.uniqueId}</p>
                                ) : null}
                            </div>
                        </div>

                    </div>


                    <div className='row mb-1'>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                                <input type="text" name="customerName" className="form-control a1" id="customerName" defaultValue={data.mod_quo_cu_name}
                                   
                                    onChange={handleCustomerName}
                                    onBlur={handleBlur}  />

                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.*</label>
                                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo" defaultValue={data.mod_quo_cu_mob}

                                    onChange={handleMobileNo}
                                    onBlur={handleBlur}  />
                            </div>
                       </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN No.</label>
                                <input type="text" name='panNo' className="form-control a1" id="panNo" defaultValue={data.mod_quo_cu_pan}
                                    onChange={handlePanNo}
                                    onBlur={handleBlur}  />
                           </div>
                        </div>
                    </div>

                    <div className="row mb-1">
                       <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No.</label>
                                <input type="text" name='gstNo' className="form-control a1" id="gstNo" defaultValue={data.mod_quo_cu_gst}
                               
                                    onChange={handleGstNo}
                                    onBlur={handleBlur}  />

                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="modulationQuotationNo" className='' style={{ fontSize: '14px' }}>Modulation Quotation No.*</label>
                                <input type="text" name='modulationQuotationNo' className="form-control a1" id="modulationQuotationNo" defaultValue={data.mod_quo_cu_quono}
                                   
                                    onChange={handleQuotationNo}
                                    onBlur={handleBlur}  />                             
                            </div>
                        </div>

                        <div className='col-md-4'>

                            <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Mode Type</label>

                            <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='liftType'
                                id='liftType' defaultValue={data.mod_quo_lift_type}
                                // value={liftType}
                                onChange={handleLiftType}
                                onBlur={handleBlur}>
                                <option value="" disabled>Select an Option</option>
                                <option value="I M P Door" style={{ background: '#eaeaea' }}>I M P Door</option>
                                <option value="Swing Door" style={{ background: '#eaeaea' }}>Swing Door</option>
                                <option value="Automatic Door MR" style={{ background: '#eaeaea' }}>Automatic Door MR</option>
                                <option value="Automatic Door MRL" style={{ background: '#eaeaea' }}>Automatic Door MRL</option>
                                <option value="Hydrolic Lift" style={{ background: '#eaeaea' }}>Hydrolic Lift</option>
                                <option value="Good's Lif" style={{ background: '#eaeaea' }}>Good's Lift</option>
                                <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>

                            </select>
                        </div>
                    </div>

                    <div className='row mb-1'>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                                <input type="text" name='stops' className="form-control a1" id="stops" defaultValue={data.mod_quo_stops}
                                    // value={stops}
                                    onChange={handleStops}
                                    onBlur={handleBlur} />                              
                            </div>
                        </div>

                        <div className='col-md-4'>

                            <label htmlFor="modWorkStatus" className='' style={{ fontSize: '14px' }}>Status</label>

                            <select className="form-select border border-dark" aria-label="Default select example" defaultValue={data.mod_quo_work_status}
                                name='modWorkStatus'
                                id='modWorkStatus'
                               
                                onChange={handleModWorkStatus}
                                onBlur={handleBlur}>
                                <option value="">Select an Option</option>
                                <option value="Visit Site" style={{ background: '#dddddd', color: "blue" }}>Visit Site</option>
                                <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                                <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                                <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                                <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                                <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                                <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>

                            </select>                         
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="callBackDate" className='name_label stops' >Follow up Date</label>
                                <input type="date" name='callBackDate' className="form-control a1 stops_input" id="callBackDate"
                                    // value={data.mod_call_back_date || ''} 
                                    defaultValue={data.mod_call_back_date}
                                    onChange={handleCallBackDate}
                                    onBlur={handleBlur} />
                            </div>
                        </div>
                   </div>

                    <div classname="row mb-1">
                        <div className="row mt-4">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                                    <textarea className="form-control a1" name="preAddress" id="preAddress" rows="5"
                                     
                                        value={preAddress}
                                        defaultValue={data.mod_quo_cu_address}
                                        onChange={handlePreAddress}
                                        onBlur={handleBlur} ></textarea>
                                    
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row mb-1'>

                        <div className='col-md-4'>

                            <label htmlFor="termsAndConditions" className='name_label door_type' style={{ fontSize: '14px' }} >Terms and Conditions</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='termsAndConditions'
                                id='termsAndConditions'
                                value={termsAndConditions}
                                // value={data.mod_quo_term_con || ''}
                                defaultValue={data.mod_quo_term_con}
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                <option value="" disabled>Select an Option</option>
                                {/* <option value="80 % Advance Payment" style={{ background: '#eaeaea' }}>80 % Advance Payment</option> */}
                                <option value="GST @18% Extra at Actual" style={{ background: '#eaeaea' }}>GST @18% Extra at Actual</option>
                                {/* <option value="Completed" style={{ background: '#eaeaea' }}>Type Manually</option> */}
                            </select>
                        </div>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <div className='  mt-5 col-md-8'>
                            <Table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col">Sl No</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Particulars</th>
                                        <th scope="col">Make</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Unit Rate</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                             

                                <tbody>
                                    {data.modu_perticulars &&
                                        data.modu_perticulars.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input className="form-check-input" type="checkbox" value="" />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{item.date}</td>
                                                <td>
                                                    {item.isEditable ? (
                                                        <input
                                                            type="text" style={{ width: "80px" }}
                                                            value={item.perticular}
                                                            onChange={(e) => handleInputChange(e, index, 'perticular')} // Implement handleInputChange function
                                                        />
                                                    ) : (
                                                        item.particular
                                                    )}
                                                </td>
                                                <td>
                                                    {item.isEditable ? (
                                                        <input style={{ width: "80px" }}
                                                            type="text"
                                                            value={item.make}
                                                            onChange={(e) => handleInputChange(e, index, 'make')} // Implement handleInputChange function
                                                        />
                                                    ) : (
                                                        item.make
                                                    )}
                                                </td>
                                                <td>
                                                    {item.isEditable ? (
                                                        <input
                                                            type="text" style={{ width: "80px" }}
                                                            value={item.quantity}
                                                            onChange={(e) => handleInputChange(e, index, 'quantity')} // Implement handleInputChange function
                                                        />
                                                    ) : (
                                                        item.quantity
                                                    )}
                                                </td>
                                                <td>
                                                    {item.isEditable ? (
                                                        <input
                                                            type="text" style={{ width: "80px" }}
                                                            value={item.unite_rate}
                                                            onChange={(e) => handleInputChange(e, index, 'unite_rate')} // Implement handleInputChange function
                                                        />
                                                    ) : (
                                                        item.unite_rate
                                                    )}
                                                </td>
                                                <td>{item.quantity * item.unite_rate}</td>

                                                <td>
                                                    {item.isEditable ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-secondary mx-1"
                                                            onClick={() => handleDisableEdit(index)} // Implement handleDisableEdit function
                                                        >
                                                            Disable Edit
                                                        </button>
                                                    ) : (
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary mx-1"
                                                                onClick={() => handleEdit(item.id)} // Implement handleEdit function
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger mx-1"
                                                                onClick={() => handleDelete(index)} // Implement handleDelete function
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}

                                </tbody>

                            </Table>
                        </div>

                    </div>

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>
                        <button onClick={() => storeUpdateQuoModData(data.mod_cu_unique_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>
                    </div>

                </form>
            </div>

        </>
    )
}

export default UpdateModulationQuotationAdd
