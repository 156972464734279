import * as Yup from "yup";


export const updateNewAmcAddSchema = Yup.object({

  amcquotationNo: Yup.string().required("Please enter amc quotation No."),
  invoiceDate: Yup.string().required("Please select date"),
  // amcStartDate: Yup.string().required("Please select amc start date"),
  // amcEndDate: Yup.string().required("Please select amc end date"),
  amcStatus: Yup.string().required("Please Select status"),
  paidAmt: Yup.number().required("Please enter paid amount"),
  // followUpDate: Yup.string().required("Please select follow up date"),

  // customerName: Yup.string().required("Please enter name"),
  // gstNo: Yup.string().required("Please enter your GST No."),
  // panNo: Yup.string().required("Please enter your PAN No."),
  // inputEmail: Yup.string().email().required("Please enter your email address"),
  // mobileNo: Yup.number().integer().positive().required("Please enter mobile number"),
  // amcNo: Yup.string().required("Please enter amc no."), 
  // preAddress: Yup.string().required("Please enter address "),
  // liftType: Yup.string().required("Please Select lift type"),
  // stops:Yup.string().required("Please enter stops "),
  // doorType:Yup.string().required("Please select door type"),
  // make:Yup.string().required("Please enter make "),
  // quantity:Yup.string().required("Please enter quantity "),
  // quotationAmt: Yup.number().integer().positive().required("Please enter quotation amount"),
  previousBal: Yup.number().required("Please enter  previous balance"),

  // totalBalance:Yup.number().integer().positive().required("Please enter total balance"),



});
