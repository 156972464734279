import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';


const ModulationQuotationPrint = () => {


  const navigate = useNavigate();
  const componentPDF = useRef();
  const location = useLocation();

  const { customerName, moduDate, modulationQuotationNo, uniqueId, grandTotal, preAddress, tableData,termsAndConditions,
  } = location.state;

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Userdata",

  });


  /////////////////////////////////////////////////

  const previousPage = () => {
    navigate("/app/modulation_data")
  }


  return (


    <>
      <div className=' d-flex justify-content-center align-items-center ' ref={componentPDF} style={{ color: "black" }} >
        
        <div className='' style={{width:"90%"}} >
        <div className='row   '>

        <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>


              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com</p>
                
              </div>

          <div className='d-flex justify-content-between mb-3 '>
            <div className='me-4' style={{ color: "black" }}>Job Id:{uniqueId}</div>
             <div>Quotation No:{modulationQuotationNo}</div>
          </div>



          <div className='d-flex justify-content-between'>

            <div className=''>

              <div className='ms-5' >To</div>

              <div style={{ color: "black" }} ><h6 className='ms-5'><b>{customerName}</b></h6>
                <h6 className='ms-5' style={{ color: "black" }}><b>{preAddress}</b></h6></div>
            </div>

            <div className='me-5 '>
              <div style={{ color: "black" }}><h6>Date:{moduDate}</h6>
               </div>
            </div>

          </div>

          <div className='text-center mt-3'><p><b>Thank you for your kind enquiry and pleased to quote for the following items</b></p></div>

          <div className='d-flex justify-content-center '>

            <table class="table table-bordered" style={{ width: "90%", border: "1px solid black" }}>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Sl No.</th>
                  <th style={{ width: "45%" }}>DISCRIPTION</th>
                  <th style={{ width: "15%" }}>Qty</th>
                  <th style={{ width: "15%" }}>Rate</th>
                  <th style={{ width: "15%" }}>Amount</th>
                </tr>
              </thead>

              <tbody>
                {tableData.map((row, index) => {

                  // const total = row.quantity * row.rate;
                  // grandTotal = total + grandTotal;
                  // console.log("grandtotal",grandTotal);

                  return (
                    <tr key={index}>

                      {/* <th><input className="form-check-input" type="checkbox" value="" /></th> */}
                      <th scope="row" style={{ color: "black" }}>{row.slNo}</th>
                      <td style={{ color: "black" }}>{row.perticular}</td>
                      <td style={{ color: "black" }}>{row.quantity}</td>
                      <td style={{ color: "black" }}>{row.rate}</td>
                      <td style={{ color: "black" }}>{row.quantity * row.rate}</td>
                    


                    </tr>
                  );

                })}

                <tr>
                  <td></td>
                  <td style={{ color: "black" }} ><strong>Terms & Conditions </strong><br />
                  1: 80% advance payment <br />
                  2: {termsAndConditions} <br />
                </td>
                  <td></td>
                  <td style={{ borderTop: "none" }}><h5>Nett Rs..</h5></td>
                  <td>{grandTotal}</td>
                </tr>


              </tbody>
            </table>

          </div>

          <div className='d-flex justify-content-between'>

          <div className='ms-5' style={{ color: "black" }}>
            <div>
              GST
            </div>
            <div>
              Sales and Service Office
            </div>
            <div>
              Email ID
            </div>
          </div>

          <div><h5><b>For Je-N Elevators</b></h5></div>
          

          </div>


        </div>
      </div>
      </div>

      <div className='mt-4 float-end mb-5'>
        <button type="button" onClick={previousPage} className="btn btn-primary mx-2 ms-5 ">Cancel</button>
        <button className='btn btn-primary ' onClick={generatePDF}>print</button>
      </div>
    </>
  )
}

export default ModulationQuotationPrint