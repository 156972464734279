import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const AmcExpiryPrint = () => {

    const navigate = useNavigate();    
    const componentPDF = useRef();
    const location = useLocation();

    const selectedItem = location.state.selectedItem;

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",

    });

    function backtodata() {
        navigate("/app/amc_expiry");
    }


  return (
    <>
    

      
    <div className='container d-flex justify-content-center align-items-center text-dark ' ref={componentPDF} style={{ width: "60%" }}>
                <div className='row  '>

                <div className=' d-flex  justify-content-between '>
                <div className=' ' ><img src="/img/logo1.png" alt="" className='' style={{}} /></div>
                <div className=' '><img src="/img/jnelevator.png" alt="" /></div>
              </div>

              
              <div className='text-center mt-4 mb-2 ' style={{borderTop:"2px dotted black ",borderBottom:"2px dotted black",color:"black"}}>
                
                <p style={{ margin: "0px",fontSize:"14px",paddingTop:"1px" }}>Shop No.2 Omkar Appt, Opp Harimandir, Angol Road Belgaum-590006.</p>
                <p style={{ margin: "0px",fontSize:"14px",paddingBottom:"1px" }}>Mo +91 9035913295| 8792629106. jnelevators@gmail.com</p>
                
              </div>






                    <div className='text-center mt-2'><h5><b>AMC Expiry:</b></h5></div>




                    <div className='d-flex justify-content-between  mt-4'>
                        <div className='ms-5' style={{textTransform : "capitalize"}} >Cust Id: {selectedItem.amc_fl_cu_unique_id}</div>
                        {/* <div className='me-5' style={{textTransform : "capitalize"}} >Date: {} </div> */}
                    </div><br /><br /><br /><br />

                     <div className='mt-3'>
                     
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Customer Name: {selectedItem.amc_quo_name} </div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Start Date: {selectedItem.amc_quo_start_date} </div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >End Date: {selectedItem.amc_quo_end_date} </div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Total Bal: {selectedItem.amc_quo_total_bal} </div>
                    <div className='ms-5 mb-4' style={{textTransform : "capitalize"}} >Status: {selectedItem.amc_working_status} </div>
                    

                    </div>
                   


                </div>
            </div>


            <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                <button type="button" className="btn btn-primary mx-2 ms-5" onClick={ backtodata }>Cancel</button>
                <button type="button" onClick={generatePDF} style={{ width: "80px" }} className="btn btn-primary mx-3">Ok</button>

            </div>
    
    </>
  )
}

export default AmcExpiryPrint