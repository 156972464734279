import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
// import UpdateIcon from '@mui/icons-material/Update';
import UpdateIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../pagination.css';



const NewAmcMaster = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [searchValue, setSearchValue] = useState('');

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Total number of pages


  function openAdd() {

    navigate("/app/new_amc_add")
  }

  function deleteOperation() {
    alert("Item has been deleted");
  }

  const handleUpdate = (amc_id) => {
    // Navigate two steps back
    navigate(`/app/update_new_amc_add/${amc_id}`);
  };


  function openView(item) {
    // Construct the URL with route parameters
    navigate(`/app/new_amc_view_print/${item.amc_id}`, {
      state: { data: item }, // Pass the data as part of the route state
    });
  }




  /////////////////////////////////// Delete api call////////////////////////////////

  async function deleteOperation(id) {
    // alert(id)
    const confirmDelete = window.confirm('Do you want delete?');
    if (confirmDelete) {
      let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_newamc/" + id, {
        method: 'DELETE'
      });
      result = await result.json();
      // console.log(result)
      if (result.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        if (result.message) {
          // alert(result.message);
          alert('Deletion confirmed. Deleting...');
        } else {
          alert('Deletion cancelled.');
        }

      } else {
        alert("Customer not found.");
      }

      getData();

    }
  }

  /////// api call to get data //////////

  useEffect(() => {

    getData()

  }, [currentPage])

  function getData() {

    async function fetchData() {
      try {
        let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_new_amc_list?page=${currentPage}&perPage=${itemsPerPage}`);
        const { data: fetchedData, last_page: total } = await result.json();
        // console.log("data", result);
        setData(fetchedData);
        setTotalPages(total);
      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchData();

  }


  // Filtering logic
  const filteredData = data.filter(item => {
    const cuIdString = item.amc_fl_cu_unique_id.toString();
    const lowercaseSearch = searchValue.toLowerCase();
    const status = item.amc_working_status.toLowerCase();
    const date = item.amc_quo_date.toLowerCase(); 

    return cuIdString.includes(lowercaseSearch) || item.amc_quo_name.toLowerCase().includes(lowercaseSearch) ||
      status.includes(lowercaseSearch) || 
      date.includes(lowercaseSearch);

  });

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Calculate the range of page numbers to display in the pagination UI
  const getPageRange = () => {
    const rangeSize = 5; // Number of page numbers to display
    const totalPagesInRange = Math.min(rangeSize, totalPages);
    let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
    let endPage = startPage + totalPagesInRange - 1;

    // Adjust endPage if it exceeds totalPages
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - totalPagesInRange + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };





  return (
    <>

      <div className=''>


        <div className="row ">
          <div className='col-md-10 mt-4 mt-md-0'>
            <h5>Final AMC Quotation Details</h5>
          </div>

          <div className='col-md-2'>

            <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
              onChange={e => setSearchValue(e.target.value)} />

          </div>


        </div>

        <hr />

        <button onClick={openAdd} className="btn btn-primary buttonMargin">+Add</button>

        <Table className="table table-bordered ">
          <thead>
            <tr>
              <th scope="col">Sl No</th>
              <th scope="col">Job Id </th>
              <th scope="col">Quo No.</th>
              <th scope="col">Customer name</th>
              <th scope="col">Quo date</th>
              <th scope="col">Mobile No.</th>
              <th scope="col">Address</th>
              {/* <th scope="col">Lift type</th> */}
              <th scope="col">Quantity</th>
              <th scope="col">Quo Amt</th>
              <th scope="col">Status</th>
              <th scope="col">Start date</th>
              <th scope="col">End date</th>

              <th scope="col">Action </th>
              <th></th>



            </tr>
          </thead>
          <tbody>
            {

              filteredData.map((item) =>
                <tr key={item.amc_id}>

                  <td>{item.amc_id}</td>
                  <td>{item.amc_fl_cu_unique_id}</td>
                  <td>{item.amc_quo_final_quo_no}</td>
                  <td>{item.amc_quo_name}</td>
                  <td>{item.amc_quo_date}</td>
                  <td>{item.amc_quo_mob_no}</td>
                  <td>{item.amc_quo_address}</td>

                  <td>{item.amc_quo_quantity}</td>
                  <td>{item.amc_quo_amt}</td>
                  <td>{item.amc_working_status}</td>
                  <td>{item.amc_quo_start_date}</td>
                  <td>{item.amc_quo_end_date}</td>

                  <td>
                    <button
                      type='button'
                      className='btn btn-primary mx-3'
                      onClick={() => openView(item)} // Pass the item data to the openView function
                    >
                      View
                    </button>
                  </td>

                  {/* <td><Link to={"/app/update_new_amc_add"}><span  className='update'>{<UpdateIcon/>}</span></Link><span onClick={()=>{deleteOperation(item.amc_id)}} >{<DeleteIcon style={{color:'red'}}/>}</span></td> */}
                  <td><span className='update' onClick={() => handleUpdate(item.amc_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.amc_fl_cu_unique_id) }} >{<DeleteIcon style={{ color: 'red' }} />}</span></td>
                </tr>
              )
            }
          </tbody>
        </Table>


        {/* Pagination */}
        <div className='pagination'>
          {/* Show previous arrow icon */}
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
          )}

          {/* Show page numbers */}
          {getPageRange().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}

          {/* Show next arrow icon */}
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
          )}

        </div>
      </div>
    </>
  )
}

export default NewAmcMaster