import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap'
import { modulationQuotationSchema } from '../Schema/ModulationQuotationindex';

const initialValues = {

    modulationQuotationNo: "",
    customerName: "",
    mobileNo: "",
    liftType: "",
    panNo: "",
    gstNo: "",
    stops: "",
    perticular: "",
    make: "",
    quantity: "",
    rate: "",
    slNo: '',
    moduDate: '',
    inputEmail: "",
    tableData: [],
    uniqueId: "",
    modWorkStatus: "",
    termsAndConditions: "",
};

const ModulationQuatationAdd = () => {

    const navigate = useNavigate();

    const [tableData, setTableData] = useState([]);
    const [formData, setFormData] = useState(initialValues);

    const [editMode, setEditMode] = useState(false); // Example state for edit mode

    const [selCustomerNames, setSelCustomerNames] = useState([]);

    const [uniqueId, setUniqueId] = useState("");
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [modulationQuotationNo, setModulationQuotationNo] = useState("");
    const [liftType, setLiftType] = useState("");
    const [stops, setStops] = useState("");
    const [perticular, setPerticular] = useState("");
    const [make, setMake] = useState("");
    const [quantity, setQuantity] = useState("");
    const [rate, setRate] = useState("");
    const [moduDate, setModuDate] = useState("");
    // const [previousModAmt, setPreviousModAmt] = useState(0);
    const [preAddress, setPreAddress] = useState("");
    const [modWorkStatus, setModWorkStatus] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState("");
    const [grandTotal, setGrandTotal] = useState(0);
    const [callBackDate, setCallBackDate] = useState("");

    const [editModuDate, setEditModuDate] = useState("");
    const [editPerticular, setEditPerticular] = useState("");
    const [editMake, setEditMake] = useState("");
    const [editQuantity, setEditQuantity] = useState(0);
    const [editRate, setEditRate] = useState("");
    const [editIndex, setEditIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [isEditingGrandTotal, setIsEditingGrandTotal] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        gstNo: '',
        panNo: '',
        mobileNo: ''

    });

////////////////////////////////////// Code for Edit //////////////////////////////////////

    const handleEdit = (index) => {
        const newData = [...tableData];
        newData[index].isEditing = true;
        console.log('Edited Data:', newData[index]); // Log the edited data
        setTableData(newData);
    };


    const handleDisableEdit = (index) => {
        const newData = [...tableData];
        newData[index].isEditing = false;
        setTableData(newData);
    }

        

    // const handleInputChange = (e, index, fieldName) => {
    //     console.log('Function triggered!')
    //     const { value } = e.target;
    //     const newData = [...tableData];

    //     if (fieldName === 'quantity' || fieldName === 'rate') {
    //         const parsedValue = parseInt(value);
    //         if (!isNaN(parsedValue)) {
    //             newData[index][fieldName] = parsedValue;
    //         } else {
    //             // Handle the error here, e.g., set it to 0 or display an error message
    //             newData[index][fieldName] = 0; // Set to 0 as a default value
    //             // You can also display an error message or take other actions here
    //         }
    //     } else {
    //         newData[index][fieldName] = value;
    //     }
    //     console.log('Changed Data1:', newData[index]); // Log the changed data at the specified index
    //     setTableData(newData);
    // };




    // const handleComputedInputChange = (e, index) => {
    //     const { value } = e.target;
    //     const newData = [...tableData];
    //     const parsedValue = parseInt(value);
    //     if (!isNaN(parsedValue)) {
    //         newData[index].total_amt = parsedValue; // Update the computed field in the data
    //     } else {
    //         newData[index].total_amt = 0; // Set to 0 as a default value in case of an error
            
    //     }
    //     console.log('Changed Computed Data:', newData[index]); // Log the changed computed data at the specified index
    //     setTableData(newData);
    // };

    const handleInputChange = (e, index, fieldName) => {
        const { value } = e.target;
        const newData = [...tableData];

        console.log('Changed:', newData);
        let total = 0;
    
        if (fieldName === 'quantity' || fieldName === 'rate') {
            const parsedValue = parseInt(value);
            if (!isNaN(parsedValue)) {
                newData[index][fieldName] = parsedValue;
                // Calculate the total amount when quantity or rate changes
                const quantity = newData[index]['quantity'];
                const rate = newData[index]['rate'];
                total = quantity * rate;
                newData[index]['total'] = total; // Update the total_amt field in the data
            } else {
                // Handle the error here, e.g., set it to 0 or display an error message
                newData[index][fieldName] = 0; // Set to 0 as a default value
                // You can also display an error message or take other actions here
            }
        } else {
            newData[index][fieldName] = value;
        }
    
        console.log('Changed Data123:', newData[index]); // Log the changed data at the specified index
        setTableData(newData);
        // Perform the database save operation with the updated data, including the total_amt field
        // saveDataToDatabase(newData);
    };
    
    // Function to handle the grand total edit
    const handleGrandTotalEdit = () => {
        setIsEditingGrandTotal(true);
    };

    // Function to handle changes in the grand total
    const handleGrandTotalChange = (e) => {
        const { value } = e.target;
        setGrandTotal(value); // Assuming setGrandTotal is the function to update the grand total
    };


//////////////////////////////////////////////////////////////////////////////////////

    // function modulationPrint() {
    //     // navigate("/app/quotation_print")
    //     navigate("/app/modulation_quotation_print")
    // }


//////////////edit checkbox function checkbox/////////////

    const handleCheckboxChange = (event, index) => {
        // Update the checkbox state for the corresponding row
        const newData = [...tableData];
        newData[index].isChecked = event.target.checked;
        setTableData(newData);
    };

///////////////////// handle edit checkbox table function//////////////////




 ///////////////////handle deleet function checkbox//////////////////

    const handleDelete = (index) => {
        // Calculate the total for the row to be deleted
        const rowToDelete = tableData[index];
        const totalToDelete = rowToDelete.quantity * rowToDelete.rate;

        // Subtract the total to be deleted from the current grandTotal
        const newGrandTotal = grandTotal - totalToDelete;

        // Remove the row from tableData
        const newData = tableData.filter((_, i) => i !== index);

        // Update both grandTotal and tableData
        setGrandTotal(newGrandTotal);
        setTableData(newData);
    };


const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: modulationQuotationSchema,
            onSubmit: async (values, action) => {

                console.log("values:", values);
                await handleFormSubmit();
                modulationPrint();
                // navigate("/app/modulation_data")


            },
        });


    console.log(errors);


    function openAmcAdd() {
        navigate("/app/amc_quotation_add")
    }

    function openAdd() {
        navigate("/app/lift_quotation_add")
    }

    function openModuleAdd() {
        navigate("/app/modulation_quatation_add")
    }

    ////////////////////////////////////////////////////////////


    const handleChangeUniqueId = (event) => {

        setUniqueId(event.target.value);
        handleChange(event);
    }

    // const handlePreModBal = (event) => {

    //     setPreviousModAmt(event.target.value);
    //     handleChange(event);
    // }

    const handleCustomerName = (event) => {

        setCustomerName(event.target.value);
        handleChange(event);
    }

    const handleGstNo = (event) => {
        // console.log("On Change");

        setGstNo(event.target.value);
        handleChange(event);
    }
    const handlePanNo = (event) => {
        // console.log("On Change");

        setPanNo(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }

    const handleQuotationNo = (event) => {
        // console.log("On Change");

        setModulationQuotationNo(event.target.value);
        handleChange(event);
    }

    const handlePreAddress = (event) => {
        // console.log("On Change");

        setPreAddress(event.target.value);
        handleChange(event);
    }

    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }

    const handleStops = (event) => {
        // console.log("On Change");

        setStops(event.target.value);
        handleChange(event);
    }

    const handlePerticular = (event) => {
        // console.log("On Change");

        setPerticular(event.target.value);
        handleChange(event);
    }

    const handleMake = (event) => {
        // console.log("On Change");

        setMake(event.target.value);
        handleChange(event);
    }


    const handleQuantity = (event) => {
        // console.log("On Change");

        setQuantity(event.target.value);
        handleChange(event);
    }

    const handleRate = (event) => {
        // console.log("On Change");

        setRate(event.target.value);
        handleChange(event);
    }

    const handleDate = (event) => {
        // console.log("On Change");

        setModuDate(event.target.value);
        handleChange(event);
    }

    const handleModWorkStatus = (event) => {
        // console.log("On Change");

        setModWorkStatus(event.target.value);
        handleChange(event);
    }

    const handleTermsAndConditions = (event) => {
        // console.log("On Change");

        setTermsAndConditions(event.target.value);
        handleChange(event);
    }

    const handleCallBackDate = (event) => {
        // console.log("On Change");

        setCallBackDate(event.target.value);
        handleChange(event);
    }


///////////////////////////////////////////////////////////////////////////

    const handleSearchClick = async () => {
        try {

            const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_data/${values.uniqueId}`);

            // if (!response.ok) {
            //     throw new Error('Error fetching data');
            // }

            const data = await response.json();

            if (data.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data.message);

            } else {

                console.log("data", data);
                setCustomerDetails(data);
                setCustomerName(data.cu_name)
                setGstNo(data.cu_gst)
                setPanNo(data.cu_pan)
                // setInputEmail(data.cu_email)
                setMobileNo(data.cu_mobile)
                setPreAddress(data.cu_address)
                setUniqueId(data.cu_unique_id)
                // setPreviousModAmt(data.cu_mod_pre_bal)

            }
        } catch (error) {
            console.error('Error fetching lift quotation data:', error);
        }
    };

    /////////////////////////////////api calls//////////////////////

    // useEffect(() => {
    //     getData();

    // }, []);



    // function getData() {

    //     async function fetchCustomerNames() {
    //         try {
    //             const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/get_customer_name');
    //             console.log("response:", response);
    //             if (!response.ok) {
    //                 throw new Error('Failed to fetch customer names.');
    //             }

    //             const data = await response.json();
    //             console.log("data:", data);
    //             const customerArray = Object.entries(data);
    //             console.log("customerArray:", customerArray);
    //             setSelCustomerNames(customerArray);
    //         } catch (error) {
    //             console.error('Error fetching customer names:', error);
    //         }
    //     }

    //     fetchCustomerNames();
    // }


    // const handleChangeCustomer = async (event) => {
    //     const selectedCustomerId = event.target.value;

    //     setCustomerName(event.target.options[event.target.selectedIndex].text);
    //     setSelectedCustomerId(selectedCustomerId);


    //     if (selectedCustomerId) {
    //         // console.log('Selected customer ID:', selectedCustomerId)
    //         try {
    //             const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_mod_quo_data/${selectedCustomerId}`);

    //             if (!response.ok) {
    //                 throw new Error('Failed to fetch customer details.');
    //             }

    //             const data1 = await response.json();
    //             console.log("selected", data1);

    //             setCustomerDetails(data1);
    //             setGstNo(data1.cu_gst)
    //             setPanNo(data1.cu_pan)
    //             setMobileNo(data1.cu_mobile)
    //             setUniqueId(data1.cu_unique_id)
    //             setPreviousModAmt(data1.cu_mod_pre_bal)

    //             console.log('Customer details:', data1)
    //         } catch (error) {
    //             console.error('Error fetching customer details:', error);
    //         }
    //     }
    //     else {
    //         setCustomerDetails({
    //             gstNo: '',
    //             panNo: '',
    //             inputEmail: '',
    //             mobileNo: '',
    //             preAddress: ''
    //         });
    //     }
    // }


    /////increment modulation quotation no api call ///////////////////////

    useEffect(() => {
        // Fetch the next quotation number when the component mounts
        const fetchNextQuotationNumber = async () => {
            try {
                const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/increment-modu-quotation');
                const data = await response.json();
                console.log(data);
                setModulationQuotationNo(data.newId.toString());
            } catch (error) {
                console.error('Error fetching next quotation number:', error);
            }
        };

        fetchNextQuotationNumber();
    }, []); // Empty dependency array to ensure this runs only once when the component mounts



    /////////////////////// modulation api to store data////////////////////////
    //    async function addModuQuotation(){
    //     // console.log(name,file,price,description)
    //     const formData=new FormData();

    //     formData.append("customerName",customerName)
    //     formData.append("panNo",panNo)
    //     formData.append("gstNo",gstNo)

    //     formData.append("mobileNo",mobileNo)
    //     formData.append("modulationQuotationNo",modulationQuotationNo)

    //     formData.append("liftType",liftType)
    //     formData.append("stops",stops)
    //     formData.append("perticular",perticular)
    //     formData.append("make",make)
    //     formData.append("quantity",quantity)
    //     formData.append("rate",rate)
    //     formData.append("moduDate",moduDate)


    //     try {
    //     let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/modu_quo_store',{
    //       method: 'POST',
    //       body: formData
    //     });

    //     let data = await response.json(); // Parse the response body as JSON

    //     if (data.message) {
    //         // If the response status is within the 2xx range, it means the request was successful.
    //         alert(data.message);

    //       }else{
    //         alert("An error occurred while saving the data.");
    //    }


    //   } catch (error) {
    //     console.error('Error while adding customer:', error);
    //     alert("An error occurred while saving the data2.");

    //   }
    //   }

    //////////////////////////////////////////////////////////////////////////





    /////////////////////////////////////////////////////////////////////


    const handleAdd = () => {
        // Create a new data row object

        const newTotal = values.quantity * values.rate;
        console.log("newTotal", newTotal);
        // const newTotal = values.quantity * values.rate;
        const newRow = {
            slNo: tableData.length + 1, // Calculate the new serial number
            moduDate: values.moduDate, // Use current date for demonstration
            perticular: values.perticular,
            make: values.make,
            quantity: values.quantity,
            rate: values.rate,
            total: newTotal,
        };

        const total = values.quantity * values.rate;
        // grandTotal += total;

        const newGrandTotal = grandTotal + total;

        setGrandTotal(newGrandTotal);
        // console.log("grandtotal",grandTotal);

        // console.log("newRow1",newRow);

        // Update the tableData state
        setTableData([...tableData, newRow]);


    };

    const handleFormSubmit = async (event) => {

        const confirmPrint = window.confirm('Do you want save?');
        if (confirmPrint) {


            const requestData = {

                selectedCustomerId: selectedCustomerId,
                customerName: customerName,
                uniqueId: uniqueId,
                gstNo: gstNo,
                panNo: panNo,
                mobileNo: mobileNo,
                modulationQuotationNo: modulationQuotationNo,
                termsAndConditions: termsAndConditions,
                modWorkStatus: modWorkStatus,
                liftType: liftType,
                stops: stops,
                rate: rate,
                moduDate: moduDate,
                preAddress: preAddress,
                // previousModAmt: previousModAmt,
                grandTotal: grandTotal,
                callBackDate: callBackDate,

                // ... other customer details
                tableData: tableData,
            };

            console.log("requestData", requestData);

            try {
                const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/modu_quo_store', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                });

                const data = await response.json();
                console.log("table data1", data)
                if (data.message) {
                    alert(data.message);

                } else {
                    alert("ccccc2222 An error occurred while saving the data1.");
                }
            } catch (error) {
                console.error('Error while saving data:', error);
                alert("An error occurred while saving the data1.");
            }
        }
    };



    function modulationPrint() {

        const confirmPrint = window.confirm('Do you want to proceed with printing?');

        if (confirmPrint) {


            navigate("/app/modulation_quotation_print", {

                state: {
                    uniqueId: uniqueId,
                    grandTotal: grandTotal,
                    moduDate: moduDate,
                    customerName: customerName,
                    preAddress: preAddress,
                    tableData: tableData,
                    modulationQuotationNo: modulationQuotationNo,
                    termsAndConditions: termsAndConditions,
                },
            });
        }
    }


    ////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div className='row d-flex justify-content-around'>
                <button onClick={openAdd} className='btn btn-primary col-md-3 mt-4'>Lift Quotation</button>
                <button onClick={openAmcAdd} className='btn btn-primary col-md-3 mt-4'>AMC Quotation</button>
                <button onClick={openModuleAdd} className='btn btn-primary col-md-3 mt-4'>Modulation Quotation</button>
            </div>


            <div className='container'>

                <form onSubmit={handleSubmit}>

                    <div className="row mb-1 mt-5">
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Modulation Quotation</h4>
                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                                <input type="tex" className="form-control a1" defaultValue={customerDetails.cu_unique_id} id="uniqueId" name='uniqueId'
                                    onChange={handleChangeUniqueId}
                                    disabled="true"
                                />
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="moduDate" className='' style={{ fontSize: '14px' }}>Date</label>
                                <input type="date" className="form-control a1" id="moduDate" name="moduDate"
                                    value={moduDate}
                                    onChange={handleDate}
                                />
                                {touched.moduDate && errors.moduDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.moduDate}</p>
                                ) : null}
                            </div>

                        </div>

                    </div>

                    <hr />

                    <div className="row mb-1">


                        {/* <div className='col-md-4'>

                            <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>

                            <select className="form-select border border-dark" defaultValue={customerDetails.cu_name} style={{ boxShadow: 'none' }}
                                name='customerName'
                                id='customerName'
                                // value={customerName}
                                onChange={handleChangeCustomer}>

                                <option value="1" style={{ background: '#dddddd' }}>Select Name</option>

                                {selCustomerNames.map(([cu_unique_id, cu_name]) => (
                                    <option key={cu_unique_id} value={cu_unique_id} style={{ background: '#dddddd' }}>{cu_name}</option>
                                ))}

                               

                            </select>
                        </div> */}

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Site Id:*</label>
                                <input type="text" name="uniqueId" className="form-control a1" id="uniqueId"
                                    value={uniqueId}
                                    onChange={handleChangeUniqueId}
                                    onBlur={handleBlur} />
                                {touched.uniqueId && errors.uniqueId ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.uniqueId}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 d-grid ">
                            <div className=" form-group ">
                                <button className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleSearchClick}>Search</button>
                            </div>
                        </div>

                    </div>


                    <div className='row mb-1'>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                                <input type="text" name="customerName" className="form-control a1" defaultValue={customerDetails.cu_name} id="customerName"

                                    onChange={handleCustomerName}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.*</label>
                                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo"
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.mobileNo && errors.mobileNo ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.mobileNo}</p>
                                ) : null} */}
                            </div>

                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN No.</label>
                                <input type="text" name='panNo' className="form-control a1" id="panNo"
                                    value={panNo}
                                    onChange={handlePanNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.panNo && errors.panNo ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.panNo}</p>
                                ) : null} */}
                            </div>
                        </div>

                    </div>


                    <div className="row mb-1">


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No.</label>
                                <input type="text" name='gstNo' className="form-control a1" id="gstNo"
                                    value={gstNo}
                                    onChange={handleGstNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.gstNo && errors.gstNo ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.gstNo}</p>
                                ) : null} */}
                            </div>
                        </div>



                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="modulationQuotationNo" className='' style={{ fontSize: '14px' }}>Modulation Quotation No.*</label>
                                <input type="text" name='modulationQuotationNo' className="form-control a1" id="modulationQuotationNo"
                                    value={modulationQuotationNo}
                                    onChange={handleQuotationNo}
                                    onBlur={handleBlur} disabled="true" />
                                {/* {touched.modulationQuotationNo && errors.modulationQuotationNo ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.modulationQuotationNo}</p>
                                ) : null} */}
                            </div>
                        </div>


                        <div className='col-md-4'>

                            <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Mode Type</label>

                            <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='liftType'
                                id='liftType'
                                value={values.liftType}
                                onChange={handleLiftType}
                                onBlur={handleBlur}>
                                <option value="" style={{ background: '#dddddd' }}>Select Type</option>
                                <option value="I M P Door" style={{ background: '#eaeaea' }}>I M P Door</option>
                                <option value="Swing Door" style={{ background: '#eaeaea' }}>Swing Door</option>
                                <option value="Automatic Door MR" style={{ background: '#eaeaea' }}>Automatic Door MR</option>
                                <option value="Automatic Door MRL" style={{ background: '#eaeaea' }}>Automatic Door MRL</option>
                                <option value="Hydrolic Lift" style={{ background: '#eaeaea' }}>Hydrolic Lift</option>
                                <option value="Good's Lif" style={{ background: '#eaeaea' }}>Good's Lift</option>
                                <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>


                            </select>
                            {touched.liftType && errors.liftType ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.liftType}</p>
                            ) : null}

                        </div>


                    </div>

                    <div className='row mb-1'>



                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                                <input type="text" name='stops' className="form-control a1" id="stops"
                                    value={values.stops}
                                    onChange={handleStops}
                                    onBlur={handleBlur} />
                                {touched.stops && errors.stops ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.stops}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className='col-md-4'>

                            <label htmlFor="modWorkStatus" className='' style={{ fontSize: '14px' }}>Status</label>

                            <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='modWorkStatus'
                                id='modWorkStatus'
                                value={values.modWorkStatus}
                                onChange={handleModWorkStatus}
                                onBlur={handleBlur}>
                                <option selected style={{ dispaly: 'none' }}>Select Status</option>
                                <option value="Visit Site" style={{ background: '#dddddd', color: "blue" }}>Visit Site</option>
                                <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                                <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                                <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                                <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                                <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                                <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>


                            </select>

                            {touched.modWorkStatus && errors.modWorkStatus ? (
                                <p className='form-error' style={{ color: "red", fontSize: "14px" }}>{errors.modWorkStatus}</p>
                            ) : null}
                        </div>

                    </div>

                    <div classname="row mb-1">
                        <div className="row mt-4">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                                    <textarea className="form-control a1" name="preAddress" id="preAddress" rows="5"
                                        value={preAddress}
                                        onChange={handlePreAddress}
                                        onBlur={handleBlur} disabled="true"></textarea>
                                    {/* {touched.preAddres && errors.preAddres ? (
                                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.preAddres}</p>
                                                    ) : null} */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12 ">
                        <div className="form-group">
                            <h5 className='mt-4'>Perticular Data</h5>
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="perticular" className='' style={{ fontSize: '14px' }}>Perticular</label>
                                <input type="text" name='perticular' className="form-control a1" id="perticular"
                                    value={values.perticular}
                                    onChange={handlePerticular}
                                    onBlur={handleBlur} />
                                {touched.perticular && errors.perticular ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.perticular}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="make" className='' style={{ fontSize: '14px' }}>Make</label>
                                <input type="text" name='make' className="form-control a1" id="make"
                                    value={values.make}
                                    onChange={handleMake}
                                    onBlur={handleBlur} />
                               
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="quantity" className='' style={{ fontSize: '14px' }}>Quantity </label>
                                <input type="number" name='quantity' className="form-control a1" id="quantity"
                                    value={values.quantity}
                                    onChange={handleQuantity}
                                    onBlur={handleBlur} />
                                {touched.quantity && errors.quantity ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.quantity}</p>
                                ) : null}

                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="rate" className='' style={{ fontSize: '14px' }}>Rate</label>
                                <input type="number" name='rate' className="form-control a1" id="rate"
                                    value={values.rate}
                                    onChange={handleRate}
                                    onBlur={handleBlur} />
                                {touched.rate && errors.rate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.rate}</p>
                                ) : null}

                            </div>
                        </div>






                        {/* <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="previousModAmt" className='' style={{ fontSize: '14px' }}>Previous Mod Amt</label>
                                <input type="number" name='previousModAmt' className="form-control a1" id="previousModAmt"
                                    value={previousModAmt}
                                    onChange={handlePreModBal}
                                    onBlur={handleBlur} disabled="true" />
                             
                            </div>
                        </div>
                       */}



                        <div className="col-md-4 d-grid">
                            <div className="form-group">
                                <button type="button" className='btn btn-primary col-md-3 mt-4 w-auto' onClick={handleAdd}>+Add Perticular</button>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-1'>

                        <div className='col-md-4'>

                            <label htmlFor="termsAndConditions" className='name_label door_type' style={{ fontSize: '14px' }} >Terms and Conditions</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='termsAndConditions'
                                id='termsAndConditions'
                                value={values.termsAndConditions}
                                onChange={handleTermsAndConditions}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                {/* <option value="80 % Advance Payment" style={{ background: '#eaeaea' }}>80 % Advance Payment</option> */}
                                <option value="GST @18% Extra at Actual" style={{ background: '#eaeaea' }}>GST @18% Extra at Actual</option>
                                {/* <option value="Completed" style={{ background: '#eaeaea' }}>Type Manually</option> */}
                            </select>

                            {touched.termsAndConditions && errors.termsAndConditions ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.termsAndConditions}</p>
                            ) : null}
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="callBackDate" className='name_label stops' >Follow up Date</label>
                                <input type="date" name='callBackDate' className="form-control a1 stops_input" id="callBackDate"
                                    value={callBackDate}
                                    onChange={handleCallBackDate}
                                    onBlur={handleBlur} />
                                {touched.callBackDate && errors.callBackDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.callBackDate}</p>
                                ) : null}

                            </div>
                        </div>


                    </div>

                    <div className='d-flex justify-content-center'>
                        <div className='  mt-5 col-md-8'>
                            <Table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col">Sl No</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Particulars</th>
                                        <th scope="col">Make</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Unit Rate</th>
                                        <th scope="col">Total</th>
                                        <th></th>


                                    </tr>
                                </thead>
                                <tbody>

                                    {/* {tableData.map((row, index) => {

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        value={index}
                                                        onChange={(event) => handleCheckboxChange(event, index)}
                                                    />
                                                </td>
                                               
                                                <th scope="row">{row.slNo}</th>
                                                <td>{row.moduDate}</td>
                                                <td>{row.perticular}</td>
                                                <td>{row.make}</td>
                                                <td>{row.quantity}</td>
                                                <td>{row.rate}</td>
                                                <td>{row.quantity * row.rate}</td>
                                                <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary mx-1"
                                                    onClick={() => handleEdit(index)}
                                                >
                                                    Edit
                                                </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger mx-1"
                                                        onClick={() => handleDelete(index)}
                                                    >
                                                        Delete
                                                    </button></td>


                                            </tr>
                                        );

                                    })} */}

                                    {tableData.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={index}
                                                        onChange={(event) => handleCheckboxChange(event, index)}
                                                    />
                                                </td>
                                                <th scope="row">{row.slNo}</th>
                                                <td>{row.isEditing ? <input style={{ width: "80px" }} value={row.moduDate} onChange={(e) => handleInputChange(e, index, 'moduDate')} /> : row.moduDate}</td>
                                                <td>{row.isEditing ? <input style={{ width: "80px" }} value={row.perticular} onChange={(e) => handleInputChange(e, index, 'perticular')} /> : row.perticular}</td>
                                                <td>{row.isEditing ? <input style={{ width: "80px" }} value={row.make} onChange={(e) => handleInputChange(e, index, 'make')} /> : row.make}</td>
                                                <td>{row.isEditing ? <input style={{ width: "80px" }} value={row.quantity} onChange={(e) => handleInputChange(e, index, 'quantity')} /> : row.quantity}</td>
                                                <td>{row.isEditing ? <input style={{ width: "80px" }} value={row.rate} onChange={(e) => handleInputChange(e, index, 'rate')} /> : row.rate}</td>

                                                <td>{row.quantity * row.rate}</td>

                                                {/* <td>{row.isEditing ? <input style={{ width: "80px" }} value={row.quantity * row.rate} onChange={(e) => handleInputChange(e, index)} /> : row.quantity * row.rate}</td> */}
                                                {/* <td>
                                                    {row.isEditing ? (
                                                        <input
                                                            style={{ width: "80px" }}
                                                            value={row.quantity * row.rate}
                                                            onChange={(e) => handleComputedInputChange(e, index)}
                                                        />
                                                    ) : (
                                                        row.quantity * row.rate
                                                    )}
                                                </td> */}

                                                <td>
                                                    {row.isEditing ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-secondary mx-1"
                                                            onClick={() => handleDisableEdit(index)}
                                                        >
                                                            Disable Edit
                                                        </button>
                                                    ) : (
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary mx-1"
                                                                onClick={() => handleEdit(index)}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger mx-1"
                                                                onClick={() => handleDelete(index)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    <tr>
                                        <td colSpan="7" className="text-right">Grand Total:</td>
                                        {/* <td>{grandTotal}</td> */}
                                        <td>
                                            {isEditingGrandTotal ? (
                                                <input
                                                    style={{ width: "50px" }} // Adjust the width as needed
                                                    value={grandTotal}
                                                    onChange={(e) => handleGrandTotalChange(e)}
                                                />
                                            ) : (
                                                grandTotal
                                            )}
                                        </td>

                                        <td>
                                            <button type="button" onClick={handleGrandTotalEdit}>
                                                Edit
                                            </button>
                                        </td>

                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                    </div>





                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                        {/* <button type="button" className="btn btn-primary mx-2 ms-5 ">Preview</button> */}
                        {/* <button type="button" onClick={modulationPrint} className="btn btn-primary mx-3">Print</button> */}

                        <button type="submit" className="btn btn-primary mx-3">Save</button>
                    </div>

                </form>


            </div>


        </>
    )
}

export default ModulationQuatationAdd