import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { updateNewModulationSchema } from '../Schema/UpdateNewModulationAddIndex';



const initialValues = {
    quotationNo: "",
    modulationNo: "",
    customerName: "",
    mobileNo: "",
    liftType: "",
    panNo: "",
    gstNo: "",
    stops: "",
    make: "",
    perticular: "",
    quantity: "",
    rate: "",
    preBalance: "",
    paidAmt: "",
    totalBalance: "",
    moduDate: "",
    workingStatus: "",
    followUpDate: "",

};

const UpdateNewModulationAdd = () => {

    const { id } = useParams();

    const [data, setData] = useState([])
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [formData, setFormData] = useState(initialValues);

    const [moduQuotationNo, setModuQuotationNo] = useState('');
    const [quotationNo, setQuotationNo] = useState("");
    const [moduQuotationData, setModuQuotationData] = useState(null);

    const [uniqueId, setUniqueId] = useState("");
    const [moduDate, setModuDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [mobileNo, setMobileNo] = useState("");

    const [preAddress, setPreAddress] = useState("");
    const [liftType, setLiftType] = useState("");
    const [stops, setStops] = useState("");
    const [previousBal, setPreviousBal] = useState(0);
    const [paidAmt, setPaidAmt] = useState("");
    const [totalBalance, setTotalBalance] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [workingStatus, setWorkingStatus] = useState(0);


    const [perticular, setPerticular] = useState("");
    const [make, setMake] = useState("");
    const [quantity, setQuantity] = useState("");
    const [rate, setRate] = useState("");


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            // validationSchema: updateNewModulationSchema,
            onSubmit: (values, action) => {
                // console.log("values:", values);
                // action.resetForm();
                navigate("/app/new_modulation")
            },
        });
    console.log(errors);

    function saveData() {
        navigate("/app/new_modulation")
    }

    ///////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////////////////

    const handleUniqueId = (event) => {

        setUniqueId(event.target.value);
        handleChange(event);
    }


    const handleModuQuotationNo = (event) => {

        setModuQuotationNo(event.target.value);
        handleChange(event);
    }

    const handleCustomerName = (event) => {

        setCustomerName(event.target.value);
        handleChange(event);
    }

    const handleWorkingStatus = (event) => {

        setWorkingStatus(event.target.value);
        handleChange(event);
    }


    const handleGstNo = (event) => {
        // console.log("On Change");

        setGstNo(event.target.value);
        handleChange(event);
    }
    const handlePanNo = (event) => {
        // console.log("On Change");

        setPanNo(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }

    const handleQuotationNo = (event) => {
        // console.log("On Change");

        setQuotationNo(event.target.value);
        handleChange(event);
    }

    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }

    const handleStops = (event) => {
        // console.log("On Change");

        setStops(event.target.value);
        handleChange(event);
    }

    // const handlePerticular = (event) => {
    //     // console.log("On Change");

    //     setPerticular(event.target.value);
    //     handleChange(event);
    // }

    // const handleMake = (event) => {
    //     // console.log("On Change");

    //     setMake(event.target.value);
    //     handleChange(event);
    // }


    // const handleQuantity = (event) => {
    //     // console.log("On Change");

    //     setQuantity(event.target.value);
    //     handleChange(event);
    // }

    // const handleRate = (event) => {
    //     // console.log("On Change");

    //     setRate(event.target.value);
    //     handleChange(event);
    // }

    const handleDate = (event) => {
        // console.log("On Change");

        setModuDate(event.target.value);
        handleChange(event);
    }

    // const handlePreAddress = (event) => {
    //     // console.log("On Change");

    //     setPreAddress(event.target.value);
    //     handleChange(event);
    // }

    // const handlePreviousBal = (event) => {

    //     setPreviousBal(event.target.value);
    //     handleChange(event);
    // }

    // const handlePaidAmt = (event) => {


    //     setPaidAmt(event.target.value);

    //     const newTotalBalance = parseInt(grandTotal || 0) + parseInt(previousBal || 0) - parseInt(event.target.value || 0);
    //     setTotalBalance(newTotalBalance);


    //     const newGrandTotal = Math.abs(parseInt(grandTotal || 0));
    //     setGrandTotal(newGrandTotal);
    // }

    //  const handleTotalBalance = (event) => {

    //         setTotalBalance(event.target.value);

    //     }
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {

        async function fetchresult() {
            try {
                const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_newmod_update/${id}`);
                const result = await response.json();
                console.log("result:", result);
                setData(result);
                setUniqueId(result.mod_unique_id)
                setCustomerName(result.mod_cu_name)
                setModuDate(result.mod_date)
                setQuotationNo(result.mod_quo_no)
                setModuQuotationNo(result.mod_final_quo_no)
                setGstNo(result.mod_gst_no)
                setPanNo(result.mod_pan)
                setMobileNo(result.mod_mob_no)
                setPreAddress(result.mod_address)
                setLiftType(result.mod_lift_type)
                setStops(result.mod_stops)
                setWorkingStatus(result.mod_working_status)

            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchresult();

    }, [id]);

    ///////////////////////////////////////////////////////////////////////////////////


    ///////////////////// Api to store Lift updated data ///////////////////////////////////////////



    async function handleFormSubmit(id) {


        const requestData = {

            // selectedCustomerId: selectedCustomerId,
            customerName: customerName,
            uniqueId: uniqueId,
            gstNo: gstNo,
            panNo: panNo,
            mobileNo: mobileNo,
            moduQuotationNo: moduQuotationNo,
            quotationNo: quotationNo,
            liftType: liftType,
            stops: stops,
            // rate: rate,
            moduDate: moduDate,
            preAddress: preAddress,
            // previousBal: previousBal,
            // paidAmt: paidAmt,
            // totalBalance: totalBalance,
            workingStatus: workingStatus,

        };

        console.log("requestData", requestData);

        try {

            const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/modu_update_store/' + id + '?method=PUT', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log("table data1", data)
            if (data.message) {
                alert(data.message);

            } else {
                alert("ccccc2222 An error occurred while saving the data1.");
            }
        } catch (error) {
            console.error('Error while saving data:', error);
            alert("An error occurred while saving the data1.");
        }


        // if (parseInt(paidAmt) < 0) {
        //     // Display an alert if paidAmt is negative
        //     setShowAlert(true);
        // } else {
        //     // Continue with form submission
        //     setShowAlert(false);
        //     // ... your submission logic
        // }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////



    return (
        <>

            <div className='container'>

                <form onSubmit={handleSubmit}>


                    <div className="row mb-1 mt-5">
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Lift Modulation</h4>
                            </div>
                        </div>


                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                                <input type="tex" className="form-control a1" id="uniqueId" disabled="true" name="uniqueId"
                                    value={uniqueId}
                                    onChange={handleUniqueId}
                                    onBlur={handleBlur} />

                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="moduDate" className='' style={{ fontSize: '14px' }}>Date:</label>
                                <input type="date" name='moduDate' className="form-control a1" id="moduDate"
                                    value={moduDate}
                                    onChange={handleDate}
                                    onBlur={handleBlur}  />

                            </div>
                        </div>





                    </div>

                    <hr />


                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="quotationNo" className='' style={{ fontSize: '14px' }}>Quotation number</label>
                                <input type="text" name='quotationNo' className="form-control a1" id="quotationNo"
                                    value={quotationNo}
                                    onChange={handleQuotationNo}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>


                        {/* <div className="col-md-2 ">
                            <div className="form-group ">
                                <button className='btn btn-primary col-md-3 mt-4 w-auto'>Search</button>
                            </div>
                        </div> */}
                    </div>

                    <div className="row mb-1">
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                                <input type="text" name='customerName' className="form-control a1" id="customerName"
                                    value={customerName}
                                    onChange={handleCustomerName}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No.:*</label>
                                <input type="text" name='mobileNo' className="form-control a1" id="mobileNo"
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>


                        <div className='col-md-4'>

                            <label htmlFor="liftType " className='' style={{ fontSize: '14px' }}>Type Lift:</label>

                            <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='liftType'
                                id='liftType'
                                value={liftType}
                                onChange={handleLiftType}
                                onBlur={handleBlur}  >

                                <option value="1" style={{ background: '#dddddd' }}>Auto Lift</option>
                                <option value="2" style={{ background: '#dddddd' }}>Manual Lift</option>
                                <option value="3" style={{ background: '#dddddd' }}>Hydraulic Lift</option>
                                <option value="4" style={{ background: '#dddddd' }}>SS Auto Lift</option>


                            </select>


                        </div>

                    </div>

                    <div className='row mb-1'>

                        <div className="col-md-2 ">
                            <div className="form-group">
                                <label htmlFor="panNo" className='' style={{ fontSize: '14px' }}>PAN No.</label>
                                <input type="text" name='panNo' className="form-control a1" id="panNo"
                                    value={panNo}
                                    onChange={handlePanNo}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>


                        <div className="col-md-2 ">
                            <div className="form-group">
                                <label htmlFor="gstNo" className='' style={{ fontSize: '14px' }}>GST No</label>
                                <input type="text" name='gstNo' className="form-control a1" id="gstNo"
                                    value={gstNo}
                                    onChange={handleGstNo}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="moduQuotationNo" className='' style={{ fontSize: '14px' }}>Modulation number:*</label>
                                <input type="text" name='moduQuotationNo' className="form-control a1" id="moduQuotationNo"
                                    value={moduQuotationNo}
                                    onChange={handleModuQuotationNo}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="stops" className='' style={{ fontSize: '14px' }}>Stops</label>
                                <input type="text" name='stops' className="form-control a1" id="stops"
                                    value={stops}
                                    onChange={handleStops}
                                    onBlur={handleBlur}  />

                            </div>
                        </div>


                        <div className='col-md-2'>

                            <label htmlFor="workingStatus " className='name_label door_type' >Lift Status:*</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='workingStatus'
                                id='workingStatus'
                                value={workingStatus}
                                onChange={handleWorkingStatus}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                <option value="Ongoing" style={{ background: '#eaeaea' }}>Ongoing</option>
                                <option value="Pending" style={{ background: '#eaeaea' }}>Pending</option>
                                <option value="Completed" style={{ background: '#eaeaea' }}>Completed</option>
                            </select>


                        </div>


                    </div>

                    <div classname="row mb-1">
                        <div className="row mt-4">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label htmlFor="preAddress" className='' style={{ fontSize: '14px' }}>Address</label>
                                    <textarea className="form-control a1" name="preAddress" id="preAddress" rows="5"
                                        value={preAddress}
                                        onChange={handleChange}
                                        onBlur={handleBlur} disabled="true"></textarea>
                                    {touched.preAddres && errors.preAddres ? (
                                        <p className="form-error" style={{ color: 'red', fontSize: '14px' }}>{errors.preAddres}</p>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                    </div>





                    {/* <div className="col-md-12 ">
                        <div className="form-group">
                            <h5 className='mt-4'>Perticular Data</h5>
                        </div>
                    </div> */}

                    <hr />

                    {/* <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="perticular" className='' style={{ fontSize: '14px' }}>Perticular</label>
                                <input type="text" name='perticular' className="form-control a1" id="perticular"
                                    value={values.perticular}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />

                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="make" className='' style={{ fontSize: '14px' }}>Make</label>
                                <input type="text" name='make' className="form-control a1" id="make"
                                    value={values.make}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />

                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="quantity" className='' style={{ fontSize: '14px' }}>Quantity</label>
                                <input type="email" name='quantity' className="form-control a1" id="quantity"
                                    value={values.quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />

                            </div>
                        </div>
                    </div>

                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="rate" className='' style={{ fontSize: '14px' }}>Rate</label>
                                <input type="text" name='rate' className="form-control a1" id="rate"
                                    value={values.rate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />

                            </div>
                        </div>





                        <div className="col-md-4 ">
                            <div className="form-group">
                                <button className='btn btn-primary col-md-3 mt-4 w-auto'>+Add Perticulars</button>
                            </div>
                        </div>


                    </div> */}

                    {/* <div className='row mb-1'>
                        
                    <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="followUpDate" className='name_label stops' style={{ fontSize: '14px' }} >Follow up Date</label>
                                <input type="date" name='followUpDate' className="form-control a1 stops_input" id="followUpDate"
                                    value={values.followUpDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.followUpDate && errors.followUpDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.followUpDate}</p>
                                ) : null}

                            </div>
                        </div>


                        <div className='col-md-4'>

                            <label htmlFor="termsAndConditions" className='name_label door_type' style={{ fontSize: '14px' }} >Terms and Conditions</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='termsAndConditions'
                                id='termsAndConditions'
                                value={values.termsAndConditions}
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                <option value="Ongoing" style={{ background: '#eaeaea' }}>80 % Advance Payment</option>
                                <option value="Pending" style={{ background: '#eaeaea' }}>GST @18% Extra at Actual</option>
                                <option value="Completed" style={{ background: '#eaeaea' }}>Type Manually</option>
                            </select>

                            {touched.termsAndConditions && errors.termsAndConditions ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '14px' }}>{errors.termsAndConditions}</p>
                            ) : null}
                        </div>



                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="grandTotal" className='' style={{ fontSize: '14px' }}>Quotation Amt</label>
                                <input type="number" name='grandTotal' className="form-control a1" id="grandTotal"
                                    value={grandTotal}
                                    
                                    onBlur={handleBlur} />

                            </div>
                        </div>




                    </div> */}


                    {/* <div className="row mb-1">
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="preBalance" className='' style={{ fontSize: '14px' }}>Previous balance</label>
                                <input type="number" name='preBalance' className="form-control a1" id="preBalance"
                                    value={values.preBalance}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />

                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="paidAmt" className='' style={{ fontSize: '14px' }}>Paid Amt</label>
                                <input type="number" name='paidAmt' className="form-control a1" id="paidAmt"
                                    value={values.paidAmt}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />

                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="totalBalance" className='' style={{ fontSize: '14px' }}>Total balance:*</label>
                                <input type="number" name='totalBalance' className="form-control a1" id="totalBalance"
                                    value={values.totalBalance}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />


                            </div>
                        </div>

                    </div> */}




                    <div className='d-flex justify-content-center'>
                        <div className='  mt-5 col-md-8'>
                           
                        </div>

                    </div>



                  

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                        {/* <button type="button" className="btn btn-primary mx-2 ms-5 ">Preview</button>
                        <button type="button" onClick={openPrint} className="btn btn-primary mx-3">Print</button> */}

                        <button onClick={() => handleFormSubmit(data.mod_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>
                    </div>

                </form>

            </div>

        </>
    )
}

export default UpdateNewModulationAdd