import * as Yup from "yup";

export const liftServiceSchema = Yup.object({

// customerName:  Yup.string().required("Please select customer name"),
//  mobileNo:Yup.string().required("Please enter mobile no"),
//  quotationNo:Yup.string().required("Please enter quotation no"),
//  preAddress:Yup.string().required("Please enter address"),
 quoDate:  Yup.string().required("Please select date"),
 remark:  Yup.string().required("Please enter remarks"),
 technicianName:  Yup.string().required("Please enter technician name"),
//  uniqueId: Yup.string().required("Please enter site id"),


});