import * as Yup from "yup";


export const updateAmcQuotationAddSchema = Yup.object({

    // customerName: Yup.string().min(2).max(25).required("Please enter your name"),
    // gstNo: Yup.string().required("Please enter your GST No."),
    // panNo: Yup.string().required("Please enter your PAN No."),
    // inputEmail: Yup.string().email().required("Please enter your email"),
    // mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
    // quotationNo:Yup.string().required("Please enter your  Quotation No."), 
    // preAddress: Yup.string().required("Please enter your Address "),
    // liftType: Yup.string().required("Please Select lift type"),
    // stops:Yup.string().required("Please enter your stops "),
    // doorType:Yup.string().required("Please Select Door type"),
    // make:Yup.string().required("Please enter your Make "),
    // quantity:Yup.string().required("Please enter your Quantity "),
    // quotationAmt: Yup.number().integer().positive().required("Please enter your Quotation Amount"),
    // quoDate: Yup.string().required("Please Select Date"),
    // amcStartDate: Yup.string().required("Please Select AMC Start Date"),
    // amcEndDate: Yup.string().required("Please Select AMC End Date"),

 



  });
  