import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
// import { login } from '../auth/auth'
import { login, checkToken } from '../auth/auth'; // Import the login and checkToken functions

import './Login.css';


// const Year = new Date().getFullYear()
const initialState = { email: null, password: null };

const Login = () => {

    // const token = checkToken();
    const token = checkToken();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(initialState);
    const { email, password } = userData;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    }




    const handleSubmit = async () => {

        if (!email) {
            alert("Please enter  email .");
            return;
        }

        if (!password) {
            alert("Please enter password.");
            return;
        }

        try {
            const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                }),
            });

            const data = await response.json();

            if (data.message) {

                // Handle the successful login here
                localStorage.setItem('token', data.token);
                console.log("token", data.token);
                localStorage.setItem('userRole', data.userRole);
                console.log("userRole", data.userRole);
                console.log("Login successful:");

                // login(data.token);
                navigate("/app/dashboard");
                // You can set a token in local storage or state to manage the user session
            } else {
                console.error("Login failed:", data.error);
                alert("Wrong Credentials");
            }
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    // useEffect(() => {
    //     // Check if a token exists when the component loads

    //     console.log("checktoken", token);
    //     if (!token) {
    //       // Token doesn't exist, stay on the login page
    //       console.log("User is not logged in.");
    //     } else {
    //       // Token exists, redirect to the dashboard or another protected route
    //       navigate("/app/dashboard");
    //     }
    //   },[])




    // const token = checkToken();

    // const navigate = useNavigate()

    // const initialState = { email: "info@infysky.com", password: "testpass1234" };

    // const [userData, setUserData] = useState(initialState);
    // const { email, password } = userData;

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setUserData({ ...userData, [name]: value });
    // }

    // const handleSubmit = () => {
    //     if (userData.email === "info@infysky.com" && userData.password === "testpass1234") {
    //         login();
    //     } else {
    //         alert("Wrong Credentials");
    //     }
    // };

    // useEffect(() => {
    //     if (token) navigate("/app/dashboard");

    // }, []);




    return (
        <>

            {/* <div>
            <input type="email" name="email" value={email} onChange={handleInputChange} />
            <input type="password" name="password" value={password} onChange={handleInputChange} />
            <button onClick={handleSubmit}>Login</button>
        </div> */}

            <div className='d-md-flex justify-content-center d-none mb-5'>
                <img src="/img/Je-N (3).png" alt="" style={{width:"700px",height:"250px" }} />
                
            </div>

            <div className='d-flex justify-content-center d-md-none'>
                <img src="/img/Je-N (3).png" alt="" style={{width:"300px",height:"125px" }} />
                
            </div>

            <div className='container2 ' style={{}}>

                <div className="row g-o ">
                    <div className="col-md-6  d-md-flex mb-4 d-none">
                        <div className='ImageBox' >
                            <img src="img/250-2505860_web-designer-team-animation-hd-png-download (1).png" alt="" />
                        </div>
                    </div>

                    <div className="col-md-6  ">
                        <div className='heading col-9  mb-4' style={{ margin: "0px auto" }}>
                            <h1 className='text-primary ' style={{ fontWeight: "700" }}>Welcome</h1>
                        </div>

                        {/* <form onSubmit={handleSubmit}> */}
                        <div className="form-group mb-3 col-9" style={{ margin: "0px auto" }}>
                            <label htmlFor="floatingInput" className='mb-2'>Email address</label>
                            <input type="email" className="form-control" onChange={handleInputChange} name="email" value={email} style={{ border: '2px solid Gray' }} />

                        </div>
                        <div className="form-group mb-3 col-9" style={{ margin: "0px auto" }}>
                            <label htmlFor="floatingPassword" className='mb-2'>Password</label>
                            <input type="password" className="form-control" onChange={handleInputChange} name="password" value={password} style={{ border: '2px solid Gray' }} />
                        </div>

                        <div className='form-group mb-4 col-9' style={{ margin: "0px auto" }}>
                            <Link to={'/forget_password'}>Forget Password? </Link>
                        </div>

                        <div className=' d-grid col-9' style={{ margin: "0px auto" }}>
                            <button className="btn btn-lg btn-primary" onClick={handleSubmit} >Login</button>
                        </div>
                        {/* </form> */}
                    </div>

                </div>
            </div>
        </>
    )
}

export default Login