import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import UpdateIcon from '@mui/icons-material/Update';
// import UpdateIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../pagination.css';


const AmcData = () => {


    const navigate = useNavigate();

    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [selectedDates, setSelectedDates] = useState({});


    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    const [statusByItemId, setStatusByItemId] = useState({});

    function openAdd() {

        navigate("/app/new_amc_add")
    }



    const handleUpdate = (amc_quo_id) => {
        // Navigate two steps back
        navigate(`/app/update_amc_quotation_add/${amc_quo_id}`);
      };

    function openLift() {

        navigate("/app/lift_data")
    }

    function openAmc() {
        navigate("/app/amc_data")
    }

    function openModulation() {
        navigate("/app/modulation_data")
    }

    // function openView () {
    //     navigate("/app/amc_quotation_view_print")
    // }


    function openView(item) {
        // Construct the URL with route parameters
        navigate(`/app/amc_quotation_view_print/${item.amc_quo_id}`, {
          state: { data: item }, // Pass the data as part of the route state
        });
      }



/////////////////////// Update onSElect Date date ////////////////////////////////
const handleDateChange = (amc_quo_id, newDate) => {
    setSelectedDates((prevSelectedDates) => ({
        ...prevSelectedDates,
        [amc_quo_id]: newDate,
    }));

    // Send the date value to the backend here
    updateDateInDatabase(amc_quo_id, newDate);
};


const updateDateInDatabase = async (amc_quo_id, newDate) => {
    try {
        const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/updateAmcdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amc_quo_id: amc_quo_id, // Use the ID of the record you want to update
                newDate: newDate, // Use the selected date
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Handle the response from the server (e.g., show a success message)
        console.log(data.message);
    } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error(error);
    }
};


///////////////////////////////// Api Call for onselect change the dropdown values ///////////////////

////////////////////// To select an Status //////////////////////////

async function updateStatusInAPI(amc_quo_id, newStatus) {
    try {
        const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/updateAmcStatus", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amc_quo_id: amc_quo_id,
                newStatus: newStatus,
            }),
        });

        if (!response.ok) {
            // Handle non-successful responses (e.g., server error)
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Handle the response from the server (e.g., show a success message)
        console.log(data.message);

        // Return the updated status or response data if needed
        return data.status; // You may replace 'status' with the actual property name from your API response
    } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error(error);

        // Return null or an error indicator if needed
        return null;
    }
}


//////////////////////////////////Slect an Status/////////////////////////////////////////////////

// Usage within your component
const handleStatusChange = async (amc_quo_id, newStatus) => {
// Update the status in the state immediately for a smooth user experience
setStatusByItemId((prevStatus) => ({
    ...prevStatus,
    [amc_quo_id]: newStatus,
}));

// Make the API call to update the status in the backend
const updatedStatus = await updateStatusInAPI(amc_quo_id, newStatus);

if (updatedStatus !== null) {
    // Handle the updated status if needed
} else {
    // Handle errors, e.g., revert the state to the previous value
    setStatusByItemId((prevStatus) => ({
        ...prevStatus,
        [amc_quo_id]: prevStatus[amc_quo_id], // Revert to the previous status
    }));
}
};



////////////// api call to delete data //////////


async function deleteOperation(id) {
    // alert(id)
    const confirmDelete  = window.confirm('Do you want delete?');
    if (confirmDelete ) {
    let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_amcdata/" + id, {
      method: 'DELETE'
    });
    result = await result.json();
    // console.log(result)
    if (result.message) {
      // If the response status is within the 2xx range, it means the request was successful.
      if (result.message ) {
        // alert(result.message);
        alert('Deletion confirmed. Deleting...');
        }else{
          alert('Deletion cancelled.');
        }

    } else {
      alert("Customer not found.");
    }

    getData();

  }
  }



/////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {

        getData()

    }, [currentPage])

    function getData() {

        async function fetchData() {
            try {
                let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_amc_data?page=${currentPage}&perPage=${itemsPerPage}`);
                const { data: fetchedData, last_page: total } = await result.json();
                // console.log("data", result);
                const statusData = {};
                fetchedData.forEach((item) => {
                    statusData[item.amc_quo_id] = item.amc_quo_work_status; // Replace 'li_quo_status' with the actual property name from your API response
                });


                const statusDate = {};
                fetchedData.forEach((item) => {
                    statusDate[item.amc_quo_id] = item.amc_call_back_date; // Replace 'li_quo_status' with the actual property name from your API response
                });
    

                setData(fetchedData);
                setStatusByItemId(statusData); 
                setSelectedDates(statusDate)
                setTotalPages(total);
            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchData();

    }


    const filteredData = data.filter(item => {
        const cuIdString = item.amc_cu_unique_id.toString();
        const lowercaseSearch = searchValue.toLowerCase();
        const status = item.amc_quo_work_status.toLowerCase(); // Assuming the status field exists
        const date = item.amc_quo_date.toLowerCase();

        return cuIdString.includes(lowercaseSearch) ||
            item.amc_quo_cu_name.toLowerCase().includes(lowercaseSearch) ||
            status.includes(lowercaseSearch) || 
            date.includes(lowercaseSearch) ;
    });


    // // Filtering logic to serach by id and customer name
    // const filteredData = data.filter(item => {
    //     const cuIdString = item.amc_cu_unique_id.toString();
    //     const lowercaseSearch = searchValue.toLowerCase();
    //     return cuIdString.includes(lowercaseSearch) || item.amc_quo_cu_name.toLowerCase().includes(lowercaseSearch);
    // });

    // Handle page change for pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate the range of page numbers to display in the pagination UI
    const getPageRange = () => {
        const rangeSize = 5; // Number of page numbers to display
        const totalPagesInRange = Math.min(rangeSize, totalPages);
        let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
        let endPage = startPage + totalPagesInRange - 1;

        // Adjust endPage if it exceeds totalPages
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - totalPagesInRange + 1, 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };


    return (
        <>

            <div className='d-flex justify-content-around '>
                <button onClick={openLift} className='btn btn-primary col-3 mt-4'>Lift Data</button>
                <button onClick={openAmc} className='btn btn-primary col-3 mt-4'>AMC Data</button>
                <button onClick={openModulation} className='btn btn-primary col-3 mt-4'>Modulation Data</button>
            </div>

            <div className='mt-5'>

                <div className="row ">
                    <div className='col-md-10 '>
                        <h5>Amc Data</h5>
                    </div>

                    <div className='col-md-2'>

                        <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
                            onChange={e => setSearchValue(e.target.value)} />

                    </div>


                </div>


                <hr />

                <Table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Sl No.</th>
                            <th scope="col">Job Id</th>
                            <th scope="col">Quotation No.</th>
                            <th scope="col">Customer name</th>
                            <th scope="col">Quotation date</th>
                            {/* <th scope="col">Mobile No.</th> */}
                            {/* <th scope="col">Address</th> */}
                            {/* <th scope="col">Elevator type</th>    */}
                            <th scope="col">FollowUp Date</th>
                            <th scope="col" ><label htmlFor="amcWorkStatus " className='' >AMC Status:</label></th>
                            <th scope="col">Quotation Amount</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Calling Option</th>
                            <th scope="col">View Quo</th>
                            <th scope="col">Action</th>
                            
                            {/* <th scope="col">Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {

                            filteredData.map((item) =>
                                <tr key={item.amc_quo_id}>
                                    <td>{item.amc_quo_id}</td>
                                    <td>{item.amc_cu_unique_id}</td>
                                    <td>{item.amc_quo_cu_quono}</td>
                                    <td>{item.amc_quo_cu_name}</td>
                                    <td>{item.amc_quo_date}</td>
                                    {/* <td>{item.amc_quo_cu_mobno}</td> */}
                                    {/* <td></td> */}
                                    {/* <td>{item.amc_quo_elev_type}</td> */}
                                    {/* <td>{item.amc_call_back_date}</td> */}

                                    <td><input
                                        type="date"
                                        className="form-control a1"
                                        id={`quoDate-${item.amc_quo_id}`}
                                        name="quoDate"
                                        value={selectedDates[item.amc_quo_id] || ""}
                                        onChange={(e) => handleDateChange(item.amc_quo_id, e.target.value)}
                                    /></td>
                                    {/* <td>{item.amc_quo_work_status}</td> */}

                                    <td><select className="form-select border border-dark form-control a1"
                                        style={{ boxShadow: 'none', width:"170px" }}
                                        aria-label="Default select example"
                                        name='amcWorkStatus'
                                        id='amcWorkStatus'
                                        value={statusByItemId[item.amc_quo_id] || ''} // Set the value of the select element to the state variable
                                        onChange={(e) => handleStatusChange(item.amc_quo_id, e.target.value)} // Update the state when the user changes the value
                                    >
                                        <option value="Site Visit" style={{ background: '#dddddd', color: "blue" }}>Site Visit</option>
                                        <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                                        <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                                        <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                                        <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                                        <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                                        <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                                    </select></td>

                                    <td>{item.amc_quo_quo_amount}</td>
                                    <td>{item.amc_quo_start_date}</td>
                                    <td>{item.amc_quo_end_date}</td>
                                  
                                    <td className='d-flex justify-content-evenly'><a href={`https://wa.me/${item.amc_quo_cu_mobno}`} target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                                        <a href={`tel:${item.amc_quo_cu_mobno}`}><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                                    {/* <td><button type='button' className='btn btn-primary mx-3' onClick={openView}>View</button></td> */}
                                    <td>
                                        <button
                                            type='button'
                                            className='btn btn-primary mx-3'
                                            onClick={() => openView(item)} // Pass the item data to the openView function
                                        >
                                            View
                                        </button>
                                        </td>
                                    <td><span className='update' onClick={() => handleUpdate(item.amc_quo_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.amc_quo_id) }} >{<DeleteIcon style={{ color: 'red' }} />}</span></td>
                                    
                                   
                                </tr>
                            )
                        }


                    </tbody>
                </Table>

                {/* Pagination */}
                <div className='pagination'>
                    {/* Show previous arrow icon */}
                    {currentPage > 1 && (
                        <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
                    )}

                    {/* Show page numbers */}
                    {getPageRange().map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}

                    {/* Show next arrow icon */}
                    {currentPage < totalPages && (
                        <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                    )}

                </div>

            </div>

        </>
    )
}

export default AmcData