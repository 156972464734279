import React, { useState , useEffect } from 'react'
import { useNavigate, useParams} from "react-router-dom";
import { useFormik } from "formik";
import { updateBreakdownAddSchema } from '../Schema/UpdateBreakdownAddindex';



const initialValues = {
    customerName: "",
    mobileNo: "",
    siteAddress: "",
    liftType: "",
    liftTravel: "",
    status: "",
    contactPersonName: "",
    contactNo: "",
    technicianName: "",
    technicianRemarks: "",
    breakdownDesc: "",
    quoDate: "",

};


const UpdateBreakdownAdd = () => {

    const navigate = useNavigate();
    const { id } = useParams();
   
    const [data,setData]=useState([])

    const [selCustomerNames, setSelCustomerNames] = useState([]);

    const [uniqueId, setUniqueId] = useState("");
    const [quoDate, setQuoDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [siteAddress, setSiteAddress] = useState("");
    // const [liftType, setLiftType] = useState("");
    // const [liftTravel, setLiftTravel] = useState("");
    const [breakStatus, setBreakStatus] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [technicianName, setTechnicianName] = useState("");
    const [technicianRemarks, setTechnicianRemarks] = useState("");
    const [breakdownDesc, setBreakdownDesc] = useState("");
    const [customerDetails, setCustomerDetails] = useState("");
    const [landmark, setLandmark] = useState("");

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: updateBreakdownAddSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                // action.resetForm();
                navigate("/app/breakdown")
            },
        });
    console.log(errors);


    ///////////////////////////////////////////////////


   
    

///////////////////////////////////////////////////////////////////

const handleChangeCustomerName = (event) => {

    setCustomerName(event.target.value);
    handleChange(event);
}

    const handleChangeUniqueId = (event) => {

        setUniqueId(event.target.value);
        handleChange(event);
    }


    const handleQuoDate = (event) => {

        setQuoDate(event.target.value);
        handleChange(event);
    }


    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }


    const handleSiteAddress = (event) => {
        // console.log("On Change");

        setSiteAddress(event.target.value);
        handleChange(event);
    }


    // const handleLiftType = (event) => {
    //     // console.log("On Change");

    //     setLiftType(event.target.value);
    //     handleChange(event);
    // }



    // const handleLiftTravel = (event) => {
    //     // console.log("On Change");

    //     setLiftTravel(event.target.value);
    //     handleChange(event);
    // }
    const handleBreakStatus = (event) => {
        // console.log("On Change");

        setBreakStatus(event.target.value);
        handleChange(event);
    }
    const handleContactPersonName = (event) => {
        // console.log("On Change");

        setContactPersonName(event.target.value);
        handleChange(event);
    }
    const handleContactNo = (event) => {
        // console.log("On Change");

        setContactNo(event.target.value);
        handleChange(event);
    }
    const handleTechnicianName = (event) => {
        // console.log("On Change");

        setTechnicianName(event.target.value);
        handleChange(event);
    }
    const handleTechnicianRemarks = (event) => {
        // console.log("On Change");

        setTechnicianRemarks(event.target.value);
        handleChange(event);
    }

    const handleBreakdownDesc = (event) => {
        // console.log("On Change");

        setBreakdownDesc(event.target.value);
        handleChange(event);
    }

    const handleLandmark = (event) => {

        setLandmark(event.target.value);
        handleChange(event);

    }

///////////////////////////////////api call to get data for update //////////////////////

   useEffect(()=>{

    async function fetchData(){
      try{   
      const  response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_breakdata_update/${id}`);
      const result = await response.json();
      console.log("data:", result);
      setData(result);  
      setUniqueId (result.break_cu_unique_id)  
      setCustomerName (result.break_cust_name)
      setQuoDate (result.break_date)  
      setMobileNo (result.break_cu_mob_no) 
      setSiteAddress (result.break_site_adress)
      setLandmark (result.break_landmark)    
    //   setLiftTravel (result.break_lift_travel) 
      setBreakStatus (result.break_status) 
      setContactPersonName (result.break_con_per_name)
      setContactNo (result.break_con_no)   
      setTechnicianName (result.break_tech_name)
      setTechnicianRemarks (result.break_tech_remark)  
      setBreakdownDesc (result.break_break_desc) 
                        
      } catch (error){
          console.error('Error',error);
      } 
  }

  fetchData();

  },[id]);



///////////////////////////////////////////////////// Store Updated Data /////////////////////////


async function editProduct(id)
{
      const formData=new FormData();
      formData.append("uniqueId",uniqueId)
      formData.append("quoDate",quoDate)
      formData.append("customerName",customerName)
      formData.append("siteAddress",siteAddress)  
      formData.append("mobileNo",mobileNo)
      formData.append("breakStatus",breakStatus)
    //   formData.append("liftType",liftType)
    //   formData.append("liftTravel",liftTravel)
      formData.append("contactPersonName",contactPersonName)
      formData.append("contactNo",contactNo)
      formData.append("technicianName",technicianName)
      formData.append("technicianRemarks",technicianRemarks)
      formData.append("breakdownDesc",breakdownDesc)
      formData.append("landmark",landmark)
     
     

      try {
      let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/break_update_store/'+ id + '?method=PUT',{
      method: 'POST',
      body: formData
      });

      let data1 = await response.json(); // Parse the response body as JSON

      if (data1.message) {
          // If the response status is within the 2xx range, it means the request was successful.
          navigate("/app/breakdown")
          alert(data1.message);
      

      }else{
          alert("An error occurred while saving the data.");
  }
  

  } catch (error) {
      console.error('Error while adding customer:', error);
      alert("An error occurred while saving the data2.");

 }
}



    return (
        <>
            <div className='container'>
                <form onSubmit={handleSubmit}>

                    <div className='row mb-1 mt-5'>

                        <div className="col-md-6 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Add Breakdown Details</h4>
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="uniqueId" className='' style={{ fontSize: '14px' }}>Job Id</label>
                                <input type="text" className="form-control a1" id="uniqueId"
                                value={uniqueId}
                                onChange={handleChangeUniqueId}
                                onBlur={handleBlur} disabled="true"
                                />
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date:</label>
                                <input type="date" className="form-control a1" id="quoDate" name='quoDate'
                                    value={quoDate}
                                    onChange={handleQuoDate}
                                    onBlur={handleBlur} disabled="true" />

                            </div>
                        </div>

                    </div>

                    <hr />

                    <div className="row mb-1">

                        <div className='col-md-4'>

                            <label htmlFor="customerName " className='' style={{ fontSize: '14px' }}>Customer Name:*</label>
                           
                                <input type="customerName" className="form-control a1" id="customerName" name='customerName'
                                    value={customerName}
                                    onChange={handleChangeCustomerName}
                                    onBlur={handleBlur} disabled="true" />                         

                        </div>
        
                        </div>

                        <div className='row mb-1'>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile Number</label>
                                <input type="text" className="form-control a1 " id="mobileNo"
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur} disabled="true" />
                               
                            </div>
                        </div>

                        <div className='col-md-4'>

                            <label htmlFor="breakStatus " className='' style={{ fontSize: '14px' }}>Status</label>

                            <select className="form-select border border-dark" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='breakStatus'
                                id='breakStatus'
                                value={breakStatus}
                                onChange={handleBreakStatus}
                                onBlur={handleBlur}>

                                <option selected style={{ display: 'none' }}></option>
                                <option value="New" style={{ background: '#eaeaea' }}>New</option>
                                <option value="Pending" style={{ background: '#eaeaea' }}>Pending</option>
                                <option value="Completed" style={{ background: '#eaeaea' }}>Completed</option>
                                <option value="Mod" style={{ background: '#eaeaea' }}>Mod</option>
                                <option value="Follow" style={{ background: '#eaeaea' }}>Follow</option>
                                <option value="Stop" style={{ background: '#eaeaea' }}>Stop</option>

                            </select>

                            </div>

                            <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="landmark" className='' style={{ fontSize: '14px' }}>Landmark</label>
                                <input type="text" className="form-control a1"  id="landmark" name="landmark"
                                    value={landmark}
                                    onChange={handleLandmark}
                                    onBlur={handleBlur} />
                                
                            </div>
                        </div>

                        </div>


                        <div className="row">
                            <div className="col-12 ">
                                <div className="form-group">
                                    <label htmlFor="siteAddress" className='' style={{ fontSize: '14px' }}>Site Address</label>
                                    <textarea className="form-control a1" id="siteAddress" rows="5"
                                        value={siteAddress}
                                        onChange={handleSiteAddress}
                                        onBlur={handleBlur} disabled="true"></textarea>
                                  
                                </div>
                            </div>
                        </div>
                   

                    <div className='page__main'>


                        <div className="row mb-1">

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="contactPersonName" className='' style={{ fontSize: '14px' }}>Contact Person Name</label>
                                    <input type="text" name='contactPersonName' className="form-control a1" id="contactPersonName"
                                        value={contactPersonName}
                                        onChange={handleContactPersonName}
                                        onBlur={handleBlur} />
                                   
                                </div>
                            </div>


                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="contactNo" className='' style={{ fontSize: '14px' }}>Contact Number</label>
                                    <input type="text" name='contactNo' className="form-control a1" id="contactNo"
                                        value={contactNo}
                                        onChange={handleContactNo}
                                        onBlur={handleBlur} />
                                  
                                </div>
                            </div>

                        </div>

                        <div className="row mb-1">

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="technicianName" className='' style={{ fontSize: '14px' }}>Technician name</label>
                                    <input type="text" name='technicianName' className="form-control a1" id="technicianName"
                                        value={technicianName}
                                        onChange={handleTechnicianName}
                                        onBlur={handleBlur} />
                                   
                                </div>
                            </div>


                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="technicianRemarks" className='' style={{ fontSize: '14px' }}>Technician Remarks</label>
                                    <input type="text" name='technicianRemarks' className="form-control a1" id="technicianRemarks"
                                        value={technicianRemarks}
                                        onChange={handleTechnicianRemarks}
                                        onBlur={handleBlur} />
                                   
                                </div>
                            </div>

                            <div className="row mt- ">
                                <div className="col-12 ">
                                    <div className="form-group">
                                        <label htmlFor="breakdownDesc" className='' style={{ fontSize: '14px' }}>Breakdown Description</label>
                                        <textarea className="form-control a1" name='breakdownDesc' id="breakdownDesc" rows="5"
                                            value={breakdownDesc}
                                            onChange={handleBreakdownDesc}
                                            onBlur={handleBlur}></textarea>
                                        
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="d-flex justify-content-end my-3 ">
                        <button onClick={()=>editProduct(data.break_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>
                        </div>


                    </div>

                </form>
            </div>


        </>
    )
}

export default UpdateBreakdownAdd