import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from './Layouts/DashboardLayout'
import Dashboard from './pages/DashboardMaster/Dashboard'
import Sales from "./pages/Sales";
import CustomerMaster from "./pages/Customermaster/CustomerMaster";
import CustomerAdd from "./pages/Customermaster/CustomerAdd";
import Users from "./pages/UserMaster/Users";
import SingleProduct from "./pages/SingleProduct";
import PublicRoutesLayout from "./Layouts/PublicRoutesLayout";
import Login from "./pages/Login";
import Page404 from "./pages/Page404";
import Register from "./pages/Register";
import UsersAdd from "./pages/UserMaster/UsersAdd"
import Newlift from "./pages/NewLiftMaster/Newlift";
import NewliftAdd from "./pages/NewLiftMaster/NewliftAdd";
import UpdateCustomer from "./pages/Customermaster/UpdateCustomer";
import UpdateUser from "./pages/UserMaster/UpdateUser";
import QuotationMaster from "./pages/QuotationMaster/QuotationMaster";
import QuotationAdd from "./pages/QuotationMaster/QuotationAdd";
import LiftQuatation from "./pages/QuotationMaster/LiftQuatationAdd";
import AmcModulationAdd from "./pages/QuotationMaster/AmcQuotationAdd";
import ModulationQuatationAdd from "./pages/QuotationMaster/ModulationQuatationAdd";
import NewAmcAdd from "./pages/NewAmcMaster/NewAmcAdd";
import NewAmcMaster from "./pages/NewAmcMaster/NewAmcMaster";
import NewModulationMaster from "./pages/NewModulationMaster/NewModulationMaster";
import NewModulationAdd from "./pages/NewModulationMaster/NewModulationAdd";
import OverduePaymentMaster from "./pages/OverduePaymentMaster/OverduePaymentMaster";
import PendingPaymentMaster from "./pages/PendingPaymentMaster/PendingPaymentMaster";
import AmcExpiryMaster from "./pages/AmcExpiryMaster/AmcExpiryMaster";
import BreakdownMaster from "./pages/BreakdownMaster.js/BreakdownMaster";
import BreakdownAdd from "./pages/BreakdownMaster.js/BreakdownAdd";
import LiftReportMaster from "./pages/LiftReportMaster/LiftReportMaster";
import AmcReportMaster from "./pages/AmcReportMaster/AmcReportMaster";
import BreakdownReportMaster from "./pages/BreakdownReportMaster/BreakdownReportMaster";
import ModulationReportMaster from "./pages/ModulationReportMaster/ModulationReportMaster";
import ProfileP from "./pages/ProfilePMaster/ProfileP";
import ForgetPassword from "./pages/ForgetPasswordMaster/ForgetPassword";
import ConfirmForgetPassword from "./pages/ForgetPasswordMaster/ConfirmForgetPassword";
import ResetPassword from "./pages/ForgetPasswordMaster/ResetPassword";
import UpdateBreakdownAdd from "./pages/BreakdownMaster.js/UpdateBreakdownAdd";
import UpdateNewAmcAdd from "./pages/NewAmcMaster/UpdateNewAmcAdd";
import UpdateNewModulationAdd from "./pages/NewModulationMaster/UpdateNewModulationAdd";
import UpdateAmcQuotation from "./pages/QuotationMaster/UpdateAmcQuotationAdd";
import UpdateLiftQuotationAdd from "./pages/QuotationMaster/UpdateLiftQuotationAdd";
import UpdateModulationQuotationAdd from "./pages/QuotationMaster/UpdateModulationQuotationAdd";
import UpdateNewLiftAdd from "./pages/NewLiftMaster/UpdateNewLiftAdd";
import LiftData from "./pages/QuotationMaster/LiftData";
import AmcData from "./pages/QuotationMaster/AmcData";
import ModulationData from "./pages/QuotationMaster/ModulationData";
import ReceivePayment from "./pages/ReceivePaymentMaster/ReceivePayment";
import LiftPaymentAdd from "./pages/ReceivePaymentMaster/LiftPaymentAdd";
import AmcPaymentAdd from "./pages/ReceivePaymentMaster/AmcPaymentAdd";
import ModulationPaymentAdd from "./pages/ReceivePaymentMaster/ModulationPaymentAdd";
import LiftPaidData from "./pages/ReceivePaymentMaster/LiftPaidData";
import AmcPaidData from "./pages/ReceivePaymentMaster/AmcPaidData";
import ModulationPaidData from "./pages/ReceivePaymentMaster/ModulationPaidData";
import ReceivePaymentTypes from "./pages/ReceivePaymentMaster/ReceivePaymentTypes";
import QuotationPrint from "./pages/PrintMaster/QuotationPrint";
import AmcQuatationPrint from "./pages/PrintMaster/AmcQuatationPrint";
import LiftPaymentPrint from "./pages/PrintMaster/LiftPaymentPrint";
import AmcPaymentPrint from "./pages/PrintMaster/AmcPaymentPrint";
import ModulationPaymentPrint from "./pages/PrintMaster/ModulationPaymentPrint";
import PendingPaymentPrint from "./pages/PrintMaster/PendingPaymentPrint";
import NewAmcPrint from "./pages/PrintMaster/NewAmcPrint";
import NewLIftPrint from "./pages/PrintMaster/NewLIftPrint";
import LiftReportPrint from "./pages/PrintMaster/LiftReportPrint";
import BreakdownReportPrint from "./pages/PrintMaster/BreakdownReportPrint";
import AmcReportPrint from "./pages/PrintMaster/AmcReportPrint";
import AmcPaymentReceiptPrint from "./pages/PrintMaster/AmcPaymentReceiptPrint";
import LiftPaymentReceiptPrint from "./pages/PrintMaster/LiftPaymentReceiptPrint";
import ModulationPaymentReceiptPrint from "./pages/PrintMaster/ModulationPaymentReceiptPrint";
import AmcExpiryPrint from "./pages/PrintMaster/AmcExpiryPrint";
import AmcExpiryDataPrint from "./pages/PrintMaster/AmcExpiryDataPrint";

///// for services//////
import Service from "./pages/ServiceMaster/Service";
import LiftServiceData from "./pages/ServiceMaster/LiftServiceData";
import AmcServiceData from "./pages/ServiceMaster/AmcServiceData";
import ModulationServiceData from "./pages/ServiceMaster/ModulationServiceData";
import ServiceTypes from "./pages/ServiceMaster/ServiceTypes";
import LiftServiceAdd from "./pages/ServiceMaster/LiftServiceAdd";
import AmcServiceAdd from "./pages/ServiceMaster/AmcServiceAdd";
import ModulationServiceAdd from "./pages/ServiceMaster/ModulationServiceAdd";
import ModulationQuotationPrint from "./pages/PrintMaster/ModulationQuotationPrint";
import NewModulationPrint from "./pages/PrintMaster/NewModulationPrint";
import ModulationReportPrint from "./pages/PrintMaster/ModulationReportPrint";
import EnquiryMaster from "./pages/EnquiryMaster/EnquiryMaster";
import EnquiryAdd from "./pages/EnquiryMaster/EnquiryAdd";
import EnquiryReportMaster from "./pages/EnquiryReportMaster/EnquiryReportMaster";
import EnquiryReportPrint from "./pages/PrintMaster/EnquiryReportPrint";
import UpdateEnquiryAdd from "./pages/EnquiryMaster/UpdateEnquiryAdd";
import RoughLiftReportMaster from "./pages/RoughLiftReportMaster/RoughLiftReportMaster";
import RoughAmcReportMaster from "./pages/RoughAmcReportMaster/RoughAmcReportMaster";
import RoughModulationReportMaster from "./pages/RoughModulationReportMaster/RoughModulationReportMaster";
import SecondInstallmentAmcReport from "./pages/SecondInstallmentAmcReportMaster/SecondInstallmentAmcReport";
import CustomerReportMaster from "./pages/CustomerReportMaster/CustomerReportMaster";
import AmcQuotationViewPrint from "./pages/PrintMaster/AmcQuotationViewPrint";
import LiftQuotationViewPrint from "./pages/PrintMaster/LiftQuotationViewPrint";
import ModulationQuotationViewPrint from "./pages/PrintMaster/ModulationQuotationViewPrint";
import CustomerReportPrint from "./pages/PrintMaster/CustomerReportPrint";
import RoughLiftReportPrint from "./pages/PrintMaster/RoughLiftReportPrint";
import RoughAmcReportPrint from "./pages/PrintMaster/RoughAmcReportPrint";
import RoughModulationReportPrint from "./pages/PrintMaster/RoughModulationReportPrint";
import OtherPaidData from "./pages/ReceivePaymentMaster/OtherPaidData";
import OtherPaymentAdd from "./pages/ReceivePaymentMaster/OtherPaymentAdd";
import OtherPaymentPrint from "./pages/PrintMaster/OtherPaymentPrint";
import OtherPaymentReceiptPrint from "./pages/PrintMaster/OtherPaymentReceiptPrint";
import OtherPaymentViewPrint from "./pages/PrintMaster/OtherPaymentViewPrint";
import FreeServicePrint from "./pages/PrintMaster/FreeServicePrint";
import AmcServicePrint from "./pages/PrintMaster/AmcServicePrint";
import CallbaseServicePrint from "./pages/PrintMaster/CallbaseServicePrint";

import UpdateAmcServiceAdd from "./pages/ServiceMaster/UpdateAmcServiceAdd";
import UpdateLiftServiceAdd from "./pages/ServiceMaster/UpdateLiftServiceAdd";
import UpdateModulationServiceAdd from "./pages/ServiceMaster/UpdateModulationServiceAdd";
import NewLiftViewPrint from "./pages/PrintMaster/NewLiftViewPrint";
import NewAmcViewPrint from "./pages/PrintMaster/NewAmcViewPrint";
import NewModulationViewPrint from "./pages/PrintMaster/NewModulationViewPrint";



export default function Router() {
    return useRoutes([
        {
            path: "/app",
            element: <DashboardLayout />,
            children: [
                { path: "dashboard", element: <Dashboard /> },
                { path: "users", element: <Users /> },
                { path: "customer", element: <CustomerMaster /> },
                { path: "products/:productId", element: <SingleProduct /> },
                { path: "quotation", element: < QuotationMaster /> },
                { path: "new_amc", element: <NewAmcMaster /> },
                { path: "new_modulation", element: <NewModulationMaster/> },
                { path: "add", element: <CustomerAdd /> },
                { path: "add_user", element: <UsersAdd /> },
                { path: "new_lift", element: <Newlift/> },
                { path: "new_lift_add", element: <NewliftAdd/> },
                { path: "update_new_lift_add/:id", element: <UpdateNewLiftAdd/> },
                { path: "update_customer/:id", element: <UpdateCustomer/> },
                { path: "update_user/:id", element: <UpdateUser/> },
                { path: "quotation_add", element: <QuotationAdd /> },
                { path: "lift_quotation_add", element: <LiftQuatation /> },
                { path: "update_lift_quotation_add/:id", element: <UpdateLiftQuotationAdd/> },
                { path: "lift_data", element: <LiftData /> },
                { path: "amc_quotation_add", element: <AmcModulationAdd /> },
                { path: "update_amc_quotation_add/:id", element: <UpdateAmcQuotation/> },
                { path: "amc_data", element: <AmcData /> },
                { path: "modulation_quotation_add", element: <ModulationQuatationAdd /> },
                { path: "modulation_data", element: <ModulationData/> },
                { path: "update_modulation_quotation_add/:id", element: <UpdateModulationQuotationAdd /> },
                { path: "new_amc_add", element: <NewAmcAdd /> },
                { path: "update_new_amc_add/:id", element: <UpdateNewAmcAdd /> },
                { path: "new_modulation_add", element: <NewModulationAdd/> },
                { path: "update_new_modulation_add/:id", element: <UpdateNewModulationAdd/> },
                { path: "overdue_payment", element: <OverduePaymentMaster/> },
                { path: "pending_payment", element: <PendingPaymentMaster/> },
                { path: "amc_expiry", element: <AmcExpiryMaster/> },
                { path: "breakdown", element: <BreakdownMaster/> },
                { path: "breakdown_add", element: <BreakdownAdd/> },
                { path: "update_breakdown_add/:id", element: <UpdateBreakdownAdd/> },  
                { path: "lift_report", element: <LiftReportMaster/> },
                { path: "amc_report", element: <AmcReportMaster/> },
                { path: "breakdown_report", element: <BreakdownReportMaster/> },
                { path: "modulation_report", element: <ModulationReportMaster/> },
                { path: "profile_page", element: <ProfileP/> },
                { path: "forget_password", element: <ForgetPassword/> },
                { path: "confirm_forget_password", element: <ConfirmForgetPassword/> },
                { path: "reset_password", element: <ResetPassword/> },
                // { path: "payment_types", element: <PaymentTypes/> },
                { path: "receive_payment", element: <ReceivePayment/> },
                { path: "receive_payment_types", element: <ReceivePaymentTypes/> },
                { path: "lift_paid_data", element: <LiftPaidData/> },
                { path: "amc_paid_data", element: <AmcPaidData/> },
                { path: "modulation_paid_data", element: <ModulationPaidData/> },
              
                { path: "lift_payment", element: <LiftPaymentAdd/> },
                { path: "amc_payment", element: <AmcPaymentAdd/> },
                { path: "modulation_payment", element: <ModulationPaymentAdd/> },

                { path: "quotation_print", element: <QuotationPrint/> },
                { path: "amc_quotation_print", element: <AmcQuatationPrint /> },
                { path: "lift_payment_print", element: <LiftPaymentPrint/> },
                { path: "amc_payment_print", element: <AmcPaymentPrint/> },
                { path: "modulation_payment_print", element: <ModulationPaymentPrint/> },
                { path: "pending_payment_print", element: <PendingPaymentPrint/> },
                { path: "new_amc_print", element: <NewAmcPrint /> },
                { path: "new_lift_print", element: <NewLIftPrint/> },
                { path: "lift_report_print", element: <LiftReportPrint/> },
                { path: "breakdown_report_print", element: <BreakdownReportPrint/> },
                { path: "amc_report_print", element: <AmcReportPrint/> },
                { path: "amc_payment_receipt_print", element: <AmcPaymentReceiptPrint/> },
                { path: "lift_payment_receipt_print", element: <LiftPaymentReceiptPrint/> },
                { path: "modulation_payment_receipt_print", element: <ModulationPaymentReceiptPrint/> },
                { path: "amc_expiry_print", element: <AmcExpiryPrint/> },
                { path: "amc_expiry_data_print", element: <AmcExpiryDataPrint/> },
                { path: "modulation_quotation_print", element: <ModulationQuotationPrint/> },
                { path: "new_modulation_print", element: <NewModulationPrint/> },
                { path: "modulation_report_print", element: <ModulationReportPrint/> },
                { path: "amc_quotation_view_print/:dataId", element: <AmcQuotationViewPrint/> },
                // { path: "lift_quotation_view_print", element: <LiftQuotationViewPrint/> },
                { path: "lift_quotation_view_print/:dataId", element: <LiftQuotationViewPrint/> },            
                { path: "modulation_quotation_view_print/:dataId", element: <ModulationQuotationViewPrint/> },


                //////// for services ////////
                { path: "service", element: <Service/> },
                { path: "service_types", element: <ServiceTypes/> },
                { path: "lift_service_data", element: <LiftServiceData/> },
                { path: "lift_service", element: <LiftServiceAdd/> },
                { path: "amc_service_data", element: <AmcServiceData/> },
                { path: "amc_service", element: <AmcServiceAdd/> },
                { path: "callbase_service_data", element: <ModulationServiceData/> },
                { path: "callbase_service", element: <ModulationServiceAdd/> },

                //////////////// for enquiry ////////////////////
                { path: "enquiry", element: <EnquiryMaster/> },
                { path: "enquiry_add", element: <EnquiryAdd/> },
                { path: "update_enquiry_add/:id", element: <UpdateEnquiryAdd/> },
                { path: "enquiry_report", element: <EnquiryReportMaster/> },
                { path: "enquiry_report_print", element: <EnquiryReportPrint/> },
                
                //////////////////////////////////////////////////////////////////

                { path: "rough_lift_report", element: <RoughLiftReportMaster/> },
                { path: "rough_amc_report", element: <RoughAmcReportMaster/> },
                { path: "rough_modulation_report", element: <RoughModulationReportMaster/> },
                { path: "second_installment_amc_report", element: <SecondInstallmentAmcReport/> },
                { path: "customer_report", element: <CustomerReportMaster/> },  
                
                { path: "customer_report_print", element: <CustomerReportPrint/> },
                { path: "rough_lift_report_print", element: <RoughLiftReportPrint/> },
                { path: "rough_amc_report_print", element: <RoughAmcReportPrint/> },
                { path: "rough_mod_report_print", element: <RoughModulationReportPrint/> },
                { path: "other_paid_data", element: <OtherPaidData/> },
                { path: "other_payment", element: <OtherPaymentAdd/> },
                { path: "other_payment_view_print/:id", element: <OtherPaymentViewPrint/> },
                { path: "other_payment_receipt_print", element: <OtherPaymentReceiptPrint/> },
                { path: "other_payment_print", element: <OtherPaymentPrint/> }, 
                { path: "free_service_print", element: <FreeServicePrint/> }, 
                { path: "amc_service_print", element: <AmcServicePrint/> }, 
                { path: "callbase_service_print", element: <CallbaseServicePrint/> }, 
                { path: "update_lift_service_data/:id", element: <UpdateLiftServiceAdd/> },
                { path: "update_amc_service/:id", element: <UpdateAmcServiceAdd/> },
                { path: "update_callbase_service/:id", element: <UpdateModulationServiceAdd/> },

                { path: "new_lift_view_print/:dataId", element: <NewLiftViewPrint/> },
                { path: "new_amc_view_print/:dataId", element: <NewAmcViewPrint/> },
                { path: "new_modulation_view_print/:dataId", element: <NewModulationViewPrint/> },
                
            ],
        },
        {
            path: "/",
            element: <PublicRoutesLayout />,
            children: [
                { index: true, element: <Login /> },
                { path: "register", element: <Register /> },
                // { path: "page404", element: <Page404 /> },
                { path: "profile_page", element: <ProfileP/> },
                { path: "forget_password", element: <ForgetPassword/> },
                { path: "confirm_forget_password", element: <ConfirmForgetPassword/> },
                { path: "reset_password", element: <ResetPassword/> },
                
            ],
        },
        { path: "*", element: <Navigate to="/profile_page" replace={true} /> },
    ]);
}