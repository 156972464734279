import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'

const ModulationPaymentAdd = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('');

    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    const [selectedDates, setSelectedDates] = useState({});
    const [statusByItemId, setStatusByItemId] = useState({});

    function openLift() {

        navigate("/app/lift_paid_data")
    }

    function openAmc() {
        navigate("/app/amc_paid_data")
    }

    function openModulation() {
        navigate("/app/modulation_paid_data")
    }

    function openOther() {
        navigate("/app/other_paid_data")
    }

///////////////////////////////////////////////////////////////

    const openPrint = () => {

        navigate("/app/modulation_payment_print", {

            state: { data:filteredData },
        });
    }

    const openPrintData = (item) => {

        navigate("/app/modulation_payment_receipt_print", { state: { selectedItem: item } });
    }

    /////////////////////////////////////////////////////////////////////////////


    /////////////////////// Update onselect Date change ////////////////////////////////
    const handleDateChange = (mod_pay_id, newDate) => {
        setSelectedDates((prevSelectedDates) => ({
            ...prevSelectedDates,
            [mod_pay_id]: newDate,
        }));

        // Send the date value to the backend here
        updateDateInDatabase(mod_pay_id, newDate);
    };


    const updateDateInDatabase = async (mod_pay_id, newDate) => {
        try {
            const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/update_mod_pay_date`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mod_pay_id: mod_pay_id, // Use the ID of the record you want to update
                    newDate: newDate, // Use the selected date
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Handle the response from the server (e.g., show a success message)
            console.log(data.message);
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error(error);
        }
    };

    ////////////////////// To select an Status //////////////////////////

    async function updateStatusInAPI(mod_pay_id, newStatus) {
        try {
            const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/update_mod_pay_status", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mod_pay_id: mod_pay_id,
                    newStatus: newStatus,
                }),
            });

            if (!response.ok) {
                // Handle non-successful responses (e.g., server error)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Handle the response from the server (e.g., show a success message)
            console.log(data.message);

            // Return the updated status or response data if needed
            return data.status; // You may replace 'status' with the actual property name from your API response
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error(error);

            // Return null or an error indicator if needed
            return null;
        }
    }
    //////////////////////////////////Select an Status/////////////////////////////////////////////////

    // Usage within your component
    const handleStatusChange = async (mod_pay_id, newStatus) => {
        // Update the status in the state immediately for a smooth user experience
        setStatusByItemId((prevStatus) => ({
            ...prevStatus,
            [mod_pay_id]: newStatus,
        }));

        // Make the API call to update the status in the backend
        const updatedStatus = await updateStatusInAPI(mod_pay_id, newStatus);

        if (updatedStatus !== null) {
            // Handle the updated status if needed
        } else {
            // Handle errors, e.g., revert the state to the previous value
            setStatusByItemId((prevStatus) => ({
                ...prevStatus,
                [mod_pay_id]: prevStatus[mod_pay_id], // Revert to the previous status
            }));
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    ///////////////////// api call/////////////////////////

    useEffect(() => {

        getData()

    }, [currentPage])

    function getData() {

        async function fetchData() {
            try {
                let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_mod_payment_data?page=${currentPage}&perPage=${itemsPerPage}`);
                const { data: fetchedData, last_page: total } = await result.json();
                // console.log("data", result);

                const statusDate = {};
                fetchedData.forEach((item) => {
                    statusDate[item.mod_pay_id] = item.mod_follow_date; // Replace 'li_quo_status' with the actual property name from your API response
                });

                const statusData = {};
                fetchedData.forEach((item) => {
                    statusData[item.mod_pay_id] = item.mod_pay_work_status; // Replace 'li_quo_status' with the actual property name from your API response
                });

                setData(fetchedData);
                setStatusByItemId(statusData);
                setSelectedDates(statusDate)
                setTotalPages(total);
            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchData();

    }




    const filteredData = data.filter(item => {
        const cuIdString = (item.mod_pay_unique_id || '').toString();
        const lowercaseSearch = (searchValue || '').toLowerCase();
        const status = (item.mod_pay_work_status || '').toLowerCase();
        const date = (item.mod_follow_date || '').toString();

        return cuIdString.includes(lowercaseSearch) ||
            (item.mod_pay_cu_name || '').toLowerCase().includes(lowercaseSearch) ||
            status.includes(lowercaseSearch) || 
            date.includes(lowercaseSearch);
    });



    // Filtering logic
    // const filteredData = data.filter(item => {
    //     const cuIdString = item.mod_pay_work_status.toLowerCase();
    //     const lowercaseSearch = searchValue.toLowerCase();
    //     return cuIdString.includes(lowercaseSearch) || item.mod_pay_cu_name.toLowerCase().includes(lowercaseSearch);
    // });

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Calculate the range of page numbers to display in the pagination UI
    const getPageRange = () => {
        const rangeSize = 5; // Number of page numbers to display
        const totalPagesInRange = Math.min(rangeSize, totalPages);
        let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
        let endPage = startPage + totalPagesInRange - 1;

        // Adjust endPage if it exceeds totalPages
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - totalPagesInRange + 1, 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };



    return (
        <>
            <div className='row d-flex justify-content-around '>
                <button onClick={openLift} className='btn btn-primary col-md-2 mt-4'>Lift Pending Payment</button>
                <button onClick={openAmc} className='btn btn-primary col-md-2 mt-4'>AMC Pending Payment</button>
                <button onClick={openModulation} className='btn btn-primary col-md-2 mt-4'>Modulation Pending Payment</button>
                <button onClick={openOther} className='btn btn-primary col-md-2 mt-4'>Other Pending Payment</button>
            </div>

            <div className='conatiner mt-5'>
                <div className="row ">
                    <div className='col-md-8'>
                        <h5>Modulation Payment Data</h5>
                        <button type="button" onClick={openPrint} className="btn btn-primary mx-3">Print</button>
                    </div>

                    <div className='col-md-4'>

                        <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
                            onChange={e => setSearchValue(e.target.value)} />

                    </div>

                </div>

                <hr />

                <Table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Sl No.</th>
                            <th scope="col">Job Id</th>
                            <th scope="col">Customer name</th>
                            <th scope="col">Receive Payment Date</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col">Payment Mode</th>
                            <th scope="col">Quotation Amt</th>
                            <th scope="col">Pending Amt</th>
                            <th scope="col">Recently Paid Amt</th>
                            <th scope="col">Total Bal</th>
                            <th scope="col">Site Address</th>
                            <th scope="col">Follow up Date</th>
                            <th scope="col">Working Status</th>
                            <th scope="col">Calling Option</th>
                            <th></th>


                        </tr>
                    </thead>
                    <tbody>

                        {
                            filteredData.map((item) =>
                                <tr key={item.mod_pay_id}>
                                    <td>{item.mod_pay_id}</td>
                                    <td>{item.mod_pay_unique_id}</td>
                                    <td>{item.mod_pay_cu_name}</td>
                                    <td>{item.mod_pay_date}</td>
                                    <td>{item.mod_pay_mobile_no}</td>
                                    <td>{item.mod_payment_mode}</td>
                                    <td>{item.mod_pay_quo_amt}</td>
                                    <td>{item.mod_pay_pre_bal}</td>
                                    <td>{item.mod_pay_paid_amt}</td>
                                    <td>{item.mod_pay_total_bal}</td>
                                    <td>{item.mod_pay_address}</td>
                                    <td><input
                                        type="date"
                                        className="form-control a1"
                                        id={`quoDate-${item.mod_pay_id}`}
                                        name="quoDate"
                                        value={selectedDates[item.mod_pay_id] || ""}
                                        onChange={(e) => handleDateChange(item.mod_pay_id, e.target.value)}
                                    /></td>
                                    <td><select className="form-select border border-dark form-control"
                                        style={{ boxShadow: 'none', width: "150px" }}
                                        aria-label="Default select example"
                                        name='liftStatus'
                                        id='liftStatus'
                                        value={statusByItemId[item.mod_pay_id] || ''} // Set the value of the select element to the state variable
                                        onChange={(e) => handleStatusChange(item.mod_pay_id, e.target.value)} // Update the state when the user changes the value
                                    >
                                        <option value="Payment Follow" style={{ background: '#dddddd', color: "blue" }}>Payment Follow</option>
                                        <option value="Work In Process" style={{ background: '#dddddd', color: "red" }}>Work In Process</option>
                                        <option value="Issue" style={{ background: '#dddddd', color: "green" }}>Issue</option>
                                        <option value="Completed" style={{ background: '#dddddd', color: "purple" }}>Completed</option>
                                        <option value="Take Advance" style={{ background: '#dddddd', color: "orange" }}>Take Advance</option>

                                    </select></td>
                                    <td className='d-flex justify-content-between'><a href={`https://wa.me/${item.mod_pay_mobile_no}`} target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                                        <a href={`tel:${item.mod_pay_mobile_no}`}><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                                    <td><button
                                        type="button"
                                        onClick={() => openPrintData(item)} // Pass the item data to the function
                                        className="btn btn-primary mx-3"
                                    >
                                        Print
                                    </button></td>


                                </tr>

                            )

                        }

                    </tbody>
                </Table>

                {/* Pagination */}
                <div className='pagination'>
                    {/* Show previous arrow icon */}
                    {currentPage > 1 && (
                        <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
                    )}

                    {/* Show page numbers */}
                    {getPageRange().map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}

                    {/* Show next arrow icon */}
                    {currentPage < totalPages && (
                        <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                    )}
                </div>


                {/* <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                    <button type="button" className="btn btn-primary mx-2 ms-5 ">Preview</button>
                    <button type="button" onClick={openPrint} className="btn btn-primary mx-3">Print</button>

                </div> */}

            </div>

        </>
    )
}

export default ModulationPaymentAdd