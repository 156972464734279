import * as Yup from "yup";

export const amcPaymentSchema = Yup.object({

//  customerName:  Yup.string().required("Please select customer name"),

 paidAmt:  Yup.string().required("Please enter pay amount"),
 payDate:  Yup.string().required("Please select date"),
 paymentMode:  Yup.string().required("Please enter payment mode"),
 amcPayStatus:Yup.string().required("Please select  status"),
//  followUpDate:  Yup.string().required("Please select follow up date"),
 receivedBy:  Yup.string().required("Please enter received by"),
//  remarks:  Yup.string().required("Please enter remarks"),
//  uniqueId: Yup.string().required("Please enter site id"),


});