import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import '../../pagination.css';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';

const AmcServiceData = () => {

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');

    const [data, setData] = useState([])

    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    /////////////////////////////////////////////////////////////////


    function openReceivePayment() {

        navigate("/app/service_types")
    }

    function openLift() {

        navigate("/app/lift_service_data")
    }

    function openAmc() {
        navigate("/app/amc_service_data")
    }

    function openCallBase() {
        navigate("/app/callbase_service_data")
    }

    const openPrintData = (item) => {

        navigate("/app/amc_service_print", { state: { selectedItem: item } });
    }

    ///////////////////////////////////api call////////////////////////////////
    const handleUpdate = (amc_ser_id) => {
        // Navigate two steps back
        navigate(`/app/update_amc_service/${amc_ser_id}`);
    };

    ////////////////////// Delete Api ////////////////////////////////

    async function deleteOperation(id) {
        // alert(id)
        const confirmDelete = window.confirm('Do you want delete?');
        if (confirmDelete) {
            let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_amc_service/" + id, {
                method: 'DELETE'
            });
            result = await result.json();
            // console.log(result)
            if (result.message) {
                // If the response status is within the 2xx range, it means the request was successful.
                if (result.message) {
                    // alert(result.message);
                    alert('Deletion confirmed. Deleting...');
                } else {
                    alert('Deletion cancelled.');
                }

            } else {
                alert("Customer not found.");
            }

            getData();

        }
    }


    /////////////////////////////////////////////////////////////////////////////////////////




    useEffect(() => {

        getData()

    }, [currentPage])

    function getData() {

        async function fetchData() {
            try {
                let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/amc_service_list?page=${currentPage}&perPage=${itemsPerPage}`);
                const { data: fetchedData, last_page: total } = await result.json();
                // console.log("data", result);
                setData(fetchedData);
                setTotalPages(total);
            } catch (error) {
                console.error('Error', error);
            }
        }

        fetchData();

    }

    // Filtering logic
    const filteredData = data.filter(item => {
        const cuIdString = item.amc_ser_cust_id.toString();
        const lowercaseSearch = searchValue.toLowerCase();
        return cuIdString.includes(lowercaseSearch) || item.amc_ser_cust_name.toLowerCase().includes(lowercaseSearch);
    });


    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Calculate the range of page numbers to display in the pagination UI
    const getPageRange = () => {
        const rangeSize = 5; // Number of page numbers to display
        const totalPagesInRange = Math.min(rangeSize, totalPages);
        let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
        let endPage = startPage + totalPagesInRange - 1;

        // Adjust endPage if it exceeds totalPages
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - totalPagesInRange + 1, 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };


    return (
        <>

            <div className=" ">
                <h5>Service Details</h5>
                <hr />
                <button onClick={openReceivePayment} className="btn btn-primary buttonMargin col-2 d-grip w-auto">Add Service</button>
            </div>


            <div className='row d-flex justify-content-around '>
                <button onClick={openLift} className='btn btn-primary col-md-3 mt-4'>  Free Service Data</button>
                <button onClick={openAmc} className='btn btn-primary col-md-3 mt-4'>AMC Service Data</button>
                <button onClick={openCallBase} className='btn btn-primary col-md-3 mt-4'>Callbase Service Data</button>
                {/* <button onClick={openModulation} className='btn btn-primary col-3 mt-4'>Modulation Service Data</button> */}
            </div>

            <div className='conatiner mt-5'>



                <div className="row ">
                    <div className='col-md-10'>
                        <h5>AMC Service Data</h5>
                        {/* <button type="button" onClick={openPrint} className="btn btn-primary mx-3">Print</button> */}
                    </div>

                    <div className='col-md-2'>

                        <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
                            onChange={e => setSearchValue(e.target.value)} />

                    </div>
                </div>

                <hr />

                <Table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Sl No.</th>
                            <th scope="col">Quotation No</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Site     Id </th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Date</th>
                            <th scope="col">Tech Name</th>
                            <th scope="col">Sign</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Calling Option</th>
                            <th scope="col">Action</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.map((item) =>
                                <tr key={item.amc_ser_id}>
                                    {/* <th scope="row">1</th> */}
                                    <td>{item.amc_ser_id}</td>
                                    <td>{item.amc_ser_quo_no}</td>
                                    <td>{item.amc_ser_cust_name}</td>
                                    <td>{item.amc_ser_cust_id}</td>
                                    <td>{item.amc_ser_mob_no}</td>
                                    <td>{item.amc_ser_date}</td>
                                    <td>{item.amc_ser_tech_name}</td>
                                    <td>
                                        <img src={item.amc_ser_sign_data} alt="Signature" />
                                    </td>
                                    <td>{item.amc_ser_remark}</td>
                                    <td className='d-flex justify-content-evenly'><a href={`https://wa.me/${item.amc_ser_mob_no}`} target='_blank'><img src="/img/pngtree-whatsapp-icon-social-media-png-image_6618452.png" alt="" style={{ height: "30px", width: "30px" }} /></a>
                                        <a href={`tel:${item.amc_ser_mob_no}`}><img src="/img/png-transparent-truecaller-android-telephone-phone-blue-telephone-call-mobile-phones.png" alt="" style={{ height: "30px", width: "30px" }} /></a></td>
                                    <td><span className='update' onClick={() => handleUpdate(item.amc_ser_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.amc_ser_id) }}>{<DeleteIcon style={{ color: 'red' }} />}</span></td>
                                    <td><button
                                        type="button"
                                        onClick={() => {
                                            console.log("item", item);
                                            openPrintData(item)
                                        }} // Pass the item data to the function
                                        className="btn btn-primary mx-3"
                                    >
                                        Print
                                    </button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>


                {/* Pagination */}
                <div className='pagination'>
                    {/* Show previous arrow icon */}
                    {currentPage > 1 && (
                        <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
                    )}

                    {/* Show page numbers */}
                    {getPageRange().map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}

                    {/* Show next arrow icon */}
                    {currentPage < totalPages && (
                        <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                    )}
                </div>

                {/* <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                    <button type="button" onClick={openPrint} className="btn btn-primary mx-3">Print</button>

                </div> */}


            </div>



        </>
    )
}

export default AmcServiceData