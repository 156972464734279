import React from 'react'

const SingleProduct = () => {
  return (
  <>
  <div className='page__main'>
      <h1> SingleProduct</h1>
  </div>
  </>
  )
}

export default SingleProduct