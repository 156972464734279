import React, { useState, useEffect, useRef } from 'react'
import { useNavigate , useParams} from "react-router-dom";
import { modulationServiceSchema } from '../Schema/ModulationServiceAddIndex';
import { useFormik } from 'formik';
import SignatureCanvas from 'react-signature-canvas';

const initialValues = {
    customerName: "",
    mobileNo: "",
    // quotationNo: "",
    preAddress: "",
    quoDate: "",
    remark: "",
    technicianName: "",
    uniqueId: "",

};



const UpdateModulationServiceAdd = () => {

    const navigate = useNavigate();
    const sigCanvas = useRef({})

    const { id } = useParams();
    const [data, setData] = useState([])
    const [quoDate, setQuoDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [technicianName, setTechnicianName] = useState("");
    const [remark, setRemark] = useState("");
    const [preAddress, setPreAddress] = useState("");
    const [mobileNo, setMobileNo] = useState("");


////////////////////////////////////////////////////////////////////////////////////////  

    const clear = () => {
        sigCanvas.current.clear();
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
        initialValues,

        
        onSubmit: async (values, action) => {
            console.log("values:", values);
           
            // action.resetForm();
            navigate("/app/callbase_service_data")
        },
    });
console.log(errors);


////////////////////////////////////////////////////////////////////////////////////////////////

function openLiftPayment() {
    navigate("/app/lift_service")
}

function openAmcPayment() {
    navigate("/app/amc_service")
}

function openCallBase() {
    navigate("/app/callbase_service")
}

/////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////



    const handleQuoDate = (event) => {

        setQuoDate(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");
        handleChange(event);
        setMobileNo(event.target.value);

    }

 

    const handlePreAddress = (event) => {
        // console.log("On Change");
        handleChange(event);
        setPreAddress(event.target.value);

    }

    const handleTechnicianName = (event) => {
        // console.log("On Change");

        setTechnicianName(event.target.value);
        handleChange(event);
    }

    const handleRemark = (event) => {
        // console.log("On Change");

        setRemark(event.target.value);
        handleChange(event);
    }

    const handleChangeCustomer = (event) => {
        // console.log("On Change");

        setCustomerName(event.target.value);
        handleChange(event);
    }


//////////////////////////////////////////////////////////////////////////////////////////////////

useEffect(() => {

    async function fetchData() {
        try {
            const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/update_service_callbasedata/${id}`);
            const result = await response.json();
            console.log("data:", result);
            setData(result);
          
            setCustomerName(result.mod_ser_cust_name)
            setMobileNo(result.mod_ser_mob_no)
            setPreAddress(result.mod_ser_address)
            setRemark(result.mod_ser_remark)
            
            setTechnicianName(result.mod_ser_tech_name)
            setQuoDate(result.mod_ser_date)

            const signatureData = result.mod_ser_sign_data;

            if (sigCanvas.current) {
                sigCanvas.current.fromDataURL(signatureData);
            }

        } catch (error) {
            console.error('Error', error);
        }
    }

    fetchData();

}, [id]);

//////////////////////////////////////////////////////////////////////////////////////////////////

async function editProduct(id) {
    const formData = new FormData();
   
    formData.append("quoDate", quoDate)
    formData.append("customerName", customerName)
    formData.append("mobileNo", mobileNo)  
    formData.append("preAddress", preAddress)
    formData.append("technicianName", technicianName)
    formData.append("remark", remark)

    const canvasData = sigCanvas.current.toDataURL();
    formData.append("signatureData", canvasData);

    console.log()

    try {
        let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/callbase_service_update_store/' + id + '?method=PUT', {
            method: 'POST',
            body: formData
        });

        let data1 = await response.json(); // Parse the response body as JSON

        if (data1.message) {
            // If the response status is within the 2xx range, it means the request was successful.
            alert(data1.message);


        } else {
            alert("An error occurred while saving the data.");
        }


    } catch (error) {
        console.error('Error while adding customer:', error);
        alert("An error occurred while saving the data2.");

    }
}

//////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
    
    <div className='row d-flex justify-content-around'>
                <button onClick={openLiftPayment} className='btn btn-primary col-md-3 mt-4'>Free Service</button>
                <button onClick={openAmcPayment} className='btn btn-primary col-md-3 mt-4'>AMC Service</button>
                <button onClick={openCallBase} className='btn btn-primary col-md-3 mt-4'>Callbase Service Data</button>
                {/* <button onClick={openPaidModulation} className='btn btn-primary col-md-3 mt-4'>Modulation Service Data</button> */}
            </div>
            
             
            <div className='container'>

                <form onSubmit={handleSubmit} >


                    <div className="row mb-1 mt-5">
                        <div className="col-md-9 ">
                            <div className="form-group">
                                <h4 className='mt-4'>CallBase Service</h4>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="quoDate" className='' style={{ fontSize: '14px' }}>Date</label>
                                <input type="date" name='quoDate' className="form-control a1" id="quoDate" defaultValue={data.mod_ser_date}
                                    // value={values.quoDate}
                                    onChange={handleQuoDate}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>


                    </div>

                    <hr />

                    <div className="row mb-1">


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '14px' }}>Customer</label>
                                <input type="text" name='customerName' className="form-control a1" id="customerName" defaultValue={data.mod_ser_cust_name}
                                    // value={values.customerName}
                                    onChange={handleChangeCustomer}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                    

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="technicianName" className='' style={{ fontSize: '14px' }}>Technician Name</label>
                                <input type="text" name='technicianName' className="form-control a1" id="technicianName" defaultValue={data.mod_ser_tech_name}
                                    // value={values.technicianName}
                                    onChange={handleTechnicianName}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="remark" className='' style={{ fontSize: '14px' }}>Remarks</label>
                                <input type="text" name='remark' className="form-control a1" id="remark" defaultValue={data.mod_ser_remark}
                                    // value={values.remark}
                                    onChange={handleRemark}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '14px' }}>Mobile No</label>
                                <input type="number" name='mobileNo' className="form-control a1" id="mobileNo" defaultValue={data.mod_ser_mob_no}
                                    // value={values.mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur}

                                />
                              

                            </div>
                        </div>

                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12 ">
                            <div className="form-group">
                                <label htmlFor="preAddress" className='name_label address' style={{ fontSize: '14px' }} >Address</label>
                                <textarea className="form-control a1 address_input" name='preAddress' id="preAddress" rows="5" defaultValue={data.mod_ser_address}
                                    // value={values.preAddress}
                                    onChange={handlePreAddress}
                                    onBlur={handleBlur}
                                ></textarea>

                 
                            </div>
                        </div>

                    </div>

                    <div className="row mb-5 my-5">
                        <div className="col-md-4 ">
                            <SignatureCanvas
                                penColor="black"
                                canvasProps={{ width: 500, height: 200, className: 'sigCanvas', style: { border: '1px solid #000' } }}
                                ref={sigCanvas}
                            />
                            <button type="buttton" onClick={clear}>Clear</button>
                           
                        </div>
                    </div>

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                    <button onClick={() => editProduct(data.mod_ser_id)} className="btn btn-primary btn-lg float-right " type="submit">Save</button>

                    </div>

                </form>

            </div>


    </>
  )
}

export default UpdateModulationServiceAdd