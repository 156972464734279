import React, { useState } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap';
import { RoughAmcReportSchema } from '../Schema/RoughAmcReportIndex';


const initialValues = {
    startDate: "",
    endDate: "",
    customerName: "",
};

const RoughAmcReportMaster = () => {



    const navigate = useNavigate();
    const [customerDetails, setCustomerDetails] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [selectedDates, setSelectedDates] = useState({});
    const [statusByItemId, setStatusByItemId] = useState({});

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: RoughAmcReportSchema,
            onSubmit: (values, action) => {
                console.log("values:", values);
                action.resetForm();
                // navigate("/app/breakdown")

            },
        });

/////////////////////////////////////////////////////////////

const openPrint = () => {
       
    navigate("/app/rough_amc_report_print", {
      
        state : { customerDetails , searchValue  },
      });
}

//////////////////////////////////////////////////////////////////////////////////

/////////////////////// Update onSElect Date date ////////////////////////////////
const handleDateChange = (amc_quo_id, newDate) => {
    setSelectedDates((prevSelectedDates) => ({
        ...prevSelectedDates,
        [amc_quo_id]: newDate,
    }));

    // Send the date value to the backend here
    updateDateInDatabase(amc_quo_id, newDate);
};


const updateDateInDatabase = async (amc_quo_id, newDate) => {
    try {
        const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/updateAmcdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amc_quo_id: amc_quo_id, // Use the ID of the record you want to update
                newDate: newDate, // Use the selected date
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Handle the response from the server (e.g., show a success message)
        console.log(data.message);
    } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error(error);
    }
};


///////////////////////////////// Api Call for onselect change the dropdown values ///////////////////

////////////////////// To select an Status //////////////////////////

async function updateStatusInAPI(amc_quo_id, newStatus) {
    try {
        const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/updateAmcStatus", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amc_quo_id: amc_quo_id,
                newStatus: newStatus,
            }),
        });

        if (!response.ok) {
            // Handle non-successful responses (e.g., server error)
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Handle the response from the server (e.g., show a success message)
        console.log(data.message);

        // Return the updated status or response data if needed
        return data.status; // You may replace 'status' with the actual property name from your API response
    } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error(error);

        // Return null or an error indicator if needed
        return null;
    }
}


//////////////////////////////////Slect an Status/////////////////////////////////////////////////

// Usage within your component
const handleStatusChange = async (amc_quo_id, newStatus) => {
// Update the status in the state immediately for a smooth user experience
setStatusByItemId((prevStatus) => ({
    ...prevStatus,
    [amc_quo_id]: newStatus,
}));

// Make the API call to update the status in the backend
const updatedStatus = await updateStatusInAPI(amc_quo_id, newStatus);

if (updatedStatus !== null) {
    // Handle the updated status if needed
} else {
    // Handle errors, e.g., revert the state to the previous value
    setStatusByItemId((prevStatus) => ({
        ...prevStatus,
        [amc_quo_id]: prevStatus[amc_quo_id], // Revert to the previous status
    }));
}
};





/////////////////////////////// Api Call////////////////////////////////


    const handleSearchClick = async (event) => {
        event.preventDefault();

        const startDate = values.startDate;
        const endDate = values.endDate;

        try {
            let response;

            // response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/rough-amc-report?startDate=${startDate}&endDate=${endDate}`);

            if (startDate && endDate) {
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/rough-amc-report?startDate=${startDate}&endDate=${endDate}`);
              } else {
                // If startDate and endDate are not present, make the API request without them
                response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/rough-amc-report`);
              }


            if (response.ok) {
                const data = await response.json();
                console.log("Enquiry report details:", data);

              

                const filteredData = data.filter((item) => {
                    const workStatus = item.amc_quo_work_status ? item.amc_quo_work_status.toLowerCase() : '';
                    const customerName = item.amc_quo_cu_name ? item.amc_quo_cu_name.toLowerCase() : '';
                    const followUpDate = item.amc_call_back_date ? item.amc_call_back_date : '';
                    return (
                        workStatus.includes(searchValue.toLowerCase()) ||
                        customerName.includes(searchValue.toLowerCase()) || 
                        followUpDate.includes(searchValue)
                    );
                });


                if (filteredData.length === 0) {
                    // No data found, display a message to the user
                    console.log("No data found.");
                    window.alert("No data found.");
                    // You can update the state or display a message to the user here.
                } else {
                    setCustomerDetails(filteredData);

                    const statusData = filteredData.reduce((acc, item) => {
                        acc[item.amc_quo_id] = item.amc_quo_work_status;
                        return acc;
                    }, {});
                    setStatusByItemId(statusData);

                    const statusDate = filteredData.reduce((acc, item) => {
                        acc[item.amc_quo_id] = item.amc_call_back_date;
                        return acc;
                    }, {});
                    setSelectedDates(statusDate);
                }
            } else {
                console.error('Error Enquiry report details:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching Enquiry report details:', error);
        }
    };


    return (
        <>

            <div className='container'>

                <form onSubmit={handleSubmit}>


                    <div className="row ">
                        <div className='col-md-10 mt-4 mt-md-0'>
                            <h5>Rough AMC Report</h5>
                        </div>


                       
                        <div className='col-md-2'>

                            <input
                                type="text"
                                className="form-control"
                                style={{ borderColor: 'black' }}
                                placeholder="Search by Customer ID or Name"
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />

                        </div>

                    </div>

                    <hr />

                    <div className="d-md-flex justify-content-between">

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="startDate" className='' style={{ fontSize: '12px' }}>Start Date</label>
                                <input type="date" className="form-control a1" id="startDate" name='startDate'
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.startDate && errors.startDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="endDate" className='' style={{ fontSize: '12px' }}>End Date</label>
                                <input type="date" className="form-control a1" id="endDate" name='endDate'
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {touched.endDate && errors.endDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='submit' className='btn btn-primary' onClick={(event) => { handleSearchClick(event); setSearchValue(''); }}>Search</button>
                            </div>
                        </div>


                        <div className="">
                            <div className="form-group  mt-4">
                                <button type='button' onClick={openPrint} className='btn btn-primary '>Print</button>
                            </div>
                        </div>

                    </div>

                    <Table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">Date</th>
                                <th scope="col">Cust Name</th>
                                <th scope="col">Job Id</th>
                                <th scope="col">Quo No</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Quo Amt</th>
                                <th scope="col">Status</th>
                                <th scope="col">Callback date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerDetails
                                .filter(item => {
                                    const workStatus = item.amc_quo_work_status ? item.amc_quo_work_status.toLowerCase() : '';
                                    const customerName = item.amc_quo_cu_name ? item.amc_quo_cu_name.toLowerCase() : '';
                                    const followUpDate = item.amc_call_back_date ? item.amc_call_back_date : '';
                                    return (
                                        workStatus.includes(searchValue.toLowerCase()) ||
                                        customerName.includes(searchValue.toLowerCase()) ||
                                        followUpDate.includes(searchValue)
                                    );
                                })
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>                                     
                                        <td>{item.amc_quo_date}</td>
                                        <td>{item.amc_quo_cu_name}</td>
                                        <td>{item.amc_cu_unique_id}</td>
                                        <td>{item.amc_quo_cu_quono}</td>
                                        <td>{item.amc_quo_start_date}</td>
                                        <td>{item.amc_quo_end_date}</td>
                                        <td>{item.amc_quo_quo_amount}</td>                                       
                                        {/* <td>{item.amc_quo_work_status}</td> */}
                                        {/* <td>{item.amc_call_back_date}</td> */}

                                        <td><select className="form-select border border-dark form-control a1"
                                        style={{ boxShadow: 'none', width:"170px" }}
                                        aria-label="Default select example"
                                        name='amcWorkStatus'
                                        id='amcWorkStatus'
                                        value={statusByItemId[item.amc_quo_id] || ''} // Set the value of the select element to the state variable
                                        onChange={(e) => handleStatusChange(item.amc_quo_id, e.target.value)} // Update the state when the user changes the value
                                    >
                                        <option value="Site Visit" style={{ background: '#dddddd', color: "blue" }}>Site Visit</option>
                                        <option value="Site Under Construction" style={{ background: '#dddddd', color: "red" }}>Site Under Construction</option>
                                        <option value="Take Follow up" style={{ background: '#dddddd', color: "green" }}>Take Follow up</option>
                                        <option value="Short Listed" style={{ background: '#dddddd', color: "purple" }}>Short Listed</option>
                                        <option value="Fix Meeting" style={{ background: '#dddddd', color: "orange" }}>Fix Meeting</option>
                                        <option value="Finalised" style={{ background: '#dddddd', color: "brown" }}>Finalised</option>
                                        <option value="Cancelled" style={{ background: '#dddddd', color: "yellow" }}>Cancelled</option>
                                    </select></td>

                                        <td><input
                                        type="date"
                                        className="form-control a1"
                                        id={`quoDate-${item.amc_quo_id}`}
                                        name="quoDate"
                                        value={selectedDates[item.amc_quo_id] || ""}
                                        onChange={(e) => handleDateChange(item.amc_quo_id, e.target.value)}
                                    /></td>
                                    </tr>
                                ))}
                        </tbody>

                    </Table>


                </form>

            </div>


        </>
    )
}

export default RoughAmcReportMaster