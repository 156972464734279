import React ,{ useState } from 'react'
import './ResetPassword.css';
import { useLocation, useNavigate} from 'react-router-dom';
// import { useParams } from 'react-router-dom';


const ResetPassword = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  // const { email } = useParams();
  console.log("email",email);



  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

 ////// api call////////////////////////
 
  const resetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://liftapp.infysky.in/liftapp_api/api/resetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, newPassword }),
      });

      console.log("response",response);
      if (response.ok) {
        const data = await response.json();
        console.log("data",data);
        setMessage(data.message);
        window.alert(data.message);
        navigate("/");

      } else {
        const data = await response.json();
        setMessage(data.msg);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };


  return (
    <>

      <div className='sect5 '>


        <div className=" border p-5 bg-white rounded ">
          <div className='heading   mb-4' style={{ margin: "0px auto" }}>
            <h1 className='text-primary ' style={{ fontWeight: "700" }}>Reset Password</h1>
          </div>

            {/* Hidden input field for email */}
        

          <form  onSubmit={resetPassword}>

          <input type="hidden" name="email" value={email} />

            <div className="form-group mb-3 " style={{ margin: "0px auto" }}>
              <label htmlFor="password" className='mb-2'>Password</label>
              <input type="password" className="form-control" id="password" style={{ border: '2px solid Gray' }} name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
               />
            </div>
            <div className="form-group mb-4 " style={{ margin: "0px auto" }}>
              <label htmlFor="newPassword" className='mb-2'>Confirm Password</label>
              <input type="password" className="form-control " id="newPassword" style={{ border: '2px solid Gray' }} name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
               />
            </div>



            <div className=' d-grid' style={{ margin: "0px auto" }}>
              <button type="submit" className="btn btn-lg btn-primary ">Submit</button>
            </div>
          </form>
        </div>


      </div>
    </>
  )
}

export default ResetPassword