import * as Yup from "yup";


export const modulationQuotationSchema = Yup.object({

    uniqueId:Yup.string().required("Please enter Site Id."), 
    // customerName:Yup.string().required("Please enter lift type"),
    // mobileNo: Yup.number().integer().positive().required("Please enter mobile number"),
    // liftType: Yup.string().required("Please select lift type"),
    // panNo: Yup.string().required("Please enter PAN no."),
    // gstNo: Yup.string().required("Please enter GST no."),
    termsAndConditions:Yup.string().required("Please select terms and conditions "),
    // stops:Yup.string().required("Please enter stops "),
    perticular:Yup.string().required("Please enter Perticular "),
    // make:Yup.string().required("Please enter Make "),
    quantity:Yup.string().required("Please enter quantity "),
    rate: Yup.number().integer().positive().required("Please enter rate"),
    moduDate: Yup.string().required("Please select date"),
    modWorkStatus: Yup.string().required("Please select status type"),
    // inputEmail: Yup.string().email().required("Please enter email address"),

  });
  