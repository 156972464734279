import * as Yup from "yup";



export const breakdownAddSchema = Yup.object({

     
    // customerName: Yup.string().required("Please enter name"),
    // mobileNo: Yup.number().integer().positive().required("Please enter your mobile number"),
    // siteAddress: Yup.string().required("Please enter site address "),
    // liftType: Yup.string().required("Please enter lift type"),
    // liftTravel: Yup.string().required("Please enter lift travel "),
    // breakStatus:Yup.string().required("Please enter status "),
    // contactPersonName: Yup.string().min(2).max(25).required("Please enter contact Person Name"),
    // contactNo: Yup.number().integer().positive().required("Please enter your Contact number"),
 

  // contactNo: Yup.string()
  // .matches(/^(0\d{10}|[1-9]\d{9})$/, 'Mobile number must be 10 digits')
  // .required('Please enter your mobile number'),
   
    // technicianName: Yup.string().min(2).max(25).required("Please enter Technician Name"),
    // technicianNumber: Yup.number().integer().positive().required("Please enter technician contact number"),
   
    // technicianRemarks:Yup.string().required("Please enter Technician Remarks "),
   
    // technicianNumber: Yup.string()
    // .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
    // .required('Please enter technician contact number'),
    // breakdownDesc: Yup.string().required("Please enter breakdown description "),
    quoDate: Yup.string().required("Please select date"),
    // uniqueId: Yup.string().required("Please enter Site Id"),

    
  });