import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { enquiryAddSchema } from '../Schema/EnquiryAddIndex';

const initialValues = {
    quoDate: "",
    customerName: "",
    mobileNo: "",
    liftType: "",
    liftTravel: "",
    workingStatus: "",
    preAddress: "",
    typeOfEnquiry: "",
    shaftDetails: "",

};

const EnquiryAdd = () => {


    const navigate = useNavigate();


    const [customerName, setCustomerName] = useState("");
    const [quoDate, setQuoDate] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [liftType, setLiftType] = useState("");
    const [liftTravel, setLiftTravel] = useState("");
    const [preAddress, setPreAddress] = useState("");
    const [typeOfEnquiry, setTypeOfEnquiry] = useState("");
    const [workingStatus, setWorkingStatus] = useState("");
    const [shaftDetails, setShaftDetails] = useState("");
    const [manuallyTypingInput, setManuallyTypingInput] = useState('');
    const [liftmanuallyTyping, setLiftmanuallyTyping] = useState("");
    const [typingEnquiryInput, setTypingEnquiryInput] = useState('');



    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,

            validationSchema: enquiryAddSchema,
            onSubmit: async (values, action) => {
                console.log("values:", values);
                // action.resetForm();
                await addCustomer()
                navigate("/app/enquiry")
            },
        });

    const handleCustomerName = (event) => {
        // console.log("On Change");

        setCustomerName(event.target.value);
        handleChange(event);
    }

    const handleQuoDate = (event) => {
        // console.log("On Change");

        setQuoDate(event.target.value);
        handleChange(event);
    }

    const handleMobileNo = (event) => {
        // console.log("On Change");

        setMobileNo(event.target.value);
        handleChange(event);
    }

    const handleLiftType = (event) => {
        // console.log("On Change");

        setLiftType(event.target.value);
        handleChange(event);
    }


    const handleLiftTravel = (event) => {
        // console.log("On Change");

        setLiftTravel(event.target.value);
        handleChange(event);
    }


    const handlePreAddress = (event) => {
        // console.log("On Change");

        setPreAddress(event.target.value);
        handleChange(event);
    }

    const handleWorkingStatus = (event) => {
        // console.log("On Change");

        setWorkingStatus(event.target.value);
        handleChange(event);
    }


    const handleShaftDetails = (event) => {
        // console.log("On Change");

        setShaftDetails(event.target.value);
        handleChange(event);
    }

    const handleTypeOfEnquiry = (event) => {
        // console.log("On Change");

        setTypeOfEnquiry(event.target.value);
        handleChange(event);
    }

    const handleManuallyTypingInput = (e) => {
        const newValue = e.target.value;
        setManuallyTypingInput(newValue);
    };

    

    const handleLiftManuallyTyping = (e) => {
        const newValue = e.target.value;
        setLiftmanuallyTyping(newValue);
    };


    const handleEnquiryManuallyTyping = (e) => {
        const newValue = e.target.value;
        setTypingEnquiryInput(newValue);
    };

    /////////////////////////////////// api call //////////////////////////

    async function addCustomer() {
        // console.log(name,file,price,description)
        const formData = new FormData();

        formData.append("customerName", customerName)
        formData.append("quoDate", quoDate)
        formData.append("mobileNo", mobileNo)
        formData.append("workingStatus", workingStatus)
        if (workingStatus === 'Manually Typing') {
            formData.append("manuallyTypingInput", manuallyTypingInput);
        }
        formData.append("liftType", liftType)
        if (liftType === 'Manually Typing') {
            formData.append("liftmanuallyTyping",liftmanuallyTyping);
        }

        formData.append("liftTravel", liftTravel)
        formData.append("preAddress", preAddress)
        formData.append("shaftDetails", shaftDetails)
        formData.append("typeOfEnquiry", typeOfEnquiry)
        if (typeOfEnquiry === 'Manually Typing') {
            formData.append("typingEnquiryInput",typingEnquiryInput);
        }


        try {
            let response = await fetch('http://liftapp.infysky.in/liftapp_api/api/store_lift_enquiry_data', {
                method: 'POST',
                body: formData
            });

            let data = await response.json(); // Parse the response body as JSON

            console.log("data", data);

            if (data.msg) {
                // If the response status is within the 2xx range, it means the request was successful.
                alert(data.msg);


            } else if (data.message) {

                navigate("/app/enquiry_add")
                alert(data.message);

            } else {
                alert("An error occurred while saving the data.");
            }


        } catch (error) {
            console.error('Error while adding customer:', error);
            alert("An error occurred while saving the data2.");

        }
    }




    return (
        <>
            <div className='container'>

                <form onSubmit={handleSubmit} >
                    <div className="row mb-1 mt-5">
                        <div className="col-md-9 ">
                            <div className="form-group">
                                <h4 className='mt-4'>Enquiry</h4>
                            </div>
                        </div>

                        <div className="col-md-3 ">
                            <div className="form-group">
                                <label htmlFor="quoDate" className='' style={{ fontSize: '12px' }}>Date</label>
                                <input type="date" name='quoDate' className="form-control a1" id="quoDate"
                                    value={quoDate}
                                    onChange={handleQuoDate}
                                    onBlur={handleBlur}
                                />
                                {touched.quoDate && errors.quoDate ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.quoDate}</p>
                                ) : null}

                            </div>
                        </div>

                    </div>

                    <hr />

                    <div className="row mb-1">


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="customerName" className='' style={{ fontSize: '12px' }}>Customer Name</label>
                                <input type="text" name='customerName' className="form-control a1" id="customerName"
                                    value={customerName}
                                    onChange={handleCustomerName}
                                    onBlur={handleBlur}
                                />
                                {touched.customerName && errors.customerName ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.customerName}</p>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='' style={{ fontSize: '12px' }}>Mobile No</label>
                                <input type="number" name='mobileNo' maxLength={10} className="form-control a1" id="mobileNo"
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    onBlur={handleBlur}
                                />
                                {touched.mobileNo && errors.mobileNo ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.mobileNo}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 ">

                            <div className="form-group">
                                <label htmlFor="liftType" className='name_label door_type' style={{ fontSize: '12px' }}>Lift type:*</label>
                                <div className="dropdown-select">
                                    <select
                                        name="liftType" className="form-control a1" id="liftType"
                                        value={liftType}
                                        onChange={handleLiftType}
                                        onBlur={handleBlur}
                                    >

                                        <option value="" style={{ display: 'none' }}></option>
                                        <option value="I M P Door" style={{ background: '#eaeaea' }}>I M P Door</option>
                                        <option value="Swing Door" style={{ background: '#eaeaea' }}>Swing Door</option>
                                        <option value="Automatic Door MR" style={{ background: '#eaeaea' }}>Automatic Door MR</option>
                                        <option value="Automatic Door MRL" style={{ background: '#eaeaea' }}>Automatic Door MRL</option>
                                        <option value="Hydrolic Lift" style={{ background: '#eaeaea' }}>Hydrolic Lift</option>
                                        <option value="Good's Lif" style={{ background: '#eaeaea' }}>Good's Lift</option>
                                        <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                                    </select>

                                    {liftType === 'Manually Typing' && (
                                        <input
                                            type="text"
                                            className="form-control a1"
                                            name="liftmanuallyTyping"
                                            id="liftmanuallyTyping"
                                            value={liftmanuallyTyping}
                                            onChange={handleLiftManuallyTyping}
                                            onBlur={handleBlur}
                                        />
                                     )}


                                    <i className="dropdown-icon fas fa-caret-down"></i>
                                </div>
                                {/* {touched.liftType && errors.liftType ? (
                                    <p className="form-error" style={{ color: 'red', fontSize: '12px' }}>{errors.liftType}</p>
                                ) : null} */}
                            </div>
                        </div>


                    </div>


                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="liftTravel" className='' style={{ fontSize: '12px' }}>Lift Travel</label>
                                <input type="text" name='liftTravel' className="form-control a1" id="liftTravel"
                                    value={liftTravel}
                                    onChange={handleLiftTravel}
                                    onBlur={handleBlur}
                                />
                                {/* {touched.liftTravel && errors.liftTravel ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.liftTravel}</p>
                                ) : null} */}
                            </div>
                        </div>
                        <div className='col-md-4'>

                            <label htmlFor="workingStatus " className='name_label door_type' style={{ fontSize: '12px' }} >Enquiry Status:*</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='workingStatus'
                                id='workingStatus'
                                value={workingStatus}
                                onChange={handleWorkingStatus}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                <option value="Pending" style={{ background: '#eaeaea' }}>Pending</option>
                                <option value="Finalised" style={{ background: '#eaeaea' }}>Finalised</option>
                                <option value="Quotation Raised" style={{ background: '#eaeaea' }}>Quotation Raised</option>
                                <option value="Cancelled" style={{ background: '#eaeaea' }}>Cancelled</option>
                                <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                            </select>
                            {workingStatus === 'Manually Typing' && (
                                <input
                                    type="text"
                                    className="form-control a1"
                                    name="manuallyTypingInput"
                                    id="manuallyTypingInput"
                                    value={manuallyTypingInput}
                                    onChange={handleManuallyTypingInput}
                                    onBlur={handleBlur}
                                />
                            )}
                            {/* {touched.workingStatus && errors.workingStatus ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.workingStatus}</p>
                            ) : null} */}

                        </div>

                        <div className='col-md-4'>

                            <label htmlFor="typeOfEnquiry " className='name_label door_type' style={{ fontSize: '12px' }} >Type of Enquiry</label>

                            <select className="form-select border border-dark form-control" style={{ boxShadow: 'none' }} aria-label="Default select example"
                                name='typeOfEnquiry'
                                id='typeOfEnquiry'
                                value={values.typeOfEnquiry}
                                onChange={handleTypeOfEnquiry}
                                onBlur={handleBlur}>
                                <option selected style={{ display: 'none' }}></option>
                                <option value="New Lift" style={{ background: '#eaeaea' }}>New Lift</option>
                                <option value="AMC" style={{ background: '#eaeaea' }}>AMC</option>
                                <option value="Modulation" style={{ background: '#eaeaea' }}>Modulation</option>
                                <option value="Call Base" style={{ background: '#eaeaea' }}>Call Base</option>
                                <option value="Manually Typing" style={{ background: '#eaeaea' }}>Manually Typing</option>
                            </select>

                            {typeOfEnquiry === 'Manually Typing' && (
                                <input
                                    type="text"
                                    className="form-control a1"
                                    name="typingEnquiryInput"
                                    id="typingEnquiryInput"
                                    value={typingEnquiryInput}
                                    onChange={handleEnquiryManuallyTyping}
                                    onBlur={handleBlur}
                                />
                            )}

                            {touched.typeOfEnquiry && errors.typeOfEnquiry ? (
                                <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.typeOfEnquiry}</p>
                            ) : null}

                        </div>
                    </div>


                    <div className="row mb-1">

                        <div className="col-md-4 ">
                            <div className="form-group">
                                <label htmlFor="shaftDetails" className='' style={{ fontSize: '12px' }}>Shaft Details</label>
                                <input type="text" name='shaftDetails' className="form-control a1" id="shaftDetails"
                                    value={values.shaftDetails}
                                    onChange={handleShaftDetails}
                                    onBlur={handleBlur}
                                />
                                {/* {touched.shaftDetails && errors.shaftDetails ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.shaftDetails}</p>
                                ) : null} */}
                            </div>
                        </div>
                    </div>



                    <div className="row mb-5">
                        <div className="col-12 ">
                            <div className="form-group">
                                <label htmlFor="preAddress" className='name_label address' style={{ fontSize: '12px' }} >Address</label>
                                <textarea className="form-control a1 address_input" name='preAddress' id="preAddress" rows="5"
                                    value={values.preAddress}
                                    onChange={handlePreAddress}
                                    onBlur={handleBlur}
                                ></textarea>

                                {touched.preAddress && errors.preAddress ? (
                                    <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.preAddress}</p>
                                ) : null}

                            </div>
                        </div>

                    </div>

                    <div className=' d-flex justify-content-end w-50 float-end mt-4'>

                        <button className="btn btn-primary btn-lg float-right " type="submit">Save</button>

                    </div>

                </form>

            </div>

        </>
    )
}

export default EnquiryAdd