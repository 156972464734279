import React, { useState } from 'react'
import { useNavigate, } from "react-router-dom";
import { useFormik } from "formik";
import { Table } from 'react-bootstrap';
import { customerReportSchema } from '../Schema/CustomerReportIndex';



const initialValues = {
  startDate: "",
  endDate: "",
  customerName: "",

};

const CustomerReportMaster = () => {

  const navigate = useNavigate();

  const [customerDetails, setCustomerDetails] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [statusByItemId, setStatusByItemId] = useState({});


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,

      validationSchema: customerReportSchema,
      onSubmit: (values, action) => {
        console.log("values:", values);
        action.resetForm();
        // navigate("/app/breakdown")

      },
    });


  //////////////////////////////////////////////////////////////////////////

  const openPrint = () => {

    navigate("/app/customer_report_print", {

      state: { customerDetails, searchValue },
    });
  }

  ////////////////////// To select an Status //////////////////////////

  async function updateStatusInAPI(cu_id, newStatus) {
    try {
      const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/updateCustomerStatus", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cu_id: cu_id,
          newStatus: newStatus,
        }),
      });

      if (!response.ok) {
        // Handle non-successful responses (e.g., server error)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Handle the response from the server (e.g., show a success message)
      console.log(data.message);

      // Return the updated status or response data if needed
      return data.status; // You may replace 'status' with the actual property name from your API response
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(error);

      // Return null or an error indicator if needed
      return null;
    }
  }


  //////////////////////////////////Select an Status/////////////////////////////////////////////////

  // Usage within your component
  const handleStatusChange = async (cu_id, newStatus) => {
    // Update the status in the state immediately for a smooth user experience

    console.log("Item ID:", cu_id);
    console.log("New Status:", newStatus);

    setStatusByItemId((prevStatus) => ({
      ...prevStatus,
      [cu_id]: newStatus,
    }));

    // Make the API call to update the status in the backend
    const updatedStatus = await updateStatusInAPI(cu_id, newStatus);

    if (updatedStatus !== null) {
      setCustomerDetails((prevCustomerDetails) =>
        prevCustomerDetails.map((item) =>
          item.cu_id === cu_id ? { ...item, cu_lift_type: updatedStatus } : item
        )
      );
      // fetchDataFromAPI();

    } else {
      // Handle errors, e.g., revert the state to the previous value
      setStatusByItemId((prevStatus) => ({
        ...prevStatus,
        [cu_id]: prevStatus[cu_id], // Revert to the previous status
      }));

    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////



  /////////////////////////////// Api Call////////////////////////////////


  const handleSearchClick = async (event) => {
    event.preventDefault();

    const startDate = values.startDate;
    const endDate = values.endDate;

    try {
      let response;

      // response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_report?startDate=${startDate}&endDate=${endDate}`);
      if (startDate && endDate) {
        response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_report?startDate=${startDate}&endDate=${endDate}`);
      } else {
        // If startDate and endDate are not present, make the API request without them
        response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_customer_report`);
      }


      if (response.ok) {
        const data = await response.json();
        console.log("Enquiry report details:", data);

        // Apply search filtering to the data
        // const filteredData = data
        //   .filter(item =>
        //     item.cu_lift_type.toLowerCase().includes(searchValue.toLowerCase()) ||
        //     item.cu_name.toLowerCase().includes(searchValue.toLowerCase())
        //   );

        const filteredData = data.filter((item) => {
          const workStatus = item.cu_lift_type ? item.cu_lift_type.toLowerCase() : '';
          const customerName = item.cu_name ? item.cu_name.toLowerCase() : '';
          const customerId = item.cu_unique_id ? item.cu_unique_id : '';

          return (
            workStatus.includes(searchValue.toLowerCase()) ||
            customerName.includes(searchValue.toLowerCase()) ||
            customerId.includes(searchValue)
          );
        });

        if (filteredData.length === 0) {
          // No data found, display a message to the user
          console.log("No data found.");
          window.alert("Please select Start date and End date.");
          // You can update the state or display a message to the user here.
        } else {
          setCustomerDetails(filteredData);

          const statusData = filteredData.reduce((acc, item) => {
            acc[item.cu_id] = item.cu_lift_type;
            return acc;
          }, {});
          setStatusByItemId(statusData);
        }
      } else {
        console.error('Error Enquiry report details:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching Enquiry report details:', error);
    }
  };

  return (
    <>

      <div className='container'>

        <form onSubmit={handleSubmit}>



          <div className="row ">
            <div className='col-md-10 mt-4 mt-md-0'>
              <h5>Customer Report</h5>
            </div>


            <div className='col-md-2'>

              <input
                type="text"
                className="form-control"
                style={{ borderColor: 'black' }}
                placeholder="Search by Customer ID or Name"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />

            </div>

          </div>

          <hr />


          <div className="d-md-flex justify-content-between">

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="startDate" className='' style={{ fontSize: '12px' }}>Start Date</label>
                <input type="date" className="form-control a1" id="startDate" name='startDate'
                  value={values.startDate}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                {touched.startDate && errors.startDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.startDate}</p>
                ) : null}
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="form-group">
                <label htmlFor="endDate" className='' style={{ fontSize: '12px' }}>End Date</label>
                <input type="date" className="form-control a1" id="endDate" name='endDate'
                  value={values.endDate}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                {touched.endDate && errors.endDate ? (
                  <p className='form-error' style={{ color: 'red', fontSize: '12px' }}>{errors.endDate}</p>
                ) : null}
              </div>
            </div>


            <div className="">
              <div className="form-group  mt-4">
                <button type='submit' className='btn btn-primary' onClick={(event) => { handleSearchClick(event); setSearchValue(''); }}>Search</button>
              </div>
            </div>



            <div className="">
              <div className="form-group  mt-4">
                <button type='button' onClick={openPrint} className='btn btn-primary '>Print</button>
              </div>
            </div>

          </div>


          <Table className="table table-bordered mt-5">
            <thead>
              <tr>
                <th scope="col">Sl No</th>
                <th scope="col">Job Id</th>
                <th scope="col">Date</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Mobile No.</th>
                <th scope="col">Address</th>
                <th scope="col">Landmark</th>
                <th scope="col">Type of Enquiry</th>
              </tr>
            </thead>
            <tbody>
              {customerDetails
                .filter(item => {
                  const workStatus = item.cu_lift_type ? item.cu_lift_type.toLowerCase() : '';
                  const customerName = item.cu_name ? item.cu_name.toLowerCase() : '';
                  const customerId = item.cu_unique_id ? item.cu_unique_id : '';

                  return (
                    workStatus.includes(searchValue.toLowerCase()) ||
                    customerName.includes(searchValue.toLowerCase()) ||
                    customerId.includes(searchValue)

                  );
                })
                .map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.cu_unique_id}</td>
                    <td>{item.cu_date}</td>
                    <td>{item.cu_name}</td>
                    <td>{item.cu_mobile}</td>
                    <td>{item.cu_address}</td>
                    <td>{item.cu_landmark}</td>
                    {/* <td>{item.cu_lift_type}</td> */}
                    <td><select className="form-select border border-dark form-control a1"
                      style={{ boxShadow: 'none', width: "170px" }}
                      aria-label="Default select example"
                      name='liftStatus'
                      id='liftStatus'
                      value={statusByItemId[item.cu_id] || ''} // Set the value of the select element to the state variable
                      onChange={(e) => handleStatusChange(item.cu_id, e.target.value)} // Update the state when the user changes the value
                    >
                      <option value="New Lift" style={{ background: '#dddddd', color: "blue" }}>New Lift</option>
                      <option value="AMC" style={{ background: '#dddddd', color: "red" }}>AMC</option>
                      <option value="Modulation" style={{ background: '#dddddd', color: "green" }}>Modulation</option>
                      <option value="Call Base" style={{ background: '#dddddd', color: "purple" }}>Call Base</option>
                      
                    </select></td>
                  </tr>
                ))}
            </tbody>

          </Table>


        </form>

      </div>

    </>
  )
}

export default CustomerReportMaster