import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap'
import UpdateIcon from '@mui/icons-material/Update';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../pagination.css';

const NewModulationMaster = () => {

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  const [data, setData] = useState([])
  const [viewData, setViewData] = useState(null);

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [statusByItemId, setStatusByItemId] = useState({});

  function openAdd() {

    navigate("/app/new_modulation_add")
  }

  const handleUpdate = (mod_id) => {
    // Navigate two steps back
    navigate(`/app/update_new_modulation_add/${mod_id}`);
  };

  ////////////////////////// api call to get data /////////////////////////

  // function openView(item) {
  //   // Construct the URL with route parameters
  //   navigate(`/app/new_modulation_view_print/${item.amc_quo_id}`, {
  //     state: { data: item }, // Pass the data as part of the route state
  //   });
  // }

  const openView = async (item) => {
    try {
        const response = await fetch(`http://liftapp.infysky.in/liftapp_api/api/new_fetch_modulation_quotation_data/${item.mod_unique_id}`);
        const result = await response.json();
        setViewData(result); // Set the fetched data to state
        // Navigate to the view page here
        navigate(`/app/new_modulation_view_print/${item.mod_unique_id}`, {
            state: { data: result }, // Pass the fetched data as part of the route state
        });
    } catch (error) {
        console.error('Error:', error);
    }
};


  ////////////////////// To select an Status //////////////////////////

  async function updateStatusInAPI(mod_id, newStatus) {
    try {
      const response = await fetch("http://liftapp.infysky.in/liftapp_api/api/update_new_mod_Status", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mod_id: mod_id,
          newStatus: newStatus,
        }),
      });

      if (!response.ok) {
        // Handle non-successful responses (e.g., server error)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Handle the response from the server (e.g., show a success message)
      console.log(data.message);

      // Return the updated status or response data if needed
      return data.status; // You may replace 'status' with the actual property name from your API response
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(error);

      // Return null or an error indicator if needed
      return null;
    }
  }


  //////////////////////////////////Select an Status/////////////////////////////////////////////////

  // Usage within your component
  const handleStatusChange = async (mod_id, newStatus) => {
    // Update the status in the state immediately for a smooth user experience
    setStatusByItemId((prevStatus) => ({
      ...prevStatus,
      [mod_id]: newStatus,
    }));

    // Make the API call to update the status in the backend
    const updatedStatus = await updateStatusInAPI(mod_id, newStatus);

    if (updatedStatus !== null) {
      // Handle the updated status if needed
    } else {
      // Handle errors, e.g., revert the state to the previous value
      setStatusByItemId((prevStatus) => ({
        ...prevStatus,
        [mod_id]: prevStatus[mod_id], // Revert to the previous status
      }));
    }
  };



  /////////////////////////////////// Delete api call////////////////////////////////

  async function deleteOperation(id) {
    // alert(id)
    const confirmDelete = window.confirm('Do you want delete?');
    if (confirmDelete) {
      let result = await fetch("http://liftapp.infysky.in/liftapp_api/api/delete_newmod/" + id, {
        method: 'DELETE'
      });
      result = await result.json();
      // console.log(result)
      if (result.message) {
        // If the response status is within the 2xx range, it means the request was successful.
        if (result.message) {
          // alert(result.message);
          alert('Deletion confirmed. Deleting...');
        } else {
          alert('Deletion cancelled.');
        }

      } else {
        alert("Customer not found.");
      }

      getData();

    }
  }

  /////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {

    getData()

  }, [currentPage])


  function getData() {

    async function fetchData() {
      try {
        let result = await fetch(`http://liftapp.infysky.in/liftapp_api/api/get_new_modu_list?page=${currentPage}&perPage=${itemsPerPage}`);
        const { data: fetchedData, last_page: total } = await result.json();
        // console.log("data", result);

        const statusData = {};
        fetchedData.forEach((item) => {
          statusData[item.mod_id] = item.mod_working_status; // Replace 'li_quo_status' with the actual property name from your API response
        });

        setData(fetchedData);
        setStatusByItemId(statusData);
        setTotalPages(total);
      } catch (error) {
        console.error('Error', error);
      }
    }

    fetchData();

  }

  // Filtering logic
  // const filteredData = data.filter(item => {
  //   const cuIdString = item.mod_unique_id.toString();
  //   const lowercaseSearch = searchValue.toLowerCase();
  //   return cuIdString.includes(lowercaseSearch) || item.mod_cu_name.toLowerCase().includes(lowercaseSearch);
  // });


  const filteredData = data.filter(item => {
    const cuIdString = item.mod_unique_id.toString();
    const lowercaseSearch = searchValue.toLowerCase();
    const status = item.mod_working_status.toLowerCase(); // Assuming the status field exists
    const date = item.mod_date.toLowerCase();

    return cuIdString.includes(lowercaseSearch) ||
      item.mod_cu_name.toLowerCase().includes(lowercaseSearch) ||
      status.includes(lowercaseSearch) ||
      date.includes(lowercaseSearch);
  });


  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Calculate the range of page numbers to display in the pagination UI
  const getPageRange = () => {
    const rangeSize = 5; // Number of page numbers to display
    const totalPagesInRange = Math.min(rangeSize, totalPages);
    let startPage = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
    let endPage = startPage + totalPagesInRange - 1;

    // Adjust endPage if it exceeds totalPages
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - totalPagesInRange + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };



  return (
    <>
      <div className='conatiner'>

        <div className="row ">
          <div className='col-md-10 mt-4 mt-md-0'>
            <h5>Final Modulation Quotation Details</h5>
          </div>

          <div className='col-md-2'>

            <input class="form-control" style={{ borderColor: 'black' }} type="search" placeholder="Search" aria-label="Search" value={searchValue}
              onChange={e => setSearchValue(e.target.value)} />

          </div>

        </div>

        <hr />

        <button onClick={openAdd} className="btn btn-primary buttonMargin">+Add Lift</button>

        <Table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Job Id</th>
              <th scope="col">Mod quo No</th>
              <th scope="col">Date</th>
              <th scope="col">Client Name</th>
              <th scope="col">Mobile No.</th>
              <th scope="col">Lift type</th>
              <th scope="col">Mod Status</th>
              <th scope="col">Quo Amt</th>
              <th scope="col">Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {

              filteredData.map((item) =>
                <tr key={item.mod_id}>
                  <td>{item.mod_id}</td>
                  <td>{item.mod_unique_id}</td>
                  <td>{item.mod_final_quo_no}</td>
                  <td>{item.mod_date}</td>
                  <td>{item.mod_cu_name}</td>
                  <td>{item.mod_mob_no}</td>
                  <td>{item.mod_lift_type}</td>
                  <td><select className="form-select border border-dark form-control a1"
                    style={{ boxShadow: 'none', width: "170px" }}
                    aria-label="Default select example"
                    name='liftStatus'
                    id='liftStatus'
                    value={statusByItemId[item.mod_id] || ''} // Set the value of the select element to the state variable
                    onChange={(e) => handleStatusChange(item.mod_id, e.target.value)} // Update the state when the user changes the value
                  >
                    <option value="Ongoing" style={{ background: '#dddddd', color: "blue" }}>Ongoing</option>
                    <option value="Pending" style={{ background: '#dddddd', color: "red" }}>Pending</option>
                    <option value="Completed" style={{ background: '#dddddd', color: "green" }}>Completed</option>


                  </select></td>
                  <td>{item.mod_final_total_amt}</td>

                  <td>
                    <button
                      type='button'
                      className='btn btn-primary mx-3'
                      onClick={() => openView(item)} // Pass the item data to the openView function
                    >
                      View
                    </button>
                  </td>
                 
                  {/* <td><Link to={"/app/update_new_modulation_add"}><span className='update'>{<UpdateIcon />}</span></Link><span onClick={() => { deleteOperation() }} >{<DeleteIcon style={{ color: 'red' }} />}</span></td> */}
                  <td><span className='update' onClick={() => handleUpdate(item.mod_id)}>{<UpdateIcon />}</span><span onClick={() => { deleteOperation(item.mod_unique_id) }} >{<DeleteIcon style={{ color: 'red' }} />}</span></td>
                </tr>
              )
            }
          </tbody>
        </Table>

        {/* Pagination */}
        <div className='pagination'>
          {/* Show previous arrow icon */}
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>&laquo; Prev</button>
          )}

          {/* Show page numbers */}
          {getPageRange().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}

          {/* Show next arrow icon */}
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
          )}

        </div>

      </div>
    </>
  )
}

export default NewModulationMaster